import { LoadingStates } from '../../../Shared/Models/LoadingStates';
import { ActionTypes, IDefaultSearchAction } from './DefaultSearch.action-types';

export interface IDefaultSearchState {
  trendingSearchResults: [];
  trendingSearchError: string;
  trendingSearchLoading: LoadingStates;

  frequentedContactsResults: [];
  frequentedContactsError: string;
  frequentedContactsLoading: LoadingStates;
}

export const initialDefaultSearchState: IDefaultSearchState = {
  trendingSearchResults: [],
  trendingSearchError: '',
  trendingSearchLoading: LoadingStates.NOT_STARTED,

  frequentedContactsResults: [],
  frequentedContactsError: '',
  frequentedContactsLoading: LoadingStates.NOT_STARTED,
};

// Three actions are enough i.e request, success and failed. recieved is the same as success so is not needed.
export function defaultSearchReducer(
  prev: IDefaultSearchState = initialDefaultSearchState,
  action: IDefaultSearchAction
): IDefaultSearchState {
  switch (action.type) {
    case ActionTypes.GET_TRENDING_SEARCH: {
      return {
        ...prev,
        trendingSearchLoading: LoadingStates.STARTED,
      };
    }

    case ActionTypes.GET_TRENDING_SEARCH_SUCCESS: {
      return {
        ...prev,
        trendingSearchLoading: LoadingStates.SUCCEEDED,
        trendingSearchResults: action.data,
      };
    }
    case ActionTypes.GET_TRENDING_SEARCH_FAILED: {
      return {
        ...prev,
        trendingSearchLoading: LoadingStates.FAILED,
        trendingSearchError: action.error,
      };
    }
    case ActionTypes.GET_FREQUENTED_CONTACTS: {
      return {
        ...prev,
        frequentedContactsLoading: LoadingStates.STARTED,
      };
    }

    case ActionTypes.GET_FREQUENTED_CONTACTS_SUCCESS: {
      return {
        ...prev,
        frequentedContactsLoading: LoadingStates.SUCCEEDED,
        frequentedContactsResults: action.data,
      };
    }
    case ActionTypes.GET_FREQUENTED_CONTACTS_FAILED: {
      return {
        ...prev,
        frequentedContactsLoading: LoadingStates.FAILED,
        frequentedContactsError: action.error,
      };
    }

    default:
      return prev;
  }
}
