import * as React from 'react';
import { barStyle, CardActionButton, closeIcon, messageText } from './ErrorBar.styled';

interface Props {
  onPress: () => void;
}
/* eslint-disable  @typescript-eslint/explicit-function-return-type */
export function ErrorBar(props: Props): React.ReactElement {
  const errorMessage = 'We cannot bring the building information';
  return (
    <div style={barStyle}>
      <div style={closeIcon}>
        <CardActionButton iconName="ErrorBadge" onClick={() => props.onPress()} />
      </div>
      <p style={messageText}>{errorMessage}</p>
    </div>
  );
}
