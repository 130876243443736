import { Usage, Capability, EventName, SubCapability } from '../../Shared/Types';
import * as React from 'react';
import { Announced } from 'office-ui-fabric-react/lib/Announced';
import { Catalog } from './Catalog';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { ICard } from './Shared/Models/ICard';
import { ICatalogItem } from './Shared/Models/ICatalogItem';
import { useEffect, useContext, useState } from 'react';
import { requestCatalogItemsAction, resetAction } from './Dashboard.actions';
import { LoadingStates } from '../../Shared/Models/LoadingStates';
import { LoadingScreen } from '../../Shared/Components/LoadingScreen';
import { EmptyDashboardScreen } from './EmptyDashboardScreen';
import {
  cardsSelector,
  catalogItemsLoadingStatusSelector,
  isCatalogOpenSelector,
  catalogItemsSelector,
} from './Dashboard.selectors';
import { Grid } from './Grid';
import { differenceWith, isEqual, map, filter } from 'lodash';
import { usePrevious } from '../../Shared/Hooks/UsePrevious';
import { usePageTracker } from '../../Shared/Hooks/usePageTracker';
import {
  getOofConfig,
  getOofPanelToggleStatus,
  getOOFStatusIsVisible,
} from '../../Shared/Components/OOF/OOF.selectors';
import { OOF } from '../../Shared/Components/OOF';
import { OOFStatus } from '../../Shared/Components/OOF/OOFStatus';
import { OOFConfig } from '../../Shared/Components/OOF/OOF.types';
export function Dashboard(): React.ReactElement {
  const { dispatch, useSelector } = useContext(ReduxContext);
  const cards: ICard[] = useSelector(cardsSelector);
  const catalogItemsLoadingStatus: LoadingStates = useSelector(catalogItemsLoadingStatusSelector);
  const catalogItems: ICatalogItem[] = useSelector(catalogItemsSelector);
  const isCatalogOpen: boolean = useSelector(isCatalogOpenSelector);
  const isOOFOpen: boolean = useSelector(getOofPanelToggleStatus);
  const isOOFStatusOpen: boolean = useSelector(getOOFStatusIsVisible);
  const oofConfig: OOFConfig = useSelector(getOofConfig);
  const prevCards = usePrevious<ICard[]>(cards);
  const [message, setMessage] = useState('');
  const pageEvent: Usage = {
    capability: Capability.MyHub,
    subCapability: SubCapability.Dashboard,
    eventName: EventName.PAGE_LOAD,
  };
  usePageTracker(pageEvent);
  useEffect(() => {
    dispatch(resetAction());
    dispatch(requestCatalogItemsAction());
  }, [dispatch]);

  const getAddedCardsMessage = (): string => {
    const newCards = differenceWith(cards, prevCards, isEqual);
    const addedCardIDs = map(newCards, 'id');
    const addedCardData = filter(catalogItems, (data: ICatalogItem) => addedCardIDs.includes(data.id));
    const addedCardTitles = map(addedCardData, 'title');
    return addedCardIDs.length > 1
      ? `${addedCardTitles.slice(0, -1).join(', ')} and ${addedCardTitles.slice(-1)} cards added`
      : `${addedCardTitles[0]} card added`;
  };

  useEffect(() => {
    if (prevCards !== undefined && prevCards.length > cards.length) {
      const removedCardID = prevCards.find((data) => !cards.includes(data)).id;
      setMessage(catalogItems.find((data) => data.id === removedCardID).title + ' card deleted');
    } else if (prevCards !== undefined && prevCards.length < cards.length) {
      setMessage(getAddedCardsMessage());
    } else {
      setMessage('');
    }
  }, [cards, getAddedCardsMessage]);

  if (catalogItemsLoadingStatus !== LoadingStates.SUCCEEDED) {
    return <LoadingScreen />;
  } else if (cards.length === 0) {
    return (
      <>
        <Announced message={message} aria-live="assertive" />
        <EmptyDashboardScreen />
        {isCatalogOpen && <Catalog />}
      </>
    );
  } else {
    return (
      <>
        <Announced message={message} aria-live="assertive" />
        <Grid cards={cards} />
        {isCatalogOpen && <Catalog />}
        {/*START : OOF Component  */}
        {isOOFOpen && <OOF oofOptions={oofConfig} />}
        {isOOFStatusOpen && <OOFStatus />}
        {/* END : OOF Component */}
      </>
    );
  }
}
