import { IBookmarkAction, ActionTypes } from './Bookmarks.action-types';
import { IBookmarkState } from './Bookmarks.types';
import { LoadingStates } from '../../../../Shared/Models/LoadingStates';

export const initialBookmarkState: IBookmarkState = {
  BookmarkLoadingStatus: LoadingStates.NOT_STARTED,
  Bookmarks: [],
};

export function bookmarkReducer(prev: IBookmarkState = initialBookmarkState, action: IBookmarkAction): IBookmarkState {
  switch (action.type) {
    case ActionTypes.ADD_BOOKMARK_SUCCEEDED: {
      return {
        ...prev,
        BookmarkLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }
    case ActionTypes.ADD_BOOKMARK_FAILED: {
      return {
        ...prev,
        BookmarkLoadingStatus: LoadingStates.FAILED,
      };
    }
    case ActionTypes.REMOVE_BOOKMARK_SUCCEEDED: {
      return {
        ...prev,
        BookmarkLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }
    case ActionTypes.REMOVE_BOOKMARK_FAILED: {
      return {
        ...prev,
        BookmarkLoadingStatus: LoadingStates.FAILED,
      };
    }
    case ActionTypes.RECEIVE_BOOKMARKS_DATA_FAILED: {
      return {
        ...prev,
        BookmarkLoadingStatus: LoadingStates.FAILED,
      };
    }
    case ActionTypes.RECEIVE_BOOKMARKS_DATA_SUCCEEDED: {
      return {
        ...prev,
        Bookmarks: action.bookmarks,
        BookmarkLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }
    case ActionTypes.REQUEST_BOOKMARKS_DATA: {
      return {
        ...prev,
        BookmarkLoadingStatus: LoadingStates.STARTED,
      };
    }
    default:
      return prev;
  }
}
