export enum ActionTypes {
  RESET = 'RESET',
  SUBMIT_NOTIFICATION = 'SUBMIT_NOTIFICATION',
  SUBMIT_NOTIFICATION_SUCCESS = 'SUBMIT_NOTIFICATION_SUCCESS',
  SUBMIT_NOTIFICATION_FAILED = 'SUBMIT_NOTIFICATION_FAILED',
  CHECK_USER_SG_MEMBERSHIP = 'CHECK_USER_SG_MEMBERSHIP',
  CHECK_USER_SG_MEMBERSHIP_SUCCESS = 'CHECK_USER_SG_MEMBERSHIP_SUCCESS',
  CHECK_USER_SG_MEMBERSHIP_FAILED = 'CHECK_USER_SG_MEMBERSHIP_FAILED',
  RECEIVE_SG_OR_USERS_DATA = 'RECEIVE_SG_OR_USERS_DATA',
  RECEIVE_SG_OR_USERS_DATA_SUCCESS = 'RECEIVE_SG_OR_USERS_DATA_SUCCESS',
  RECEIVE_SG_OR_USERS_DATA_FAILED = 'RECEIVE_SG_OR_USERS_DATA_FAILED',
  RESET_SUBMIT_NOTIFICATION_STATUS = 'RESET_SUBMIT_NOTIFICATION_STATUS',
  COUNTRY_CODE_DATA = 'COUNTRY_CODE_DATA',
  COUNTRY_CODE_DATA_SUCCESS = 'COUNTRY_CODE_DATA_SUCCESS',
  COUNTRY_CODE_DATA_FAILED = 'COUNTRY_CODE_DATA_FAILED',
  COMPANY_CODE_DATA = 'COMPANY_CODE_DATA',
  COMPANY_CODE_DATA_SUCCESS = 'COMPANY_CODE_DATA_SUCCESS',
  COMPANY_CODE_DATA_FAILED = 'COMPANY_CODE_DATA_FAILED',
}

import { IDropdownOption } from 'office-ui-fabric-react';
import { SGMembershipInfo } from './Admin.resources';
import { NotificationPayload, SgUserDataSearchModel } from './Admin.types';

export interface IReset {
  type: ActionTypes.RESET;
}
export interface IResetSubmitNotificationStatus {
  type: ActionTypes.RESET_SUBMIT_NOTIFICATION_STATUS;
}

export interface ISubmitNotification {
  type: ActionTypes.SUBMIT_NOTIFICATION;
  payload: NotificationPayload;
}

export interface ISubmitNotificationSuccess {
  type: ActionTypes.SUBMIT_NOTIFICATION_SUCCESS;
}

export interface ISubmitNotificationFailure {
  type: ActionTypes.SUBMIT_NOTIFICATION_FAILED;
  error: {};
}

export interface ICheckUserSGMembership {
  type: ActionTypes.CHECK_USER_SG_MEMBERSHIP;
  securityGroupId: string;
}

export interface IReceiveUserSGMembership {
  type: ActionTypes.CHECK_USER_SG_MEMBERSHIP_SUCCESS;
  result: SGMembershipInfo;
  securityGroupId: string;
}

export interface IReceiveUserSGMembershipFailed {
  type: ActionTypes.CHECK_USER_SG_MEMBERSHIP_FAILED;
  error: {};
}

export interface IReceiveSGOrUsersData {
  type: ActionTypes.RECEIVE_SG_OR_USERS_DATA;
  payload: { searchText: string; searchCategory: string };
}

export interface IReceiveSGOrUsersDataSuccess {
  type: ActionTypes.RECEIVE_SG_OR_USERS_DATA_SUCCESS;
  result: SgUserDataSearchModel;
}

export interface IReceiveSGOrUsersDataFailed {
  type: ActionTypes.RECEIVE_SG_OR_USERS_DATA_FAILED;
  error: {};
}

export interface ICountryCodeData {
  type: ActionTypes.COUNTRY_CODE_DATA;
}
export interface ICountryCodeDataSuccess {
  type: ActionTypes.COUNTRY_CODE_DATA_SUCCESS;
  result: IDropdownOption[];
}

export interface ICountryCodeDataFailed {
  type: ActionTypes.COUNTRY_CODE_DATA_FAILED;
  error: {};
}

export interface ICompanyCodeData {
  type: ActionTypes.COMPANY_CODE_DATA;
}

export interface ICompanyCodeDataSuccess {
  type: ActionTypes.COMPANY_CODE_DATA_SUCCESS;
  result: IDropdownOption[];
}

export interface ICompanyCodeDataFailed {
  type: ActionTypes.COMPANY_CODE_DATA_FAILED;
  error: {};
}

export type IAdminActions =
  | IReset
  | ISubmitNotification
  | ISubmitNotificationSuccess
  | ISubmitNotificationFailure
  | ICheckUserSGMembership
  | IReceiveUserSGMembership
  | IReceiveUserSGMembershipFailed
  | IReceiveSGOrUsersData
  | IReceiveSGOrUsersDataSuccess
  | IReceiveSGOrUsersDataFailed
  | IResetSubmitNotificationStatus
  | ICountryCodeData
  | ICountryCodeDataSuccess
  | ICountryCodeDataFailed
  | ICompanyCodeData
  | ICompanyCodeDataSuccess
  | ICompanyCodeDataFailed;
