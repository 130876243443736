import { NeutralColors } from '@cseo/styles';
import { FontWeights } from '@uifabric/styling';
import { minWidth } from '../../../../Styles/Media';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { SurfaceColors } from '@cseo/styles';
export const Container = styled(DefaultButton)`
  display: flex;
  padding: 12px 0 11px 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: none;
  text-align: left;
  &:active {
    cursor: grabbing;
  }

  &.container-selected {
    border-left: 3px solid ${SurfaceColors.defaultAccent};
    background-color: ${NeutralColors.gray30};
  }

  &:hover {
    background-color: ${NeutralColors.gray20};
  }
`;

export const Heading = styled.div`
  font-weight: ${FontWeights.semibold};
`;

export const Image = styled.img`
  align-self: end;
  margin-left: 16px;
  min-height: 48px;
  min-width: 48px;
  max-height: 48px;
  max-width: 48px;

  ${minWidth.m} {
    margin-left: 32px;
  }

  ${minWidth.l} {
    margin-left: 48px;
    min-height: 100px;
    min-width: 100px;
    max-height: 100px;
    max-width: 100px;
  }
`;

export const SubHeading = styled.div`
  line-height: 20px; /* height is 2x line-height, so two lines will display */
  overflow: hidden; /* prevents extra lines from being visible */
  padding-right: 32px; /* to stop overflow of the text at the right*/
`;
export const TextContainer = styled.div`
  margin-left: 10px;
  align-self: baseline;

  ${minWidth.l} {
    margin-left: 12px;
  }
`;
