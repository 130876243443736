import { IAuthClient } from '@employee-experience/common/lib/Models';
import { actionChannel, all, call, getContext, SimpleEffect, take, takeLatest } from 'redux-saga/effects';
import { IUsePushNotificationSubscription } from '../../Shared/Hooks/usePushNotificationSubscription';
import { ActionTypes as PushNotificationActionTypes } from '../../Shared/PushNotification/PushNotification.action-types';
import { TelemetryService } from '../../Shared/shared';
import { ActionTypes } from './Header.action-types';

/**
 * Worker saga to handle action steps related to logout
 */
export function* logout(action: { payload: IUsePushNotificationSubscription }): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const { unsubscribeUser } = action.payload;
    const authClient: IAuthClient | undefined = yield getContext('authClient');
    const pushNotificationDeregisterActionChannel = yield actionChannel([
      PushNotificationActionTypes.DEREGISTER_NOTIFICATION_SUBSCRIPTION_COMPLETED,
    ]);
    if (unsubscribeUser) yield call(unsubscribeUser);

    // wait till push notification degistration gets completed and then logout
    yield take(pushNotificationDeregisterActionChannel);
    yield call([authClient.logOut().catch()]);
  } catch (error) {
    TelemetryService.trackException(error);
  }
}

/**
 * Watcher for headerSagas
 */
export function* headerSagas(): IterableIterator<{}> {
  yield all([takeLatest(ActionTypes.LOGOUT, logout)]);
}
