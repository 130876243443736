import { IMyHubAppState } from '../../App.types';
import { FooterReducerName } from './Footer.resources';
import { initialFooterState } from './Footer.reducer';
import { createSelector } from 'reselect';
import { IFooterState } from './Footer.types';

export const top3FrequentlyUsedLinksSelector = createSelector(
  (state: IMyHubAppState) => state[FooterReducerName] ?? initialFooterState,
  (footerState: IFooterState) => footerState.frequentlyUsedLinks.slice(0, 3)
);
