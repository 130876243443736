import { createSelector } from 'reselect';
import { IMyHubAppState } from '../../../App.types';
import { initialDefaultSearchState, IDefaultSearchState } from './DefaultSearch.reducer';
import { DefaultSearchReducerName } from './DefaultSearch.resources';

export const getTrendingSearchResults = createSelector(
  (state: IMyHubAppState) => state[DefaultSearchReducerName] ?? initialDefaultSearchState,
  (searchState: IDefaultSearchState) => {
    return searchState.trendingSearchResults;
  }
);

export const getFrequentedContacts = createSelector(
  (state: IMyHubAppState) => state[DefaultSearchReducerName] ?? initialDefaultSearchState,
  (searchState: IDefaultSearchState) => {
    return searchState.frequentedContactsResults;
  }
);
