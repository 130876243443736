import { IDashboardAction, ActionTypes } from './Dashboard.action-types';
import { IDashboardState } from './Dashboard.types';
import { LoadingStates } from '../../Shared/Models/LoadingStates';

export const initialDashboardState: IDashboardState = {
  cards: [],
  catalogItems: [],
  catalogItemsLoadingStatus: LoadingStates.NOT_STARTED,
  isCatalogOpen: false,
};

export function dashboardReducer(
  prev: IDashboardState = initialDashboardState,
  action: IDashboardAction
): IDashboardState {
  switch (action.type) {
    case ActionTypes.ADD_CARDS: {
      return {
        ...prev,
        cards: prev.cards.concat(action.cards),
      };
    }
    case ActionTypes.MOVE_CARD: {
      const updatedCards = [...prev.cards];
      updatedCards.splice(action.toIndex, 0, updatedCards.splice(action.fromIndex, 1)[0]);
      return {
        ...prev,
        cards: updatedCards,
      };
    }
    case ActionTypes.RECEIVE_CATALOG_ITEMS: {
      return {
        ...prev,
        catalogItems: action.items,
        catalogItemsLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }
    case ActionTypes.REMOVE_CARD: {
      return {
        ...prev,
        cards: prev.cards.filter((card) => card.id !== action.id),
      };
    }
    case ActionTypes.REQUEST_CATALOG_ITEMS: {
      return {
        ...prev,
        catalogItemsLoadingStatus: LoadingStates.STARTED,
      };
    }
    case ActionTypes.RESET: {
      return {
        ...prev,
        isCatalogOpen: initialDashboardState.isCatalogOpen,
      };
    }
    case ActionTypes.TOGGLE_CATALOG: {
      return {
        ...prev,
        isCatalogOpen: action.isOpen,
      };
    }
    case ActionTypes.UPDATE_CARD: {
      return {
        ...prev,
        cards: prev.cards.map((card) => (card.id === action.card.id ? action.card : card)),
      };
    }
    default:
      return prev;
  }
}
