import { INotificationsModel } from './Models/INotificationsModel';

export enum ActionTypes {
  RECEIVE_NOTIFICATIONS_DATA_FAILED = 'RECEIVE_NOTIFICATIONS_DATA_FAILED',
  RECEIVE_NOTIFICATIONS_DATA_SUCCEEDED = 'RECEIVE_NOTIFICATIONS_DATA_SUCCEEDED',
  REQUEST_NOTIFICATIONS_DATA = 'REQUEST_NOTIFICATIONS_DATA',
  UPDATE_NOTIFICATIONS_AS_READ = 'UPDATE_NOTIFICATIONS_AS_READ',
  UPDATE_NOTIFICATIONS_AS_READ_SUCCEEDED = 'UPDATE_NOTIFICATIONS_AS_READ_SUCCEEDED',
  UPDATE_NOTIFICATIONS_AS_READ_FAILED = 'UPDATE_NOTIFICATIONS_AS_READ_FAILED',
  DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',
  DELETE_NOTIFICATION_SUCCEEEDED = 'DELETE_NOTIFICATION_SUCCEEEDED',
  DELETE_NOTIFICATION_FAILED = 'DELETE_NOTIFICATION_FAILED',
}

export interface IReceiveNotificationsDataFailedAction {
  type: ActionTypes.RECEIVE_NOTIFICATIONS_DATA_FAILED;
}

export interface IReceiveNotificationsDataSucceededAction {
  type: ActionTypes.RECEIVE_NOTIFICATIONS_DATA_SUCCEEDED;
  Notifications: INotificationsModel[];
}

export interface IRequestNotificationsDataAction {
  type: ActionTypes.REQUEST_NOTIFICATIONS_DATA;
}

export interface IUpdateNotificationsReadAction {
  type: ActionTypes.UPDATE_NOTIFICATIONS_AS_READ;
  payload: INotificationsModel[];
}

export interface IUpdateNotificationsReadSucceededAction {
  type: ActionTypes.UPDATE_NOTIFICATIONS_AS_READ_SUCCEEDED;
}

export interface IUpdateNotificationsReadFailedAction {
  type: ActionTypes.UPDATE_NOTIFICATIONS_AS_READ_FAILED;
}

export interface IDeleteNotificationAction {
  type: ActionTypes.DELETE_NOTIFICATION;
  payload: string;
}

export interface IDeleteNotificationSucceededAction {
  type: ActionTypes.DELETE_NOTIFICATION_SUCCEEEDED;
}

export interface IDeleteNotificationFailedAction {
  type: ActionTypes.DELETE_NOTIFICATION_FAILED;
}

export type INotificationsAction =
  | IReceiveNotificationsDataFailedAction
  | IReceiveNotificationsDataSucceededAction
  | IRequestNotificationsDataAction
  | IUpdateNotificationsReadAction
  | IUpdateNotificationsReadSucceededAction
  | IUpdateNotificationsReadFailedAction
  | IDeleteNotificationAction
  | IDeleteNotificationSucceededAction
  | IDeleteNotificationFailedAction;
