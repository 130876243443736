import { all, put, takeLatest, getContext, call, SimpleEffect } from 'redux-saga/effects';
import { size } from 'lodash-es';
import { ActionTypes } from './Notifications.action-types';
import { IAuthClient, IUser } from '@employee-experience/common/lib/Models';
import { HttpClient } from '@employee-experience/core';
import { ApiHelper } from '../../../Shared/Helpers/ApiHelper';
import { INotificationsModel, ITasksModel } from './Models/INotificationsModel';
import {
  receiveNotificationsDataFailedAction,
  receiveNotificationsDataSucceededAction,
  requestNotificationsDataAction,
  setNotificationsAsReadSucceededAction,
  setNotificationsAsReadFailedAction,
  deleteNotificationSucceededAction,
  deleteNotificationFailedAction,
} from './Notifications.actions';
import moment from 'moment';

/**
 * To map tasks to notifications model
 */
const convertTasksToNotifications = (tasks: ITasksModel[]): INotificationsModel[] => {
  const notifications: INotificationsModel[] = [];
  if (size(tasks) > 0) {
    tasks.map((item: ITasksModel) => {
      const { id, status, eventPublishedTime, name, ui, read } = item;
      let subjectIcon;
      let messageBodyText;
      let actionRequiredText;
      let actionRequiredLink;
      let readStatus;
      if (ui) {
        if (ui.notificationIcon) {
          subjectIcon = ui.notificationIcon === 'LockFeedback' ? 'Feedback' : ui.notificationIcon;
        }
        if (ui.description && ui.longDescription) {
          messageBodyText = `${ui.description} ${ui.longDescription}`;
        } else if (ui.description) {
          messageBodyText = ui.description;
        } else if (ui.longDescription) {
          messageBodyText = ui.longDescription;
        }
        if (ui.buttons && ui.buttons[0]) {
          actionRequiredText = ui.buttons[0].buttonText;
          actionRequiredLink = ui.buttons[0].route;
        }
      }
      if (read) {
        readStatus = read === true || status === 'complete' ? 'read' : 'unread';
      } else {
        readStatus = status === 'New' ? 'unread' : 'read';
      }
      notifications.push({
        itemKey: id,
        status: readStatus,
        displayStatus: undefined,
        timeDelivered: moment(eventPublishedTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ'),
        senderName: undefined,
        senderImageUrl: undefined,
        subjectIcon: subjectIcon,
        subjectHeader: name,
        eventTimeBlock: undefined,
        eventLocation: undefined,
        messageBodyText: messageBodyText,
        actionRequiredText: actionRequiredText,
        actionRequiredLink: actionRequiredLink,
        read,
      });
    });
  }
  return notifications;
};

export function* getNotificationsData(): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: HttpClient = yield getContext('httpClient');
    const authClient: IAuthClient = yield getContext('authClient');

    const user: IUser = yield call([authClient, authClient.getUser]);
    const { data: tasks }: { data: ITasksModel[] } = yield call([httpClient, httpClient.request], {
      url: __API__.myMsftApim.resourceUrl + '/tasks/' + user.id + '?status=New,complete,snooze,dismiss',
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    });
    const notifications = convertTasksToNotifications(tasks);
    yield put(receiveNotificationsDataSucceededAction(notifications));
  } catch (error) {
    yield put(receiveNotificationsDataFailedAction());
  }
}

export function* updateNotificationsAsRead(payload: {
  payload: INotificationsModel[];
}): IterableIterator<SimpleEffect<{}, {}>> {
  const taskIds: string[] = [];
  payload.payload.forEach((element: { itemKey: string }) => {
    taskIds.push(element.itemKey);
  });
  try {
    const httpClient: HttpClient = yield getContext('httpClient');
    const authClient: IAuthClient = yield getContext('authClient');
    const user: IUser = yield call([authClient, authClient.getUser]);

    const request = {
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
      data: taskIds,
    };

    const url = `${__API__.myMsftApim.resourceUrl}/tasks/${user.id}/read`;
    yield call([httpClient, httpClient.put], url, request);
    yield put(setNotificationsAsReadSucceededAction());
  } catch (error) {
    yield put(setNotificationsAsReadFailedAction());
  }
  yield put(requestNotificationsDataAction());
}

export function* deleteNotification(payload: { payload: string }): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: HttpClient = yield getContext('httpClient');
    const authClient: IAuthClient = yield getContext('authClient');
    const user: IUser = yield call([authClient, authClient.getUser]);

    const request = {
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    };

    const url = `${__API__.myMsftApim.resourceUrl}/tasks/${user.id}/${payload.payload}/delete?modifiedBy=${user.id}`;
    yield call([httpClient, httpClient.put], url, request);
    yield put(deleteNotificationSucceededAction());
  } catch (error) {
    yield put(deleteNotificationFailedAction());
  }
  yield put(requestNotificationsDataAction());
}

export function* notificationsSagas(): IterableIterator<{}> {
  yield all([
    takeLatest(ActionTypes.REQUEST_NOTIFICATIONS_DATA, getNotificationsData),
    takeLatest(ActionTypes.UPDATE_NOTIFICATIONS_AS_READ, updateNotificationsAsRead),
    takeLatest(ActionTypes.DELETE_NOTIFICATION, deleteNotification),
  ]);
}
