import { ActionTypes, IToggleNavAction, IAppLoad } from './App.action-types';

export function toggleNav(): IToggleNavAction {
  return {
    type: ActionTypes.TOGGLE_NAV,
  };
}

export function appLoad(): IAppLoad {
  return {
    type: ActionTypes.INITIATE_APP_LOAD,
  };
}
