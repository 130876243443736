import { NeutralColors } from '@cseo/styles';

export const PivotStyles = {
  root: {
    paddingBottom: '25px',
  },
};

export const textfieldStyles = {
  height: '353px',
};

export const Container = styled.div`
  border-top: 1px solid ${NeutralColors.gray30};
`;
