import { TelemetryService } from '../TelemetryService';
import { ActionTypes } from './Location.action-types';
import { all, takeLatest, call, put, SimpleEffect, select } from 'redux-saga/effects';
import { LocationService } from './LocationService';
import { setLocation, setLocationPermissions } from './Location.actions';
import { BUILDING_92 } from './Location.types';
import { getUserBuildingLocation } from '../../User/User.selectors';

export function* checkSetLocationPermissionsAndCoordinates(): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    yield call(LocationService.getCurrentPosition);
    const permissions = yield call(LocationService.checkLocationPermmission);
    yield put(setLocationPermissions(permissions));
    if (permissions !== 'granted') {
      const { data } = yield select(getUserBuildingLocation);
      // If permissions are Not granted will return User Assigned building, if not available will return Building 92
      if (data) {
        const { latitude, longitude } = data;
        yield put(setLocation({ latitude: Number(latitude), longitude: Number(longitude), accuracy: 25 }));
      } else {
        yield put(setLocation(BUILDING_92));
      }
    } else {
      // If permissions are granted will pull user GPS location
      const location = yield call(LocationService.getCurrentPosition);
      if (location) {
        const coordinates = {
          latitude: location.latitude,
          longitude: location.longitude,
          accuracy: location.accuracy,
        };
        yield put(setLocation(coordinates));
      }
    }
  } catch (exception) {
    TelemetryService.trackException(exception, 1, {
      triggerMethod: 'Location.saga.ts, checkSetLocationPermissionsAndCoordinates',
    });
    yield put(setLocation(BUILDING_92));
  }
}

export function* locationSagas(): IterableIterator<{}> {
  yield all([
    takeLatest(ActionTypes.UPDATE_LOCATION_PERMISSIONS_AND_COORDINATES, checkSetLocationPermissionsAndCoordinates),
  ]);
}
