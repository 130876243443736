import { ActionTypes, IGetFaqDataAction, ISetFaqDataAction } from './Help.action-types';
import { IQnaDocumentModel } from './Models/IQnaDocumentModel';

export function getFaqData(configs: string[]): IGetFaqDataAction {
  return {
    type: ActionTypes.GET_FAQ_DATA,
    configs: configs,
  };
}

export function setFaqData(payload: IQnaDocumentModel[]): ISetFaqDataAction {
  return {
    type: ActionTypes.SET_FAQ_DATA,
    payload: payload,
  };
}
