import * as React from 'react';
import * as Styled from './LoadingScreen.styled';
import { ILoadingScreenProps } from './LoadingScreen.types';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { CenterContainer } from '../../../Shared/Components/CenterContainer';

export function LoadingScreen(props: ILoadingScreenProps): React.ReactElement {
  const { innerPageUse } = props;
  return (
    <CenterContainer innerPageUse={innerPageUse}>
      <Spinner size={SpinnerSize.large} />
      <Styled.LoadingText>Loading your page</Styled.LoadingText>
    </CenterContainer>
  );
}
