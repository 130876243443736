import {
  ActionTypes,
  IGetExploreBuildings,
  IGetExploreBuildingsSuccess,
  IGetExploreBuildingsFailed,
  IReceiveExploreBuildingsSuccess,
  RequestBuildingsDataActionPayload,
  RequestBuildingDetailsActionPayload,
  IReceiveBuildingDetailsSuccess,
  IGetBuildingDetails,
} from './MapView.action-types';

export function requestBuildingsDataAction(payload: RequestBuildingsDataActionPayload): IGetExploreBuildings {
  return {
    payload: payload,
    type: ActionTypes.GET_EXPLORE_BUILDINGS,
  };
}

export function requestBuildingsDataSuccessAction(): IGetExploreBuildingsSuccess {
  return {
    type: ActionTypes.GET_EXPLORE_BUILDINGS_SUCCESS,
  };
}

export function receiveBuildingsDataFailedAction(): IGetExploreBuildingsFailed {
  return {
    type: ActionTypes.GET_EXPLORE_BUILDINGS_FAILED,
  };
}

export function receiveBuildingsDataAction(payload: {}): IReceiveExploreBuildingsSuccess {
  return {
    buildings: payload,
    type: ActionTypes.RECEIVE_BUILDINGS,
  };
}

export function requestBuildingDetailsAction(payload: RequestBuildingDetailsActionPayload): IGetBuildingDetails {
  return {
    payload: payload,
    type: ActionTypes.GET_BUILDING_DETAILS,
  };
}

export function recieveBuildingDetailsAction(payload: {}): IReceiveBuildingDetailsSuccess {
  return {
    buildingDetails: payload,
    type: ActionTypes.RECEIVE_BUILDING_DETAILS,
  };
}
