import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { FLIGHTS } from './Constants/flightingConstants';
import { Home } from './Pages/Home';
import { DashboardRoutes } from './Pages/Dashboard';
import { Explore } from './Pages/Explore';
import { Admin } from './Pages/Admin';
import { HRwebSearch } from './Pages/HRwebSearch';
import { NotAllowedScreen } from './Shared/Components/NotAllowedScreen';
import { useUserGlobalConfig } from './Shared/Hooks/useUserGlobalConfig';
import { MicrosoftLife } from './Pages/Home/Feed/MicrosoftLife';
import { News } from './Pages/Home/Feed/News';
import { Events } from './Pages/Home/Feed/Events';
import { Bookmarks } from './Pages/Home/Feed/Bookmarks';

export function AppRoutes(): React.ReactElement {
  const configs = useUserGlobalConfig([
    FLIGHTS.DASHBOARD,
    FLIGHTS.EXPLORE,
    FLIGHTS.MANAGER_DASHBOARD,
    FLIGHTS.EVENTS,
    FLIGHTS.NEWS,
    FLIGHTS.SHOW_NOT_ALLOWED_PAGE,
  ]);

  let redirectTo = '/';
  if (configs[FLIGHTS.NEWS] || configs[FLIGHTS.EVENTS]) redirectTo = '/feed';
  if (configs[FLIGHTS.DASHBOARD]) redirectTo = configs[FLIGHTS.MANAGER_DASHBOARD] ? '/dashboard/team' : '/dashboard';
  if (configs[FLIGHTS.SHOW_NOT_ALLOWED_PAGE]) redirectTo = '/not-allowed';

  return (
    <Switch>
      <Redirect from="/" to={redirectTo} exact={true} />
      {(configs[FLIGHTS.NEWS] || configs[FLIGHTS.EVENTS]) && <Route path="/feed" component={Home} exact={true} />}
      {configs[FLIGHTS.DASHBOARD] && <Route path="/dashboard" component={DashboardRoutes} />}
      {<Route path="/microsoftLife" component={MicrosoftLife} />}
      {<Route path="/events" component={Events} />}
      {<Route path="/news" component={News} />}
      {<Route path="/bookmarks" component={Bookmarks} />}
      {configs[FLIGHTS.DASHBOARD] && <Route path="/dashboard" component={DashboardRoutes} />}
      {configs[FLIGHTS.EXPLORE] && <Route path="/explore" component={Explore} exact={true} />}
      {configs[FLIGHTS.MANAGER_DASHBOARD] && <Route path="/search" component={HRwebSearch} exact={true} />}
      {configs[FLIGHTS.SHOW_NOT_ALLOWED_PAGE] && (
        <Route path="/not-allowed" component={NotAllowedScreen} exact={true} />
      )}
      <Route path="/admin" component={Admin} exact={true} />
      <Redirect to="/" />
    </Switch>
  );
}
