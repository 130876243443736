import { createSelector } from 'reselect';
import { IMyHubAppState } from '../../App.types';
import { initialMicroSentimentState } from './MicroSentiment.reducer';
import { MicroSentimentReducerName } from './MicroSentiment.resources';
import { IMicroSentimentState } from './MicroSentiment.types';

export const microSentimentRequestSelector = createSelector(
  (state: IMyHubAppState) => state[MicroSentimentReducerName] ?? initialMicroSentimentState,
  (microSentimentState: IMicroSentimentState) => microSentimentState.request
);

export const microSentimentSubmitLoadingStatusSelector = createSelector(
  (state: IMyHubAppState) => state[MicroSentimentReducerName] ?? initialMicroSentimentState,
  (microSentimentState: IMicroSentimentState) => microSentimentState.submitLoadingStatus
);
