import { ILocationService } from '../../Types';
import { TelemetryService } from '../TelemetryService';
import { BUILDING_92, Coordinates } from './Location.types';

export class LocationServiceImpl implements ILocationService {
  public async getCurrentPosition(): Promise<Coordinates> {
    return new Promise(async (resolve) => {
      try {
        await navigator.geolocation.getCurrentPosition(
          (position) => {
            if (position) {
              resolve(position.coords);
            } else {
              resolve(BUILDING_92);
            }
          },
          (error) => {
            TelemetryService.trackException(error, 1, {
              triggerMethod: 'LocationService- Cannot get user current location',
            });
            resolve(BUILDING_92);
          },
          { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
        );
      } catch (exception) {
        TelemetryService.trackException(exception, 1, {
          triggerMethod: 'LocationService- Unable to get UserCurrentLocation',
        });
        resolve(BUILDING_92);
      }
    });
  }
  public async checkLocationPermmission(): Promise<string> {
    let checkResponse = 'denied';
    if (navigator.geolocation) {
      await navigator.permissions
        .query({
          name: 'geolocation',
        })
        .then((result) => {
          checkResponse = result.state;
        });
    } else {
      // console.warn('navigator.geolocation Service Not available');
      TelemetryService.trackTrace('navigator.geolocation Service Not available');
    }

    return checkResponse;
  }
}

export const LocationService: ILocationService = new LocationServiceImpl();
