import { WhatsNewRelease } from './WhatsNew.types';

export enum ActionTypes {
  FETCH_WHATS_NEW_DATA = 'FETCH_WHATS_NEW_DATA',
  SET_WHATS_NEW_DATA = 'SET_WHATS_NEW_DATA',
  SET_WHATS_NEW_DATA_AVAILABLE = 'SET_WHATS_NEW_DATA_AVAILABLE',
}

export interface IFetchWhatsNewDataAction {
  type: ActionTypes.FETCH_WHATS_NEW_DATA;
}

export interface ISetWhatsNewDataAction {
  type: ActionTypes.SET_WHATS_NEW_DATA;
  data: WhatsNewRelease[];
}

export interface ISetWhatsNewDataAvailableAction {
  type: ActionTypes.SET_WHATS_NEW_DATA_AVAILABLE;
  isAvailable: boolean;
}

export type WhatsNewAction = IFetchWhatsNewDataAction | ISetWhatsNewDataAction | ISetWhatsNewDataAvailableAction;
