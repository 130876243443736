export enum ActionTypes {
  RECEIVE_USAGE_USER_ID = 'RECEIVE_USAGE_USER_ID',
  RECEIVE_USAGE_USER_ID_FAILED = 'RECEIVE_USAGE_USER_ID_FAILED',
  REQUEST_USAGE_USER_ID = 'REQUEST_USAGE_USER_ID',
  START_NEW_SESSION = 'START_NEW_SESSION',
  SET_LAST_ACTIVE_TIME = 'SET_LAST_ACTIVE_TIME',
  FETCH_USER_GLOBAL_CONFIG_WEB = 'FETCH_USER_GLOBAL_CONFIG_WEB',
  FETCH_USER_GLOBAL_CONFIG_WEB_FAILED = 'FETCH_USER_GLOBAL_CONFIG_WEB_FAILED',
  FETCH_USER_GLOBAL_CONFIG_WEB_SUCCESS = 'FETCH_USER_GLOBAL_CONFIG_WEB_SUCCESS',
  FETCH_USER_DETAILS = 'FETCH_USER_DETAILS',
  FETCH_USER_DETAILS_FAILED = 'FETCH_USER_DETAILS_FAILED',
  FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS',
  FETCH_USER_BUILDING_LOCATION_SUCCESS = 'FETCH_USER_BUILDING_LOCATION_SUCCESS',
  FETCH_USER_BUILDING_LOCATION_FAILED = 'FETCH_USER_BUILDING_LOCATION_FAILED',
}

export interface IReceiveUsageUserIdAction {
  type: ActionTypes.RECEIVE_USAGE_USER_ID;
  UsageUserId: string;
}

export interface IReceiveUsageUserIdFailedAction {
  type: ActionTypes.RECEIVE_USAGE_USER_ID_FAILED;
}

export interface IRequestUsageUserIdAction {
  type: ActionTypes.REQUEST_USAGE_USER_ID;
}

export interface IStartNewSession {
  type: ActionTypes.START_NEW_SESSION;
}

export interface ISetLastActiveTime {
  type: ActionTypes.SET_LAST_ACTIVE_TIME;
}

export interface IFetchUserGlobalConfigWeb {
  type: ActionTypes.FETCH_USER_GLOBAL_CONFIG_WEB;
}

export interface IFetchUserGlobalConfigWebSuccess {
  payload: {};
  userDetails: {};
  type: ActionTypes.FETCH_USER_GLOBAL_CONFIG_WEB_SUCCESS;
}
export interface IFetchUserDetailsWeb {
  type: ActionTypes.FETCH_USER_DETAILS;
}

export interface IFetchUserDetailsWebSuccess {
  payload: User;
  type: ActionTypes.FETCH_USER_DETAILS_SUCCESS;
}

export interface IFetchUserBuildingWebSuccess {
  payload: {};
  type: ActionTypes.FETCH_USER_BUILDING_LOCATION_SUCCESS;
}

export interface IFetchUserBuildingWebFailed {
  payload: {};
  type: ActionTypes.FETCH_USER_BUILDING_LOCATION_FAILED;
}

export type IUserAction =
  | IReceiveUsageUserIdAction
  | IReceiveUsageUserIdFailedAction
  | IRequestUsageUserIdAction
  | ISetLastActiveTime
  | IStartNewSession
  | IFetchUserGlobalConfigWeb
  | IFetchUserGlobalConfigWebSuccess
  | IFetchUserDetailsWeb
  | IFetchUserDetailsWebSuccess
  | IFetchUserBuildingWebSuccess
  | IFetchUserBuildingWebFailed;

export type userGlobalConfigPayload = {
  featureType: string;
  name: string;
  status: string;
};

export type userGlobalConfigDetailsPayload = {
  firstName: string;
  lastName: string;
  preferredFirstName: string;
  preferredLastName: string;
  preferredName: string;
};

export type User = {
  alias: string;
  /**
   * Name of the user
   */
  businessPhones?: string[];
  displayName: string;
  entityType: string;
  givenName: string;
  id: string;
  initials: string;
  /**
   * JobTitle of the user
   */
  jobTitle: string;
  mail: string;
  mobilePhone: string;
  /**
   * OfficeLocation of the user
   */
  officeLocation?: string;
  officeLocLat: string;
  officeLocLong: string;
  surname: string;
  /**
   * User's alias email address i.e. alias@microsoft.com
   */
  userPrincipalName: string;
  workPhone: string;
};
