import * as React from 'react';
import { CoherencePanel } from '@cseo/controls';
import { SearchBox, Announced } from 'office-ui-fabric-react';
import { faqSelector } from '../Help.selector';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import * as Styled from '../Help.styled';
import { ZeroSearchResults } from './ZeroSearchResults';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { TelemetryService } from '../../../Shared/shared';
import { Capability, SubCapability, EventName } from '../../../Shared/Types';
import { FeaturedAreas } from './FeaturedAreas';
import { FaqList } from './FaqList';
import TextEllipsis from 'react-text-ellipsis';
import { searchResults, featuredAreasHeader } from '../Help.resources';
import { FaqDetail } from './FaqDetail';
import { ReportProblem } from '../../Header/ReportProblem/ReportProblem';
import { IQnaDocumentModel } from '../Models/IQnaDocumentModel';
import ReactMarkdown = require('react-markdown');
import { BackButtonText } from '../../../Shared/Resources/Panel.resources';
import { IPanelProps } from '../../../Shared/Types/interfaces/IPanelProps';
import {
  PanelHeader,
  PanelSection,
  PanelSectionHeader,
  PanelSectionLink,
  PanelSectionSeparator,
  PanelSectionSubLink,
} from '../../../Styles/Panel';

interface IFaqProps extends IPanelProps {
  categories: string[];
}

export function Faq(props: IFaqProps): React.ReactElement {
  const { useSelector } = React.useContext(ReduxContext);
  const helpSelector = useSelector(faqSelector);
  const [faqs] = React.useState(helpSelector.faqData);
  const [headerText, setHeaderText] = React.useState(featuredAreasHeader);
  const [searchTerm, setSearchTerm] = React.useState('');
  const { telemetryClient } = React.useContext(ComponentContext);
  const [filteredFaq, setfilteredFaq] = React.useState(helpSelector.faqData);
  const [category, setCategory] = React.useState('');
  const [isFaqListOpen, setListPanelState] = React.useState(false);
  const [isPanelOpen, setPanelState] = React.useState(false);
  const [question, setQuestion] = React.useState('');
  const [answer, setAnswer] = React.useState('');
  const [isReportPanelOpen, setReportPanelState] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const toggleFaqList = (): void => {
    if (!isFaqListOpen)
      TelemetryService.trackEvent({
        capability: Capability.MyHub,
        subCapability: SubCapability.Faq_List,
        eventName: EventName.PANEL_OPENED,
      });
    setListPanelState(!isFaqListOpen);
  };
  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const resetState = (): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Faq_Search_Clear,
      eventName: EventName.BUTTON_CLICKED,
    });
    setHeaderText(featuredAreasHeader);
    setfilteredFaq(faqs);
  };

  const filterResults = (searchVal: string | undefined): void => {
    TelemetryService.trackEvent(
      {
        capability: Capability.MyHub,
        subCapability: SubCapability.Faq_Search,
        eventName: EventName.BUTTON_CLICKED,
      },
      { searchVal }
    );
    if (searchVal) {
      setSearchTerm(searchVal);
      setHeaderText(searchResults);
      const results = [];
      for (let i = 0; i < faqs.length; i++) {
        if (faqs[i]['questions'][0]?.toLowerCase().search(searchVal?.toLowerCase()) >= 0) {
          results.push(faqs[i]);
        }
      }
      setfilteredFaq(results);
      if (results.length == 0) {
        setMessage('No results found for : ' + searchVal);
        TelemetryService.trackTrace('FAQ Search: No results found', 4, { searchVal });
      } else {
        setMessage('Showing ' + results.length + ' results for your search : ' + searchVal);
      }
    } else if (searchVal == '') {
      setMessage('No search results found');
      resetState();
    }
  };

  const toggleDetailPanel = (): void => {
    if (!isPanelOpen)
      TelemetryService.trackEvent({
        capability: Capability.MyHub,
        subCapability: SubCapability.Faq_Details,
        eventName: EventName.PANEL_OPENED,
      });
    setPanelState(!isPanelOpen);
  };

  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const featuredAreaClicked = (category: string, filteredFaq: IQnaDocumentModel[]): void => {
    TelemetryService.trackTrace('Featured Area Clicked', 4, category);
    setCategory(category);
    setfilteredFaq(filteredFaq);
    toggleFaqList();
  };

  const openDetail = (index: number): void => {
    setQuestion(filteredFaq[index].questions[0]);
    setAnswer(filteredFaq[index].answer);
    TelemetryService.trackEvent(
      {
        capability: Capability.MyHub,
        subCapability: SubCapability.Faq_List,
        eventName: EventName.LINK_CLICKED,
      },
      { FaqQuestion: filteredFaq[index].questions[0], index: index }
    );
    toggleDetailPanel();
  };

  const toggleReportProblemPanel = (): void => {
    setReportPanelState(!isReportPanelOpen);
  };

  // Handles light dismiss.
  const onPanelClosed = (ev?: React.SyntheticEvent<HTMLElement, Event> | undefined): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Faq_List,
      eventName: EventName.PANEL_CLOSED,
    });
    if (ev) {
      props.backButton?.onClick();
    }
  };

  return (
    <div>
      <CoherencePanel
        isOpen={props.isOpen}
        backButton={props.backButton}
        isLightDismiss={true}
        telemetryHook={telemetryClient}
        isBlocking={false}
        titleText={'Help'}
        onDismiss={onPanelClosed}
      >
        <PanelHeader>Frequently asked questions</PanelHeader>
        <SearchBox
          placeholder="Search"
          onClear={resetState}
          onChange={(_, newValue: string | undefined): void => filterResults(newValue)}
          onSearch={(newValue): void => {
            filterResults(newValue);
          }}
          autoFocus={true}
        />
        {message && <Announced message={message} aria-live="assertive" />}

        {filteredFaq.length == 0 ? (
          <Styled.ZeroResultsFound>No result found for : {searchTerm}</Styled.ZeroResultsFound>
        ) : null}
        <PanelSection>
          {filteredFaq.length == 0 ? null : <PanelSectionHeader>{headerText}</PanelSectionHeader>}
          {filteredFaq.length == 0 ? (
            <ZeroSearchResults />
          ) : headerText === searchResults ? (
            filteredFaq.map((item: IQnaDocumentModel, index: number) => {
              return (
                <div key={index}>
                  <Styled.FaqQuestion>{item.questions[0]}</Styled.FaqQuestion>
                  <Styled.FaqAnswer>
                    <TextEllipsis lines={6}>
                      <ReactMarkdown source={item.answer} escapeHtml={false} />
                    </TextEllipsis>
                  </Styled.FaqAnswer>
                  <PanelSectionSubLink key={index} onClick={(): void => openDetail(index)}>
                    Learn more
                  </PanelSectionSubLink>
                </div>
              );
            })
          ) : (
            <>
              <FeaturedAreas categories={props.categories} faqs={faqs} onClicked={featuredAreaClicked} />
            </>
          )}
        </PanelSection>
        {filteredFaq.length == 0 || headerText == searchResults ? (
          <>
            <PanelSectionSeparator />
            <PanelSection>
              <PanelSectionHeader>Need more help?</PanelSectionHeader>
              <PanelSectionLink onClick={(): void => toggleReportProblemPanel()}>Report a problem</PanelSectionLink>
            </PanelSection>
          </>
        ) : null}
      </CoherencePanel>
      {isFaqListOpen ? (
        <FaqList
          isOpen={isFaqListOpen}
          backButton={{ ariaLabel: BackButtonText, onClick: toggleFaqList }}
          category={category}
          filteredFaq={filteredFaq}
        ></FaqList>
      ) : null}
      {isPanelOpen ? (
        <FaqDetail
          isOpen={isPanelOpen}
          question={question}
          answer={answer}
          backButton={{ ariaLabel: BackButtonText, onClick: toggleDetailPanel }}
        ></FaqDetail>
      ) : null}
      {isReportPanelOpen ? (
        <ReportProblem
          isOpen={isReportPanelOpen}
          backButton={{ ariaLabel: BackButtonText, onClick: toggleReportProblemPanel }}
        ></ReportProblem>
      ) : null}
    </div>
  );
}
