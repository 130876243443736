import moment from 'moment';
import { IFeedCardModel } from '../../Models/IFeedCardModel';
import { IMicrosoftLifeModel } from '../../Models/IMicrosoftLifeModel';
const twitterPlaceHolderImage = '/assets/Feed/TwitterImagePlaceHolder.svg';
const twitterIcon = '/assets/Feed/TwitterLogo.svg';
const instagramPlaceHolderImage = '/assets/Feed/InstagramImagePlaceHolder.svg';
const instagramIcon = '/assets/Feed/InstagramLogo.svg';

export const getFeedItemProps = (post: IMicrosoftLifeModel): IFeedCardModel => {
  const {
    post_id: id,
    network,
    post_time: postTime,
    text: title,
    photos,
    user_name: userName,
    permalink: link,
    seeMore,
  } = post;
  const iconUrl = network === 'instagram' ? instagramIcon : twitterIcon;
  const formattedPostTime = moment.unix(Number(postTime)).format('MMM D, YYYY');
  const imageUrl = photos && photos.length > 0 && photos[0].m;
  let fallbackImageUrl = '';
  let linkLabel = '';

  if (!imageUrl) {
    fallbackImageUrl = network === 'instagram' ? twitterPlaceHolderImage : instagramPlaceHolderImage;
  }

  linkLabel = link && userName ? `@${userName}` : 'Go to post';

  return {
    imageUrl,
    fallbackImageUrl,
    title: title,
    dateLabel: formattedPostTime,
    link: link,
    linkLabel,
    linkIcon: iconUrl,
    feedId: id,
    seeMore: seeMore,
    feedType: 'microsoftLife',
    linkAccessibilitylabel: `@${userName}, opens in ${network}`,
    bookmarkPayload: {
      id,
      images: imageUrl,
      title,
      link,
      feedsType: '2',
      extended: {
        postTime,
        network,
        userName,
      },
    },
  };
};
