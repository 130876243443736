import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths';
import { SurfaceColors, NeutralColors, FontSizes } from '@cseo/styles';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { minWidth } from '../../../../Styles/Media';
import { IconButton } from 'office-ui-fabric-react';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: calc(5vw - 8px);
  margin-right: calc(5vw - 8px);
  margin-top: 44px;

  ${minWidth.xl} {
    margin-left: calc(5vw - 12px);
    margin-right: calc(5vw - 12px);
  }
`;

export const Card = styled.div`
  background-color: ${SurfaceColors.primary};
  border-radius: 2px;
  box-shadow: ${Depths.depth4};
  margin-bottom: 16px;
  min-width: 98%;
  max-width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  height: 266px;

  ${minWidth.m} {
    min-width: 48%;
    max-width: 48%;
    margin-left: 1%;
    margin-right: 1%;
  }

  ${minWidth.l} {
    min-width: 32%;
    max-width: 32%;
    margin-left: 0.4%;
    margin-right: 1%;
  }

  ${minWidth.xl} {
    min-width: 23%;
    max-width: 23%;
    margin-left: 1%;
    margin-right: 1%;
  }

  ${minWidth.xxl} {
    min-width: 18%;
    max-width: 18%;
    margin-left: 1%;
    margin-right: 1%;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%; // Subtracting card footer height.
`;

export const CardMetadataContainer = styled.div`
  bottom: 4px;
  position: absolute;
  width: 100%;
`;

export const CardMetadata = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

export const CardMetadataIcon = styled(FontIcon)`
  margin-right: 8px;
`;

export const CardMetadataText = styled.div`
  color: ${NeutralColors.gray130};
  font-size: ${FontSizes.size12};
  width: 100%;
`;

export const CardFooter = styled.div`
  border-top: 1px solid ${NeutralColors.gray40};
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
`;

export const CardFooterItem = styled.div`
  align-items: center;
  display: flex;
`;

export const CardTag = styled.div`
  align-items: center;
  background: ${SurfaceColors.tertiary};
  border-radius: 2px;
  display: flex;
  font-size: ${FontSizes.size12};
  height: 20px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const CardActionButton = styled(IconButton)`
  height: 40px;
`;
