export interface IPushNotificationSubscription {
  id: string;
  upn: string;
  endpoint: string;
  expirationTime: string;
  auth: string;
  p256dh: string;
}

export enum SafariPushNotificationPermissionTypes {
  DEFAULT = 'default',
  GRANTED = 'granted',
  DENIED = 'denied',
}

export type ISafariPushNotificationPermission = {
  permission: SafariPushNotificationPermissionTypes;
  deviceToken: string;
};
