import { NeutralColors, FontSizes } from '@cseo/styles';
import { FontWeights } from 'office-ui-fabric-react';

export const TextContainer = styled.div`
  height: 100%;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  position: relative;
`;

export const Title = styled.div`
  font-size: ${FontSizes.size18};
  font-weight: ${FontWeights.semibold};
  margin-bottom: 16px;
`;

export const Desc = styled.div`
  color: ${NeutralColors.gray200};
  font-size: ${FontSizes.size18};
  line-height: 1.5;
  padding-right: 16px;
`;
