export const AdminReducerName = 'sendNotifcations';

// The ID for the "MyHub Notification Admins" SG
export const notificationSGGuid = 'cf42ec08-bca9-4c7f-956d-0c28a92e5658';

export const oDataContextKey = '@odata.context';

export type SGMembershipInfo = {
  [oDataContextKey]: string;
  value: string[];
};

// Copied (with modifications) from 'navigationConstants.ts' in the mobile code
export const mobileScreens = {
  LOGIN: 'login', // **
  DASHBOARD: 'dashboard', // **
  BOOK_SHUTTLE: 'bookShuttle', // **
  // CAFE_MENU: "cafeMenu",
  CAFEV2_MENU: 'cafev2Menu', // **
  // CAFEV2_CART: "cafev2Cart",
  // CAFE_CHECKOUT: "cafeCheckout",
  // CAFE_ITEM: "cafeItem",
  // CAFE_STATION: "cafeStation",
  BOOK_CONNECTOR: 'bookConnector', // **
  // REPORT_PROBLEM: "reportProblem",
  // WEB_VIEW: "webView",
  FOODTRUCK: 'foodTruck', // **
  FEEDBACK: 'feedback', // **
  // FEEDBACK_ITEM: "feedbackItem",
  PRIVACYPOLICY: 'privacyPolicy', // **
  SETTINGS: 'settings', // **
  // WAYFINDING: "wayfinding",
  WHATSNEW: 'whatsNew', // **
  // PROFILECONTENT: "profileContent",
  BOOKMARK: 'bookmark', // **
  // ORGANIZATION: "organization",
  // EMPLOYMENTINFO: "employmentInfo",
  // SHAREIDEA: "shareIdea",
  FAQ: 'faq', // **
  // FAQ_ITEM: "faqItem",
  // FAQ_LIST: "faqList",
  // OOF: "oof",
  REPORT_A_PROBLEM: 'reportAProblem', // **
  // OPEN_TICKETS: "openTickets",
  BOOKROOM: 'bookRoom', // **
  // GLOBALIZATION_OVERRIDES: "globalizationOverrides",
  // USER_VOICE_IDEAS: "userVoiceIdeas",
  // USER_VOICE_IDEA_DETAILS: "userVoiceIdeaDetail",
  // BUILDING_INDOOR_MAP: "buildingIndoorMap",
  // TEST_PAGE: "testPage",
  STAY_FIT_LANDING: 'StayFitLanding',
  STOCK_AWARDS: 'stockAwards',
  HOLIDAYS_LANDING: 'HolidaysLanding',
  PAYSTUB_LEGAL_NOTICE: 'PaystubLegalNotice',
  TIME_AWAY_SCREEN_LANDING: 'TimeAwayLandingScreen',
};
