import * as React from 'react';
import { ComponentProvider } from '@employee-experience/common/lib/ComponentProvider';
import { PageBody, PageContent } from '../../Styles/Page';
import { ErrorScreen } from '../../Shared/Components/ErrorScreen';

export function ManagerDashboard(): React.ReactElement {
  return (
    <PageBody>
      <PageContent>
        <ComponentProvider
          config={__MANAGER_CONFIGS__.dashboard}
          renderError={(): React.ReactElement => <ErrorScreen innerPageUse />}
        />
      </PageContent>
    </PageBody>
  );
}
