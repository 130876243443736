//import { ActionTypes } from './App.action-types';
import { actionChannel, all, takeLatest, SimpleEffect, call, take } from 'redux-saga/effects';
import { ActionTypes } from './App.action-types';
import { checkSetLocationPermissionsAndCoordinates } from './Shared/shared/Location/Location.saga';
import { ActionTypes as UserActionTypes } from './Shared/User/User.action-types';
import { fetchUserBuildingLocation, fetchUserDetails, fetchUserGlobalConfigWeb } from './Shared/User/User.sagas';

const { FETCH_USER_DETAILS_SUCCESS, FETCH_USER_DETAILS_FAILED } = UserActionTypes;

export function* initiateAppLoad(): IterableIterator<SimpleEffect<{}, {}>> {
  yield call(fetchUserGlobalConfigWeb);
  yield call(fetchUserDetails);

  const fetchUserChannel = yield actionChannel([FETCH_USER_DETAILS_SUCCESS, FETCH_USER_DETAILS_FAILED]);
  // disabling below line, since we don't require user building, as explore is out of scope in web.
  // yield call(fetchUserBuildingLocation);
  yield call(checkSetLocationPermissionsAndCoordinates);
  yield take(fetchUserChannel);
}

export function* appSagas(): IterableIterator<{}> {
  yield all([takeLatest(ActionTypes.INITIATE_APP_LOAD, initiateAppLoad)]);
}
