import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';

export const AddCardsButton = styled(PrimaryButton)``;

export const componentStyles = {
  header: [
    'headerText',
    {
      marginBottom: '24px',
      marginTop: '8px',
      marginLeft: '48px',
      marginRight: '49px',
      lineHeight: '32px',
    },
  ],
};
