import { find } from 'lodash-es';
import { ApiHelper } from '../../../Shared/Helpers/ApiHelper';
import { ActionTypes, IGetBuildingDetails, IGetExploreBuildings } from './MapView.action-types';
import { receiveBuildingsDataAction, recieveBuildingDetailsAction } from './MapView.actions';
import { HttpClient } from '@employee-experience/core';
import { all, put, takeLatest, getContext, call, SimpleEffect } from 'redux-saga/effects';
import { IMapBuildingsModel, IMapExploreBuildingsModel } from './MapView.types';
import { TelemetryService } from '../../../Shared/shared';

export const filterExploreAllBuildings = (buildings: IMapBuildingsModel[]): IMapExploreBuildingsModel[] => {
  const exploreAllBuildings = [];
  for (const building of buildings) {
    const { id: spaceId, location, name, properties } = building;
    let lat = 0;
    let long = 0;
    if (location) {
      const { latitude, longitude } = location;
      lat = latitude;
      long = longitude;
    }
    const refBuildingData = find(properties, ['name', 'REFBuildingId']);
    let refBuildingId;
    if (refBuildingData) {
      const { value } = refBuildingData;
      refBuildingId = value;
    }
    const hasCafeData = find(properties, ['name', 'HasCafe']);
    let hasCafe = false;
    if (hasCafeData) {
      const { value } = hasCafeData;
      if (value === 'true') {
        hasCafe = true;
      }
    }
    if (lat !== 0 && long !== 0 && name && spaceId && refBuildingId) {
      // Prefixing the "building" string if the building name from response is a number
      const formattedName = !isNaN(parseInt(name, 10)) ? `Building ${name}` : name;
      exploreAllBuildings.push({
        buildingId: refBuildingId,
        spaceId: building,
        latitude: lat,
        longitude: long,
        hasCafe,
        name,
        formattedName,
        coordinates: { latitude: lat, longitude: long },
      });
    }
  }
  return exploreAllBuildings;
};

/**
 * Worker saga to fetch explore buildings details
 */
export function* fetchExploreBuildings(action: IGetExploreBuildings): IterableIterator<SimpleEffect<{}, {}>> {
  // sbManagement resource has been removed. Commenting out below code referencing sbManagement to avoid errors.
  // Changing to always dispatch GET_EXPLORE_BUILDINGS_FAILED until function is migrated to a different api resource.

  /*   try {
    const { regionId } = action.payload;
    const httpClient: HttpClient = yield getContext('httpClient');
    const url = `/api/v1.0/spaces?types=Venue&statuses=Active&includes=Properties,Location&spaceId=${regionId}&traverse=Down`;
    const { data: buildings }: { data: IMapBuildingsModel[] } = yield call([httpClient, httpClient.request], {
      url: __API__.sbManagement.resourceUrl + url,
      resource: __API__.sbManagement.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    });
    yield put({ type: ActionTypes.GET_EXPLORE_BUILDINGS_SUCCESS });
    const data = filterExploreAllBuildings(buildings);
    yield put(receiveBuildingsDataAction(data));
  } catch (error) {
    yield put({ type: ActionTypes.GET_EXPLORE_BUILDINGS_FAILED });
  } */
  yield put({ type: ActionTypes.GET_EXPLORE_BUILDINGS_FAILED });
}

export function* fetchBuildingDetails(action: IGetBuildingDetails): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const { name } = action.payload;
    const httpClient: HttpClient = yield getContext('httpClient');
    // Get the dip-id
    const searchUrl = `/dip/query/api/v1.0/spaces?$filter=startswith(name,'${name + ''}')&type=building`;
    const result1 = yield call([httpClient, httpClient.request], {
      url: __API__.adtV2.resourceUrl + searchUrl,
      resource: __API__.adtV2.resourceId,
      header: ApiHelper.getADTv2ApiHeaders(),
    });
    const dtid = result1.data.items[0].dtId;
    const url = `/dip/query/api/v1.0/spaces/${dtid}?includes=address&type=building`;
    const result = yield call([httpClient, httpClient.request], {
      url: __API__.adtV2.resourceUrl + url,
      resource: __API__.adtV2.resourceId,
      header: ApiHelper.getADTv2ApiHeaders(),
    });
    const data: {} = result.data;
    yield put({ type: ActionTypes.GET_BUILDING_DETAILS_SUCCESS });
    yield put(recieveBuildingDetailsAction(data));
  } catch (error) {
    const errorString = JSON.stringify(error, Object.getOwnPropertyNames(error));
    TelemetryService.trackException(error, 1, { message: 'MapView.sagas.ts ===> Fetch Builing Details Failed' });
    yield put({ type: ActionTypes.GET_BUILDINGS_DETAILS_FAILED, error: errorString });
  }
}

/**
 * Watcher for exploreSagas
 */
export function* exploreSagas(): IterableIterator<{}> {
  yield all([takeLatest(ActionTypes.GET_EXPLORE_BUILDINGS, fetchExploreBuildings)]);
  yield all([takeLatest(ActionTypes.GET_BUILDING_DETAILS, fetchBuildingDetails)]);
}
