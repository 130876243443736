import { usePageTracking } from '@employee-experience/common';
import * as React from 'react';
import { PrimaryButton } from '@employee-experience/common/lib/PrimaryButton';
import { getFeature, getClickFeature, getPageLoadFeature } from '@employee-experience/common/lib/UsageTelemetryHelper';
import { Capability, SubCapability } from '../../Types/cuppConstants';
import * as Styled from './NotAllowedScreen.styled';

export function NotAllowedScreen(): React.ReactElement {
  const feature = getFeature(Capability.MyHub, SubCapability.NotAllowed);

  usePageTracking(getPageLoadFeature(feature, ''));

  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const handleButtonClicked = (): void => {
    window.open('mailto:globalhd@microsoft.com');
  };

  return (
    <Styled.ErrorRoot>
      <Styled.ErrorImage alt="Not allowed" src="/assets/Shared/Page/Not_Allowed.png" />

      <Styled.ErrorHeading>MyHub is currently just for people managers</Styled.ErrorHeading>

      <Styled.ErrorDescription>
        We&apos;ll let you know when there&apos;s features for individual contributors. If you&apos;re a people manager
        and still having issues accessing MyHub, please submit a support request.
      </Styled.ErrorDescription>

      <PrimaryButton
        onClick={handleButtonClicked}
        title="Create a support ticket"
        {...getClickFeature(feature, 'SupportTicketLink')}
      >
        Create support ticket
      </PrimaryButton>
    </Styled.ErrorRoot>
  );
}
