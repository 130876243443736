import * as React from 'react';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { toggleCatalog } from '../Dashboard.actions';
import * as Styled from './ActionBar.styled';
import { cardsCountSelector, catalogItemsCountSelector } from '../Dashboard.selectors';
import { useContext, useCallback } from 'react';
import { useUserGlobalConfig } from '../../../Shared/Hooks/useUserGlobalConfig';
import { FLIGHTS } from '../../../Constants/flightingConstants';

export function ActionBar(): React.ReactElement {
  const { dispatch, useSelector } = useContext(ReduxContext);
  const cardsCount: number = useSelector(cardsCountSelector);
  const catalogItemsCount: number = useSelector(catalogItemsCountSelector);
  const isAddCardsButtonDisabled = catalogItemsCount === 0 || cardsCount === catalogItemsCount;

  const configs = useUserGlobalConfig([FLIGHTS.ADD_TILE]);
  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const addCardsButtonClicked = useCallback((): void => {
    dispatch(toggleCatalog(true));
  }, [dispatch]);

  return (
    <Styled.Container>
      {configs.addtile ? (
        <Styled.LargeScreenButton
          ariaLabel="Add cards"
          disabled={isAddCardsButtonDisabled}
          iconProps={{ iconName: 'Add' }}
          onClick={addCardsButtonClicked}
          text="Add Cards"
        />
      ) : null}
      {configs.addtile ? (
        <Styled.SmallScreenButton
          ariaLabel="Add cards"
          disabled={isAddCardsButtonDisabled}
          iconProps={{ iconName: 'Add' }}
          onClick={addCardsButtonClicked}
        />
      ) : null}
    </Styled.Container>
  );
}
