const headerHeight = 48;
const footerHeight = 80;

export const ErrorRoot = styled.div`
  text-align: center;
  margin-top: ${window.innerHeight / 2 - (headerHeight + footerHeight)}px;
  transform: translateY(-50%);
`;

export const ErrorImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 42px;
  width: 216px;
  height: auto;
`;

export const ErrorHeading = styled.h1`
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 16px;
`;

export const ErrorDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #171717;
  margin-bottom: 48px;
`;
