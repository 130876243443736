import { IFeedAction, ActionTypes } from './Feed.action-types';
import { IFeedState } from './Feed.types';
import { LoadingStates } from './../../../Shared/Models/LoadingStates';

export const initialFeedState: IFeedState = {
  Events: [],
  FeedItemsLoadingStatus: LoadingStates.NOT_STARTED,
  EventFeedItemsLoadingStatus: LoadingStates.NOT_STARTED,
  NewsFeedItemsLoadingStatus: LoadingStates.NOT_STARTED,
  News: [],
  MicrosoftLife: [],
  MicrosoftLifeLoadingStatus: LoadingStates.NOT_STARTED,
};

export function feedReducer(prev: IFeedState = initialFeedState, action: IFeedAction): IFeedState {
  switch (action.type) {
    case ActionTypes.RECEIVE_EVENTS: {
      return {
        ...prev,
        Events: action.events,
      };
    }
    case ActionTypes.RECEIVE_MICROSOFT_LIFE_DATA_SUCCEEDED: {
      return {
        ...prev,
        MicrosoftLife: action.microsoftLife,
        MicrosoftLifeLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }
    case ActionTypes.RECEIVE_MICROSOFT_LIFE_DATA_FAILED: {
      return {
        ...prev,
        MicrosoftLifeLoadingStatus: LoadingStates.FAILED,
      };
    }
    case ActionTypes.RECEIVE_NEWS_FEED_DATA_FAILED: {
      return {
        ...prev,
        NewsFeedItemsLoadingStatus: LoadingStates.FAILED,
      };
    }
    case ActionTypes.RECEIVE_NEWS_FEED_DATA_SUCCEEDED: {
      return {
        ...prev,
        NewsFeedItemsLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }
    case ActionTypes.RECEIVE_EVENT_FEED_DATA_SUCCEEDED: {
      return {
        ...prev,
        EventFeedItemsLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }
    case ActionTypes.RECEIVE_EVENT_FEED_DATA_FAILED: {
      return {
        ...prev,
        EventFeedItemsLoadingStatus: LoadingStates.FAILED,
      };
    }

    case ActionTypes.RECEIVE_NEWS: {
      return {
        ...prev,
        News: action.news,
      };
    }
    case ActionTypes.REQUEST_NEWS_FEED_DATA: {
      return {
        ...prev,
        NewsFeedItemsLoadingStatus: LoadingStates.STARTED,
      };
    }
    case ActionTypes.REQUEST_EVENT_FEED_DATA: {
      return {
        ...prev,
        EventFeedItemsLoadingStatus: LoadingStates.STARTED,
      };
    }
    case ActionTypes.REQUEST_MICROSOFT_LIFE: {
      return {
        ...prev,
        MicrosoftLifeLoadingStatus: LoadingStates.STARTED,
      };
    }
    default:
      return prev;
  }
}
