import { Capability, SubCapability, EventName } from '.';
import { UserEvent, EventType, SystemEvent } from '@employee-experience/common/lib/Models';
import { UsageEventDetails } from './UsageEventDetails';

export type Usage = {
  businessTransactionId?: string;
  capability: Capability;
  subCapability: SubCapability | string;
  eventName: EventName;
};

export const asUserEvent = (usage: Usage): UserEvent => {
  return {
    feature: usage.capability,
    subFeature: usage.subCapability,
    eventName: usage.eventName?.toString(),
    type: EventType.User,
    businessTransactionId: usage.businessTransactionId,
  };
};
export const asSystemEvent = (usage: UsageEventDetails): SystemEvent => {
  return {
    feature: usage.capability,
    subFeature: usage.subCapability,
    eventName: usage.eventName.toString(),
    type: EventType.System,
    businessTransactionId: usage.businessTransactionId,
    timeTaken: usage.usageTimeTaken || 0,
  };
};
