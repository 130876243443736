/* eslint-disable react/display-name */
import { useEffect } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ICoherenceHeaderProps, SearchSettings, HeaderItemClick, PersonaSettings } from '@cseo/controls/lib/header';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { HeaderPanels } from '../../Layout/Header/Header.types';
import { ReduxContext, useUser, useGraphPhoto, TelemetryEvents } from '@employee-experience/common';
import { IUserGlobalConfigDetails } from '../Models/User.types';
import { isEmpty } from 'lodash-es';
import {
  notificationsSelector,
  notificationsUnreadListSelector,
} from '../../Layout/Header/Notifications/Notifications.selector';
import {
  loadFloodgateScript,
  initializeOBFeedback,
  loadFeedbackScript,
  officeFeedbackClicked,
  initializeFeedback,
} from '../../../officeBrowserFeedback/OfficeBrowserFeedbackWrapper';
import {
  setNotificationsAsRead,
  deleteNotification,
  requestNotificationsDataAction,
} from '../../Layout/Header/Notifications/Notifications.actions';
import { ICoherencePanelProps, ICoherenceNotificationPanelProps, ILaunchOptionsInAppFeedback } from '@cseo/controls';
import moment = require('moment');
import { HelpScreen } from '../../Layout/Help/HelpScreen';
import { ItemDisplayStatus } from '@cseo/controls/lib/notification/notificationItem/CoherenceNotificationItem.types';
import { TelemetryService } from '../shared';
import { Capability, SubCapability, EventName } from '../Types';
import { useUserGlobalConfig, useUserGlobalConfigDetails } from './useUserGlobalConfig';
import { FLIGHTS } from '../../Constants/flightingConstants';
import { getCategories } from '../../Layout/Header/Header.Resources';
import { browserName, browserVersion } from 'react-device-detect';
import { getFaqData } from '../../Layout/Help/Help.actions';
import { useFeaturedFlights } from './useFeaturedFlights';
import { usePushNotificationSubscription } from '../../Shared/Hooks/usePushNotificationSubscription';
import { Logout } from './../../Layout/Header/Header.action';
import { Settings } from '../../Layout/Header/Settings/Settings';
import { DefaultSearch } from '../../Layout/Header/DefaultSearch/DefaultSearch';

export const getFeedbackLaunchOptions = (configs, user): ILaunchOptionsInAppFeedback => {
  const launchOptions: ILaunchOptionsInAppFeedback = {
    categories: {
      show: true,
      customCategories: getCategories(configs.ManagerDashboard, configs.You).map((category) => category.text),
    },
    telemetryGroup: {
      audience: __OCV__.audience,
      audienceGroup: __OCV__.audienceGroup,
      channel: __OCV__.channel,
      loggableUserId: user ? user.id : '',
      platform: __OCV__.platform,
      officeBuild: __OCV__.build,
    },
    webGroup: {
      browser: browserName,
      browserVersion: browserVersion,
    },
  };
  return launchOptions;
};

export function useHeaderConfig(): ICoherenceHeaderProps {
  const { telemetryClient: telemetryHook, authClient } = React.useContext(ComponentContext);
  const { dispatch, useSelector } = React.useContext(ReduxContext);
  const { telemetryClient } = React.useContext(ComponentContext);
  TelemetryService.SetTelemetryClient(telemetryClient);
  const history = useHistory();
  const user = useUser();
  const photo = useGraphPhoto();
  const notifications = useSelector(notificationsSelector);
  const unreadNotificationList = useSelector(notificationsUnreadListSelector);
  const { unsubscribeUser } = usePushNotificationSubscription();
  const userDetails = useUserGlobalConfigDetails();
  const featureFlights = useFeaturedFlights();
  const configs = useUserGlobalConfig([
    FLIGHTS.MANAGER_DASHBOARD,
    FLIGHTS.NOTIFICATIONS,
    FLIGHTS.SETTINGS,
    FLIGHTS.HELP,
    FLIGHTS.MYHUB_DASHBOARD,
    FLIGHTS.FEEDBACK,
    FLIGHTS.SEARCH,
    FLIGHTS.HEADER_SEARCH,
  ]);
  const [isHelpFetched, setHelpState] = React.useState(false);
  const [sessionID, setSessionID] = React.useState(__UNINITIALIZED__);
  const [ocvButtonIsFocused, setOCVButtonIsFocused] = React.useState(false);
  React.useEffect(() => {
    dispatch(requestNotificationsDataAction());
  }, [dispatch]);
  React.useEffect(() => {
    if (!isEmpty(featureFlights) && !isHelpFetched) {
      dispatch(getFaqData(featureFlights));
      setHelpState(true);
    }
  }, [featureFlights]);

  useEffect(() => {
    setSessionID(telemetryClient.telemetryContext.usageUser().sessionId);
  }, [telemetryClient]);

  useEffect(() => {
    if (!configs[FLIGHTS.FEEDBACK]) return;

    if (sessionID != __UNINITIALIZED__) {
      loadFloodgateScript().then(() =>
        authClient.getUserId().then((email) =>
          initializeOBFeedback(sessionID, email ? email : '', () => {
            const ocvButton = document.getElementById('coher-header-ocv-button-id');
            if (ocvButton) {
              ocvButton.focus();
            }
            setOCVButtonIsFocused(false);
          })
        )
      );
    }
  }, [sessionID, configs[FLIGHTS.FEEDBACK]]);
  const getPanelOpenHandler = (panel: HeaderPanels): (() => void) => {
    return (): void => {
      telemetryClient.trackTrace({
        message: TelemetryEvents.HeaderPanelOpened,
        properties: { panel },
      });
      telemetryClient.startTrackPage(panel);
    };
  };
  const logOutClicked = (): void => {
    dispatch(Logout({ unsubscribeUser }));
  };

  const handleFeedbackClicked = (): boolean => {
    loadFeedbackScript()
      .then(() =>
        initializeFeedback(sessionID, user ? user.email : '', () => {
          const ocvButton = document.getElementById('coher-header-ocv-button-id');
          if (ocvButton) {
            ocvButton.focus();
          }
          setOCVButtonIsFocused(false);
        })
      )
      .then(() => officeFeedbackClicked(getFeedbackLaunchOptions(configs, user)));
    setOCVButtonIsFocused(true);
    return true;
  };
  const valueSearched = (value: string): void => {
    if (configs.ManagerDashboard) history.push(`/search?q=${value}`);
  };

  const getPanelDismissHandler = (panel: HeaderPanels): (() => void) => {
    return (): void => {
      telemetryClient.trackTrace({
        message: TelemetryEvents.HeaderPanelClosed,
        properties: { panel },
      });
      telemetryClient.stopTrackPage(panel);
    };
  };

  const goToNotificationCenter = () => {
    return (): void => {
      const itemTypes: { [key: string]: number } = {};
      TelemetryService.trackEvent(
        {
          capability: Capability.MyHub,
          eventName: EventName.NOTIFICATIONS_READ,
          subCapability: SubCapability.Events_ClickNotifications,
        },
        { notificationsUnreadAvailable: unreadNotificationList.length > 0 },
        { notificationUnreadCount: unreadNotificationList.length }
      );
      if (unreadNotificationList.length > 0) {
        unreadNotificationList.map((item) => {
          const { type } = item;
          itemTypes[type] ? (itemTypes[type] += 1) : (itemTypes[type] = 1);
        });
        TelemetryService.trackEvent(
          {
            capability: Capability.MyHub,
            eventName: EventName.NOTIFICATIONS_READ,
            subCapability: SubCapability.Events_ClickNotifications,
          },
          { notificationsAction: 'Conversion Unread to read' },
          itemTypes
        );
        dispatch(setNotificationsAsRead(unreadNotificationList));
      }
    };
  };

  const updateNotification = (itemKey: string, displayStatus: ItemDisplayStatus): void => {
    if (displayStatus === 'dismiss') {
      dispatch(deleteNotification(itemKey));
    }
  };

  const searchSettings: SearchSettings = {
    onRender: () => <DefaultSearch />,
    onSearch: valueSearched,
  };
  const helpSettings: ICoherencePanelProps & HeaderItemClick = {
    body: <HelpScreen />,
    onClick: undefined,
    onDismissed: getPanelDismissHandler(HeaderPanels.Help),
    onOpened: getPanelOpenHandler(HeaderPanels.Help),
    telemetryHook: telemetryClient,
    focusTrapZoneProps: {
      forceFocusInsideTrap: false,
    },
  };
  const profileSettings: ICoherencePanelProps & PersonaSettings & HeaderItemClick = {
    emailAddress: user ? user.email : '',
    fullName: user ? user.name : '',
    imageUrl: photo || undefined,
    onDismissed: getPanelDismissHandler(HeaderPanels.Profile),
    onLogOut: logOutClicked,
    onOpened: getPanelOpenHandler(HeaderPanels.Profile),
    telemetryHook: telemetryClient,
  };
  const settingsSettings: ICoherencePanelProps = {
    body: <Settings />,
    onClick: undefined,
    onDismissed: getPanelDismissHandler(HeaderPanels.Settings),
    onOpened: getPanelOpenHandler(HeaderPanels.Settings),
    telemetryHook: telemetryClient,
    focusTrapZoneProps: {
      forceFocusInsideTrap: false,
    },
  };
  const notificationsSettings: ICoherenceNotificationPanelProps & HeaderItemClick = {
    // Will remove this console log once delete notifications is added
    // eslint-disable-next-line no-console
    onDismissMultiple: (): void => console.log('dismissAllClicked'),
    items: !isEmpty(notifications) && moment.isMoment(notifications[0].timeDelivered) ? notifications : [],
    newNotifications: unreadNotificationList.length,
    onClick: goToNotificationCenter(),
    updateItem: (itemKey: string, displayStatus: ItemDisplayStatus): void => {
      return updateNotification(itemKey, displayStatus);
    },
    onDismissed: getPanelDismissHandler(HeaderPanels.Notification),
    onOpened: getPanelOpenHandler(HeaderPanels.Notification),
    telemetryHook: telemetryClient,
  };
  const getFormattedName = (userDetails: IUserGlobalConfigDetails): string => {
    const fName =
      userDetails && userDetails.preferredFirstName && userDetails.preferredFirstName.trim() !== ''
        ? userDetails.preferredFirstName
        : userDetails.firstName || '';
    const lName =
      userDetails && userDetails.preferredLastName && userDetails.preferredLastName.trim() !== ''
        ? userDetails.preferredLastName
        : userDetails.lastName || '';
    return `${fName} ${lName}`;
  };
  return {
    headerLabel: __APP_NAME__,
    appNameSettings: {
      label: __APP_NAME__,
    },
    searchSettings: configs[FLIGHTS.HEADER_SEARCH] ? searchSettings : null,
    profileSettings,
    farRightSettings: {
      notificationsSettings: configs.notifications && notificationsSettings,
      feedbackSettings: configs[FLIGHTS.FEEDBACK]
        ? {
            onClick: handleFeedbackClicked,
            launchOptions: getFeedbackLaunchOptions(configs, user),
            ocvButtonIsFocused: ocvButtonIsFocused,
          }
        : undefined,
      settingsSettings: configs.settings && settingsSettings,
      helpSettings: configs.help && helpSettings,
      profileSettings: {
        fullName: getFormattedName(userDetails),
      },
    },
    telemetryHook,
  };
}
