export const FEATURES = [
  { name: 'Time off', details: 'HR benefits', thumbnail: '/assets/Building_Placeholder.svg' },
  { name: 'Stay Fit', details: 'HR benefits', thumbnail: '/assets/Building_Placeholder.svg' },
];
export const BUILDINGS = [
  {
    name: 'Building 84',
    details: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
    thumbnail: '/assets/Building_Placeholder.svg',
  },
  {
    name: 'Mellinium E',
    details: '3891 Ranchview Dr. Richardson, California 62639',
    thumbnail: '/assets/Building_Placeholder.svg',
  },
];
export const PEOPLE = [
  {
    name: 'Ralph Edwards',
    details: 'alma.lawson@example.com',
    thumbnail: '/assets/Building_Placeholder.svg',
  },
  {
    name: 'Kathryn Murphy',
    details: 'michelle.rivera@example.com',
    thumbnail: '/assets/Building_Placeholder.svg',
  },
];
