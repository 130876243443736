import * as React from 'react';
import { MapView } from './MapView';
import { useState, ReactElement, useContext } from 'react';
import { Capability, SubCapability, EventName } from '../../Shared/Types';
import { TelemetryService } from '../../Shared/shared';
import { IMapExploreBuildingsModel } from './MapView/MapView.types';
import { BuildingPanel } from './BuildingPanel/BuildingPanel';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { requestBuildingDetailsAction } from './MapView/MapView.actions';

export function Explore(): ReactElement {
  const [selectedBuilding, setSelectedBuilding] = useState<IMapExploreBuildingsModel | undefined>(undefined);
  const { dispatch } = useContext(ReduxContext);

  // const selectBuilding = (building: IMapExploreBuildingsModel): void => {
  //     setSelectedBuilding(building);
  // };

  const onBuildingPanelOpen = (): void => {
    const { name } = selectedBuilding as { name: string };
    dispatch(requestBuildingDetailsAction({ name }));
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Explore,
      eventName: EventName.PANEL_OPENED,
    });
  };

  const onBuildingPanelClose = (): void => {
    setSelectedBuilding(undefined);
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Explore,
      eventName: EventName.PANEL_CLOSED,
    });
  };

  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const onBuildingSelected = (): void => {
    // Uncomment to enable building panel.
    // TODO: Do ADTv2 prod integration.
    // selectBuilding(building);
  };

  return (
    <>
      <MapView onBuildingSelected={onBuildingSelected} />
      <BuildingPanel
        selectedBuilding={selectedBuilding}
        onOpen={onBuildingPanelOpen}
        onDismiss={onBuildingPanelClose}
      ></BuildingPanel>
    </>
  );
}
