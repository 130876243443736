import { ICatalogItem } from './Shared/Models/ICatalogItem';
import { ICard } from './Shared/Models/ICard';

export enum ActionTypes {
  ADD_CARDS = 'ADD_CARDS',
  MOVE_CARD = 'MOVE_CARD',
  RECEIVE_CATALOG_ITEMS = 'RECEIVE_CATALOG_ITEMS',
  REMOVE_CARD = 'REMOVE_CARD',
  REQUEST_CATALOG_ITEMS = 'REQUEST_CATALOG_ITEMS',
  RESET = 'RESET',
  TOGGLE_CATALOG = 'TOGGLE_CATALOG',
  UPDATE_CARD = 'UPDATE_CARD',
}

export interface IAddCardsAction {
  type: ActionTypes.ADD_CARDS;
  cards: ICard[];
}

export interface IMoveCardAction {
  type: ActionTypes.MOVE_CARD;
  fromIndex: number;
  toIndex: number;
}

export interface IReceiveCatalogItemsAction {
  type: ActionTypes.RECEIVE_CATALOG_ITEMS;
  items: ICatalogItem[];
}

export interface IRemoveCardAction {
  type: ActionTypes.REMOVE_CARD;
  id: string;
}

export interface IRequestCatalogItemsAction {
  type: ActionTypes.REQUEST_CATALOG_ITEMS;
}

export interface IResetAction {
  type: ActionTypes.RESET;
}

export interface IToggleCatalogAction {
  type: ActionTypes.TOGGLE_CATALOG;
  isOpen: boolean;
}

export interface IUpdateCardAction {
  type: ActionTypes.UPDATE_CARD;
  card: ICard;
}

export type IDashboardAction =
  | IAddCardsAction
  | IMoveCardAction
  | IReceiveCatalogItemsAction
  | IRemoveCardAction
  | IRequestCatalogItemsAction
  | IResetAction
  | IToggleCatalogAction
  | IUpdateCardAction;
