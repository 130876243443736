import { ISearchResultsModel } from './Models/ISearchResultsModel';
import { IFetchSearchResultsActionPayload } from './Search.types';

export enum ActionTypes {
  FETCH_SEARCH_RESULTS = 'FETCH_SEARCH_RESULTS',
  FETCH_SEARCH_RESULTS_SUCCESS = 'FETCH_SEARCH_RESULTS_SUCCESS',
  FETCH_SEARCH_RESULTS_FAILURE = 'FETCH_SEARCH_RESULTS_FAILURE',
  RESET_SEARCH_RESULTS = 'RESET_SEARCH_RESULTS',
}

export interface IFetchSearchResultsAction {
  type: ActionTypes.FETCH_SEARCH_RESULTS;
  payload: IFetchSearchResultsActionPayload;
}

export interface IFetchSearchResultsSuccessAction {
  type: ActionTypes.FETCH_SEARCH_RESULTS_SUCCESS;
  SearchResults: ISearchResultsModel;
}

export interface IFetchSearchResultsFailureAction {
  type: ActionTypes.FETCH_SEARCH_RESULTS_FAILURE;
  error: unknown;
}

export interface IResetSearchResultsAction {
  type: ActionTypes.RESET_SEARCH_RESULTS;
}

export type ISearchAction =
  | IFetchSearchResultsAction
  | IFetchSearchResultsSuccessAction
  | IFetchSearchResultsFailureAction
  | IResetSearchResultsAction;
