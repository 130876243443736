import { createSelector } from 'reselect';
import { IMyHubAppState } from '../../App.types';
import {
  IAdminState,
  confirmedUserSGsKey,
  confirmedUserSGsErrorKey,
  submitNotificationStatusKey,
  submitNotificationErrorKey,
  searchSGorUsersKey,
  searchSGorUsersLoadingKey,
} from './Admin.types';
import { AdminReducerName } from './Admin.resources';
import { initialAdminState } from './Admin.reducer';

export const confirmedUserSGsSelector = createSelector(
  (state: IMyHubAppState) => state[AdminReducerName] ?? initialAdminState,
  (submitNotificationsState: IAdminState) => submitNotificationsState[confirmedUserSGsKey]
);

export const confirmedUserSGsErrorSelector = createSelector(
  (state: IMyHubAppState) => state[AdminReducerName] ?? initialAdminState,
  (submitNotificationsState: IAdminState) => submitNotificationsState[confirmedUserSGsErrorKey]
);

export const submitNotificationStatusSelector = createSelector(
  (state: IMyHubAppState) => state[AdminReducerName] ?? initialAdminState,
  (submitNotificationsState: IAdminState) => submitNotificationsState[submitNotificationStatusKey]
);

export const submitNotificationErrorSelector = createSelector(
  (state: IMyHubAppState) => state[AdminReducerName] ?? initialAdminState,
  (submitNotificationsState: IAdminState) => submitNotificationsState[submitNotificationErrorKey]
);

export const fetchSgUserSearchSelector = createSelector(
  (state: IMyHubAppState) => state[AdminReducerName] ?? initialAdminState,
  (submitNotificationsState: IAdminState) => submitNotificationsState[searchSGorUsersKey]
);

export const fetchSgUserLoadingSelector = createSelector(
  (state: IMyHubAppState) => state[AdminReducerName] ?? initialAdminState,
  (submitNotificationsState: IAdminState) => submitNotificationsState[searchSGorUsersLoadingKey]
);

export const fetchCountryCodeDataSelector = createSelector(
  (state: IMyHubAppState) => state[AdminReducerName] ?? initialAdminState,
  (submitNotificationState: IAdminState) => submitNotificationState.countryCode
);

export const fetchCompanyCodeDataSelector = createSelector(
  (state: IMyHubAppState) => state[AdminReducerName] ?? initialAdminState,
  (submitNotificationState: IAdminState) => submitNotificationState.companyCode
);
