import { ActionTypes } from './Help.action-types';
import { IFaqState } from './Help.types';

export const initialFaqState: IFaqState = {
  faqData: [],
};

export function faqReducer(prev = initialFaqState, action): IFaqState {
  switch (action.type) {
    case ActionTypes.SET_FAQ_DATA: {
      return {
        ...prev,
        faqData: action.payload.faqData,
      };
    }
    default: {
      return prev;
    }
  }
}
