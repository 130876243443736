import { NeutralColors, FontSizes } from '@cseo/styles';
import { FontWeights } from 'office-ui-fabric-react';

export const ImageContainer = styled.div`
  height: 216px;
  position: relative;
`;

export const Image = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const FeedSponsorText = styled.div`
  background: ${NeutralColors.gray20};
  bottom: 0px;
  font-size: ${FontSizes.size12};
  max-width: 100%;
  opacity: 0.8;
  overflow: hidden;
  padding: 6px 16px;
  position: absolute;
  right: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TextContainer = styled.div`
  height: calc(100% - 216px);
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 10px;
  position: relative;
`;

export const Title = styled.div`
  font-size: ${FontSizes.size14};
  font-weight: ${FontWeights.semibold};
  margin-top: 4px;
`;

export const Desc = styled.div`
  color: ${NeutralColors.gray200};
  margin-top: 4px;
`;
