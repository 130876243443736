export const FaqQuestion = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-top: 16px;
`;

export const FaqAnswer = styled.div`
  margin-top: 4px;
  font-size: 14px;
`;

export const ZeroResultsFound = styled.div`
  font-size: 12px;
  color: #646464;
`;
