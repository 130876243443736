import { ActionTypes, WhatsNewAction } from './WhatsNew.action-types';
import { IWhatsNewState } from './WhatsNew.types';

export const initialWhatsNewState: IWhatsNewState = {
  whatsNewReleases: [],
  isWhatsNewDataAvailable: false,
};

export function whatsNewReducer(prev = initialWhatsNewState, action: WhatsNewAction): IWhatsNewState {
  switch (action.type) {
    case ActionTypes.SET_WHATS_NEW_DATA: {
      return {
        ...prev,
        whatsNewReleases: action.data,
      };
    }
    case ActionTypes.SET_WHATS_NEW_DATA_AVAILABLE: {
      return {
        ...prev,
        isWhatsNewDataAvailable: action.isAvailable,
      };
    }
    default: {
      return prev;
    }
  }
}
