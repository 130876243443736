import * as React from 'react';
import { IErrorBoundaryProps, IErrorBoundaryState } from './ErrorBoundary.types';
import { ErrorScreen } from '../../Shared/Components/ErrorScreen';

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): IErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(): void {
    // TODO([abred]: 1/12/2019): Log error
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <ErrorScreen />;
    }
    return this.props.children;
  }
}
