import { minWidth } from '../../../Styles/Media';
import { FontWeights } from 'office-ui-fabric-react';
import { FontSizes } from '@cseo/styles';

export const Image = styled.img`
  height: 100px;
  width: 100px;

  ${minWidth.xl} {
    height: 120px;
    width: 120px;
  }

  ${minWidth.xxxl} {
    height: 180px;
    width: 180px;
  }
`;

export const NoFeedErrorImage = styled.img`
  height: 130px;
  width: 130px;

  ${minWidth.xl} {
    height: 165px;
    width: 165px;
  }

  ${minWidth.xxxl} {
    height: 190px;
    width: 190px;
  }
`;

export const Title = styled.div`
  font-size: ${FontSizes.size18};
  font-weight: ${FontWeights.semibold};
  margin-top: 16px;

  ${minWidth.xl} {
    font-size: ${FontSizes.size26};
    margin-top: 24px;
  }
`;

// TODO: Leaving this here as the current font size doesn't quite fit the design
export const FeedTitle = styled.div`
  font-size: ${FontSizes.size12};
  font-weight: ${FontWeights.semibold};
  margin-top: 16px;

  ${minWidth.xl} {
    font-size: ${FontSizes.size16};
    margin-top: 24px;
  }
`;

export const Description = styled.div`
  margin-top: 12px;

  ${minWidth.xl} {
    margin-top: 16px;
  }
`;

export const ChildContainer = styled.div`
  margin-top: 32px;

  ${minWidth.xl} {
    margin-top: 48px;
  }
`;
