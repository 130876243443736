export interface IOOFState {
  BlockCalendarStatus: ICalendarStatusState;
  CancelConnectorStatus: ICalendarStatusState;
  CancelMeetingsStatus: ICalendarStatusState;
  GetEventsStatus: ICalendarStatusState;
  NonBlockingEventStatus: ICalendarStatusState;
  OofOptions: OOFOptions;
  OofStatus: Status;
  OofStatusIsVisible: boolean;
  RespondToMeetingsStatus: ICalendarStatusState;
  SendEmailStatus: ICalendarStatusState;
  SetAutomaticReplyStatus: ICalendarStatusState;
  OofPanelToggle: boolean;
  ConnectorItineraries: {};
  OofConfig: {};
}

export interface ICalendarStatusState {
  error: string;
  status: Status;
}
export declare type ToggleName =
  | 'blockCalendar'
  | 'cancelConnector'
  | 'cancelMeetings'
  | 'respondToMeetings'
  | 'setAutomaticReply'
  | ToggleNameWithRecipients;
export declare type ToggleNameWithRecipients = 'nonBlockingEvent' | 'sendEmail';

export declare type OOFOptions = {
  blockCalendar: boolean;
  cancelConnector: boolean;
  cancelMeetings: boolean;
  nonBlockingEvent: boolean;
  respondToMeetings: boolean;
  sendEmail: boolean;
  setAutomaticReply: boolean;
};
export enum OOFOptionKeys {
  BlockCalendar = 'blockCalendar',
  CancelConnector = 'cancelConnector',
  CancelMeetings = 'cancelMeetings',
  NonBlockingEvent = 'nonBlockingEvent',
  RespondToMeetings = 'respondToMeetings',
  SendEmail = 'sendEmail',
  SetAutomaticReply = 'setAutomaticReply',
}
export declare type GraphEmail = {
  address: string;
  name: string;
};
export declare type OOFEvent = {
  attendees?: GraphEmail[];
  description: string;
  fromDate: string;
  title: string;
  toDate: string;
};
export declare type OOFConfig = {
  /** TODO: content: JSX.Element; **/
  events: OOFEvent[];
  toggleConfig: OOFOptions;
};
export declare type OOFEmailInfo = {
  content: string;
  recipients: Array<{
    emailAddress: GraphEmail;
  }>;
};
export declare type OOFPayload = {
  automaticReplyMessage?: string;
  cancelConnectorItineraries?: ConnectorItinerary[];
  emailInfo: OOFEmailInfo;
  events: OOFEvent[];
  options: OOFOptions;
};
export declare type ConnectorItinerary = {
  actualArrivalTime: string;
  actualDepartureTime: string;
  bookingId: number;
  bookingStatusMessage: string;
  bookingSuccessful: boolean;
  dropOffId: number;
  dropOffLatitude: number;
  dropOffLongitude: number;
  dropOffName: string;
  estimatedArrivalTime: string;
  estimatedDepartureTime: string;
  isBikeRackRequest: boolean;
  isConfirmed: boolean;
  isRecurring: boolean;
  isStandbyBikeRack: boolean;
  needsWheelChairLift: boolean;
  pickupId: number;
  pickupLatitude: number;
  pickupLongitude: number;
  pickupName: string;
  recurringDayOfWeek: string;
  routeId: number;
  routeMasterId: number;
  routeMasterName: string;
  seats: number;
  standBy: number;
  status: null;
  tripDate: string;
  tripId: number;
  vehicleLatitude: number;
  vehicleLongitude: number;
  vehicleName: string;
};

export declare type OOFOperationStatus = {
  error: string;
  status: Status;
};

export declare type Attendee = {
  emailAddress: GraphEmail;
  status: {
    response: 'none' | 'organizer' | 'tentativelyAccepted' | 'accepted' | 'declined' | 'notResponded';
    time: string;
  };
  type: 'required' | 'optional' | 'resource';
};

export declare type AutomaticRepliesSetting = {
  externalAudience?: ExternalAudienceScope;
  externalReplyMessage?: string;
  internalReplyMessage: string;
  scheduledEndDateTime: DateTimeTimeZone;
  scheduledStartDateTime: DateTimeTimeZone;
  status: AutomaticReplyStatus;
};

export enum ExternalAudienceScope {
  All = 'all',
  ContactsOnly = 'contactsOnly',
  None = 'none',
}

export declare type DateTimeTimeZone = {
  dateTime: string;
  timeZone: string;
};

export enum AutomaticReplyStatus {
  AlwaysEnabled = 'alwaysEnabled',
  Disabled = 'disabled',
  Scheduled = 'scheduled',
}

export enum Status {
  Failed = 'failed',
  InProgress = 'inProgress',
  NotStarted = 'notStarted',
  Success = 'success',
}

export enum OOFStatusType {
  GetEvents = 'Getting your events',
  CancelConnector = 'Cancelling your Connector',
  CancelMeetings = 'Cancelling meetings',
  SetAutomaticReply = 'Setting your automatic replies',
  RespondToMeetings = 'Responding to invites',
  BlockCalendar = 'Creating a blocking calendar event',
  NonBlockingEvent = 'Creating a non-blocking calendar event',
  SendEmail = 'Sending email',
}

export declare type OOFStatuses = OOFStatusType[];

export declare type EmailMessage = {
  body: {
    content: string;
    contentType: 'text' | 'html';
  };
  subject: string;
  toRecipients: Array<{
    emailAddress: GraphEmail;
  }>;
};

export interface ISaga {
  oofSagas: () => Generator;
}

export declare type Meeting = {
  attendees?: Attendee[];
  body?: {
    content: string;
    contentType: 'text' | 'html';
  };
  end?: DateTimeTimeZone;
  id?: string;
  isAllDay?: boolean;
  organizer?: {
    emailAddress: GraphEmail;
  };
  responseRequested?: boolean;
  showAs: ShowAsValues;
  start?: DateTimeTimeZone;
  subject: string;
};

export enum ShowAsValues {
  Busy = 'busy',
  Free = 'free',
  Oof = 'oof',
  Tentative = 'tentative',
  Unknown = 'unknown',
  WorkingElsewhere = 'workingElsewhere',
}

export declare type Recipient = {
  givenName: string;
  imageSrc:
    | {
        uri: string;
      }
    | undefined;
  name: string;
  surname: string;
  upn: string;
};

export declare const OOFParamName = 'oofOptions';
export declare const OOFSearchParamName = 'toggleName';
export declare type OOFPageConfig = {
  [OOFParamName]: OOFConfig;
};

export declare type Result<T = Record<string, unknown>> = {
  data?: T;
  error?: Error | Record<string, unknown>;
  status?: number;
};

export declare type User = {
  alias: string;
  /**
   * Name of the user
   */
  businessPhones?: string[];
  displayName: string;
  entityType: string;
  givenName: string;
  id: string;
  initials: string;
  /**
   * JobTitle of the user
   */
  jobTitle: string;
  mail: string;
  mobilePhone: string;
  /**
   * OfficeLocation of the user
   */
  officeLocation?: string;
  officeLocLat: string;
  officeLocLong: string;
  surname: string;
  /**
   * User's alias email address i.e. alias@microsoft.com
   */
  userPrincipalName: string;
  workPhone: string;
};
