import * as React from 'react';
import { useState } from 'react';
import * as Styled from './Card.styled';
import { Announced } from 'office-ui-fabric-react/lib/Announced';
import { ICardProps } from './Card.types';
import { CardSizes } from '../Shared/Models/CardSizes';
import { IContextualMenuItem, CommandButton } from 'office-ui-fabric-react';
import { ErrorCard } from './ErrorCard';
import { ComponentProvider } from '@employee-experience/common/lib/ComponentProvider';
import { IComponentConfig } from '@employee-experience/common/lib/Models';
import { MenuItemStyles } from './Card.styled';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { useContext } from 'react';
import { removeCardAction, updateCardAction } from '../Dashboard.actions';
import { LoadingCard } from './LoadingCard';
import { TelemetryService } from '../../../Shared/shared';
import { SubCapability, EventName, Capability } from '../../../Shared/Types';
import { OofPanelToggle, receiveOofConfig } from '../../../Shared/Components/OOF/OOF.actions';
import { OOFConfig } from '../../../Shared/Components/oof/OOF.types';

export const Card: React.FC<ICardProps> = React.memo((props: ICardProps) => {
  const { id, scriptPath, size, title, componentName, data } = props;
  const { dispatch } = useContext(ReduxContext);
  const [message, setMessage] = useState();
  // Sample Code to Onboard Partner CDN
  // const [notificationMessage, setNotificationMessage] = React.useState<string>('No Notifications');
  // function callbackFunction(childData: string): void {
  //     setNotificationMessage((prev: string) => childData);
  // }

  const componentConfig: IComponentConfig = {
    id: id,
    name: componentName,
    script: scriptPath,
  };

  function removeCard(): void {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Dashboard_RemoveCard,
      eventName: EventName.BUTTON_CLICKED,
    });
    dispatch(removeCardAction(id));
  }

  function resizeCard(newSize: CardSizes): void {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Dashboard_ResizeCard,
      eventName: EventName.BUTTON_CLICKED,
    });
    dispatch(
      updateCardAction({
        id: id,
        size: newSize,
      })
    );
    setMessage(newSize + ' card selected');
  }

  const resizeOptions = Object.values(CardSizes).map(
    (item: CardSizes): IContextualMenuItem => ({
      key: item,
      text: item,
      ariaLabel: (size === item ? ' Selected' : '') + item.toString(),
      iconProps: size === item ? { iconName: 'CheckMark', style: MenuItemStyles } : {},
      onClick: (): void => resizeCard(item),
    })
  );

  /* To Handle OOF callback from Partner Card */
  const handleOofData = (data: OOFConfig): void => {
    TelemetryService.trackEvent(
      {
        capability: Capability.OOF,
        subCapability: SubCapability.OOF_PARTNER_CALLBACK,
        eventName: EventName.BUTTON_CLICKED,
      },
      data
    );
    dispatch(receiveOofConfig(data));
    dispatch(OofPanelToggle(true));
  };

  return (
    <Styled.Container>
      <Announced message={message} aria-live="assertive" />
      <Styled.Header>
        <Styled.Title>{title}</Styled.Title>
        <CommandButton
          menuProps={{
            items: [
              {
                key: 'resize',
                text: 'Resize',
                iconProps: {
                  iconName: 'SnapToGrid',
                  style: MenuItemStyles,
                },
                subMenuProps: {
                  items: resizeOptions,
                },
                style: MenuItemStyles,
              },
              {
                key: 'remove',
                text: 'Remove',
                iconProps: {
                  iconName: 'Delete',
                  style: MenuItemStyles,
                },
                onClick: (): void => removeCard(),
              },
            ],
          }}
          menuIconProps={{ iconName: 'More' }}
          ariaLabel={`See ${title} card options`}
          title={`See ${title} card options`}
        />
      </Styled.Header>
      <Styled.Body>
        <ComponentProvider
          renderPlaceholder={(): React.ReactElement => <LoadingCard />}
          config={componentConfig}
          renderError={(): React.ReactElement => <ErrorCard />}
          data={{
            size: size,
            sendOofData: handleOofData,
            ...(data != undefined ? data : {}),
          }}
        />
      </Styled.Body>
    </Styled.Container>
  );
});

Card.displayName = 'Card';
