import { IDropdownOption } from 'office-ui-fabric-react/lib/components/Dropdown/Dropdown.types';

//TODO[SaloniDaga 20Mar2020] : Once finalized, categories for feedback and reportProblem needs to be picked up from the dashboard.resources cardname
const managerCategories: IDropdownOption[] = [
  { key: 'Dashboard', text: 'Dashboard' },
  { key: 'Workforce Management ', text: 'Workforce Management' },
  { key: 'Approvals', text: 'Approvals' },
  { key: 'Hiring', text: 'Hiring' },
  { key: 'Hiring v2', text: 'Hiring v2' },
  { key: 'Learning', text: 'Learning' },
  { key: 'Insight', text: 'Insight' },
];

const commonCategories: IDropdownOption[] = [
  { key: 'MyHub', text: 'MyHub' },
  { key: 'App Performance', text: 'App Performance' },
  { key: 'Search', text: 'Search' },
  { key: 'Accessibility', text: 'Accessibility' },
  { key: 'Security', text: 'Security' },
];

const myHubCategories: IDropdownOption[] = [
  { key: 'Cafe', text: 'Cafe' },
  { key: 'Connector', text: 'Connector' },
  { key: 'Food Truck', text: 'Food Truck' },
  { key: 'Shuttle', text: 'Shuttle' },
  { key: 'Stay Fit', text: 'Stay Fit' },
  { key: 'Time Off', text: 'Time Off' },
];

export const ticketStates = [
  { label: 'New', value: '1' },
  { label: 'Resolved', value: '6' },
  { label: 'Scheduled', value: '-1' },
  { label: 'Active', value: '2' },
  { label: 'Assigned', value: '8' },
  { label: 'Awaiting User', value: '9' },
  { label: 'Awaiting 3rd party', value: '11' },
  { label: 'Closed', value: '7' },
];

export const issueTypes = [
  { key: 'Tool Issue', text: 'Tool Issue' },
  { key: 'Data Issue', text: 'Data Issue' },
  { key: 'Information', text: 'Information' },
];
export const getCategories = (isManagerHubUser: boolean, isMyHubUser: boolean): IDropdownOption[] => {
  let category: IDropdownOption[];
  category = commonCategories;

  if (isMyHubUser) {
    category = [...category, ...myHubCategories];
  }
  if (isManagerHubUser) {
    category = [...category, ...managerCategories];
  }
  return category;
};

export const _MYHUB_WEBUX_SERVICETREE_CI_SYSID_ = '461847161b9ad8d411d477bc1d4bcbdd';
