import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths';
import { NeutralColors, SurfaceColors } from '@cseo/styles';
import { FontWeights } from 'office-ui-fabric-react';

export const Container = styled.div`
  background-color: ${SurfaceColors.primary};
  border-radius: 2px;
  box-shadow: ${Depths.depth4};
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid ${NeutralColors.gray40};
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
  padding-left: 16px;
  // Excluding the 4px default button padding.
  padding-right: 12px;
`;

export const Title = styled.div`
  font-weight: ${FontWeights.semibold};
`;

export const Body = styled.div`
  // Removing header height.
  height: calc(100% - 40px);
  overflow: hidden;
  width: 100%;
`;

export const MenuItemStyles = { color: NeutralColors.black };
