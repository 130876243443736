import { ActionTypes, ISetLocation, IFetchLocationCoordinates, ISetLocationPermissions } from './Location.action-types';

export function setLocationPermissions(item: string): ISetLocationPermissions {
  return {
    type: ActionTypes.SET_LOCATION_PERMISSIONS,
    item,
  };
}

export function setLocation(location: { latitude: number; longitude: number; accuracy: number }): ISetLocation {
  return {
    type: ActionTypes.SET_LOCATION,
    location,
  };
}

export function fetchLocationCoordinates(): IFetchLocationCoordinates {
  return {
    type: ActionTypes.UPDATE_LOCATION_PERMISSIONS_AND_COORDINATES,
  };
}
