import { OOFOperationStatus, OOFPayload, OOFOptions, Status, OOFConfig } from './OOF.types';

export enum OOFActionTypes {
  MANAGE_OOF = 'MANAGE_OOF',
  RESPOND_TO_MEETINGS_STATUS = 'RESPOND_TO_MEETINGS_STATUS',
  BLOCK_CALENDAR_STATUS = 'BLOCK_CALENDAR_STATUS',
  CANCEL_CONNECTOR_STATUS = 'CANCEL_CONNECTOR_STATUS',
  CANCEL_MEETINGS_STATUS = 'CANCEL_MEETINGS_STATUS',
  GET_EVENTS_STATUS = 'GET_EVENTS_STATUS',
  NON_BLOCKING_EVENT_STATUS = 'NON_BLOCKING_EVENT_STATUS',
  RESET_OOF_STATUS = 'RESET_OOF_STATUS',
  RESET_OOF_STATUS_VISIBILITY = 'RESET_OOF_STATUS_VISIBILITY',
  SEND_EMAIL_STATUS = 'SEND_EMAIL_STATUS',
  SET_AUTOMATIC_REPLY_STATUS = 'SET_AUTOMATIC_REPLY_STATUS',
  UPDATE_OOF_OPTIONS = 'UPDATE_OOF_OPTIONS',
  UPDATE_OOF_STATUS = 'UPDATE_OOF_STATUS',
  UPDATE_OOF_STATUS_VISIBILITY = 'UPDATE_OOF_STATUS_VISIBILITY',
  OOF_PANEL_TOGGLE = 'OOF_PANEL_TOGGLE',
  OOF_STATUS_PANEL_TOGGLE = 'OOF_STATUS_PANEL_TOGGLE',
  RECEIVE_OOF_CONFIG = 'RECEIVE_OOF_CONFIG',
}

export enum ConnectorActionTypes {
  GET_CONNECTOR_ITINERARIES = 'GET_CONNECTOR_ITINERARIES',
  GET_CONNECTOR_ITINERARIES_SUCCESS = 'GET_CONNECTOR_ITINERARIES_SUCCESS',
  GET_CONNECTOR_ITINERARIES_FAILED = 'GET_CONNECTOR_ITINERARIES_FAILED',
}

export interface IManageOOFAction {
  type: OOFActionTypes.MANAGE_OOF;
  payload: OOFPayload;
}

export interface IRespondToMeetingsStatusAction {
  type: OOFActionTypes.RESPOND_TO_MEETINGS_STATUS;
  payload: OOFOperationStatus;
}

export interface IResetOOFStatusAction {
  type: OOFActionTypes.RESET_OOF_STATUS;
}
export interface IBlockCalendarDoneAction {
  type: OOFActionTypes.BLOCK_CALENDAR_STATUS;
  payload: OOFOperationStatus;
}
export interface ICancelConnectorDoneAction {
  type: OOFActionTypes.CANCEL_CONNECTOR_STATUS;
  payload: OOFOperationStatus;
}
export interface ICancelMeetingsDoneAction {
  type: OOFActionTypes.CANCEL_MEETINGS_STATUS;
  payload: any;
}
export interface IGetEventsStatusAction {
  type: OOFActionTypes.GET_EVENTS_STATUS;
  payload: OOFOperationStatus;
}
export interface INonBlockingEventDoneAction {
  type: OOFActionTypes.NON_BLOCKING_EVENT_STATUS;
  payload: OOFOperationStatus;
}
export interface IUpdateOOFOptionsAction {
  type: OOFActionTypes.UPDATE_OOF_OPTIONS;
  payload: OOFOptions;
}
export interface IUpdateOOFStatusAction {
  type: OOFActionTypes.UPDATE_OOF_STATUS;
  payload: Status;
}
export interface IUpdateOOFStatusVisibilityAction {
  type: OOFActionTypes.UPDATE_OOF_STATUS_VISIBILITY;
  payload: boolean;
}
export interface ISendEmailDoneAction {
  type: OOFActionTypes.SEND_EMAIL_STATUS;
  payload: OOFOperationStatus;
}
export interface ISetAutomaticReplyDoneAction {
  type: OOFActionTypes.SET_AUTOMATIC_REPLY_STATUS;
  payload: OOFOperationStatus;
}

export interface IOOFPanelToggleAction {
  type: OOFActionTypes.OOF_PANEL_TOGGLE;
  isOpen: boolean;
}

export interface IToggleOOFStatusPanelToggleAction {
  type: OOFActionTypes.OOF_STATUS_PANEL_TOGGLE;
  isOpen: boolean;
}
/**
 * Action to get connector itineraries
 * @param payload
 */
export interface IFetchConnectorItinerariesAction {
  type: ConnectorActionTypes.GET_CONNECTOR_ITINERARIES;
}

export interface IFetchConnectorItinerariesSuccessAction {
  type: ConnectorActionTypes.GET_CONNECTOR_ITINERARIES_SUCCESS;
  payload: any;
}
export interface IFetchConnectorItinerariesFailedAction {
  type: ConnectorActionTypes.GET_CONNECTOR_ITINERARIES_FAILED;
  payload: any;
}

export interface IReceiveOOFConfigAction {
  type: OOFActionTypes.RECEIVE_OOF_CONFIG;
  payload: OOFConfig;
}

export type IOOFActions =
  | IManageOOFAction
  | IRespondToMeetingsStatusAction
  | IResetOOFStatusAction
  | IBlockCalendarDoneAction
  | ICancelConnectorDoneAction
  | ICancelMeetingsDoneAction
  | IGetEventsStatusAction
  | INonBlockingEventDoneAction
  | IUpdateOOFOptionsAction
  | IUpdateOOFStatusAction
  | IUpdateOOFStatusVisibilityAction
  | ISendEmailDoneAction
  | ISetAutomaticReplyDoneAction
  | IOOFPanelToggleAction
  | IToggleOOFStatusPanelToggleAction
  | IFetchConnectorItinerariesAction
  | IFetchConnectorItinerariesSuccessAction
  | IFetchConnectorItinerariesFailedAction
  | IReceiveOOFConfigAction;
