import { all, put, takeLatest, getContext, call, SimpleEffect } from 'redux-saga/effects';
import moment from 'moment';
import { ActionTypes } from './Feed.action-types';
import { INewsModel } from './Models/INewsModel';
import { IEventsModel } from './Models/IEventsModel';
import { IAuthClient, IUser } from '@employee-experience/common/lib/Models';
import { HttpClient } from '@employee-experience/core';
import { IFeedData } from './Models/IFeedData';
import { ApiHelper } from '../../../Shared/Helpers/ApiHelper';
import { isUri } from 'valid-url';
import {
  receiveEventsAction,
  receiveNewsAction,
  receiveMicrosoftLifeDataFailedAction,
  receiveEventFeedDataFailedAction,
  receiveEventFeedDataSucceededAction,
  receiveNewsFeedDataFailedAction,
  receiveNewsFeedDataSucceededAction,
  receiveMicrosoftLifeDataSucceededAction,
} from './Feed.actions';
import { IMicrosoftLifeModel } from './Models/IMicrosoftLifeModel';

/**
 * Filters out past events
 */
const filterEvents = (events: IEventsModel[]): IEventsModel[] => {
  return events.filter((event: IEventsModel) => moment(event.EndDate).isSameOrAfter(moment()));
};

export function* getMicrosoftLife(): IterableIterator<SimpleEffect<{}, {}>> {
  const httpClient: HttpClient = yield getContext('httpClient');
  try {
    const MAX_POST_COUNT = 20;
    const { data: microsoftLifeData }: { data: IMicrosoftLifeModel[] } = yield call([httpClient, httpClient.request], {
      url: `${__API__.myMsftApim.resourceUrl}/microsoftlife/posts/357087?count=${MAX_POST_COUNT}`,
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    });

    yield put(receiveMicrosoftLifeDataSucceededAction(microsoftLifeData));
  } catch (error) {
    // TODO: Log error.
    yield put(receiveMicrosoftLifeDataFailedAction());
  }
}

export function* getNewsFeedData(): IterableIterator<SimpleEffect<{}, {}>> {
  const httpClient: HttpClient = yield getContext('httpClient');
  const authClient: IAuthClient = yield getContext('authClient');
  try {
    const user: IUser = yield call([authClient, authClient.getUser]);
    const { data: feedData }: { data: IFeedData[] } = yield call([httpClient, httpClient.request], {
      url: __API__.myMsftApim.resourceUrl + '/feed/user/' + user.id + '/feed?type=news',
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    });

    let news: INewsModel[] = [];
    feedData.map((item: IFeedData) => {
      /* Check to see if the Link provided is a valid Uri. Note: We intentionally do not check if it is a http or
            https url just in case magnet links and other alternative (but valid) urls are provided instead
            */
      if (!isUri(item.Link)) {
        item.Link = '';
      }
      news.push(item as INewsModel);
    });

    news = news.sort((a, b) => new Date(b.PublicationDate).getTime() - new Date(a.PublicationDate).getTime());

    yield put(receiveNewsAction(news));
    yield put(receiveNewsFeedDataSucceededAction());
  } catch (error) {
    // TODO: Log error.
    yield put(receiveNewsFeedDataFailedAction());
  }
}

export function* getEventsFeedData(): IterableIterator<SimpleEffect<{}, {}>> {
  const httpClient: HttpClient = yield getContext('httpClient');
  const authClient: IAuthClient = yield getContext('authClient');
  try {
    const user: IUser = yield call([authClient, authClient.getUser]);
    const { data: feedData }: { data: IFeedData[] } = yield call([httpClient, httpClient.request], {
      url: __API__.myMsftApim.resourceUrl + '/feed/user/' + user.id + '/feed?type=events',
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    });
    let events: IEventsModel[] = [];
    feedData.map((item: IFeedData) => {
      /* Check to see if the Link provided is a valid Uri. Note: We intentionally do not check if it is a http or
            https url just in case magnet links and other alternative (but valid) urls are provided instead
            */
      if (!isUri(item.Link)) {
        item.Link = '';
      }
      events.push(item as IEventsModel);
    });

    events = filterEvents(events);
    events = events.sort((a, b) => new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime());

    yield put(receiveEventsAction(events));
    yield put(receiveEventFeedDataSucceededAction());
  } catch (error) {
    // TODO: Log error.
    yield put(receiveEventFeedDataFailedAction());
  }
}

export function* feedSagas(): IterableIterator<{}> {
  yield all([
    takeLatest(ActionTypes.REQUEST_NEWS_FEED_DATA, getNewsFeedData),
    takeLatest(ActionTypes.REQUEST_EVENT_FEED_DATA, getEventsFeedData),
    takeLatest(ActionTypes.REQUEST_MICROSOFT_LIFE, getMicrosoftLife),
  ]);
}
