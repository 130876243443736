import * as React from 'react';
import { Card, CardBody, CardContainer } from './../Card';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { eventFeedLoadingStatus, eventsSelector } from '../Feed.selectors';
import { LoadingScreen } from '../../../../Shared/Components/LoadingScreen';
import { ErrorScreen } from '../../../../Shared/Components/ErrorScreen';
import { LoadingStates } from '../../../../Shared/Models/LoadingStates';
import { Usage, Capability, SubCapability, EventName } from '../../../../Shared/Types';
import { usePageTracker } from '../../../../Shared/Hooks/usePageTracker';
import { FeedCarousel, FeedCarouselItem } from '../FeedCarousel';
import { IEventsModel } from '../Models/IEventsModel';
import { getEventFeedItemProps } from './EventCard/EventCard.util';
import { CarouselContainer } from '../Feed.styled';
import { Page, PageHeadingContainer, PageHeading } from '../../../../Styles/Page';
import { useState } from 'react';
import { requestEventsFeedDataAction } from '../Feed.actions';

export function Events({ isScrollable }: { isScrollable: boolean }): React.ReactElement {
  const { useSelector, dispatch } = React.useContext(ReduxContext);
  const [itemNo, setItemNumber] = useState(0);
  let eventsList = useSelector(eventsSelector);
  if (eventsList.length > 16 && isScrollable) {
    const seeMore = [
      {
        seeMore: true,
        Title: 'See All',
        Link: '/events',
      },
    ];
    eventsList = [...eventsList, ...seeMore];
  }
  const eventItemsLoadingStatus = useSelector(eventFeedLoadingStatus);
  const pageEvent: Usage = {
    capability: Capability.MyHub,
    subCapability: SubCapability.Events,
    eventName: EventName.PAGE_LOAD,
  };
  const elementNumber = (itemNumber: number): void => {
    setItemNumber(itemNumber);
  };
  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const reloadEvents = (): void => {
    dispatch(requestEventsFeedDataAction());
  };
  usePageTracker(pageEvent);
  window.scrollTo(0, 0);
  if (eventItemsLoadingStatus === LoadingStates.NOT_STARTED || eventItemsLoadingStatus === LoadingStates.STARTED) {
    return <LoadingScreen innerPageUse={true} />;
  } else if (eventItemsLoadingStatus === LoadingStates.FAILED || !eventsList.length) {
    return (
      <CarouselContainer>
        <FeedCarousel header="Events" seeMoreLink="/events">
          <Card>
            <CardBody>
              <ErrorScreen
                innerPageUse={true}
                showTitle={true}
                showDescription={true}
                imagePath={'/assets/binocularWoman.svg'}
                onRefresh={reloadEvents}
                imageSize="medium"
                title="Nothing to see here"
                description="Check back later"
                noButton
                titleSize
              />
            </CardBody>
          </Card>
        </FeedCarousel>
      </CarouselContainer>
    );
  } else {
    if (isScrollable) {
      // TODO: Should get error/ empty screens for horizontal feed scrollF
      return (
        <CarouselContainer>
          <FeedCarousel currentFocusElement={itemNo} header="Events" seeMoreLink="/events">
            {eventsList &&
              eventsList.map((post: IEventsModel, index: number) => (
                <FeedCarouselItem
                  {...getEventFeedItemProps(post)}
                  seeMoreCard={post.seeMore}
                  getFocusEle={elementNumber}
                  itemNumber={index}
                  showBookmark={true}
                  key={index}
                />
              ))}
          </FeedCarousel>
        </CarouselContainer>
      );
    }

    return (
      <Page>
        <PageHeadingContainer>
          <PageHeading tabIndex={0} aria-label="Events">
            Events
          </PageHeading>
        </PageHeadingContainer>
        <CardContainer>
          {eventsList &&
            eventsList.map((item: IEventsModel, index: number) => (
              <FeedCarouselItem
                {...getEventFeedItemProps(item)}
                descLines={4}
                itemNumber={index}
                showBookmark={true}
                showFeedSponsorTitle={true}
                showDescription={true}
                key={index}
              />
            ))}
        </CardContainer>
      </Page>
    );
  }
}
