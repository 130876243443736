import { css, FlattenSimpleInterpolation } from 'styled-components';
import { maxWidth, minWidth } from '../../Styles/Media';
import { Link } from 'office-ui-fabric-react';
import { NeutralColors } from '@cseo/styles';

export const Container = styled.div<{ isNavOpen: boolean }>`
  border-top: 1px solid ${NeutralColors.gray60};
  height: 80px;
  padding-bottom: 30px;
  padding-top: 30px;

  ${minWidth.xl} {
    ${({ isNavOpen }): FlattenSimpleInterpolation =>
      isNavOpen
        ? css`
            margin-left: 180px;
            width: calc(100% - 180px);
          `
        : css`
            width: 100%;
          `}
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  margin-right: 15px;

  ${minWidth.m} {
    margin-left: 26px;
    margin-right: 26px;
  }

  ${minWidth.l} {
    margin-left: 34px;
    margin-right: 34px;
  }

  ${minWidth.xl} {
    margin-left: 48px;
    margin-right: 48px;
  }
`;

export const FrequentlyUsedLink = styled(Link)`
  margin-left: 10px;
`;

export const FrquentlyUsedLinksSection = styled.div`
  ${maxWidth.xl} {
    display: none;
  }
`;

export const CopyrightSection = styled.div`
  // Right align copyright section.
  margin-left: auto;
`;
