import { ILocationAction, ActionTypes } from './Location.action-types';
import { ILocationState } from './Location.types';

export const initialLocationState: ILocationState = {
  locationValue: '',
  locationCoordinates: { latitude: 0, longitude: 0, accuracy: 0 },
};

export function LocationReducer(prev: ILocationState = initialLocationState, action: ILocationAction): ILocationState {
  switch (action.type) {
    case ActionTypes.SET_LOCATION_PERMISSIONS: {
      return {
        ...prev,
        locationValue: action.item,
      };
    }
    case ActionTypes.SET_LOCATION: {
      return {
        ...prev,
        locationCoordinates: action.location,
      };
    }
    default:
      return prev;
  }
}
