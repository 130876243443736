import * as React from 'react';
import { ISplashScreenProps } from './SplashScreen.types';
import * as Styled from './SplashScreen.styled';
import { CenterContainer } from '../../Components/CenterContainer';

/**
 * This method returns the image component with the required size
 * @param imageAltText String: alternate text if image doesn't load
 * @param imagePath String: path of the image
 * @param imageSize String: ex: "large" (optional)
 */
const getImage = (imageAltText, imagePath, imageSize): React.ReactElement => {
  const IMAGE_MAP = {
    large: <Styled.NoFeedErrorImage alt={imageAltText || ''} src={imagePath} />,
    small: <Styled.Image alt={imageAltText || ''} src={imagePath} />,
  };
  return IMAGE_MAP[imageSize] || IMAGE_MAP.small;
};
export const SplashScreen: React.FC<ISplashScreenProps> = (props: ISplashScreenProps): React.ReactElement => {
  const { title, description, imagePath, innerPageUse, children, imageAltText, imageSize, titleSize } = props;

  // TODO: The below implementation modifies the current UI to fit within the design scope
  // Once approved by Design, uncomment and use the mainTitle variable for the title component.

  // let mainTitle;
  // if (title && !titleSize) {
  //     mainTitle = <Styled.Title tabIndex={0}>{title}</Styled.Title>
  // } else if (title && titleSize) {
  //     mainTitle = <Styled.FeedTitle tabIndex={0}>{title}</Styled.FeedTitle>
  // }
  return (
    <CenterContainer innerPageUse={innerPageUse}>
      {imagePath && getImage(imageAltText, imagePath, imageSize)}
      {title && <Styled.Title tabIndex={0}>{title}</Styled.Title>}
      {description && <Styled.Description tabIndex={0}>{description}</Styled.Description>}
      {children && <Styled.ChildContainer>{children}</Styled.ChildContainer>}
    </CenterContainer>
  );
};
