import { all, put, takeLatest, getContext, call, SimpleEffect } from 'redux-saga/effects';
import { HttpClient } from '@employee-experience/core';
import { ActionTypes } from './Admin.action-types';
import { ApiHelper } from '../../Shared/Helpers/ApiHelper';
import {
  NotificationPayload,
  ISgUsersModel,
  UsersDataSearchModel,
  SgUserDataSearchModel,
  SgDataSearchModel,
  IReferenceData,
} from './Admin.types';
import { TelemetryService } from '../../Shared/shared';
import { size } from 'lodash-es';
import { fetchCompanyCodeDataSuccess, fetchCountryCodeDataSuccess } from './Admin.actions';
import { IDropdownOption } from 'office-ui-fabric-react';

export function* checkUserSGMembership(action: { securityGroupId: string }): IterableIterator<SimpleEffect<{}, {}>> {
  const httpClient: HttpClient = yield getContext('httpClient');
  try {
    const { data: result } = yield call(
      [httpClient, httpClient.post],
      __API__.myMsftApim.resourceUrl + `/msgraph/v1.0/me/checkMemberGroups`,
      {
        resource: __API__.myMsftApim.resourceId,
        header: ApiHelper.getMyMsftApiHeaders(),
        data: {
          groupIds: [action.securityGroupId],
        },
      }
    );
    yield put({
      type: ActionTypes.CHECK_USER_SG_MEMBERSHIP_SUCCESS,
      result,
      securityGroupId: action.securityGroupId,
    });
  } catch (error) {
    TelemetryService.trackException(error);
    yield put({ type: ActionTypes.CHECK_USER_SG_MEMBERSHIP_FAILED, error });
  }
}

export function* submitNotification(action: { payload: NotificationPayload }): IterableIterator<SimpleEffect<{}, {}>> {
  const httpClient: HttpClient = yield getContext('httpClient');
  try {
    const { data: result } = yield call(
      [httpClient, httpClient.post],
      __API__.myMsftApim.resourceUrl + `/tasks/api/createNotificationEvent`,
      {
        resource: __API__.myMsftApim.resourceId,
        header: ApiHelper.getMyMsftApiHeaders(),
        data: {
          typeVersion: '1.0',
          extension: {},
          specversion: '1.0',
          data: action.payload,
        },
      }
    );
    yield put({ type: ActionTypes.SUBMIT_NOTIFICATION_SUCCESS, result });
  } catch (error) {
    TelemetryService.trackException(error);
    yield put({ type: ActionTypes.SUBMIT_NOTIFICATION_FAILED, error });
  }
}

const convertUsers = (users: UsersDataSearchModel[]): ISgUsersModel[] => {
  const resultItems: ISgUsersModel[] = [];
  if (size(users) > 0) {
    users.map((item: UsersDataSearchModel) => {
      const { alias, displayName, jobTitle, initials } = item;
      resultItems.push({
        alias,
        imageInitials: initials,
        isValid: true,
        secondaryText: jobTitle,
        text: displayName,
      });
    });
  }
  return resultItems;
};

const convertGroups = (groups: SgDataSearchModel[]): ISgUsersModel[] => {
  const resultItems: ISgUsersModel[] = [];
  if (size(groups) > 0) {
    groups.map((item: SgDataSearchModel, index) => {
      const { displayName, mail } = item;
      resultItems.push({
        imageInitials: `#${index + 1}`,
        isValid: true,
        secondaryText: mail,
        text: displayName,
        mail,
      });
    });
  }
  return resultItems;
};

const convertDropdownOptions = (referenceData: IReferenceData[]): IDropdownOption[] => {
  const resultItems: IDropdownOption[] = [];
  if (size(referenceData) > 0) {
    referenceData.map((item: IReferenceData) => {
      resultItems.push({
        key: item.code,
        text: `${item.code} - ${item.description}`,
      });
    });
  }
  return resultItems;
};

export function* fetchSgUserSearchData(action: {
  payload: { searchText: string; searchCategory: string; resolve: Function; reject: Function };
}): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: HttpClient = yield getContext('httpClient');
    const { data: response }: { data: SgUserDataSearchModel } = yield call(
      [httpClient, httpClient.get],
      __API__.myMsftApim.resourceUrl +
        `/api/search?searchText=${action.payload.searchText}&noOfRecords=20&includes=${action.payload.searchCategory}
      `,
      {
        resource: __API__.myMsftApim.resourceId,
        header: ApiHelper.getMyMsftApiHeaders(),
      }
    );
    const result =
      action.payload.searchCategory === 'users' ? convertUsers(response.users) : convertGroups(response.groups);
    yield put({ type: ActionTypes.RECEIVE_SG_OR_USERS_DATA_SUCCESS, result });
    action.payload.resolve();
  } catch (error) {
    action.payload.reject();
    TelemetryService.trackException(error);
    yield put({ type: ActionTypes.RECEIVE_SG_OR_USERS_DATA_FAILED, error });
  }
}

export function* fetchCountryCodeData(): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: HttpClient = yield getContext('httpClient');
    const { data: response }: { data: IReferenceData[] } = yield call(
      [httpClient, httpClient.get],
      __API__.myMsftApim.resourceUrl + `/hrcorerefdomain/api/v1/ReferenceDomain/Country/details/en-us`,
      {
        resource: __API__.myMsftApim.resourceId,
        header: ApiHelper.getMyMsftApiHeaders(),
      }
    );
    const result = convertDropdownOptions(response);
    yield put(fetchCountryCodeDataSuccess(result));
  } catch (error) {
    TelemetryService.trackException(error);
    yield put({ type: ActionTypes.COMPANY_CODE_DATA_FAILED, error });
  }
}

export function* fetchCompanyCodeData(): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: HttpClient = yield getContext('httpClient');
    const { data: response }: { data: IReferenceData[] } = yield call(
      [httpClient, httpClient.get],
      __API__.myMsftApim.resourceUrl + `/hrcorerefdomain/api/v1/ReferenceDomain/CompanyCode/details/en-us`,
      {
        resource: __API__.myMsftApim.resourceId,
        header: ApiHelper.getMyMsftApiHeaders(),
      }
    );
    const result = convertDropdownOptions(response);
    yield put(fetchCompanyCodeDataSuccess(result));
  } catch (error) {
    TelemetryService.trackException(error);
    yield put({ type: ActionTypes.COMPANY_CODE_DATA_FAILED, error });
  }
}

export function* adminSagas(): IterableIterator<{}> {
  yield all([takeLatest(ActionTypes.CHECK_USER_SG_MEMBERSHIP, checkUserSGMembership)]);
  yield all([takeLatest(ActionTypes.SUBMIT_NOTIFICATION, submitNotification)]);
  yield all([takeLatest(ActionTypes.RECEIVE_SG_OR_USERS_DATA, fetchSgUserSearchData)]);
  yield all([takeLatest(ActionTypes.COUNTRY_CODE_DATA, fetchCountryCodeData)]);
  yield all([takeLatest(ActionTypes.COMPANY_CODE_DATA, fetchCompanyCodeData)]);
}
