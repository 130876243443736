import { useContext } from 'react';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { getUserGlobalConfig, getUserGlobalDetailsConfig } from '../User/User.selectors';
import { IUserGlobalConfigDetails } from '../Models/User.types';

export function useUserGlobalConfig(configName: string | string[]): { [key: string]: boolean } {
  const result: { [key: string]: boolean } = {};
  const requestedConfigs = Array.isArray(configName) ? [...configName] : [configName];

  const { useSelector } = useContext(ReduxContext);
  const config = useSelector(getUserGlobalConfig);
  requestedConfigs.forEach((requested: string) => {
    const match =
      Object.keys(config).findIndex((key: string): boolean => {
        const configValue = config[key];
        return (
          configValue.name?.toLowerCase() === requested.toLowerCase() && configValue.status?.toLowerCase() === 'active'
        );
      }) !== -1;

    result[requested] = match;
  });

  return result;
}

export function useUserGlobalConfigDetails(): IUserGlobalConfigDetails {
  const { useSelector } = useContext(ReduxContext);
  const config = useSelector(getUserGlobalDetailsConfig);

  return config;
}
