import { IMyHubAppState } from '../../../../App.types';
import { IBookmarkState } from './Bookmarks.types';
import { BookmarkReducerName } from './Bookmarks.resources';
import { initialBookmarkState } from './Bookmarks.reducer';
import { createSelector } from 'reselect';

export const bookmarksSelector = createSelector(
  (state: IMyHubAppState) => state[BookmarkReducerName] ?? initialBookmarkState,
  (bookmarkState: IBookmarkState) => bookmarkState.Bookmarks
);

export const bookmarksLoadingStatusSelector = createSelector(
  (state: IMyHubAppState) => state[BookmarkReducerName] ?? initialBookmarkState,
  (bookmarkState: IBookmarkState) => bookmarkState.BookmarkLoadingStatus
);
