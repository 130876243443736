import { minWidth } from './Media';
import { NeutralColors, FontSizes } from '@cseo/styles';

export const Page = styled.div`
  margin-left: auto;
  margin-right: auto;
  // xxxl media width - navbar width.
  max-width: 1872px;

  ${minWidth.xl} {
    margin-top: 48px;
  }
`;

export const PageHeadingContainer = styled.div`
  width: 100%;
  align-items: center;
  border-bottom: 1px solid ${NeutralColors.gray40};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // Using padding instead of margin so that the bottom border isn't affected.
  // Left padding to accommodate the small screen nav toggle button.
  padding-left: 48px;
  padding-right: 5vw;
  min-height: 48px;

  ${minWidth.xl} {
    border-bottom: none;
    padding-left: 5vw;
    padding-right: 6.5vw;
  }
`;

export const PageHeading = styled.h1`
  font-size: ${FontSizes.size14};

  ${minWidth.xl} {
    font-size: ${FontSizes.size26};
  }
`;

export const PageBody = styled.div`
  margin-left: 5vw;
  margin-right: 5vw;
`;

export const PageContent = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;
