/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars, no-console */
// KA - Lint rules for newweb are much stricter than they were for shared
// TODO: Fix linter errors resulted from importing this file from "shared"
import { Store } from 'redux';

/**
 * ReducerRegistry enables dynamic reducers.
 * It enables reducers to be added to the store’s reducer after the store is created.
 * MoreInfo: http://nicolasgallagher.com/redux-modules-and-code-splitting/
 */

export interface IReducerRegistry {
  getInitialState(): any | undefined;
  getReducers(): any;
  getStore(): Store | undefined;
  register(name: string, reducer: any): void;
  setChangeListener(listener: any): void;
  setInitialState(initialState: any): void;
  setStore(store: Store): void;
}

export class ReducerRegistryImpl {
  private emitChange: any;
  private initialState: any;
  private reducers: any;
  private store?: Store;

  constructor() {
    this.emitChange = undefined;
    this.reducers = {};
  }

  public getInitialState(): any {
    return { ...this.initialState };
  }

  public getReducers(): any {
    return { ...this.reducers };
  }

  public getStore(): Store | undefined {
    return this.store;
  }

  public register(name: string, reducer: any): void {
    this.reducers = { ...this.reducers, [name]: reducer };
    if (this.emitChange) {
      this.emitChange(this.getReducers());
    }
  }

  public setChangeListener(listener: any): void {
    this.emitChange = listener;
  }

  public setInitialState(state: {}) {
    this.initialState = state;
  }

  public setStore(store: Store) {
    this.store = store;
  }
}

const reducerRegistry: IReducerRegistry = new ReducerRegistryImpl();
export { reducerRegistry };
