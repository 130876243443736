import * as React from 'react';
import { ComponentProvider } from '@employee-experience/common/lib/ComponentProvider';
import { PageBody, Page } from '../../Styles/Page';
import { ErrorScreen } from '../../Shared/Components/ErrorScreen';

export function HRwebSearch(): React.ReactElement {
  return (
    <Page>
      <PageBody>
        <ComponentProvider
          config={__MANAGER_CONFIGS__.search}
          renderError={(): React.ReactElement => <ErrorScreen innerPageUse />}
        />
      </PageBody>
    </Page>
  );
}
