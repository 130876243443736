import {
  OOFActionTypes,
  ConnectorActionTypes,
  IManageOOFAction,
  IRespondToMeetingsStatusAction,
  IResetOOFStatusAction,
  IBlockCalendarDoneAction,
  ICancelConnectorDoneAction,
  ICancelMeetingsDoneAction,
  IGetEventsStatusAction,
  INonBlockingEventDoneAction,
  IUpdateOOFOptionsAction,
  IUpdateOOFStatusAction,
  IUpdateOOFStatusVisibilityAction,
  ISendEmailDoneAction,
  ISetAutomaticReplyDoneAction,
  IOOFPanelToggleAction,
  IFetchConnectorItinerariesAction,
  IReceiveOOFConfigAction,
} from './OOF.action-types';

import { OOFOperationStatus, OOFPayload, OOFOptions, Status, OOFConfig } from './OOF.types';

export function manageOOF(payload: OOFPayload): IManageOOFAction {
  return {
    type: OOFActionTypes.MANAGE_OOF,
    payload,
  };
}

export function updateRespondToMeetingsStatus(payload: OOFOperationStatus): IRespondToMeetingsStatusAction {
  return {
    type: OOFActionTypes.RESPOND_TO_MEETINGS_STATUS,
    payload,
  };
}

export function resetOOFStatus(): IResetOOFStatusAction {
  return {
    type: OOFActionTypes.RESET_OOF_STATUS,
  };
}

export function updateBlockingEventStatus(payload: OOFOperationStatus): IBlockCalendarDoneAction {
  return {
    type: OOFActionTypes.BLOCK_CALENDAR_STATUS,
    payload,
  };
}

export function updateCancelConnectorStatus(payload: OOFOperationStatus): ICancelConnectorDoneAction {
  return {
    type: OOFActionTypes.CANCEL_CONNECTOR_STATUS,
    payload,
  };
}

export function updateCancelMeetingsStatus(payload: OOFOperationStatus): ICancelMeetingsDoneAction {
  return {
    type: OOFActionTypes.CANCEL_MEETINGS_STATUS,
    payload,
  };
}

export function updateGetEventsStatus(payload: OOFOperationStatus): IGetEventsStatusAction {
  return {
    type: OOFActionTypes.GET_EVENTS_STATUS,
    payload,
  };
}

export function updateNonBlockingEventStatus(payload: OOFOperationStatus): INonBlockingEventDoneAction {
  return {
    type: OOFActionTypes.NON_BLOCKING_EVENT_STATUS,
    payload,
  };
}

export function updateOOFOptions(payload: OOFOptions): IUpdateOOFOptionsAction {
  return {
    type: OOFActionTypes.UPDATE_OOF_OPTIONS,
    payload,
  };
}

export function updateOOFStatus(payload: Status): IUpdateOOFStatusAction {
  return {
    type: OOFActionTypes.UPDATE_OOF_STATUS,
    payload,
  };
}

export function updateOOFStatusVisibility(payload: boolean): IUpdateOOFStatusVisibilityAction {
  return {
    type: OOFActionTypes.UPDATE_OOF_STATUS_VISIBILITY,
    payload,
  };
}

export function updateSendEmailStatus(payload: OOFOperationStatus): ISendEmailDoneAction {
  return {
    type: OOFActionTypes.SEND_EMAIL_STATUS,
    payload,
  };
}

export function updateSetAutomaticReplyStatus(payload: OOFOperationStatus): ISetAutomaticReplyDoneAction {
  return {
    type: OOFActionTypes.SET_AUTOMATIC_REPLY_STATUS,
    payload,
  };
}

/**
 * Action to get connector itineraries
 * @param payload
 */
export function fetchConnectorItineraries(): IFetchConnectorItinerariesAction {
  return {
    type: ConnectorActionTypes.GET_CONNECTOR_ITINERARIES,
  };
}

export function OofPanelToggle(isOpen: boolean): IOOFPanelToggleAction {
  return {
    type: OOFActionTypes.OOF_PANEL_TOGGLE,
    isOpen,
  };
}

export function receiveOofConfig(payload: OOFConfig): IReceiveOOFConfigAction {
  return {
    type: OOFActionTypes.RECEIVE_OOF_CONFIG,
    payload,
  };
}
