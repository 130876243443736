import * as React from 'react';
import { SplashScreen } from '../../../Shared/Components/SplashScreen';
import * as Styled from './EmptyDashboardScreen.styled';
import { PrimaryButton } from 'office-ui-fabric-react';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { toggleCatalog, addCardsAction } from '../Dashboard.actions';
import { ICatalogItem } from '../Shared/Models/ICatalogItem';
import { catalogItemsSelector } from '../Dashboard.selectors';
import * as CardService from '../Shared/Services/CardService';
import { useContext } from 'react';
import { Usage, Capability, SubCapability, EventName } from '../../../Shared/Types';
import { usePageTracker } from '../../../Shared/Hooks/usePageTracker';
import { TelemetryService } from '../../../Shared/shared';

export function EmptyDashboardScreen(): React.ReactElement {
  const { dispatch, useSelector } = useContext(ReduxContext);
  const catalogItems: ICatalogItem[] = useSelector(catalogItemsSelector);
  const dashboardEvent: Usage = {
    capability: Capability.MyHub,
    subCapability: SubCapability.Dashboard_Empty,
    eventName: EventName.PAGE_LOAD,
  };
  usePageTracker(dashboardEvent);
  function addCardsButtonClicked(): void {
    TelemetryService.trackEvent({
      ...dashboardEvent,
      subCapability: SubCapability.Dashboard_AddCard,
      eventName: EventName.BUTTON_CLICKED,
    });
    dispatch(toggleCatalog(true));
  }

  function getDefaultSetButtonClicked(): void {
    TelemetryService.trackEvent({
      ...dashboardEvent,
      subCapability: SubCapability.Dashboard_GetDefault,
      eventName: EventName.BUTTON_CLICKED,
    });
    dispatch(addCardsAction(CardService.getNewCards(catalogItems.map((item) => item.id))));
  }

  return (
    <SplashScreen
      description="Customise your dashboard by adding cards from the catalogue."
      imagePath="/assets/Shared/Page/Empty.svg"
      title="Add cards to see them on your dashboard."
      innerPageUse={true}
    >
      <Styled.ButtonContainer>
        <PrimaryButton ariaLabel="Add cards now" text="Add cards now" onClick={addCardsButtonClicked} />
        <Styled.SecondaryButton
          ariaLabel="Get default set"
          text="Get default set"
          onClick={getDefaultSetButtonClicked}
        />
      </Styled.ButtonContainer>
    </SplashScreen>
  );
}
