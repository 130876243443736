import * as React from 'react';
import { Card, CardBody, CardContainer } from './../Card';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { microsoftLifeSelector, microsoftLifeLoadingStatus } from '../Feed.selectors';
import { LoadingScreen } from '../../../../Shared/Components/LoadingScreen';
import { LoadingStates } from '../../../../Shared/Models/LoadingStates';
import { Usage, Capability, SubCapability, EventName } from '../../../../Shared/Types';
import { usePageTracker } from '../../../../Shared/Hooks/usePageTracker';
import { ErrorScreen } from '../../../../Shared/Components/ErrorScreen';
import { IMicrosoftLifeModel } from '../Models/IMicrosoftLifeModel';
import { FeedCarousel, FeedCarouselItem } from '../FeedCarousel';
import { getFeedItemProps } from './MicrosoftLifeCard/MicrosoftLife.util';
import { CarouselContainer } from '../Feed.styled';
import { Page, PageHeadingContainer, PageHeading } from '../../../../Styles/Page';
import { useState } from 'react';
import { requestMicrosoftLifeData } from '../Feed.actions';

// TODO: Make appropriate interface for props
export function MicrosoftLife({ isScrollable }: { isScrollable: boolean }): React.ReactElement {
  const { useSelector, dispatch } = React.useContext(ReduxContext);
  let microsoftLifeFeed = useSelector(microsoftLifeSelector).posts;
  if (microsoftLifeFeed && microsoftLifeFeed.length > 16 && isScrollable) {
    const seeMore = [
      {
        seeMore: true,
        text: 'See All',
        Link: '/microsoftLife',
      },
    ];
    microsoftLifeFeed = [...microsoftLifeFeed, ...seeMore];
  }
  const microsoftLifeFeedStatus = useSelector(microsoftLifeLoadingStatus);
  const pageEvent: Usage = {
    capability: Capability.MyHub,
    subCapability: SubCapability.MicrosoftLife,
    eventName: EventName.PAGE_LOAD,
  };
  usePageTracker(pageEvent);
  const [itemNo, setItemNumber] = useState(0);
  const elementNumber = (itemNo: number): void => {
    setItemNumber(itemNo);
  };
  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const reloadMicrosoftLife = (): void => {
    dispatch(requestMicrosoftLifeData());
  };
  if (microsoftLifeFeedStatus === LoadingStates.NOT_STARTED || microsoftLifeFeedStatus === LoadingStates.STARTED) {
    return <LoadingScreen innerPageUse={true} />;
  } else if (microsoftLifeFeedStatus === LoadingStates.FAILED || !microsoftLifeFeed.length) {
    return (
      <CarouselContainer>
        <FeedCarousel currentFocusElement={itemNo} header="#MicrosoftLife" seeMoreLink="/microsoftLife">
          <Card>
            <CardBody>
              <ErrorScreen
                innerPageUse={true}
                showTitle={true}
                showDescription={false}
                imagePath={'/assets/binocularWoman.svg'}
                onRefresh={reloadMicrosoftLife}
                imageSize="medium"
                title="Nothing to see here"
                description="Check back later"
                noButton
                titleSize
              />
            </CardBody>
          </Card>
        </FeedCarousel>
      </CarouselContainer>
    );
  } else {
    if (isScrollable) {
      // TODO: Should get error/ empty screens for horizontal feed scroll
      return (
        <>
          <CarouselContainer>
            <FeedCarousel currentFocusElement={itemNo} header="#MicrosoftLife" seeMoreLink="/microsoftLife">
              {microsoftLifeFeed &&
                microsoftLifeFeed.map((post: IMicrosoftLifeModel, index: number) => (
                  <FeedCarouselItem
                    {...getFeedItemProps(post)}
                    getFocusEle={elementNumber}
                    itemNumber={index}
                    seeMoreCard={post.seeMore}
                    showBookmark={true}
                    key={post.post_id}
                  />
                ))}
            </FeedCarousel>
          </CarouselContainer>
        </>
      );
    }
    // TODO: Fix margin top to match styles
    return (
      <Page>
        {window.scrollTo(0, 0)}
        <PageHeadingContainer>
          <PageHeading tabIndex={0} aria-label="Microsoft Life">
            #Microsoft Life
          </PageHeading>
        </PageHeadingContainer>
        <CardContainer>
          {microsoftLifeFeed.map((post: IMicrosoftLifeModel) => (
            <FeedCarouselItem
              {...getFeedItemProps(post)}
              showBookmark={true}
              showFeedSponsorTitle={true}
              showDescription={false}
              descLines={4}
              key={post.post_id}
            />
          ))}
        </CardContainer>
      </Page>
    );
  }
}
