import { ReduxContext } from '@employee-experience/common';
import { Callout, DirectionalHint, SearchBox } from 'office-ui-fabric-react';
import React, { ChangeEvent, useContext, useState } from 'react';
import { ReactElement } from 'react';
import { fetchTrendingSearch } from './DefaultSearch.action';
import { getFrequentedContacts, getTrendingSearchResults } from './DefaultSearch.selector';
import { SearchList } from './SearchList/SearchList';
import { FEATURES } from './DefaultSearchMocks';
import { debounce } from 'lodash-es';
import { fetchSearchResults, resetSearchResults } from '../Search/Search.actions';
import { searchSelector } from '../Search/Search.selector';
import { ISearchResultsBuildingModel, ISearchResultsUserModel } from '../Search/Models/ISearchResultsModel';
import { BuildingPanel } from '../../../Pages/Explore/BuildingPanel';
import {
  requestBuildingDetailsAction,
  requestBuildingsDataAction,
} from '../../../Pages/Explore/MapView/MapView.actions';
import { buildingsSelector } from '../../../Pages/Explore/MapView/MapView.selectors';
import { find } from 'lodash-es';
import { getLocationCoordinates, getLocationPermissions } from '../../../Shared/shared/Location/Location.selectors';
import { TelemetryService } from '../../../Shared/shared';
import { Capability, SubCapability, EventName } from '../../../Shared/Types';
import axios from 'axios';

const degreeToRadian = (degree: number): number => {
  const factor = Math.PI / 180;
  const rad = degree * factor;
  return rad;
};

function distance(position1, position2): number {
  const lat1 = position1.latitude;
  const lat2 = position2.latitude;
  const lon1 = position1.longitude;
  const lon2 = position2.longitude;
  const R = 6371000; // metres
  const φ1 = degreeToRadian(lat1);
  const φ2 = degreeToRadian(lat2);
  const Δφ = degreeToRadian(lat2 - lat1);
  const Δλ = degreeToRadian(lon2 - lon1);

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c;
  return d;
}

let CancelToken = axios.CancelToken;
let source = CancelToken.source();

export function DefaultSearch(): ReactElement {
  const [showCallout, setShowCallout] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedBuilding, setSelectedBuilding] = useState<ISearchResultsBuildingModel | undefined>(undefined);
  const { dispatch, useSelector } = useContext(ReduxContext);
  const trendingResults = useSelector(getTrendingSearchResults);
  const frequentedContacts = useSelector(getFrequentedContacts);
  const searchResults = useSelector(searchSelector);
  const buildings = useSelector(buildingsSelector);
  const locationPermission = useSelector(getLocationPermissions);
  const currentLocation = useSelector(getLocationCoordinates);

  const globalSpaceId = 'd64fa6a7-b615-e711-80c3-00155daa74cb';
  React.useEffect(() => {
    dispatch(requestBuildingsDataAction({ regionId: globalSpaceId }));
  }, [dispatch]);

  const onSearchFocus = (): void => {
    if (searchText === '') {
      dispatch(resetSearchResults());
    }
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Search,
      eventName: EventName.VIEW_ACTIVATED,
    });
    CancelToken = axios.CancelToken;
    source = CancelToken.source();
    setShowCallout(true);
    dispatch(fetchTrendingSearch());
  };

  const toggleIsCalloutVisible = (): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Search,
      eventName: EventName.VIEW_DEACTIVATED,
    });
    setShowCallout(false);
    source.cancel();
    document.activeElement.blur();
  };

  const onPersonClick = (item): void => {
    // TODO: Open profile action TBD
    TelemetryService.trackEvent(
      {
        capability: Capability.MyHub,
        subCapability: SubCapability.Search,
        eventName: EventName.BUTTON_CLICKED,
      },
      { item }
    );
    toggleIsCalloutVisible();
    setSearchText('');
  };

  const mapPeople = (item): {} => {
    return { name: item.displayName, details: item.userPrincipalName, onClick: (): void => onPersonClick(item) };
  };
  const filterPeople = (item): {} => item.userPrincipalName;
  const people = frequentedContacts.filter(filterPeople).slice(0, 2).map(mapPeople);

  const mapSearchPeople = (item: ISearchResultsUserModel): {} => {
    return { name: item.displayName, details: item.alias, onClick: (): void => onPersonClick(item) };
  };
  const filterSearchPeople = (item: ISearchResultsUserModel): {} => item.alias;
  const getFilteredPeople = (): {}[] => {
    let filteredPeople = [{}];
    if (searchResults?.users) {
      filteredPeople = searchResults.users.filter(filterSearchPeople).slice(0, 3).map(mapSearchPeople);
    }
    return filteredPeople;
  };

  const mapSearchBuildings = (item: ISearchResultsBuildingModel): {} => {
    const name = item.name;
    let address;
    if (buildings) {
      const matchingExploreBuilding = find(buildings, { buildingId: item.id });
      if (matchingExploreBuilding) {
        const properties = matchingExploreBuilding.spaceId?.properties;
        if (properties) {
          const streetAddress = find(properties, ['name', 'Address1'])?.value;
          const city = find(properties, ['name', 'CityName'])?.value;
          const province = find(properties, ['name', 'ProvinceCode'])?.value;
          const zipCode = find(properties, ['name', 'ZipCode'])?.value;
          address = `${streetAddress} ${city}, ${province} ${zipCode}`;
        }
      }
    }
    const onClick = (): void => {
      TelemetryService.trackEvent(
        {
          capability: Capability.MyHub,
          subCapability: SubCapability.Search,
          eventName: EventName.BUTTON_CLICKED,
        },
        { item }
      );
      setSelectedBuilding(item);
      setSearchText('');
    };
    return { name, details: address, onClick, thumbnail: '/assets/Building_Placeholder.svg' };
  };

  const mapDefaultSearchBuildings = (item: ISearchResultsBuildingModel): {} => {
    const name = item.name;
    let address;
    const properties = item.spaceId?.properties;
    if (properties) {
      const streetAddress = find(properties, ['name', 'Address1'])?.value;
      const city = find(properties, ['name', 'CityName'])?.value;
      const province = find(properties, ['name', 'ProvinceCode'])?.value;
      const zipCode = find(properties, ['name', 'ZipCode'])?.value;
      address = `${streetAddress} ${city}, ${province} ${zipCode}`;
    }
    const onClick = (): void => {
      setSelectedBuilding(item);
      setSearchText('');
    };
    return { name, details: address, onClick, thumbnail: '/assets/Building_Placeholder.svg' };
  };

  const getFilteredSearchBuildings = (): {}[] => {
    let filteredBuildings = [{}];
    if (searchResults?.buildings) {
      filteredBuildings = searchResults.buildings.slice(0, 3).map(mapSearchBuildings);
    }
    return filteredBuildings;
  };

  const callSearchAPI = (searchText: string): void => {
    dispatch(searchText ? fetchSearchResults({ searchText }) : resetSearchResults());
  };

  const onSearchTextChangeDelayed = debounce(callSearchAPI, 500, { maxWait: 1000 });

  const onSearchTextChange = (event?: ChangeEvent<HTMLInputElement>, newValue?: string): void => {
    if (newValue || newValue === '') {
      source.cancel();
      CancelToken = axios.CancelToken;
      source = CancelToken.source();
      TelemetryService.trackEvent(
        {
          capability: Capability.MyHub,
          subCapability: SubCapability.Search,
          eventName: EventName.TEXT_CHANGED,
        },
        { newValue }
      );
      setSearchText(newValue);
      onSearchTextChangeDelayed(newValue);
    }
  };

  const onBuildingPanelOpen = (): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Building,
      eventName: EventName.PANEL_OPENED,
    });
    const { name } = selectedBuilding as { name: string };
    dispatch(requestBuildingDetailsAction({ name }));
  };

  const onBuildingPanelClose = (): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Building,
      eventName: EventName.PANEL_CLOSED,
    });
    setSelectedBuilding(undefined);
  };

  const getMostClosestBuildings = () => {
    if (!buildings && typeof buildings !== typeof []) {
      return [];
    }
    if (locationPermission === 'granted') {
      if (buildings.length < 3) {
        return [];
      }
      const closestDistance = [
        { ...buildings[0], distance: distance(buildings[0], currentLocation) },
        { ...buildings[1], distance: distance(buildings[1], currentLocation) },
        { ...buildings[2], distance: distance(buildings[2], currentLocation) },
      ];
      closestDistance.sort((b1, b2) => b1.distance - b2.distance);
      for (let i = 3; i < buildings.length; i++) {
        if (distance(buildings[i], currentLocation) < closestDistance[closestDistance.length - 1].distance) {
          closestDistance.push({ ...buildings[i], distance: distance(buildings[i], currentLocation) });
          closestDistance.sort((b1, b2) => b1.distance - b2.distance);
          if (closestDistance.length > 3) {
            closestDistance.pop();
          }
        }
      }
      return closestDistance.map(mapDefaultSearchBuildings);
    } else {
      return buildings
        .filter(
          (item: any) => item.longitude === currentLocation.longitude && item.latitude === currentLocation.latitude
        )
        .map(mapDefaultSearchBuildings);
    }
  };

  return (
    <div>
      <div className="search-box ms-Grid-col ms-sm12 ms-hiddenMdDown">
        <SearchBox
          onFocus={onSearchFocus}
          placeholder={'Search people and buildings'}
          onChange={onSearchTextChange}
          value={searchText}
          autoComplete="off"
        />
      </div>
      <div className="ms-Grid-col ms-sm12 ms-hiddenMdDown">
        {showCallout && (
          <Callout
            calloutMaxHeight={584}
            calloutWidth={window.innerWidth < 1024 ? 256 : 450}
            directionalHint={DirectionalHint.bottomCenter}
            isBeakVisible={false}
            target={'.search-box'}
            onDismiss={toggleIsCalloutVisible}
          >
            {searchText ? (
              <SearchList
                key={'search'}
                features={FEATURES}
                buildings={getFilteredSearchBuildings()}
                people={getFilteredPeople()}
                trendingResults={trendingResults.slice(0, 2)}
                source={source}
              />
            ) : (
              <SearchList
                key={'default-search'}
                features={FEATURES}
                buildings={getMostClosestBuildings()}
                people={people}
                trendingResults={trendingResults.slice(0, 2)}
                source={source}
              />
            )}
          </Callout>
        )}
      </div>
      <div>
        <BuildingPanel
          selectedBuilding={selectedBuilding}
          onOpen={onBuildingPanelOpen}
          onDismiss={onBuildingPanelClose}
        />
      </div>
    </div>
  );
}
