import {
  ActionTypes,
  IReceiveEventsAction,
  IReceiveNewsAction,
  IRequestMicrosoftLifeAction,
  IReceiveMicrosoftLifeDataSucceededAction,
  IReceiveMicrosoftLifeDataFailedAction,
  IRequestEventFeedDataAction,
  IReceiveEventFeedDataFailedAction,
  IReceiveEventFeedDataSucceededAction,
  IRequestNewsFeedDataAction,
  IReceiveNewsFeedDataFailedAction,
  IReceiveNewsFeedDataSucceededAction,
} from './Feed.action-types';
import { INewsModel } from './Models/INewsModel';
import { IEventsModel } from './Models/IEventsModel';
import { IMicrosoftLifeModel } from './Models/IMicrosoftLifeModel';

export function receiveEventsAction(events: IEventsModel[]): IReceiveEventsAction {
  return {
    type: ActionTypes.RECEIVE_EVENTS,
    events: events,
  };
}

export function receiveNewsFeedDataFailedAction(): IReceiveNewsFeedDataFailedAction {
  return {
    type: ActionTypes.RECEIVE_NEWS_FEED_DATA_FAILED,
  };
}

export function receiveNewsFeedDataSucceededAction(): IReceiveNewsFeedDataSucceededAction {
  return {
    type: ActionTypes.RECEIVE_NEWS_FEED_DATA_SUCCEEDED,
  };
}

export function receiveEventFeedDataFailedAction(): IReceiveEventFeedDataFailedAction {
  return {
    type: ActionTypes.RECEIVE_EVENT_FEED_DATA_FAILED,
  };
}

export function receiveEventFeedDataSucceededAction(): IReceiveEventFeedDataSucceededAction {
  return {
    type: ActionTypes.RECEIVE_EVENT_FEED_DATA_SUCCEEDED,
  };
}

export function requestMicrosoftLifeData(): IRequestMicrosoftLifeAction {
  return {
    type: ActionTypes.REQUEST_MICROSOFT_LIFE,
  };
}

export function receiveMicrosoftLifeDataSucceededAction(
  microsoftLife: IMicrosoftLifeModel[]
): IReceiveMicrosoftLifeDataSucceededAction {
  return {
    type: ActionTypes.RECEIVE_MICROSOFT_LIFE_DATA_SUCCEEDED,
    microsoftLife,
  };
}

export function receiveMicrosoftLifeDataFailedAction(): IReceiveMicrosoftLifeDataFailedAction {
  return {
    type: ActionTypes.RECEIVE_MICROSOFT_LIFE_DATA_FAILED,
  };
}

export function receiveNewsAction(news: INewsModel[]): IReceiveNewsAction {
  return {
    type: ActionTypes.RECEIVE_NEWS,
    news: news,
  };
}

export function requestNewsFeedDataAction(): IRequestNewsFeedDataAction {
  return {
    type: ActionTypes.REQUEST_NEWS_FEED_DATA,
  };
}
export function requestEventsFeedDataAction(): IRequestEventFeedDataAction {
  return {
    type: ActionTypes.REQUEST_EVENT_FEED_DATA,
  };
}
