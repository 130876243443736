import { FontWeights } from 'office-ui-fabric-react';

export const Image = styled.img`
  height: 32px;
  width: 32px;
`;

export const Title = styled.div`
  font-weight: ${FontWeights.semibold};
  margin-top: 9px;
`;
