import { all, put, takeLatest, getContext, call, SimpleEffect, select } from 'redux-saga/effects';
import { ActionTypes } from './Search.action-types';
import { HttpClient } from '@employee-experience/core';
import { ApiHelper } from '../../../Shared/Helpers/ApiHelper';
import { fetchSearchResultsFailureAction, fetchSearchResultsSuccessAction } from './Search.actions';
import { TelemetryService } from '../../../Shared/shared';
import { IFetchSearchResultsActionPayload } from './Search.types';
import { ISearchResultsModel } from './Models/ISearchResultsModel';
import { buildingsIdSetSelector } from '../../../Pages/Explore/MapView/MapView.selectors';

export function* fetchSearchResults({
  payload,
}: {
  payload: IFetchSearchResultsActionPayload;
}): IterableIterator<SimpleEffect<{}, {}>> {
  const { searchText, includes } = payload;
  try {
    if (searchText && searchText !== '') {
      const httpClient: HttpClient = yield getContext('httpClient');
      const request = {
        resource: __API__.myMsftApim.resourceId,
        header: ApiHelper.getMyMsftApiHeaders(),
      };
      let url = `${__API__.myMsftApim.resourceUrl}/api/search?searchText=${searchText}`;
      if (includes && includes !== '') {
        url = url + '&includes=' + includes;
      }
      url = url + '&noOfRecords=100';
      const { data: searchResults }: { data: ISearchResultsModel } = yield call(
        [httpClient, httpClient.get],
        url,
        request
      );
      let filteredSearchResultsBuildings;
      if (searchResults?.buildings) {
        const buildingsIds = yield select(buildingsIdSetSelector);
        if (buildingsIds) {
          filteredSearchResultsBuildings = searchResults.buildings.filter((searchResultBuilding) =>
            buildingsIds.has(searchResultBuilding.id)
          );
        }
      }
      searchResults.buildings = filteredSearchResultsBuildings;
      yield put(fetchSearchResultsSuccessAction(searchResults));
    }
  } catch (error) {
    yield put(fetchSearchResultsFailureAction(error));
    TelemetryService.trackException(error, 1, { message: 'Search.sagas.ts: Unable to fetch search results' });
  }
}

export function* searchSagas(): IterableIterator<{}> {
  yield all([takeLatest(ActionTypes.FETCH_SEARCH_RESULTS, fetchSearchResults)]);
}
