import { UsageTelemetryConfig } from '@employee-experience/common/lib/Models';
import { TelemetryClient, HttpClient, ComponentLoader, GraphClient, UsageClient } from '@employee-experience/core';
import { MSALV2Client } from '@employee-experience/core/lib/MSALV2Client';
import { Shell } from '@employee-experience/shell';
import { ReducerRegistry } from '@employee-experience/shell/lib/ReducerRegistry';
import { StoreBuilder } from '@employee-experience/shell/lib/StoreBuilder';
import { withStore } from '@employee-experience/shell/lib/withStore';
import { appReducer, initialAppState } from './App.reducer';
import { AppReducerName } from './App.resources';
import { footerReducer, initialFooterState } from './Layout/Footer/Footer.reducer';
import { FooterReducerName } from './Layout/Footer/Footer.resources';
import { footerSagas } from './Layout/Footer/Footer.sagas';
import { headerSagas } from './Layout/Header/Header.sagas';
import { initialNotificationsState, notificationsReducer } from './Layout/Header/Notifications/Notifications.reducer';
import { NotificationsReducerName } from './Layout/Header/Notifications/Notifications.resources';
import { notificationsSagas } from './Layout/Header/Notifications/Notifications.sagas';
import { initialReportProblemState, reportProblemReducer } from './Layout/Header/ReportProblem/ReportProblem.reducer';
import { ReportProblemReducerName } from './Layout/Header/ReportProblem/ReportProblem.resources';
import { reportProblemSagas } from './Layout/Header/ReportProblem/ReportProblem.saga';
import { faqReducer, initialFaqState } from './Layout/Help/Help.reducer';
import { FaqReducerName } from './Layout/Help/Help.resources';
import { faqSagas } from './Layout/Help/Help.saga';
import { adminReducer, initialAdminState } from './Pages/Admin/Admin.reducer';
import { AdminReducerName } from './Pages/Admin/Admin.resources';
import { adminSagas } from './Pages/Admin/Admin.sagas';
import { dashboardReducer, initialDashboardState } from './Pages/Dashboard/Dashboard.reducer';
import { DashboardReducerName } from './Pages/Dashboard/Dashboard.resources';
import { dashboardSagas } from './Pages/Dashboard/Dashboard.sagas';
import { exploreReducer, initialExploreState } from './Pages/Explore/MapView/MapView.reducer';
import { ExploreReducerName } from './Pages/Explore/MapView/MapView.resources';
import { exploreSagas } from './Pages/Explore/MapView/MapView.sagas';
import { bookmarkReducer, initialBookmarkState } from './Pages/Home/Feed/Bookmarks/Bookmarks.reducer';
import { BookmarkReducerName } from './Pages/Home/Feed/Bookmarks/Bookmarks.resources';
import { bookmarkSagas } from './Pages/Home/Feed/Bookmarks/Bookmarks.sagas';
import { feedReducer, initialFeedState } from './Pages/Home/Feed/Feed.reducer';
import { FeedReducerName } from './Pages/Home/Feed/Feed.resources';
import { feedSagas } from './Pages/Home/Feed/Feed.sagas';
import { ApiHelper } from './Shared/Helpers/ApiHelper';
import { initialUserState, userReducer } from './Shared/User/User.reducer';
import { UserReducerName } from './Shared/User/User.resources';
import { userSagas } from './Shared/User/User.sagas';
import { forEach } from 'lodash-es';
import { pushNotificationSagas } from './Shared/PushNotification/PushNotification.sagas';
import { oofReducer, initialOOFState } from './Shared/Components/OOF/OOF.reducer';
import { OOFReducerName } from './Shared/Components/OOF/OOF.resources';
import { oofSaga } from './Shared/Components/OOF/OOF.sagas';
import ReduxThunk from 'redux-thunk';
import { MicroSentimentReducerName } from './Shared/MicroSentiment/MicroSentiment.resources';
import { initialMicroSentimentState, microSentimentReducer } from './Shared/MicroSentiment/MicroSentiment.reducer';
import { microSentimentSagas } from './Shared/MicroSentiment/MicroSentiment.sagas';
import { WhatsNewReducerName } from './Layout/Header/Settings/WhatsNew/WhatsNew.resources';
import { initialWhatsNewState, whatsNewReducer } from './Layout/Header/Settings/WhatsNew/WhatsNew.reducer';
import { whatsNewSagas } from './Layout/Header/Settings/WhatsNew/WhatsNew.sagas';
import { SearchReducerName } from './Layout/Header/Search/Search.resources';
import { initialSearchState, searchReducer } from './Layout/Header/Search/Search.reducer';
import { searchSagas } from './Layout/Header/Search/Search.sagas';
import { initialLocationState, LocationReducer } from './Shared/shared/Location/Location.reducer';
import { LocationReducerName } from './Shared/shared/Location/Location.types';
import { locationSagas } from './Shared/shared/Location/Location.saga';
import { appSagas } from './App.saga';
import { defaultSearchSagas } from './Layout/Header/DefaultSearch/DefaultSearch.sagas';
import { initialDefaultSearchState, defaultSearchReducer } from './Layout/Header/DefaultSearch/DefaultSearch.reducer';
import { DefaultSearchReducerName } from './Layout/Header/DefaultSearch/DefaultSearch.resources';

const telemetryClient = new TelemetryClient({
  instrumentationKey: __TELEMETRY__.appInsightsInstrumentationKey,
  UTPConfig: {
    ComponentId: __TELEMETRY__.utp.componentId,
    ComponentName: __TELEMETRY__.utp.componentName,
    EnvironmentName: __TELEMETRY__.utp.environmentName,
    Service: __TELEMETRY__.utp.service,
    ServiceLine: __TELEMETRY__.utp.serviceLine,
    ServiceOffering: __TELEMETRY__.utp.serviceOffering,
  },
});

const usageTelemetryConfig: UsageTelemetryConfig = {
  usageApi: {
    url: `${__API__.myMsftApim.resourceUrl}/usageUserId`,
    headers: { ...ApiHelper.getMyMsftApiHeaders() },
    resourceId: __API__.myMsftApim.resourceId,
    method: 'GET',
    cache: 'localStorage',
  },
  enableUpnLogging: false,
  sessionDurationMinutes: 15,
};
const cleanUpAdalStorage = (): void => {
  const cookiesToDelete = ['ai_user'];
  const hasAdal = Object.entries(localStorage)
    .map((x) => x[0])
    .some((x) => x.startsWith('adal.'));
  if (hasAdal) {
    localStorage.clear();
    sessionStorage.clear();
    const cookies = document.cookie;
    forEach(cookies.split(';'), (cookie: string) => {
      const pos = cookie.indexOf('=');
      const name = pos > -1 ? cookie.substr(0, pos) : cookie;
      if (cookiesToDelete.indexOf(name) >= 0) document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    });
  }
};

//Cleanup the adal client's storage
cleanUpAdalStorage();
const authClient = new MSALV2Client(
  {
    auth: {
      authority: 'https://login.microsoftonline.com/microsoft.onmicrosoft.com',
      clientId: __AUTH__.clientId,
      redirectUri: `${window.location.origin}`,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  },
  telemetryClient
);

export const httpClient = new HttpClient(telemetryClient, authClient);
const usageClient = new UsageClient(httpClient, usageTelemetryConfig);
const graphClient = new GraphClient(httpClient);
const componentLoader = new ComponentLoader(telemetryClient, httpClient);

const reducerRegistry = new ReducerRegistry()
  .register(AdminReducerName, adminReducer)
  .register(AppReducerName, appReducer)
  .register(BookmarkReducerName, bookmarkReducer)
  .register(DashboardReducerName, dashboardReducer)
  .register(DefaultSearchReducerName, defaultSearchReducer)
  .register(ExploreReducerName, exploreReducer)
  .register(FaqReducerName, faqReducer)
  .register(FeedReducerName, feedReducer)
  .register(FooterReducerName, footerReducer)
  .register(LocationReducerName, LocationReducer)
  .register(MicroSentimentReducerName, microSentimentReducer)
  .register(NotificationsReducerName, notificationsReducer)
  .register(ReportProblemReducerName, reportProblemReducer)
  .register(SearchReducerName, searchReducer)
  .register(UserReducerName, userReducer)
  .register(WhatsNewReducerName, whatsNewReducer)
  .register(OOFReducerName, oofReducer);

const storeResult = new StoreBuilder(reducerRegistry, {
  [AdminReducerName]: initialAdminState,
  [AppReducerName]: initialAppState,
  [BookmarkReducerName]: initialBookmarkState,
  [DashboardReducerName]: initialDashboardState,
  [DefaultSearchReducerName]: initialDefaultSearchState,
  [ExploreReducerName]: initialExploreState,
  [FaqReducerName]: initialFaqState,
  [FeedReducerName]: initialFeedState,
  [FooterReducerName]: initialFooterState,
  [LocationReducerName]: initialLocationState,
  [MicroSentimentReducerName]: initialMicroSentimentState,
  [NotificationsReducerName]: initialNotificationsState,
  [ReportProblemReducerName]: initialReportProblemState,
  [SearchReducerName]: initialSearchState,
  [UserReducerName]: initialUserState,
  [WhatsNewReducerName]: initialWhatsNewState,
  [OOFReducerName]: initialOOFState,
})
  .addMiddleware(ReduxThunk.withExtraArgument(httpClient))
  .configureSaga({
    telemetryClient,
    authClient,
    httpClient,
    componentLoader,
    graphClient,
    usageClient,
    appName: __APP_NAME__,
  })
  .addRootSagas([
    adminSagas,
    appSagas,
    bookmarkSagas,
    dashboardSagas,
    defaultSearchSagas,
    exploreSagas,
    faqSagas,
    feedSagas,
    footerSagas,
    headerSagas,
    locationSagas,
    microSentimentSagas,
    notificationsSagas,
    pushNotificationSagas,
    reportProblemSagas,
    searchSagas,
    userSagas,
    whatsNewSagas,
    oofSaga.oofSagas,
  ])
  .build();

export const ShellWithStore = withStore(storeResult)(Shell);
