/// <reference path="./OfficeBrowserFeedback.d.ts"/>
import campaignDefinitions from './CampaignDefinitions';
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect';
import { ILaunchOptionsInAppFeedback } from '@cseo/controls';

function isOCVLoaded(): boolean {
    return !!window.OfficeBrowserFeedback && !!window.OfficeBrowserFeedback.multiFeedback ? true : false;
}

export function loadFeedbackScript(): Promise<void> {
    return new Promise((resolve: Function, reject: OnErrorEventHandlerNonNull): void => {
        if (isOCVLoaded()) return resolve();
        const script: HTMLScriptElement = document.createElement('script');
        script.onload = (): void => {
            resolve();
        };
        script.onerror = reject;
        script.src = '/OCV/officebrowserfeedback.min.js';
        document.body.appendChild(script);
    });
}
export function loadFloodgateScript(): Promise<void> {
    return new Promise((resolve: Function, reject: OnErrorEventHandlerNonNull): void => {
        if (isOCVLoaded()) return resolve();
        const script: HTMLScriptElement = document.createElement('script');
        script.onload = (): void => {
            resolve();
        };
        script.onerror = reject;
        script.src = '/OCV/officebrowserfeedback_floodgate.min.js';
        document.body.appendChild(script);
    });
}

export async function initializeFeedback(
    sessionIDDetails: string,
    userEmail: string,
    onCancel: () => void
): Promise<void> {
    const environment = __ENVIRONMENT__ === 'production' ? 0 : 1; // 0 - Prod, 1 - Int
    const additionalData = {
        'OS Name': osName
    };
    OfficeBrowserFeedback.initOptions = {
        appId: __OCV__.appId,
        stylesUrl: '/OCV/officebrowserfeedback.min.css',
        intlUrl: '/OCV/intl/',
        environment: environment,
        userEmail: userEmail,
        userVoice: {
            url: __OCV__.ideasPortalUrl
        },
        build: __OCV__.build,
        sessionId: sessionIDDetails,
        applicationGroup: {
            appData: JSON.stringify(additionalData)
        },
        telemetryGroup: {
            audience: __OCV__.audience,
            audienceGroup: __OCV__.audienceGroup,
            channel: __OCV__.channel,
            platform: __OCV__.platform,
            osBuild: osVersion
        },
        webGroup: {
            browser: browserName,
            browserVersion: browserVersion
        },
        screenshot: false,
        onError: onCancel,
        onDismiss: onCancel
    };
}

export async function initializeOBFeedback(
    sessionIDDetails: string,
    userEmail: string,
    onCancel: () => void
): Promise<void> {
    // [ToDO saloni 15 May 2020]: Disabled ocv floodgate survey for web prod untill we get a new survey id for web
    // const environment = __OCV__.environment === 'prod' ? 0 : 1; // 0 - Prod, 1 - Int\
    const environment = __ENVIRONMENT__ === 'production' ? 0 : 1; // 0 - Prod, 1 - Int
    const additionalData = {
        'OS Name': osName
    };
    OfficeBrowserFeedback.initOptions = {
        appId: __OCV__.appId,
        stylesUrl: '/OCV/officebrowserfeedback.min.css',
        intlUrl: '/OCV/intl/',
        environment: environment,
        userEmail: userEmail,
        userVoice: {
            url: __OCV__.ideasPortalUrl
        },
        build: __OCV__.build,
        sessionId: sessionIDDetails,
        applicationGroup: {
            appData: JSON.stringify(additionalData)
        },
        telemetryGroup: {
            audience: __OCV__.audience,
            audienceGroup: __OCV__.audienceGroup,
            channel: __OCV__.channel,
            platform: __OCV__.platform,
            osBuild: osVersion
        },
        webGroup: {
            browser: browserName,
            browserVersion: browserVersion
        },
        screenshot: false,
        onError: onCancel,
        onDismiss: onCancel
    };
    OfficeBrowserFeedback.floodgate.initOptions = {
        autoDismiss: 0, // will automatically dismiss the survey if not clicked after 7 * x seconds (currently 21s)
        campaignDefinitions: campaignDefinitions
    };
    await window.OfficeBrowserFeedback.floodgate.initialize().then(() => console.log('successfully inititatized'));
    await window.OfficeBrowserFeedback.floodgate.start();
    triggerSurveyActivity();

    /*
     * Need to start and stop the floodgate as it should only be in the 'start' state while the window is in focus
     */

    window.onfocus = () => {
        if (window.OfficeBrowserFeedback.floodgate) {
            window.OfficeBrowserFeedback.floodgate.start();
        }
    };

    window.onblur = () => {
        if (window.OfficeBrowserFeedback.floodgate) {
            window.OfficeBrowserFeedback.floodgate.stop();
        }
    };

    window.onunload = () => {
        if (window.OfficeBrowserFeedback.floodgate) {
            window.OfficeBrowserFeedback.floodgate.stop();
        }
    };
}

export const officeFeedbackClicked = async (options: ILaunchOptionsInAppFeedback): Promise<void> => {
    const launchOptions = options;

    //ToDo [Saloni Daga - 18/02/2020] Add logs to Appinsights
    window.OfficeBrowserFeedback.multiFeedback(launchOptions);
};

/**
 * The function ran after floodgate is initialized on every page load
 * This does this actual triggering of the survey activity
 */
function triggerSurveyActivity(): void {
    window.OfficeBrowserFeedback.floodgate
        .getEngine()
        .getActivityListener()
        .logActivity('SurveyActivityTriggered');
    // 'SurveyActivityTriggered' based on CampaignDefinitions
}
