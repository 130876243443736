import { CoherencePanel } from '@cseo/controls';
import { ComponentContext, ReduxContext } from '@employee-experience/common';
import moment from 'moment';
import * as React from 'react';
import { TelemetryService } from '../../../../Shared/shared';
import { Capability, EventName, SubCapability } from '../../../../Shared/Types';
import { IPanelProps } from '../../../../Shared/Types/interfaces/IPanelProps';
import { PanelSection, PanelSectionSeparator } from '../../../../Styles/Panel';
import { setWhatsNewDataAvailableAction } from './WhatsNew.actions';
import { whatsNewReleasesSelector } from './WhatsNew.selectors';
import { WhatsNewFeature, WhatsNewRelease } from './WhatsNew.types';
import * as Styled from './WhatsNew.styled';
import { useFeaturedFlights } from '../../../../Shared/Hooks/useFeaturedFlights';
import { SplashScreen } from '../../../../Shared/Components/SplashScreen';
import { Link } from 'office-ui-fabric-react';

export function WhatsNew(props: IPanelProps): React.ReactElement {
  const { isOpen, backButton } = props;
  const { dispatch, useSelector } = React.useContext(ReduxContext);
  const { telemetryClient } = React.useContext(ComponentContext);
  const whatsNewReleases = useSelector<WhatsNewRelease[]>(whatsNewReleasesSelector);
  const featureFlights = useFeaturedFlights();

  React.useEffect(() => {
    TelemetryService.trackEvent({
      capability: Capability.Settings,
      subCapability: SubCapability.Settings_WhatsNew,
      eventName: EventName.PANEL_OPENED,
    });
    dispatch(setWhatsNewDataAvailableAction(false));
  }, []);

  const onPanelClosed = (ev?: React.SyntheticEvent<HTMLElement, Event> | undefined): void => {
    TelemetryService.trackEvent({
      capability: Capability.Settings,
      subCapability: SubCapability.Settings_WhatsNew,
      eventName: EventName.PANEL_CLOSED,
    });
    if (ev) {
      backButton.onClick();
    }
  };

  const whatsNewFeatures =
    whatsNewReleases[0]?.features.length > 0
      ? whatsNewReleases[0].features.filter((feature) => featureFlights.includes(feature.featureFlight.toLowerCase()))
      : null;

  const whatsNewContent =
    whatsNewFeatures != null && whatsNewFeatures.length > 0
      ? Object.values(
          whatsNewFeatures.reduce((accumulator, item: WhatsNewFeature) => {
            if (!accumulator[item.date]) {
              accumulator[item.date] = {
                date: item.date,
                data: [],
              };
            }
            accumulator[item.date].data.push(item);
            return accumulator;
          }, {})
        ).sort((a: unknown, b: unknown) => new Date(b.date).getTime() - new Date(a.date).getTime())
      : null;

  return (
    <CoherencePanel
      titleText={"What's New"}
      isOpen={isOpen}
      backButton={backButton}
      isLightDismiss={true}
      telemetryHook={telemetryClient}
      isBlocking={false}
      onDismiss={onPanelClosed}
      focusTrapZoneProps={{ forceFocusInsideTrap: false }}
      closeButtonAriaLabel="Close"
    >
      <PanelSectionSeparator />
      {whatsNewContent ? (
        whatsNewContent.map((whatsNewDate, dateIndex: number) => (
          <div key={dateIndex}>
            <PanelSection>
              <p>{moment(whatsNewDate.date).format('MMM D, YYYY')}</p>
              {whatsNewDate.data.map((whatsNewFeature: WhatsNewFeature, featureIndex: number) => (
                <div key={featureIndex}>
                  {featureIndex === 0 ? (
                    <Styled.WhatsNewFirstItemTitle>{whatsNewFeature.title}</Styled.WhatsNewFirstItemTitle>
                  ) : (
                    <Styled.WhatsNewItemTitle>{whatsNewFeature.title}</Styled.WhatsNewItemTitle>
                  )}
                  <Styled.WhatsNewItemDescription>{whatsNewFeature.description}</Styled.WhatsNewItemDescription>
                </div>
              ))}
            </PanelSection>
            <PanelSectionSeparator />
          </div>
        ))
      ) : (
        <SplashScreen
          imagePath="/assets/Shared/Panel/Empty.svg"
          title="Nothing new here"
          description={
            <>
              There are no new features or updates in MyHub to share right now. Check back in a few days to see
              what&apos;s new, or{' '}
              <Link
                href="https://microsoft.sharepoint.com/teams/MicrosoftMyHub/SitePages/Roadmap.aspx"
                target="_blank"
                onClick={(): void =>
                  TelemetryService.trackEvent({
                    capability: Capability.Settings,
                    subCapability: SubCapability.WhatsNew_NoContent_Roadmap,
                    eventName: EventName.LINK_CLICKED,
                  })
                }
              >
                check out our roadmap.
              </Link>
            </>
          }
        />
      )}
    </CoherencePanel>
  );
}
