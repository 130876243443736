import { INewsModel } from '../../Models/INewsModel';
import { IFeedCardModel } from '../../Models/IFeedCardModel';
import moment from 'moment';

export const getNewsFeedItemProps = (post: INewsModel): IFeedCardModel => {
  const { Images, FeedSponsorTitle, Title, Description, PublicationDate, Link, FeedsType, Id, seeMore } = post;
  const imagePath = Images && Images[0];
  const formattedPostTime = PublicationDate ? moment(new Date(PublicationDate)).format('MMM D, YYYY') : '';
  return {
    imageUrl: imagePath,
    fallbackImageUrl: '/assets/Feed/NewsDefault.svg',
    title: Title,
    dateLabel: formattedPostTime,
    link: Link,
    linkLabel: 'Read story',
    feedId: FeedsType,
    feedType: 'news',
    linkAccessibilitylabel: 'Read story',
    seeMore: seeMore,
    bookmarkPayload: {
      description: Description,
      feedSponsorTitle: FeedSponsorTitle,
      images: imagePath,
      link: Link,
      publicationDate: formattedPostTime,
      title: Title,
      feedsType: FeedsType,
      id: Id,
    },
  };
};
