export enum UsageEventName {
  BOOKMARK_DELETE_ON_ACCESSIBILITY = 'BookmarkDeleteOnAccessibility',
  BUTTON_CLICKED = 'ButtonClicked',
  BACKEND_API_CALL = 'BackEndAPICall',
  PAGE_LOAD = 'PageLoad',
  DRAWER_OPENED = 'PanelOpened',
  DRAWER_CLOSED = 'PanelClosed',
  PICKER_SELECTED = 'PickerSelected',
  PICKER_DESELECTED = 'PickerDeselected',
  TILE_ADDED = 'TileAdded',
  TILE_CLICKED = 'TileClicked',
  TILE_DELETED = 'TileDeleted',
  TILE_ORDER_CHANGED = 'TileOrderChanged',
  TILE_META_DATA_CHANGED = 'TileMetaDataChanged',
  VIEW_ACTIVATED = 'ViewActivated',
  VIEW_DEACTIVATED = 'ViewDeactivated',
  TILE_DRAGGED = 'TileDragged',
  TILE_DELETE = 'TileDelete',
  TILE_DELETE_ON_ACCESSIBILITY = 'TileDeleteOnAccessibility',
  ALERT = 'Alert',
  TAB_NAVIGATION = 'TabNavigation',
  PAGE_NAVIGATION = 'PageNavigation',
  DRAWER_OPEN = 'DrawerOpen',
  DRAWER_CLOSE = 'DrawerClose',
  MARKER_SELECTED = 'MarkerSelected',
  LIST_SELECTED = 'ListSelected',
  LINK_CLICKED = 'LinkClicked',
  VIEW_PHOTO = 'ViewPhoto',
  SELECT_PHOTO = 'SelectPhoto',
  CAPTURE_PHOTO = 'CapturePhoto',
  DROP_DOWN_SELECTED = 'DropdownSelected',
  CHECK_BOX_CHANGED = 'CheckBoxChanged',
  RADIO_BUTTON_CHANGED = 'RadioButtonChanged',
  SCROLL = 'Scroll',
  TEXT_CHANGED = 'TextChanged',
  FOCUSSED = 'OnFocus',
  BLURRED = 'OnBlur',
  TILE_LOADED = 'TileLoaded',
  MOBILE_REFRESH = 'MobileRefresh',
  APP_LOCK = 'APP_LOCK',
  GRANTED = 'Granted',
  DENIED = 'Denied',
}
