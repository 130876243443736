import { IMyHubAppState } from '../../../App.types';
import { INotificationsState } from './Notifications.types';
import { NotificationsReducerName } from './Notifications.resources';
import { initialNotificationsState } from './Notifications.reducer';
import { createSelector } from 'reselect';

export const notificationsSelector = createSelector(
  (state: IMyHubAppState) => state[NotificationsReducerName] ?? initialNotificationsState,
  (NotificationsState: INotificationsState) => NotificationsState.Notifications
);

export const notificationsLoadingStatusSelector = createSelector(
  (state: IMyHubAppState) => state[NotificationsReducerName] ?? initialNotificationsState,
  (NotificationsState: INotificationsState) => NotificationsState.NotificationsLoadingStatus
);

export const notificationsUnreadListSelector = createSelector(
  (state: IMyHubAppState) => state[NotificationsReducerName] ?? initialNotificationsState,
  (NotificationsState: INotificationsState) => {
    return (
      NotificationsState.Notifications &&
      NotificationsState.Notifications.filter((item) => {
        return item && (item.read === false || item.read === undefined);
      })
    );
  }
);
