import { IPushNotificationSubscription } from './PushNotification.types';

export enum ActionTypes {
  REGISTER_NOTIFICATION_SUBSCRIPTION = 'REGISTER_NOTIFICATION_SUBSCRIPTION',
  DEREGISTER_NOTIFICATION_SUBSCRIPTION = 'DEREGISTER_NOTIFICATION_SUBSCRIPTION',
  DEREGISTER_NOTIFICATION_SUBSCRIPTION_COMPLETED = 'DEREGISTER_NOTIFICATION_SUBSCRIPTION_COMPLETED',
}

export interface IRegisterNotificationSubscription {
  type: ActionTypes.REGISTER_NOTIFICATION_SUBSCRIPTION;
  payload: IPushNotificationSubscription;
}

export interface IDeRegisterNotificationSubscription {
  type: ActionTypes.DEREGISTER_NOTIFICATION_SUBSCRIPTION;
  payload: PushSubscription;
}

export type IPushNotificationAction = IRegisterNotificationSubscription | IDeRegisterNotificationSubscription;
