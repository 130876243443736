import { DefaultButton } from 'office-ui-fabric-react';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SecondaryButton = styled(DefaultButton)`
  margin-left: 12px;
`;
