import { PanelSectionHeader } from '../../../../Styles/Panel';

export const WhatsNewItemTitle = styled(PanelSectionHeader)`
  margin-top: 20px;
`;

export const WhatsNewFirstItemTitle = styled(WhatsNewItemTitle)`
  margin-top: 16px;
`;

export const WhatsNewItemDescription = styled.p`
  margin-top: 14px;
`;
