import moment from 'moment';
import { IEventsModel } from '../../Models/IEventsModel';
import { IFeedCardModel } from '../../Models/IFeedCardModel';
const eventPlaceHolderImage = '/assets/Feed/EventsDefaultImage.svg';

export const getEventFeedItemProps = (post: IEventsModel): IFeedCardModel => {
  const {
    EndDate: rawEndDate,
    Title,
    Link,
    StartDate: rawStartDate,
    Description,
    MoreInfoURL,
    ShortDescription,
    MswAudience,
    Id,
    FeedsType,
    Location,
  } = post;
  const EndDate = new Date(rawEndDate);

  const StartDate = new Date(rawStartDate);
  let formattedDate = '';

  if (moment(StartDate).isValid() && moment(EndDate).isValid()) {
    if (StartDate.getFullYear() === EndDate.getFullYear()) {
      if (StartDate.getMonth() === EndDate.getMonth()) {
        if (StartDate.getDate() === EndDate.getDate()) {
          formattedDate =
            moment(StartDate).format('DD') +
            ' ' +
            moment(EndDate).format('MMM') +
            ', ' +
            moment(EndDate).format('YYYY');
        } else {
          formattedDate =
            moment(StartDate).format('DD') +
            '-' +
            moment(EndDate).format('DD') +
            ' ' +
            moment(EndDate).format('MMM') +
            ', ' +
            moment(EndDate).format('YYYY');
        }
      } else {
        formattedDate =
          moment(StartDate).format('DD MMM') +
          '-' +
          moment(EndDate).format('DD MMM') +
          ' ' +
          moment(EndDate).format('YYYY');
      }
    } else {
      formattedDate = moment(StartDate).format('DD MMM YYYY') + '-' + moment(EndDate).format('DD MMM YYYY');
    }
    formattedDate += ' ' + moment(StartDate).format('hh:mm A');
  }

  return {
    imageUrl: eventPlaceHolderImage,
    fallbackImageUrl: eventPlaceHolderImage,
    title: Title,
    dateLabel: formattedDate,
    link: Link,
    linkLabel: 'Go to event',
    linkIcon: '',
    feedId: FeedsType,
    feedType: 'events',
    linkAccessibilitylabel: 'Go to event',
    bookmarkPayload: {
      description: Description,
      endDate: EndDate ? EndDate.toString() : undefined,
      link: Link,
      location: Location,
      mswAudience: MswAudience,
      shortDescription: ShortDescription,
      startDate: StartDate ? StartDate.toString() : undefined,
      title: Title,
      registrationUrl: MoreInfoURL,
      feedsType: FeedsType,
      id: Id,
    },
  };
};
