import * as React from 'react';
import { ReduxContext } from '@employee-experience/common';
import { BackButtonText } from '../../../Shared/Resources/Panel.resources';
import { PanelSection, PanelSectionLink, PanelSectionSeparator } from '../../../Styles/Panel';
import { WhatsNew } from './WhatsNew/WhatsNew';
import { fetchWhatsNewDataAction } from './WhatsNew/WhatsNew.actions';
import { isWhatsNewDataAvailableSelector } from './WhatsNew/WhatsNew.selectors';
import { useUserGlobalConfig } from '../../../Shared/Hooks/useUserGlobalConfig';
import { FLIGHTS } from '../../../Constants/flightingConstants';
import * as Styled from './Settings.styled';

export function Settings(): React.ReactElement {
  const [whatsNewPanelOpen, setWhatsNewPanelOpen] = React.useState(false);
  const { dispatch, useSelector } = React.useContext(ReduxContext);
  const isWhatsNewDataAvailable = useSelector<boolean>(isWhatsNewDataAvailableSelector);
  const config = useUserGlobalConfig([FLIGHTS.WHATS_NEW]);

  React.useEffect(() => {
    dispatch(fetchWhatsNewDataAction());
  }, []);

  const toggleWhatsNewPanel = (): void => {
    setWhatsNewPanelOpen(!whatsNewPanelOpen);
  };

  return (
    <>
      <PanelSectionSeparator />
      <PanelSection>
        {config[FLIGHTS.WHATS_NEW] && (
          <PanelSectionLink onClick={(): void => toggleWhatsNewPanel()}>
            {isWhatsNewDataAvailable ? (
              <Styled.Icon src="/assets/Settings/Whats_New_Available_Icon.svg" alt="" />
            ) : (
              <Styled.Icon src="/assets/Settings/Whats_New_Icon.svg" alt="" />
            )}
            What&apos;s New
          </PanelSectionLink>
        )}
      </PanelSection>

      {whatsNewPanelOpen && (
        <WhatsNew isOpen={whatsNewPanelOpen} backButton={{ ariaLabel: BackButtonText, onClick: toggleWhatsNewPanel }} />
      )}
    </>
  );
}
