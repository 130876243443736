import {
  ActionTypes,
  IRequestUsageUserIdAction,
  IReceiveUsageUserIdAction,
  IReceiveUsageUserIdSucceededAction,
  IReceiveUsageUserIdFailedAction,
  IStartNewSession,
  ISetLastActiveTime,
  IFetchUserGlobalConfigWeb,
  IFetchUserGlobalConfigWebSuccess,
  userGlobalConfigPayload,
  userGlobalConfigDetailsPayload,
  IFetchUserDetailsWeb,
  IFetchUserDetailsWebSuccess,
  User,
} from './User.action-types';

export function receiveUsageUserIdFailed(): IReceiveUsageUserIdFailedAction {
  return {
    type: ActionTypes.RECEIVE_USAGE_USER_ID_FAILED,
  };
}

export function receiveUsageUserIdSucceeded(): IReceiveUsageUserIdSucceededAction {
  return {
    type: ActionTypes.RECEIVE_USAGE_USER_ID_SUCCEEDED,
  };
}

export function receiveUsageUserId(usageUserId: string): IReceiveUsageUserIdAction {
  return {
    type: ActionTypes.RECEIVE_USAGE_USER_ID,
    UsageUserId: usageUserId,
  };
}

export function requestUsageUserId(): IRequestUsageUserIdAction {
  return {
    type: ActionTypes.REQUEST_USAGE_USER_ID,
  };
}

export function startNewSession(): IStartNewSession {
  return {
    type: ActionTypes.START_NEW_SESSION,
  };
}

export function setLastActiveTime(): ISetLastActiveTime {
  return {
    type: ActionTypes.SET_LAST_ACTIVE_TIME,
  };
}

export function fetchUserGlobalConfigWeb(): IFetchUserGlobalConfigWeb {
  return {
    type: ActionTypes.FETCH_USER_GLOBAL_CONFIG_WEB,
  };
}

export function fetchUserGlobalConfigSuccess(
  payload: userGlobalConfigPayload,
  userDetails: userGlobalConfigDetailsPayload
): IFetchUserGlobalConfigWebSuccess {
  return {
    payload,
    userDetails,
    type: ActionTypes.FETCH_USER_GLOBAL_CONFIG_WEB_SUCCESS,
  };
}
export function fetchUserDetails(): IFetchUserDetailsWeb {
  return {
    type: ActionTypes.FETCH_USER_DETAILS,
  };
}

export function fetchUserDetailsSuccess(payload: User): IFetchUserDetailsWebSuccess {
  return {
    payload,
    type: ActionTypes.FETCH_USER_DETAILS_SUCCESS,
  };
}
