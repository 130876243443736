import { all, put, takeLatest, SimpleEffect } from 'redux-saga/effects';
import { TelemetryService } from '../shared';
import { ActionTypes } from './MicroSentiment.action-types';
import { submitMicroSentimentFailedAction, submitMicroSentimentSucceededAction } from './MicroSentiment.actions';

export function* submitMicroSentiment(): IterableIterator<SimpleEffect<{}, {}>> {
  // TODO: Integrate once API is ready.
  // const httpClient: HttpClient = yield getContext('httpClient');
  try {
    // const request = {
    //     resource: __API__.myMsftApim.resourceId,
    //     header: ApiHelper.getMyMsftApiHeaders(),
    //     data: {}
    // };
    // const url = `${__API__.myMsftApim.resourceUrl}/microSentiment`;
    // yield call([httpClient, httpClient.post], url, request);
    yield put(submitMicroSentimentSucceededAction());
  } catch (error) {
    yield put(submitMicroSentimentFailedAction());
    TelemetryService.trackException(error);
  }
}

export function* microSentimentSagas(): IterableIterator<{}> {
  yield all([takeLatest(ActionTypes.SUBMIT_MICRO_SENTIMENT, submitMicroSentiment)]);
}
