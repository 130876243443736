import { UsageTelemetryLogDataUserEventName } from '@cseousage/cseousagetelemetrymodel';

export enum EventName {
  BUTTON_CLICKED = UsageTelemetryLogDataUserEventName.ButtonClicked,
  BACKEND_API_CALL = UsageTelemetryLogDataUserEventName.BackEndAPICall,
  PAGE_LOAD = UsageTelemetryLogDataUserEventName.PageLoad,
  PANEL_OPENED = UsageTelemetryLogDataUserEventName.PanelOpened,
  PANEL_CLOSED = UsageTelemetryLogDataUserEventName.PanelClosed,
  PICKER_SELECTED = UsageTelemetryLogDataUserEventName.PickerSelected,
  PICKER_DESELECTED = UsageTelemetryLogDataUserEventName.PickerDeselected,
  TILE_ADDED = UsageTelemetryLogDataUserEventName.TileAdded,
  TILE_CLICKED = UsageTelemetryLogDataUserEventName.TileClicked,
  TILE_DELETED = UsageTelemetryLogDataUserEventName.TileDeleted,
  TILE_ORDER_CHANGED = UsageTelemetryLogDataUserEventName.TileOrderChanged,
  TILE_META_DATA_CHANGED = UsageTelemetryLogDataUserEventName.TileMetaDataChanged,
  VIEW_ACTIVATED = UsageTelemetryLogDataUserEventName.ViewActivated,
  VIEW_DEACTIVATED = UsageTelemetryLogDataUserEventName.ViewDeactivated,
  TILE_DRAGGED = UsageTelemetryLogDataUserEventName.TileDragged,
  TILE_DELETE = UsageTelemetryLogDataUserEventName.TileDelete,
  TILE_DELETE_ON_ACCESSIBILITY = UsageTelemetryLogDataUserEventName.TileDeleteOnAccessibility,
  ALERT = UsageTelemetryLogDataUserEventName.Alert,
  TAB_NAVIGATION = UsageTelemetryLogDataUserEventName.TabNavigation,
  PAGE_NAVIGATION = UsageTelemetryLogDataUserEventName.PageNavigation,
  DRAWER_OPEN = UsageTelemetryLogDataUserEventName.DrawerOpen,
  DRAWER_CLOSE = UsageTelemetryLogDataUserEventName.DrawerClose,
  MARKER_SELECTED = UsageTelemetryLogDataUserEventName.MarkerSelected,
  LIST_SELECTED = UsageTelemetryLogDataUserEventName.ListSelected,
  LINK_CLICKED = UsageTelemetryLogDataUserEventName.LinkClicked,
  VIEW_PHOTO = UsageTelemetryLogDataUserEventName.ViewPhoto,
  SELECT_PHOTO = UsageTelemetryLogDataUserEventName.SelectPhoto,
  CAPTURE_PHOTO = UsageTelemetryLogDataUserEventName.CapturePhoto,
  DROP_DOWN_SELECTED = UsageTelemetryLogDataUserEventName.DropdownSelected,
  CHECK_BOX_CHANGED = UsageTelemetryLogDataUserEventName.CheckBoxChanged,
  RADIO_BUTTON_CHANGED = UsageTelemetryLogDataUserEventName.RadioButtonChanged,
  SCROLL = UsageTelemetryLogDataUserEventName.Scroll,
  TEXT_CHANGED = UsageTelemetryLogDataUserEventName.TextChanged,
  FOCUSSED = UsageTelemetryLogDataUserEventName.OnFocus,
  BLURRED = UsageTelemetryLogDataUserEventName.OnBlur,
  TILE_LOADED = UsageTelemetryLogDataUserEventName.TileLoaded,
  NOTIFICATION_CREATED = UsageTelemetryLogDataUserEventName.NotificationCreated,
  NOTIFICATIONS_READ = UsageTelemetryLogDataUserEventName.NotificationsRead,
}
