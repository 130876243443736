import { IMyHubAppState } from '../../../App.types';
import { IFeedState } from './Feed.types';
import { FeedReducerName } from './Feed.resources';
import { initialFeedState } from './Feed.reducer';
import { createSelector } from 'reselect';

export const eventsSelector = createSelector(
  (state: IMyHubAppState) => state[FeedReducerName] ?? initialFeedState,
  (feedState: IFeedState) => feedState.Events
);

export const microsoftLifeSelector = createSelector(
  (state: IMyHubAppState) => state[FeedReducerName] ?? initialFeedState,
  (feedState: IFeedState) => feedState.MicrosoftLife
);

export const microsoftLifeLoadingStatus = createSelector(
  (state: IMyHubAppState) => state[FeedReducerName] ?? initialFeedState,
  (feedState: IFeedState) => feedState.MicrosoftLifeLoadingStatus
);

export const feedItemsLoadingStatusSelector = createSelector(
  (state: IMyHubAppState) => state[FeedReducerName] ?? initialFeedState,
  (feedState: IFeedState) => feedState.FeedItemsLoadingStatus
);

export const newsFeedLoadingStatus = createSelector(
  (state: IMyHubAppState) => state[FeedReducerName] ?? initialFeedState,
  (feedState: IFeedState) => feedState.NewsFeedItemsLoadingStatus
);

export const eventFeedLoadingStatus = createSelector(
  (state: IMyHubAppState) => state[FeedReducerName] ?? initialFeedState,
  (feedState: IFeedState) => feedState.EventFeedItemsLoadingStatus
);
export const newsSelector = createSelector(
  (state: IMyHubAppState) => state[FeedReducerName] ?? initialFeedState,
  (feedState: IFeedState) => feedState.News
);
