import * as React from 'react';
import { Link } from 'react-router-dom';
import { Page, PageHeadingContainer, PageHeading } from '../../../Styles/Page';
import * as Styled from './Feed.styled';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { requestEventsFeedDataAction, requestMicrosoftLifeData, requestNewsFeedDataAction } from './Feed.actions';
import { requestBookmarksDataAction } from './Bookmarks/Bookmarks.actions';
import { MicrosoftLife } from './MicrosoftLife';
import { News } from './News';
import { Events } from './Events/Events';
import { useUserGlobalConfig } from '../../../Shared/Hooks/useUserGlobalConfig';
import { FLIGHTS } from '../../../Constants/flightingConstants';
import { TelemetryService } from '../../../Shared/shared/TelemetryService';
import { Capability, EventName, SubCapability } from '../../../Shared/Types';

export function Feed(): React.ReactElement {
  const { dispatch } = React.useContext(ReduxContext);
  const configs = useUserGlobalConfig([FLIGHTS.EVENTS, FLIGHTS.NEWS, FLIGHTS.BOOKMARKS, FLIGHTS.MICROSOFT_LIFE]);
  React.useEffect(() => {
    dispatch(requestNewsFeedDataAction());
    dispatch(requestMicrosoftLifeData());
    dispatch(requestEventsFeedDataAction());
    dispatch(requestBookmarksDataAction());
  }, [dispatch]);

  const seeAllBookmarkClick = (): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Bookmark,
      eventName: EventName.BUTTON_CLICKED,
    });
  };

  return (
    <Page>
      <PageHeadingContainer>
        <PageHeading tabIndex={0} aria-label="Feed">
          Feed
        </PageHeading>
        {configs.bookmark ? (
          <Styled.LinkItem>
            <Link to={'/bookmarks'} onClick={seeAllBookmarkClick} aria-label={'See all Bookmarked items'}>
              <Styled.BookmarkedIcon iconProps={{ iconName: 'DoubleBookmark' }} alt="" />
              Bookmarks
            </Link>
          </Styled.LinkItem>
        ) : null}
      </PageHeadingContainer>
      {configs?.news ? <News isScrollable /> : null}
      {configs?.events ? <Events isScrollable /> : null}
      {configs?.microsoftLife ? <MicrosoftLife isScrollable /> : null}
    </Page>
  );
}
