import { createSelector } from 'reselect';
import { IMyHubAppState } from '../../App.types';
import { IUserState } from '../Models/User.types';
import { UserReducerName } from './User.resources';
import { initialUserState } from './User.reducer';

export const userDetailsSelector = createSelector(
  (state: IMyHubAppState) => state[UserReducerName] ?? initialUserState,
  (userState: IUserState) => userState
);

export const getUserGlobalConfig = createSelector(
  (state: IMyHubAppState) => state[UserReducerName] ?? initialUserState,
  (userState: IUserState) => userState.userGlobalConfig
);

export const getUserGlobalDetailsConfig = createSelector(
  (state: IMyHubAppState) => state[UserReducerName] ?? initialUserState,
  (userState: IUserState) => userState.userGlobalConfigDetails
);
export const getUserDetailsConfig = createSelector(
  (state: IMyHubAppState) => state[UserReducerName] ?? initialUserState,
  (userState: IUserState) => userState.user
);

export const getUserBuildingLocation = createSelector(
  (state: IMyHubAppState) => state[UserReducerName] ?? initialUserState,
  (userState: IUserState) => userState.userBuildingLocation
);
