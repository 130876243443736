import {
  Attendee,
  AutomaticRepliesSetting,
  AutomaticReplyStatus,
  EmailMessage,
  GraphEmail,
  ISaga,
  Meeting,
  OOFEmailInfo,
  OOFEvent,
  OOFOptionKeys,
  OOFOptions,
  OOFPayload,
  ShowAsValues,
  Status,
} from './OOF.types';
import { OOFActionTypes, IManageOOFAction, ConnectorActionTypes } from './OOF.action-types';
import { forOwn, includes } from 'lodash-es';
import moment from 'moment';
import { all, call, put, takeLatest, getContext } from 'redux-saga/effects';
import { ApiHelper } from '../../Helpers/ApiHelper';
import { HttpClient } from '@employee-experience/core';
import {
  updateRespondToMeetingsStatus,
  updateBlockingEventStatus,
  updateCancelConnectorStatus,
  updateCancelMeetingsStatus,
  updateGetEventsStatus,
  updateNonBlockingEventStatus,
  updateOOFOptions,
  updateOOFStatus,
  updateOOFStatusVisibility,
  updateSendEmailStatus,
  updateSetAutomaticReplyStatus,
} from './OOF.actions';
import { TelemetryService } from '../../shared';
import { IAuthClient, IUser } from '@employee-experience/common';
import { chunk } from 'lodash-es';
class OOFSaga implements ISaga {
  private timeZone = 'Pacific Standard Time';
  private timeZoneAppend = {
    end: 'T23:59:59-08:00',
    start: 'T00:00:01-08:00',
  };
  private MAILBOX_BATCH_LIMIT = 4;
  constructor() {
    this.cancelConnectorItineraries = this.cancelConnectorItineraries.bind(this);
    this.cancelMeetings = this.cancelMeetings.bind(this);
    this.createEvent = this.createEvent.bind(this);
    this.createEvents = this.createEvents.bind(this);
    this.getEvents = this.getEvents.bind(this);
    this.handleEvents = this.handleEvents.bind(this);
    this.handleMeeting = this.handleMeeting.bind(this);
    this.manageCalendar = this.manageCalendar.bind(this);
    this.manageOOF = this.manageOOF.bind(this);
    this.resetStatus = this.resetStatus.bind(this);
    this.respondToMeetings = this.respondToMeetings.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.setAutomaticReply = this.setAutomaticReply.bind(this);
    this.updateEventStatus = this.updateEventStatus.bind(this);
    this.oofSagas = this.oofSagas.bind(this);
    this.fetchConnectorItineraries = this.fetchConnectorItineraries.bind(this);
  }

  public *manageOOF({ payload }: IManageOOFAction) {
    const actions: any[] = [];
    if (payload) {
      yield put(updateOOFStatus(Status.InProgress));
      yield put(updateOOFStatusVisibility(true));
      yield put(updateOOFOptions(payload.options));
      const calendarManagement = call(this.manageCalendar, payload);
      const cancelConnectorItineraries = call(this.cancelConnectorItineraries, payload);
      const sendMail = call(this.sendMail, payload);
      const setAutomaticReply = call(this.setAutomaticReply, payload);
      // For adding new options, add a case to this switch case.
      // In that newly added case, add the appropriate function call to the actions array.
      forOwn(payload.options, (value: boolean, key: string) => {
        if (value) {
          switch (key) {
            case OOFOptionKeys.BlockCalendar:
            case OOFOptionKeys.CancelMeetings:
            case OOFOptionKeys.NonBlockingEvent:
            case OOFOptionKeys.RespondToMeetings:
              if (!includes(actions, calendarManagement)) {
                actions.push(calendarManagement);
              }
              break;
            case OOFOptionKeys.SendEmail:
              actions.push(sendMail);
              break;
            case OOFOptionKeys.CancelConnector:
              actions.push(cancelConnectorItineraries);
              break;
            case OOFOptionKeys.SetAutomaticReply:
              actions.push(setAutomaticReply);
            default:
              break;
          }
        }
      });
    }

    const results: boolean[] = yield all(actions);

    let oofManagementSuccess = true;
    results.forEach((success: boolean) => {
      oofManagementSuccess = oofManagementSuccess && success;
    });

    yield put(updateOOFStatus(oofManagementSuccess ? Status.Success : Status.Failed));
  }

  public *resetStatus() {
    yield all([
      put(updateOOFStatus(Status.NotStarted)),
      put(updateBlockingEventStatus({ error: '', status: Status.NotStarted })),
      put(updateCancelConnectorStatus({ error: '', status: Status.NotStarted })),
      put(updateCancelMeetingsStatus({ error: '', status: Status.NotStarted })),
      put(updateGetEventsStatus({ error: '', status: Status.NotStarted })),
      put(updateNonBlockingEventStatus({ error: '', status: Status.NotStarted })),
      put(updateRespondToMeetingsStatus({ error: '', status: Status.NotStarted })),
      put(updateSendEmailStatus({ error: '', status: Status.NotStarted })),
      put(updateSetAutomaticReplyStatus({ error: '', status: Status.NotStarted })),
    ]);
  }
  /**
   * Worker saga to fetch connector itineraries
   */
  public *fetchConnectorItineraries() {
    try {
      const url = `${__API__.myMsftApim.resourceUrl}/mergeconnector/api/connector/itineraries`;
      const httpClient: HttpClient = yield getContext('httpClient');
      const { error, data, status } = yield call([httpClient, httpClient.request], {
        url: url,
        resource: __API__.myMsftApim.resourceId,
        header: ApiHelper.getMyMsftApiHeaders(),
      });
      if (!status || (status !== 200 && status !== 204)) {
        TelemetryService.trackException(new Error('Fetch Connector Itineraries failed'), 1, error);
        yield put({ type: ConnectorActionTypes.GET_CONNECTOR_ITINERARIES_FAILED, payload: { error } });
      } else {
        yield put({ type: ConnectorActionTypes.GET_CONNECTOR_ITINERARIES_SUCCESS, payload: { data } });
      }
    } catch (error) {
      TelemetryService.trackException(error);
      yield put({ type: ConnectorActionTypes.GET_CONNECTOR_ITINERARIES_FAILED, payload: { error } });
    }
  }

  public *oofSagas() {
    yield all([
      takeLatest(OOFActionTypes.MANAGE_OOF, this.manageOOF),
      takeLatest(OOFActionTypes.RESET_OOF_STATUS, this.resetStatus),
      takeLatest(ConnectorActionTypes.GET_CONNECTOR_ITINERARIES, this.fetchConnectorItineraries),
    ]);
  }

  private *cancelConnectorItineraries(payload: OOFPayload) {
    yield put(updateCancelConnectorStatus({ error: '', status: Status.InProgress }));
    const bookingIds: number[] = [];
    // fetch Booking Id to cancel connector Itineraries
    payload.cancelConnectorItineraries?.forEach((trip) => {
      bookingIds.push(trip.bookingId);
    });

    const postData = {
      bookingIds,
      isRecurring: false,
      cancelFlex: true,
    };

    try {
      const url = `${__API__.myMsftApim.resourceUrl}/mergeconnector/api/connector/cancel`;
      const request = {
        resource: __API__.myMsftApim.resourceId,
        header: ApiHelper.getMyMsftApiHeaders(),
        data: postData,
      };
      const httpClient: HttpClient = yield getContext('httpClient');
      const response = yield call([httpClient, httpClient.post], url, request);

      if (!response || response.status !== 200) {
        TelemetryService.trackException(new Error('Cancel Connector  failed'), 1, response);
        yield put(
          updateCancelConnectorStatus({
            error:
              'We won’t be able to cancel your Connector reservation. Please go to the Connector card to cancel it.',
            status: Status.Failed,
          })
        );
        return false;
      }

      yield put(updateCancelConnectorStatus({ error: '', status: Status.Success }));
      return true;
    } catch (error) {
      TelemetryService.trackException(error);
      yield put(
        updateCancelConnectorStatus({
          error: 'We won’t be able to cancel your Connector reservation. Please go to the Connector card to cancel it.',
          status: Status.Failed,
        })
      );
    }
    return false;
  }

  private *cancelMeetings(meetings: Meeting[], options: OOFOptions, description: string) {
    let success = true;
    if (options.cancelMeetings && meetings.length > 0) {
      yield put(
        updateCancelMeetingsStatus({
          error: '',
          status: Status.InProgress,
        })
      );
      const data = {
        Comment: description,
      };

      /* Chunk the meeting into 4 (default batch limit for Mailboxes is 4.) to avoid Application is over its MailboxConcurrency limit error */
      const chunkMeetings = chunk(meetings, this.MAILBOX_BATCH_LIMIT);
      for (let i = 0; i < chunkMeetings.length; i++) {
        const meetings = chunkMeetings[i];
        const cancelMeetingsActions: any[] = [];
        for (let j = 0; j < meetings.length; j++) {
          const meeting = meetings[j];
          const url = `/msgraph/beta/me/events/${meeting.id}/cancel`;
          const cancelMeeting = this.handleMeeting(url, data);
          cancelMeetingsActions.push(cancelMeeting);
        }
        const responses = yield all(cancelMeetingsActions);
        responses.map((response: { status: number }) => {
          TelemetryService.trackException(new Error('Cancelling meeting failed'), 1, response);
          if (!response || response.status !== 202) {
            success = false;
          }
        });
      }
    }

    if (success) {
      yield put(updateCancelMeetingsStatus({ error: '', status: Status.Success }));
    } else {
      yield put(
        updateCancelMeetingsStatus({
          error: 'We won’t be able to cancel your meetings. Please go to your calendar to cancel.',
          status: Status.Failed,
        })
      );
    }

    return success;
  }

  private createAttendees(inputAttendees: GraphEmail[] | undefined): Attendee[] | undefined {
    if (!inputAttendees) {
      return undefined;
    }

    const attendees: Attendee[] = [];
    for (const inputAttendee of inputAttendees) {
      const attendee: Attendee = {
        emailAddress: inputAttendee,
        status: {
          response: 'none',
          time: moment.utc().format(),
        },
        type: 'optional',
      };
      attendees.push(attendee);
    }
    return attendees;
  }

  private *createEvents(events: OOFEvent[], showAs: ShowAsValues) {
    yield this.updateEventStatus(showAs, Status.InProgress);
    let tempSuccess = true;
    for (const event of events) {
      tempSuccess = (yield this.createEvent(event, showAs)) && tempSuccess;
    }
    if (tempSuccess) {
      yield this.updateEventStatus(showAs, Status.Success);
    } else {
      yield this.updateEventStatus(showAs, Status.Failed);
    }
    return tempSuccess;
  }

  private *createEvent(event: OOFEvent, showAs: ShowAsValues) {
    // Exchange doesn't allow all day events that have the same start and end date.
    // In order to work properly, we need to set the end date to the next day.

    const endTime = moment(event.toDate).add(1, 'days').format('YYYY-MM-DD');

    const calendarEvent: Meeting = {
      subject: event.title,
      attendees: showAs === 'free' ? this.createAttendees(event.attendees) : undefined,
      responseRequested: false,
      body: {
        content: event.description,
        contentType: 'text',
      },
      start: {
        dateTime: event.fromDate,
        timeZone: this.timeZone,
      },
      end: {
        dateTime: endTime,
        timeZone: this.timeZone,
      },
      isAllDay: true,
      showAs,
    };

    const url = '/msgraph/v1.0/me/events';

    const httpClient: HttpClient = yield getContext('httpClient');
    try {
      const response = yield call([httpClient, httpClient.post], __API__.myMsftApim.resourceUrl + url, {
        resource: __API__.myMsftApim.resourceId,
        header: ApiHelper.getMyMsftApiHeaders(),
        data: calendarEvent,
      });
      if (!response || response.status !== 201) {
        TelemetryService.trackException(new Error('Creating event failed'), 1, response);
        return false;
      }

      return true;
    } catch (error) {
      TelemetryService.trackException(error);
    }
    return false;
  }

  private *getEvents(event: OOFEvent) {
    yield put(updateGetEventsStatus({ error: '', status: Status.InProgress }));
    const startDateTime = event.fromDate + this.timeZoneAppend.start;
    const endDateTime = event.toDate + this.timeZoneAppend.end;
    const maxLength = 999;
    // We don't use the meetings' subject, but we still want to get it to help debugging.
    const selectParams = 'organizer,subject,responseRequested';
    // Filter the meetings
    // TODO: Filter only when responding to meetings (this will fix a bug where some owned meetings aren't getting cancelled)
    const filter = "(responseRequested eq true) and (isCancelled eq false) and (showAs ne 'free')";
    const url = `/msgraph/v1.0/me/calendar/calendarView?startDateTime=${startDateTime}&endDateTime=${endDateTime}&$top=${maxLength}&select=${selectParams}&filter=${filter}`;
    try {
      const httpClient: HttpClient = yield getContext('httpClient');
      const { data: eventsData } = yield call([httpClient, httpClient.request], {
        url: __API__.myMsftApim.resourceUrl + url,
        resource: __API__.myMsftApim.resourceId,
        header: ApiHelper.getMyMsftApiHeaders(),
      });
      if (eventsData?.value) {
        yield put(updateGetEventsStatus({ error: '', status: Status.Success }));
      } else {
        TelemetryService.trackException(new Error('Getting events failed'), 1, eventsData);
        yield put(
          updateGetEventsStatus({
            error: 'We can’t get your events to update your calendar. Please go to your calendar to make changes.',
            status: Status.Failed,
          })
        );
      }
      return eventsData?.value as Meeting[];
    } catch (error) {
      TelemetryService.trackException(error);
    }
    return [] as Meeting[];
  }

  private *handleEvents(meetings: Meeting[], options: OOFOptions, description: string) {
    if (!meetings) {
      TelemetryService.trackException(new Error('Meetings array is undefined or null'), 1, meetings);
      return false;
    }

    let success = true;

    const authClient: IAuthClient = yield getContext('authClient');

    const user: IUser = yield call([authClient, authClient.getUser]);
    // Filter Meetings Organized by User to Cancel Meetings
    const meetingsToCancel = meetings.filter((meeting) => meeting.organizer?.emailAddress.name === user.name);

    // Filter Meetings Organized by Others to reply Tentative
    const meetingsToRespond = meetings.filter(
      (meeting) => meeting.organizer?.emailAddress.name !== user.name && meeting.responseRequested === true
    );

    const cancelMeetingsResponse = yield call(this.cancelMeetings, meetingsToCancel, options, description);
    if (!cancelMeetingsResponse) {
      success = false;
    }
    const respondToMeetingsResponse = yield call(this.respondToMeetings, meetingsToRespond, options, description);
    if (!respondToMeetingsResponse) {
      success = false;
    }
    return success;
  }

  private *handleMeeting(url: string, data: any): any {
    const httpClient: HttpClient = yield getContext('httpClient');
    try {
      const response = yield call([httpClient, httpClient.post], __API__.myMsftApim.resourceUrl + url, {
        resource: __API__.myMsftApim.resourceId,
        header: ApiHelper.getMyMsftApiHeaders(),
        data: data,
      });

      return response;
    } catch (error) {
      TelemetryService.trackException(error);
    }
    return [];
  }

  private *manageCalendar(payload: OOFPayload) {
    let success = true;

    if (payload.options.cancelMeetings || payload.options.respondToMeetings) {
      let tempSuccess = true;
      for (const event of payload.events) {
        const meetings: Meeting[] = yield this.getEvents(event);
        tempSuccess = (yield this.handleEvents(meetings, payload.options, event.description)) && tempSuccess;
      }
      if (tempSuccess) {
        yield put(updateCancelMeetingsStatus({ error: '', status: Status.Success }));
        yield put(updateRespondToMeetingsStatus({ error: '', status: Status.Success }));
      } else {
        yield put(
          updateCancelMeetingsStatus({
            error: 'We won’t be able to cancel your meetings. Please go to your calendar to cancel.',
            status: Status.Failed,
          })
        );
        yield put(
          updateRespondToMeetingsStatus({
            error:
              'We won’t be able to respond to meeting invites that you get. Please go to your calendar to respond.',
            status: Status.Failed,
          })
        );
      }
      success = tempSuccess && success;
    }

    if (payload.options.blockCalendar) {
      success = (yield this.createEvents(payload.events, ShowAsValues.Oof)) && success;
    }

    if (payload.options.nonBlockingEvent) {
      success = (yield this.createEvents(payload.events, ShowAsValues.Free)) && success;
    }

    return success;
  }

  private *respondToMeetings(meetings: Meeting[], options: OOFOptions, description: string) {
    let success = true;
    if (options.respondToMeetings && meetings.length > 0) {
      yield put(
        updateRespondToMeetingsStatus({
          error: '',
          status: Status.InProgress,
        })
      );
      const data = {
        comment: description,
        sendResponse: true,
      };
      /* Chunk the meeting into 4 (default batch limit for Mailboxes is 4.)to avoid Application is over its MailboxConcurrency limit error */
      const chunkMeetings = chunk(meetings, this.MAILBOX_BATCH_LIMIT);
      for (let i = 0; i < chunkMeetings.length; i++) {
        const meetings = chunkMeetings[i];
        const respondToMeetingsActions: any[] = [];
        for (let j = 0; j < meetings.length; j++) {
          const meeting = meetings[j];
          const url = `/msgraph/v1.0/me/events/${meeting.id}/tentativelyAccept`;
          const responseToMeeting = this.handleMeeting(url, data);
          respondToMeetingsActions.push(responseToMeeting);
        }
        const responses = yield all(respondToMeetingsActions);
        responses.map((response: { status: number }) => {
          if (!response || response.status !== 202) {
            TelemetryService.trackException(new Error('Responding to meetings failed'), 1, response);
            success = false;
          }
        });
      }
    }

    if (success) {
      yield put(updateRespondToMeetingsStatus({ error: '', status: Status.Success }));
    } else {
      yield put(
        updateRespondToMeetingsStatus({
          error: 'We won’t be able to respond to meeting invites that you get. Please go to your calendar to respond.',
          status: Status.Failed,
        })
      );
    }

    return success;
  }

  private *sendEmail(event: OOFEvent, emailInfo: OOFEmailInfo) {
    yield put(updateSendEmailStatus({ error: '', status: Status.InProgress }));
    const url = '/msgraph/v1.0/me/sendMail';
    const emailMessage: EmailMessage = {
      body: {
        content: emailInfo.content,
        contentType: 'text',
      },
      subject: event.title,
      toRecipients: emailInfo.recipients,
    };

    try {
      const httpClient: HttpClient = yield getContext('httpClient');
      const response = yield call([httpClient, httpClient.post], __API__.myMsftApim.resourceUrl + url, {
        resource: __API__.myMsftApim.resourceId,
        header: ApiHelper.getMyMsftApiHeaders(),
        data: { message: emailMessage },
      });

      if (!response || response.status !== 202) {
        TelemetryService.trackException(new Error('Sending email failed'), 1, response);
        yield put(
          updateSendEmailStatus({
            error: 'We won’t be able to send an email to the chosen recipients. Please go to Outlook to send it.',
            status: Status.Failed,
          })
        );
        return false;
      }

      yield put(updateSendEmailStatus({ error: '', status: Status.Success }));
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error=' + error);
      TelemetryService.trackException(error);
      yield put(
        updateSendEmailStatus({
          error: 'We won’t be able to send an email to the chosen recipients. Please go to Outlook to send it.',
          status: Status.Failed,
        })
      );
    }
    return false;
  }

  private *sendMail(payload: OOFPayload) {
    let success = true;
    for (const event of payload.events) {
      success = (yield this.sendEmail(event, payload.emailInfo)) && success;
    }
    return success;
  }

  private *setAutomaticReply(payload: OOFPayload) {
    yield put(updateSetAutomaticReplyStatus({ error: '', status: Status.InProgress }));
    // The OOF reply will only be used for individual date-ranges.
    const event: OOFEvent = payload.events[0];
    const url = '/msgraph/v1.0/me/mailboxSettings';
    const automaticRepliesSetting: AutomaticRepliesSetting = {
      internalReplyMessage: payload.automaticReplyMessage ? payload.automaticReplyMessage : event.description,
      scheduledEndDateTime: {
        dateTime: `${event.toDate}T23:59:59`,
        timeZone: 'Pacific Standard Time',
      },
      scheduledStartDateTime: {
        dateTime: `${event.fromDate}T00:00:00`,
        timeZone: 'Pacific Standard Time',
      },
      status: AutomaticReplyStatus.Scheduled,
    };

    try {
      const httpClient: HttpClient = yield getContext('httpClient');
      const response = yield call([httpClient, httpClient.patch], __API__.myMsftApim.resourceUrl + url, {
        resource: __API__.myMsftApim.resourceId,
        header: ApiHelper.getMyMsftApiHeaders(),
        data: { automaticRepliesSetting },
      });

      if (!response || response.status !== 200) {
        TelemetryService.trackException(new Error('Setting automatic reply failed'), 1, response);
        yield put(
          updateSetAutomaticReplyStatus({
            error:
              'We can’t set your automatic replies right now. Go to Outlook > File > Automatic Replies (Out of Office).',
            status: Status.Failed,
          })
        );
        return false;
      }

      yield put(updateSetAutomaticReplyStatus({ error: '', status: Status.Success }));
      return true;
    } catch (error) {
      TelemetryService.trackException(error);
      yield put(
        updateSetAutomaticReplyStatus({
          error:
            'We can’t set your automatic replies right now. Go to Outlook > File > Automatic Replies (Out of Office).',
          status: Status.Failed,
        })
      );
    }
    return false;
  }

  private *updateEventStatus(showAs: ShowAsValues, status: Status) {
    let error = '';
    if (showAs === 'oof') {
      if (status === 'failed') {
        error = 'We can’t update your calendar with a blocking event. Please go to your calendar to update.';
      }
      yield put(updateBlockingEventStatus({ error, status }));
    } else if (showAs === 'free') {
      if (status === 'failed') {
        error = 'We can’t update your calendar with a non-blocking event. Please go to your calendar to update.';
      }
      yield put(updateNonBlockingEventStatus({ error, status }));
    }
  }
}

export const oofSaga = new OOFSaga();
