import { createSelector } from 'reselect';
import { IMyHubAppState } from '../../../App.types';
import { IOOFState, ICalendarStatusState } from './OOF.types';
import { initialOOFState } from './OOF.reducer';
import { OOFReducerName } from './OOF.resources';

export const getOofPanelToggleStatus = createSelector(
  (state: IMyHubAppState) => state[OOFReducerName] ?? initialOOFState,
  (OOFState: IOOFState) => OOFState.OofPanelToggle
);

export const getBlockCalendarStatus = createSelector(
  (state: IMyHubAppState) => state[OOFReducerName]?.BlockCalendarStatus ?? initialOOFState,
  (OOFState: ICalendarStatusState) => OOFState
);
export const getCancelConnectorStatus = createSelector(
  (state: IMyHubAppState) => state[OOFReducerName]?.CancelConnectorStatus ?? initialOOFState,
  (OOFstate: ICalendarStatusState) => OOFstate
);
export const getCancelMeetingsStatus = createSelector(
  (state: IMyHubAppState) => state[OOFReducerName]?.CancelMeetingsStatus ?? initialOOFState,
  (OOFstate: ICalendarStatusState) => OOFstate
);
export const getEventsStatus = createSelector(
  (state: IMyHubAppState) => state[OOFReducerName]?.GetEventsStatus ?? initialOOFState,
  (OOFState: ICalendarStatusState) => OOFState
);
export const getNonBlockingEventStatus = createSelector(
  (state: IMyHubAppState) => state[OOFReducerName]?.NonBlockingEventStatus ?? initialOOFState,
  (OOFstate: ICalendarStatusState) => OOFstate
);
export const getOOFOptions = createSelector(
  (state: IMyHubAppState) => state[OOFReducerName] ?? initialOOFState,
  (OOFstate: IOOFState) => OOFstate.OofOptions
);

export const getOOFStatus = createSelector(
  (state: IMyHubAppState) => state[OOFReducerName] ?? initialOOFState,
  (OOFstate: IOOFState) => OOFstate.OofStatus
);
export const getOOFStatusIsVisible = createSelector(
  (state: IMyHubAppState) => state[OOFReducerName] ?? initialOOFState,
  (OOFstate: IOOFState) => OOFstate.OofStatusIsVisible
);

export const getRespondToMeetingsStatus = createSelector(
  (state: IMyHubAppState) => state[OOFReducerName]?.RespondToMeetingsStatus ?? initialOOFState,
  (OOFstate: ICalendarStatusState) => OOFstate
);
export const getSendEmailStatus = createSelector(
  (state: IMyHubAppState) => state[OOFReducerName]?.SendEmailStatus ?? initialOOFState,
  (OOFstate: ICalendarStatusState) => OOFstate
);

export const getSetAutomaticReplyStatus = createSelector(
  (state: IMyHubAppState) => state[OOFReducerName]?.SetAutomaticReplyStatus ?? initialOOFState,
  (OOFstate: ICalendarStatusState) => OOFstate
);

/**
 * get connector itineraries state
 */
export const getConnectorItineraries = createSelector(
  (state: IMyHubAppState) => state[OOFReducerName] ?? initialOOFState,
  (OOFstate: IOOFState) => OOFstate.ConnectorItineraries
);

/**
 * get Oof Config state
 */
export const getOofConfig = createSelector(
  (state: IMyHubAppState) => state[OOFReducerName] ?? initialOOFState,
  (OOFstate: IOOFState) => OOFstate.OofConfig
);
