import { ActionButton, IconButton } from 'office-ui-fabric-react';
import { maxWidth, minWidth } from '../../../Styles/Media';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LargeScreenButton = styled(ActionButton)`
  height: 44px;

  ${maxWidth.m} {
    display: none;
  }
`;

export const SmallScreenButton = styled(IconButton)`
  height: 48px;
  width: 48px;

  ${minWidth.m} {
    display: none;
  }
`;
