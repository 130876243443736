import * as React from 'react';
import * as Styled from './ManagerFooter.styled';
import { Link } from '@employee-experience/common/lib/Link';

export function ManagerFooter(): React.ReactElement {
  const year = new Date().getFullYear();

  return (
    <Styled.FooterMenus>
      <Styled.FooterMenuItem>
        <Link
          to="http://go.microsoft.com/fwlink/?LinkId=518021"
          title="Microsoft Data Privacy Notice"
          target="_blank"
          usageEvent={{
            feature: 'MyHub.Footer',
            subFeature: 'MicrosoftDataPrivacyNoticeLink',
          }}
        >
          Microsoft Data Privacy Notice
        </Link>
      </Styled.FooterMenuItem>
      <Styled.FooterMenuItem>
        <Link
          to="https://microsoft.sharepoint.com/sites/hrw/Pages/Legal-Notice.aspx"
          title="Legal notice"
          target="_blank"
          usageEvent={{
            feature: 'MyHub.Footer',
            subFeature: 'LegalNoticeLink',
          }}
        >
          Legal Notice
        </Link>
      </Styled.FooterMenuItem>
      <Styled.FooterMenuItem>&copy; {year} Microsoft</Styled.FooterMenuItem>
    </Styled.FooterMenus>
  );
}
