export enum ActionTypes {
  SET_LOCATION_PERMISSIONS = 'SET_LOCATION_PERMISSIONS',
  UPDATE_LOCATION_PERMISSIONS_AND_COORDINATES = 'UPDATE_LOCATION_PERMISSIONS_AND_COORDINATES',
  SET_LOCATION = 'SET_LOCATION',
}
export interface ISetLocationPermissions {
  type: ActionTypes.SET_LOCATION_PERMISSIONS;
  item: string;
}
export interface ISetLocation {
  type: ActionTypes.SET_LOCATION;
  location: { latitude: number; longitude: number; accuracy: number };
}

export interface IFetchLocationCoordinates {
  type: ActionTypes.UPDATE_LOCATION_PERMISSIONS_AND_COORDINATES;
}

export type ILocationAction = ISetLocation | IFetchLocationCoordinates | ISetLocationPermissions;
