import { IQnaDocumentModel } from './Models/IQnaDocumentModel';

export enum ActionTypes {
  GET_FAQ_DATA = 'GET_FAQ_DATA',
  SET_FAQ_DATA = 'SET_FAQ_DATA',
  GET_FAQ_DATA_FAILED = 'GET_FAQ_DATA_FAILED',
}

export interface IGetFaqDataAction {
  type: ActionTypes.GET_FAQ_DATA;
  configs: string[];
}

export interface ISetFaqDataAction {
  type: ActionTypes.SET_FAQ_DATA;
  payload: IQnaDocumentModel[];
}

export type IHelpAction = IGetFaqDataAction | ISetFaqDataAction;
