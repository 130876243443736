import * as React from 'react';
import * as Styled from './Footer.styled';
import { useEffect, useContext } from 'react';
import { IFrequentlyUsedLink } from './Footer.types';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { requestFrequentlyUsedLinksAction } from './Footer.actions';
import { top3FrequentlyUsedLinksSelector } from './Footer.selectors';
import { isNavOpenSelector } from '../../App.selectors';
import { useUserGlobalConfig } from '../../Shared/Hooks/useUserGlobalConfig';
import { FLIGHTS } from '../../Constants/flightingConstants';
import { ManagerFooter } from './ManagerFooter';

export function Footer(): React.ReactElement {
  const { dispatch, useSelector } = useContext(ReduxContext);
  const frequentlyUsedLinks: IFrequentlyUsedLink[] = useSelector(top3FrequentlyUsedLinksSelector);
  const configs = useUserGlobalConfig([FLIGHTS.MANAGER_DASHBOARD, FLIGHTS.SHOW_NOT_ALLOWED_PAGE]);
  const isNavOpen: boolean = useSelector(isNavOpenSelector);
  const year = new Date().getFullYear();

  useEffect(() => {
    dispatch(requestFrequentlyUsedLinksAction());
  }, [dispatch]);

  return (
    <Styled.Container isNavOpen={isNavOpen}>
      {(configs[FLIGHTS.MANAGER_DASHBOARD] || configs[FLIGHTS.SHOW_NOT_ALLOWED_PAGE]) && <ManagerFooter />}
      {!configs[FLIGHTS.MANAGER_DASHBOARD] && !configs[FLIGHTS.SHOW_NOT_ALLOWED_PAGE] && (
        <Styled.ContentContainer>
          {frequentlyUsedLinks.length && (
            <Styled.FrquentlyUsedLinksSection>
              Frequently used:
              {frequentlyUsedLinks.map((link) => (
                <Styled.FrequentlyUsedLink key={link.id} href={link.url} target="_blank">
                  {link.text}
                </Styled.FrequentlyUsedLink>
              ))}
            </Styled.FrquentlyUsedLinksSection>
          )}
          <Styled.CopyrightSection>&copy; Microsoft {year}</Styled.CopyrightSection>
        </Styled.ContentContainer>
      )}
    </Styled.Container>
  );
}
