import * as React from 'react';
import * as Styled from './SubmitTicket.styled';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { TextField } from '@employee-experience/common/lib/TextField';
import { IReportProblemModel } from '../Models/IReportProblemModel';
import { submitTickets, fetchTickets, resetTicket } from '../ReportProblem.actions';
import { submitTicketsLoadingStatusSelector } from '../ReportProblem.selector';
import { LoadingStates } from '../../../../Shared/Models/LoadingStates';
import { browserName, browserVersion, osName } from 'react-device-detect';
import { IUserState, sessionIDKey } from '../../../../Shared/Models/User.types';
import { userDetailsSelector } from '../../../../Shared/User/User.selectors';
import { ErrorScreen } from '../../../../Shared/Components/ErrorScreen';
import { ISubmitTicketProps } from './SubmitTicket.types';
import { InputGroup } from '@employee-experience/common/lib/InputGroup';
import { issueTypes, getCategories, _MYHUB_WEBUX_SERVICETREE_CI_SYSID_ } from '../../Header.Resources';
import { TelemetryService } from '../../../../Shared/shared';
import { Capability, SubCapability, EventName } from '../../../../Shared/Types';
import { useUserGlobalConfig } from '../../../../Shared/Hooks/useUserGlobalConfig';
import { FLIGHTS } from '../../../../Constants/flightingConstants';
import { IComboBox, IComboBoxOption, ComboBox } from 'office-ui-fabric-react';
import moment = require('moment');
import { MicroSentimentRequest, startMicroSentimentFlowAction } from '@microsoft/myhub-web-partner-tools';

export const SubmitTicket: React.FC<ISubmitTicketProps> = (props: ISubmitTicketProps) => {
  const { onClicked } = props;
  const { dispatch, useSelector } = React.useContext(ReduxContext);
  const [incidentDetails, setIncidentDetails] = React.useState({} as IReportProblemModel);
  const submitTicketsLoadingStatus = useSelector(submitTicketsLoadingStatusSelector);
  const userStateParams: IUserState = useSelector(userDetailsSelector);
  const [showError, setError] = React.useState(false);
  const [showShortDescError, setShortDescError] = React.useState(false);
  const [showLongDescError, setLongDescError] = React.useState(false);
  const configs = useUserGlobalConfig([FLIGHTS.MANAGER_DASHBOARD, FLIGHTS.MYHUB_DASHBOARD]);
  const categoryRef = React.useRef(null);
  const shortDescriptionRef = React.useRef(null);
  const longDescriptionRef = React.useRef(null);

  React.useEffect(() => {
    dispatch(resetTicket());
  }, []);

  React.useEffect(() => {
    if (submitTicketsLoadingStatus === LoadingStates.SUCCEEDED) {
      TelemetryService.trackEvent({
        capability: Capability.MyHub,
        subCapability: SubCapability.ReportAProblem_SubmitTab,
        eventName: EventName.PANEL_OPENED,
      });
      dispatch(fetchTickets());
      onClicked();
      dispatch(resetTicket());
      const request: MicroSentimentRequest = {
        feature: 'Report a problem',
        taskMessage: 'Ticket successfully created',
        ratingMessage: 'Rate your experience',
      };
      dispatch(startMicroSentimentFlowAction(request));
    }
  }, [submitTicketsLoadingStatus]);

  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const handleUpdate = (name: string, value: string): void => {
    setIncidentDetails(
      (prev: IReportProblemModel): IReportProblemModel => ({
        ...prev,
        [name]: value,
      })
    );
    if (name == 'shortDescription') {
      value.length > 3 || value.length < 60 ? setShortDescError(false) : setShortDescError(true);
    } else if (name == 'issueDescription') {
      value.length > 0 ? setLongDescError(false) : setLongDescError(true);
    }
  };

  const updateCategory = (event: React.FormEvent<IComboBox>, option: IComboBoxOption | undefined): void => {
    TelemetryService.trackEvent(
      {
        capability: Capability.MyHub,
        subCapability: SubCapability.ReportAProblem,
        eventName: EventName.DROP_DOWN_SELECTED,
      },
      { option }
    );
    if (option) handleUpdate('category', option.text);
    setError(false);
  };

  const updateIssueType = (event: React.FormEvent<IComboBox>, option: IComboBoxOption | undefined): void => {
    TelemetryService.trackEvent(
      {
        capability: Capability.MyHub,
        subCapability: SubCapability.ReportAProblem,
        eventName: EventName.DROP_DOWN_SELECTED,
      },
      { option }
    );
    if (option) handleUpdate('issueType', option.text);
  };

  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const _getShortDescriptionErrorMessage = (value: string): string => {
    if (value.length < 3 || value.length > 60) {
      setShortDescError(true);
      return 'Input value length must be between 3 to 60 characters';
    } else {
      return '';
    }
  };

  const telemetry =
    'Browser Name:' +
    browserName +
    '\n Browser Version: ' +
    browserVersion +
    '\n SessionID: ' +
    userStateParams[sessionIDKey];
  const validateForm = (): boolean => {
    if (
      incidentDetails.category != undefined &&
      incidentDetails.shortDescription != undefined &&
      incidentDetails.issueDescription != undefined
    ) {
      return true;
    } else {
      if (incidentDetails.category == undefined) {
        setError(true);
        categoryRef?.current.focus();
      }
      if (incidentDetails.shortDescription == undefined) {
        setShortDescError(true);
        showError && shortDescriptionRef?.current.focus();
      }
      if (incidentDetails.issueDescription == undefined) {
        setLongDescError(true);
        showError && showShortDescError && longDescriptionRef?.current.focus();
      }
      return false;
    }
  };
  const submitTicket = (): void => {
    const longDescription =
      'Category: ' +
      incidentDetails.category +
      '\n Issue Type: ' +
      incidentDetails.issueType +
      '\n Issue Description: ' +
      incidentDetails.issueDescription +
      '\n Ring: MyHub_Web \n TimeStamp: ' +
      moment().format('YYYY-MM-DD hh:mm:ss') +
      '\n OS: ' +
      osName;
    // camelcase rule is disabled explictly as snow api allows predefined variable with - and not with camelcase
    /* eslint-disable @typescript-eslint/camelcase */
    const data = {
      short_description: incidentDetails.shortDescription,
      description: longDescription,
      work_notes: telemetry,
      cmdb_ci: _MYHUB_WEBUX_SERVICETREE_CI_SYSID_,
    };
    TelemetryService.trackEvent(
      {
        capability: Capability.MyHub,
        subCapability: SubCapability.ReportAProblem_SubmitTicket,
        eventName: EventName.BUTTON_CLICKED,
      },
      { data }
    );
    if (validateForm()) {
      dispatch(submitTickets({ data }));
    }
  };
  if (submitTicketsLoadingStatus === LoadingStates.FAILED) {
    return <ErrorScreen innerPageUse={true} />;
  } else {
    return (
      <div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <InputGroup>
              <ComboBox
                defaultSelectedKey={incidentDetails.category}
                label="Category"
                options={getCategories(configs.ManagerDashboard, configs.You)}
                placeholder="Please select an option"
                required
                errorMessage={showError && incidentDetails.category == undefined ? 'Category is required' : undefined}
                onChange={updateCategory}
                styles={Styled.comboboxStyles}
                selectedKey={incidentDetails.category}
                useComboBoxAsMenuWidth={true}
                componentRef={categoryRef}
              />
            </InputGroup>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <InputGroup>
              <ComboBox
                defaultSelectedKey={incidentDetails.issueType}
                label="Issue type"
                options={issueTypes}
                placeholder="Please select an option"
                onChange={updateIssueType}
                styles={Styled.comboboxStyles}
                selectedKey={incidentDetails.issueType}
                useComboBoxAsMenuWidth={true}
              />
            </InputGroup>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <InputGroup>
              <TextField
                value={incidentDetails.shortDescription}
                name="shortDescription"
                onChange={handleUpdate}
                label="Short description"
                required
                errorMessage={
                  showShortDescError && incidentDetails.shortDescription == undefined
                    ? 'Short description is required'
                    : undefined
                }
                onGetErrorMessage={_getShortDescriptionErrorMessage}
                validateOnLoad={false}
                usageEvent={{
                  feature: 'MyHub.Help',
                  subFeature: 'ReportAProblem_ShortDescription',
                }}
                componentRef={shortDescriptionRef}
              />
            </InputGroup>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <InputGroup>
              <TextField
                value={incidentDetails.issueDescription}
                name="issueDescription"
                onChange={handleUpdate}
                label="Please describe your issue"
                styles={Styled.textfieldStyles}
                multiline
                required
                resizable={true}
                errorMessage={
                  showLongDescError && incidentDetails.issueDescription == undefined
                    ? 'Issue description is required'
                    : undefined
                }
                usageEvent={{
                  feature: 'MyHub.Help',
                  subFeature: 'ReportAProblem_IssueDescription',
                }}
                componentRef={longDescriptionRef}
              />
            </InputGroup>
          </div>
        </div>
        <Styled.SubmitButton
          ariaLabel="Submit"
          disabled={
            submitTicketsLoadingStatus === LoadingStates.STARTED || showError || showShortDescError || showLongDescError
          }
          onClick={submitTicket}
        >
          Submit
        </Styled.SubmitButton>
      </div>
    );
  }
};
