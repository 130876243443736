import * as React from 'react';
import { CoherencePanel, CoherencePanelSize } from '@cseo/controls';
import { ComponentContext, ReduxContext } from '@employee-experience/common';
import { TelemetryService } from '../shared';
import { endMicroSentimentFlowAction, submitMicroSentimentAction } from './MicroSentiment.actions';
import { Capability, EventName, SubCapability } from '../Types';
import * as Styled from './MicroSentiment.styled';
import { DefaultButton, ImageIcon, PrimaryButton, registerIcons } from 'office-ui-fabric-react';
import { Rating, RatingSize } from '@fluentui/react';
import { microSentimentRequestSelector, microSentimentSubmitLoadingStatusSelector } from './MicroSentiment.selectors';
import { useUserGlobalConfig } from '../Hooks/useUserGlobalConfig';
import { LoadingStates } from '../Models/LoadingStates';
import { LoadingScreen } from '../Components/LoadingScreen';
import { ErrorScreen } from '../Components/ErrorScreen';
import { FLIGHTS } from '../../Constants/flightingConstants';
import {
  loadFeedbackScript,
  officeFeedbackClicked,
  initializeFeedback,
} from '../../../officeBrowserFeedback/OfficeBrowserFeedbackWrapper';
import { getFeedbackLaunchOptions } from '../Hooks/useHeaderConfig';

export function MicroSentiment(): React.ReactElement {
  const { dispatch, useSelector } = React.useContext(ReduxContext);
  const { telemetryClient } = React.useContext(ComponentContext);
  const request = useSelector(microSentimentRequestSelector);
  const sessionID = telemetryClient.telemetryContext.usageUser().sessionId;
  const submitLoadingStatus = useSelector(microSentimentSubmitLoadingStatusSelector);
  const [selectedRating, setRating] = React.useState(0);
  // Feature gets cleared when panel closes. Storing feature for OCV panel category selection.
  const [feature, setFeature] = React.useState();
  const configs = useUserGlobalConfig([FLIGHTS.MANAGER_DASHBOARD]);

  registerIcons({
    icons: {
      'custom-star': (
        <ImageIcon
          imageProps={{
            src: '/assets/Shared/MicroSentiment/Star_Icon.svg',
          }}
        />
      ),
      'custom-selected-star': (
        <ImageIcon
          imageProps={{
            src: '/assets/Shared/MicroSentiment/Selected_Star_Icon.svg',
          }}
        />
      ),
    },
  });

  React.useEffect(() => {
    // Clear request data from previous session.
    dispatch(endMicroSentimentFlowAction());
  }, []);

  React.useEffect(() => {
    if (!request && submitLoadingStatus === LoadingStates.NOT_STARTED) {
      const selectElement = document.getElementById('obf-BasicFormCategoriesDropdown');
      if (selectElement) {
        Array.from(selectElement.options).map((option) =>
          option.value.includes(feature) ? (option.selected = true) : ''
        );
        setFeature(undefined);
      }
    }
  }, [request, submitLoadingStatus, feature]);

  React.useEffect(() => {
    if (request) {
      setFeature(request.feature);
      if (submitLoadingStatus === LoadingStates.NOT_STARTED) {
        TelemetryService.trackEvent({
          capability: Capability.MicroSentiment,
          subCapability: SubCapability.MicroSentiment_HomePage,
          eventName: EventName.PANEL_OPENED,
        });
      } else if (submitLoadingStatus === LoadingStates.SUCCEEDED) {
        TelemetryService.trackEvent({
          capability: Capability.MicroSentiment,
          subCapability: SubCapability.MicroSentiment_ThanksScreen,
          eventName: EventName.PANEL_OPENED,
        });
      }
      setRating(0);
    }
  }, [request, submitLoadingStatus]);

  const onRatingPanelClosed = (): void => {
    TelemetryService.trackEvent({
      capability: Capability.MicroSentiment,
      subCapability: SubCapability.MicroSentiment_HomePage,
      eventName: EventName.PANEL_CLOSED,
    });
    dispatch(endMicroSentimentFlowAction());
  };

  const onRatingChange = (ev: React.FormEvent<HTMLElement>, rating?: number): void => {
    if (rating) {
      TelemetryService.trackEvent(
        {
          capability: Capability.MicroSentiment,
          subCapability: SubCapability.MicroSentiment_RatingClicked,
          eventName: EventName.BUTTON_CLICKED,
        },
        { rating, ...request }
      );
      setRating(rating);
    }
  };

  const sendButtonClicked = (): void => {
    TelemetryService.trackEvent(
      {
        capability: Capability.MicroSentiment,
        subCapability: SubCapability.MicroSentiment_RatingSubmitted,
        eventName: EventName.BUTTON_CLICKED,
      },
      { selectedRating, ...request }
    );
    dispatch(submitMicroSentimentAction());
  };

  const notNowButtonClicked = (): void => {
    TelemetryService.trackEvent({
      capability: Capability.MicroSentiment,
      subCapability: SubCapability.MicroSentiment_CloseNotNow,
      eventName: EventName.BUTTON_CLICKED,
    });
    dispatch(endMicroSentimentFlowAction());
  };

  const onResultPanelClosed = (): void => {
    TelemetryService.trackEvent({
      capability: Capability.MicroSentiment,
      subCapability: SubCapability.MicroSentiment_ThanksScreen,
      eventName: EventName.PANEL_CLOSED,
    });
    dispatch(endMicroSentimentFlowAction());
  };

  const handleFeedbackNavigation = (): boolean => {
    const launchOptions = getFeedbackLaunchOptions(configs, telemetryClient.telemetryContext.usageUser());
    loadFeedbackScript()
      .then(() =>
        initializeFeedback(
          sessionID,
          telemetryClient.telemetryContext.usageUser() ? telemetryClient.telemetryContext.usageUser().email : '',
          () => {}
        )
      )
      .then(() => officeFeedbackClicked(launchOptions));
    return true;
  };

  const tellUsMoreButtonClicked = (): void => {
    TelemetryService.trackEvent({
      capability: Capability.MicroSentiment,
      subCapability: SubCapability.MicroSentiment_ThanksScreen_TellUsMore,
      eventName: EventName.BUTTON_CLICKED,
    });
    handleFeedbackNavigation();
    dispatch(endMicroSentimentFlowAction());
  };

  const closeButtonClicked = (): void => {
    TelemetryService.trackEvent({
      capability: Capability.MicroSentiment,
      subCapability: SubCapability.MicroSentiment_ThanksScreen_CloseNotNow,
      eventName: EventName.BUTTON_CLICKED,
    });
    dispatch(endMicroSentimentFlowAction());
  };

  const getRatingComponentAriaLabel = (rating: number, maxRating: number): string => {
    return `Rating value is ${rating} of ${maxRating}`;
  };

  return (
    request && (
      <>
        <CoherencePanel
          titleText={feature}
          panelSize={CoherencePanelSize.medium}
          isOpen={submitLoadingStatus === LoadingStates.NOT_STARTED || submitLoadingStatus === LoadingStates.STARTED}
          isLightDismiss={true}
          telemetryHook={telemetryClient}
          isBlocking={true}
          onDismiss={onRatingPanelClosed}
          closeButtonAriaLabel="Close"
          onRenderFooter={(): JSX.Element => {
            if (submitLoadingStatus === LoadingStates.NOT_STARTED) {
              return (
                <>
                  <PrimaryButton ariaLabel="Send" disabled={selectedRating === 0} onClick={sendButtonClicked}>
                    Send
                  </PrimaryButton>
                  <DefaultButton ariaLabel="Not Now" onClick={notNowButtonClicked}>
                    Not Now
                  </DefaultButton>
                </>
              );
            } else {
              return <></>;
            }
          }}
        >
          {((): React.ReactElement => {
            if (submitLoadingStatus === LoadingStates.NOT_STARTED) {
              return (
                <Styled.PanelContent>
                  <Styled.TickImage src="/assets/Shared/MicroSentiment/Tick.svg" alt="" />
                  <Styled.PanelContentTitle>{request.taskMessage}</Styled.PanelContentTitle>
                  <Styled.RatingMessage>{request.ratingMessage}</Styled.RatingMessage>
                  <Rating
                    allowZeroStars
                    ariaLabelFormat={'{0} of {1} stars'}
                    getAriaLabel={getRatingComponentAriaLabel}
                    icon="custom-selected-star"
                    max={5}
                    min={1}
                    onChange={onRatingChange}
                    rating={selectedRating}
                    size={RatingSize.Large}
                    styles={Styled.RatingStyles}
                    unselectedIcon="custom-star"
                  />
                </Styled.PanelContent>
              );
            } else {
              return <LoadingScreen innerPageUse={true} />;
            }
          })()}
        </CoherencePanel>
        <CoherencePanel
          titleText={feature}
          panelSize={CoherencePanelSize.medium}
          isOpen={submitLoadingStatus === LoadingStates.FAILED || submitLoadingStatus === LoadingStates.SUCCEEDED}
          isLightDismiss={true}
          telemetryHook={telemetryClient}
          isBlocking={true}
          onDismiss={onResultPanelClosed}
          onRenderFooter={(): JSX.Element => {
            if (submitLoadingStatus === LoadingStates.SUCCEEDED) {
              return (
                <>
                  <PrimaryButton ariaLabel="Tell us more" onClick={(): void => tellUsMoreButtonClicked()}>
                    Tell us more
                  </PrimaryButton>
                  <DefaultButton ariaLabel="Close" onClick={closeButtonClicked}>
                    Close
                  </DefaultButton>
                </>
              );
            } else {
              return <></>;
            }
          }}
        >
          {((): React.ReactElement => {
            if (submitLoadingStatus === LoadingStates.SUCCEEDED) {
              return (
                <Styled.PanelContent>
                  <Styled.SuccessImage src="/assets/Shared/MicroSentiment/Success.svg" alt="" />
                  <Styled.PanelContentTitle>Thanks for your feedback</Styled.PanelContentTitle>
                </Styled.PanelContent>
              );
            } else {
              return <ErrorScreen innerPageUse={true} />;
            }
          })()}
        </CoherencePanel>
      </>
    )
  );
}
