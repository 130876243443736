import { FontWeights } from 'office-ui-fabric-react';
import { minWidth } from '../../../Styles/Media';
import { FontSizes } from '@cseo/styles';

export const LoadingText = styled.div`
  font-size: ${FontSizes.size18};
  font-weight: ${FontWeights.semibold};
  margin-top: 16px;

  ${minWidth.xl} {
    font-size: ${FontSizes.size26};
    margin-top: 24px;
  }
`;
