import {
  ActionTypes,
  IFetchWhatsNewDataAction,
  ISetWhatsNewDataAction,
  ISetWhatsNewDataAvailableAction,
} from './WhatsNew.action-types';
import { WhatsNewRelease } from './WhatsNew.types';

export function fetchWhatsNewDataAction(): IFetchWhatsNewDataAction {
  return {
    type: ActionTypes.FETCH_WHATS_NEW_DATA,
  };
}

export function setWhatsNewDataAction(data: WhatsNewRelease[]): ISetWhatsNewDataAction {
  return {
    type: ActionTypes.SET_WHATS_NEW_DATA,
    data: data,
  };
}

export function setWhatsNewDataAvailableAction(isAvailable: boolean): ISetWhatsNewDataAvailableAction {
  return {
    type: ActionTypes.SET_WHATS_NEW_DATA_AVAILABLE,
    isAvailable: isAvailable,
  };
}
