import * as React from 'react';
import { CoherencePanel } from '@cseo/controls';
import * as Styled from '../Help.styled';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import ReactMarkdown = require('react-markdown');
import { TelemetryService } from '../../../Shared/shared';
import { Capability, EventName, SubCapability } from '../../../Shared/Types';
import { IPanelProps } from '../../../Shared/Types/interfaces/IPanelProps';

interface IFaqDetailProps extends IPanelProps {
  question: string;
  answer: string;
}

export function FaqDetail(props: IFaqDetailProps): React.ReactElement {
  const { telemetryClient } = React.useContext(ComponentContext);
  // Handles light dismiss.
  const onPanelClosed = (ev?: React.SyntheticEvent<HTMLElement, Event> | undefined): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Faq_List,
      eventName: EventName.PANEL_CLOSED,
    });
    if (ev) {
      props.backButton?.onClick();
    }
  };
  return (
    <div>
      <CoherencePanel
        isOpen={props.isOpen}
        backButton={props.backButton}
        telemetryHook={telemetryClient}
        isBlocking={false}
        titleText={props.question}
        onDismiss={onPanelClosed}
      >
        <Styled.FaqAnswer>
          <ReactMarkdown source={props.answer} escapeHtml={false} />
        </Styled.FaqAnswer>
      </CoherencePanel>
    </div>
  );
}
