import { INotificationsAction, ActionTypes } from './Notifications.action-types';
import { INotificationsState } from './Notifications.types';
import { LoadingStates } from '../../../Shared/Models/LoadingStates';

export const initialNotificationsState: INotificationsState = {
  NotificationsLoadingStatus: LoadingStates.NOT_STARTED,
  Notifications: [],
};

export function notificationsReducer(
  prev: INotificationsState = initialNotificationsState,
  action: INotificationsAction
): INotificationsState {
  switch (action.type) {
    case ActionTypes.RECEIVE_NOTIFICATIONS_DATA_FAILED: {
      return {
        ...prev,
        NotificationsLoadingStatus: LoadingStates.FAILED,
      };
    }
    case ActionTypes.RECEIVE_NOTIFICATIONS_DATA_SUCCEEDED: {
      return {
        ...prev,
        Notifications: action.Notifications,
        NotificationsLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }
    case ActionTypes.REQUEST_NOTIFICATIONS_DATA: {
      return {
        ...prev,
        NotificationsLoadingStatus: LoadingStates.STARTED,
      };
    }
    default:
      return prev;
  }
}
