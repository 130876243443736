import { IFrequentlyUsedLink } from './Footer.types';

export const FooterReducerName = 'footer';

export const FrequentlyUsedLinks: IFrequentlyUsedLink[] = [
  {
    id: '1',
    text: 'msn.com',
    url: 'https://msn.com',
  },
  {
    id: '2',
    text: 'merge.com',
    url: 'https://merge.com',
  },
  {
    id: '3',
    text: 'benefits.com',
    url: 'https://benefits.com',
  },
];
