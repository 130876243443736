import * as React from 'react';
import * as Styled from './MicrosoftLife.styled';
import {
  Card,
  CardBody,
  CardFooter,
  CardFooterItem,
  CardMetadata,
  CardMetadataContainer,
  CardMetadataText,
} from './../../Card';
import TextEllipsis from 'react-text-ellipsis';
import moment from 'moment';
import { StripHtmlTagsRegEx } from '../../Feed.resources';
import { Link as LinkTag } from './../../../../../Styles/Link';
import { BookmarkButton } from '../../Bookmarks/BookmarkButton/BookmarkButton';
import Entities = require('entities');
import { IBookmarksModel } from '../../Models/IBookmarksModel';
import { IMicrosoftLifeCardProps } from './MicrosoftLife.types';

export function MicrosoftLifeCard(props: IMicrosoftLifeCardProps): React.ReactElement {
  const {
    post_id: id,
    network,
    post_time: postTime,
    text: title,
    photos,
    user_name: userName,
    permalink: link,
  } = props.post;

  let imageUrl = photos && photos.length > 0 && photos[0].m;
  if (!imageUrl) {
    imageUrl =
      network === 'instagram'
        ? '/assets/Feed/InstagramImagePlaceHolder.svg'
        : '/assets/Feed/TwitterImagePlaceHolder.svg';
  }
  const iconUrl = network === 'instagram' ? '/assets/Feed/InstagramLogo.svg' : '/assets/Feed/TwitterLogo.svg';
  const formattedPostTime = moment.unix(Number(postTime)).format('MMM D, YYYY');

  const post: IBookmarksModel = {
    id,
    images: imageUrl,
    title,
    link,
    feedsType: '2',
    extended: {
      postTime,
      network,
      userName,
    },
  };

  function renderUserHandle(): React.ReactElement | string {
    if (link) {
      if (userName) {
        return (
          <>
            <Styled.IconImage src={iconUrl} alt="" />
            {`@${userName}`}
          </>
        );
      }
      return 'Go to post';
    }
    return '';
  }

  return (
    <Card>
      <CardBody>
        <Styled.PostImageContainer tabIndex={0} role="img">
          <Styled.PostImage src={imageUrl} alt="" />
        </Styled.PostImageContainer>
        <Styled.TextContainer role="text">
          {title && (
            <Styled.Title tabIndex={0} role="text">
              <TextEllipsis lines={4} accessibile={true}>
                {Entities.decodeHTML(title).replace(StripHtmlTagsRegEx, '')}
              </TextEllipsis>
            </Styled.Title>
          )}
          {postTime && (
            <CardMetadataContainer>
              {postTime && (
                <CardMetadata>
                  <CardMetadataText>
                    <Styled.Posted tabIndex={0} role="text">
                      <TextEllipsis lines={1}>{formattedPostTime}</TextEllipsis>
                    </Styled.Posted>
                  </CardMetadataText>
                </CardMetadata>
              )}
            </CardMetadataContainer>
          )}
        </Styled.TextContainer>
      </CardBody>
      <CardFooter>
        <CardFooterItem>
          <LinkTag href={link} target="_blank" aria-label={`@${userName}, opens in ${network}`}>
            <Styled.HandleContainer>{renderUserHandle()}</Styled.HandleContainer>
          </LinkTag>
        </CardFooterItem>
        <CardFooterItem>{props.showBookmark ? <BookmarkButton {...post}></BookmarkButton> : null}</CardFooterItem>
      </CardFooter>
    </Card>
  );
}
