export const FLIGHTS = {
  NEWS: 'news',
  EVENTS: 'events',
  BOOKMARKS: 'bookmark',
  FEEDBACK: 'feedback',
  HELP: 'help',
  SETTINGS: 'settings',
  NOTIFICATIONS: 'notifications',
  DASHBOARD: 'dashboard',
  EXPLORE: 'explore',
  HRWEB: 'hrweb',
  ADD_TILE: 'addtile',
  MANAGER_DASHBOARD: 'ManagerDashboard',
  MYHUB_DASHBOARD: 'You',
  MANAGER_CHECK: 'ManagerCheck',
  SEARCH: 'Search',
  HEADER_SEARCH: 'HeaderSearch',
  SHOW_NOT_ALLOWED_PAGE: 'ShowNotAllowedPage',
  MICROSOFT_LIFE: 'microsoftLife',
  WHATS_NEW: 'whatsNew',
};
