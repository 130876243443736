import { breakpointMap, minWidth } from '../../../Styles/Media';
import { IconButton } from 'office-ui-fabric-react';
// import { FontSizes, NeutralColors, TextColors } from '@cseo/styles';

export const PivotStyles = {
  root: {
    marginLeft: '5vw',
    marginRight: '5vw',
    marginBottom: '24px',
    selectors: {
      [`@media (min-width: ${breakpointMap.xl}px)`]: {
        marginTop: '24px',
      },
    },
  },
};

export const CarouselContainer = styled.div`
  ${minWidth.m} {
    margin-left: 18px;
    margin-right: 18px;
  }

  ${minWidth.s} {
    margin-left: 10px;
    margin-right: 10px;
  }

  ${minWidth.xl} {
    margin-left: 5vw;
    margin-right: 5vw;
  }
`;
export const BookmarkedIcon = styled(IconButton)`
  position: relative;
  width: 14px;
  height: 14px;
  right: 3px;
  top: 4px;
`;

export const LinkItem = styled.div`
  font-size: 14px;
`;
