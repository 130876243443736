import * as React from 'react';
import * as Styled from './FeedCarousel.styled';
import { breakpointMap } from '../../../../Styles/Media';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

export interface IFeedCarouselProps {
  header: string;
  seeMoreLink: string;
  children: React.ReactNode;
  currentFocusElement?: number;
}

const scrollsize = {
  xxl: 40,
  xl: 38,
  l: 34,
  m: 25,
  s: 0,
};

const scrollBack = {
  xxl: 47,
  xl: 47,
  l: 47,
  m: 45,
  s: 45,
};

const cardsPerSlideLookUp = {
  xxl: 5,
  xl: 4,
  l: 3,
  m: 2,
  s: 1,
};

function getWindowSize(): string {
  const currentWindowWidth = window.innerWidth;
  if (currentWindowWidth >= breakpointMap.xxl) {
    return 'xxl';
  } else if (currentWindowWidth >= breakpointMap.xl) {
    return 'xl';
  } else if (currentWindowWidth >= breakpointMap.l) {
    return 'l';
  } else if (currentWindowWidth >= breakpointMap.m) {
    return 'm';
  }
  return 's';
}

export function GetCardLayoutParams(): number {
  const [cardsPerSlide, setCardsPerSlide] = useState(3);
  function handleResize(): void {
    const newCardsPerSlide = cardsPerSlideLookUp[getWindowSize()];
    setCardsPerSlide(newCardsPerSlide);
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  return cardsPerSlide;
}

export function FeedCarousel(props: IFeedCarouselProps): React.ReactElement {
  const totalCarouselItemCount = props.children ? props.children.length : 0;
  //eslint-disable react-hooks/rules-of-hooks
  const cardsPerSlide = GetCardLayoutParams();
  const [isLastSlide, setIsLastSlide] = React.useState(totalCarouselItemCount <= cardsPerSlide);
  const [isFirstSlide, setIsFirstSlide] = React.useState(true);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [lastNumFocus, setlastNumFocus] = React.useState(0);

  const resetCarousel = (): void => {
    setScrollPosition(0);
    setIsFirstSlide(true);
    setCurrentSlide(0);
    setIsLastSlide(totalCarouselItemCount <= cardsPerSlide);
  };

  React.useEffect(() => {
    // Resetting the scroll position if cardsPerSlide are updated to recaluclate new scroll postions
    resetCarousel();
  }, [cardsPerSlide]);

  const numberOfCards = props.children.length ? props.children.length : 0;
  const cal = numberOfCards / cardsPerSlide - 1;
  const numberOfSlides = Math.floor(Number.isInteger(cal) ? cal : cal + 1);
  let slideWidth = 100;

  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const handleLeftClick = (): void => {
    if (currentSlide != 0) {
      const newSlide = currentSlide - 1;
      setIsLastSlide(false);
      setCurrentSlide(newSlide);
      setScrollPosition(scrollPosition + slideWidth);
      setIsFirstSlide(newSlide == 0);
    }
  };

  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const handleRightClick = (): void => {
    if (currentSlide !== numberOfSlides) {
      const newSlide = currentSlide + 1;
      setIsFirstSlide(false);
      setCurrentSlide(newSlide);
      setScrollPosition(scrollPosition - slideWidth);
      setIsLastSlide(newSlide === numberOfSlides);
    }
  };
  const current = props.currentFocusElement ? props.currentFocusElement : 0;
  const carouselItemCount = cardsPerSlideLookUp[getWindowSize()];
  if (lastNumFocus < current && lastNumFocus !== current && current % carouselItemCount === 0) {
    slideWidth = scrollsize[getWindowSize()];
    handleRightClick();
    setlastNumFocus(current);
  } else if (lastNumFocus > current && lastNumFocus !== current && (current + 1) % carouselItemCount === 0) {
    slideWidth = scrollBack[getWindowSize()];
    handleLeftClick();
    setlastNumFocus(current);
  }
  return (
    <>
      <Styled.CarouselHeaderContainer>
        <Styled.Header aria-label={props.header}>{props.header}</Styled.Header>
        {numberOfCards ? (
          <Styled.CarouselControls>
            {!isLastSlide && <Link to={props.seeMoreLink}>See all</Link>}
            <Styled.LeftButton
              data-testid={`${props.header}-leftArrow`}
              iconProps={{ iconName: 'ChevronLeft' }}
              disabled={isFirstSlide}
              onClick={handleLeftClick}
            />
            <Styled.RightButton
              data-testid={`${props.header}-rightArrow`}
              iconProps={{ iconName: 'ChevronRight' }}
              disabled={isLastSlide}
              onClick={handleRightClick}
            />
          </Styled.CarouselControls>
        ) : null}
      </Styled.CarouselHeaderContainer>
      <Styled.CarouselScrollContainer>
        <Styled.CarouselScroll translateX={scrollPosition}>{props.children}</Styled.CarouselScroll>
      </Styled.CarouselScrollContainer>
    </>
  );
}
