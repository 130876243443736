import { useCallback, useContext, useEffect, useState } from 'react';
import { shouldUseAnchorTag } from '@employee-experience/common/lib/Link';
import { useHistory, useLocation } from 'react-router-dom';
import { INavLinkGroup, INavLink } from './Nav.types';
import { INavLinkGroup as IBaseNavLinkGroup, INavLink as IBaseNavLink } from '@cseo/controls/lib/Nav';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { breakpointMap } from '../../Styles/Media';
import { toggleNav } from '../../App.actions';

const rootHref = '/';

function isLinkSelected(href: string): boolean {
  const { pathname } = window.location;
  if (href === rootHref) return pathname === href;
  return pathname.startsWith(href);
}

export function useNavGroups(groups: INavLinkGroup[], deps: boolean[]): IBaseNavLinkGroup[] {
  const history = useHistory();
  const { pathname } = useLocation();
  const { dispatch } = useContext(ReduxContext);

  const createLink = useCallback(
    (name: string, href: string): {} => {
      return {
        name,
        onClick: (): void => {
          shouldUseAnchorTag(href, false) ? window.open(href) : history.push(href);
          if (window.innerWidth < breakpointMap.xl) {
            dispatch(toggleNav());
          }
        },
        isSelected: isLinkSelected(href),
      };
    },
    [dispatch, history]
  );

  const createChildLinks = (childLinks: INavLink[] | undefined): IBaseNavLink[] | undefined => {
    if (!childLinks) return;
    return childLinks.map(
      ({ links, href, name, ...otherLinkProps }): IBaseNavLink => {
        return {
          ...(otherLinkProps as IBaseNavLink),
          ...createLink(name, href),
          links: createChildLinks(links),
        };
      }
    );
  };

  const createNavGroups = (groups: INavLinkGroup[]): IBaseNavLinkGroup[] =>
    groups.map(
      ({ links: groupLinks, ...otherGroupProps }): IBaseNavLinkGroup => {
        return {
          ...otherGroupProps,
          links: createChildLinks(groupLinks) || [],
        };
      }
    );

  const [nav, setNav] = useState<IBaseNavLinkGroup[]>([]);

  useEffect(() => {
    setNav(createNavGroups(groups));
  }, [pathname, ...deps]);

  return nav;
}
