import * as React from 'react';
import * as Styled from './ZeroSearchResults.styled';

export const ZeroSearchResults = (): React.ReactElement => {
  return (
    <Styled.ImageInfoText>
      <img src="/assets/Help/Faq_Zero_Search_Results.svg" alt="zero search results" />
      <Styled.HeaderText>Looks empty in here</Styled.HeaderText>
      <Styled.DetailText>
        There are no results for the keywords you have entered. Try searching for something else or go to Report a
        problem.
      </Styled.DetailText>
    </Styled.ImageInfoText>
  );
};
