export enum ActionTypes {
  GET_EXPLORE_BUILDINGS = 'GET_EXPLORE_BUILDINGS',
  GET_EXPLORE_BUILDINGS_SUCCESS = 'GET_EXPLORE_BUILDINGS_SUCCESS',
  GET_EXPLORE_BUILDINGS_FAILED = 'GET_EXPLORE_BUILDINGS_FAILED',
  RECEIVE_BUILDINGS = 'RECEIVE_BUILDINGS',
  GET_BUILDING_DETAILS = 'GET_BUILDING_DETAILS',
  GET_BUILDING_DETAILS_SUCCESS = 'GET_BUILDING_DETAILS_SUCCESS',
  GET_BUILDINGS_DETAILS_FAILED = 'GET_BUILDINGS_DETAILS_FAILED',
  RECEIVE_BUILDING_DETAILS = 'RECEIVE_BUILDING_DETAILS',
}

export interface RequestBuildingsDataActionPayload {
  regionId: string;
}

export interface RequestBuildingDetailsActionPayload {
  spaceId: string;
}

export interface IGetExploreBuildings {
  type: ActionTypes.GET_EXPLORE_BUILDINGS;
  payload: RequestBuildingsDataActionPayload;
}

export interface IGetExploreBuildingsSuccess {
  type: ActionTypes.GET_EXPLORE_BUILDINGS_SUCCESS;
}

export interface IGetExploreBuildingsFailed {
  type: ActionTypes.GET_EXPLORE_BUILDINGS_FAILED;
}

export interface IReceiveExploreBuildingsSuccess {
  type: ActionTypes.RECEIVE_BUILDINGS;
  buildings: {};
}

export interface IGetBuildingDetails {
  type: ActionTypes.GET_BUILDING_DETAILS;
  payload: RequestBuildingDetailsActionPayload;
}

export interface IGetBuildingDetailsSuccess {
  type: ActionTypes.GET_BUILDING_DETAILS_SUCCESS;
}

export interface IGetBuildingDetailsFailed {
  type: ActionTypes.GET_BUILDINGS_DETAILS_FAILED;
  error: string;
}

export interface IReceiveBuildingDetailsSuccess {
  type: ActionTypes.RECEIVE_BUILDING_DETAILS;
  buildingDetails: {};
}

export type IExploreActions =
  | IGetExploreBuildings
  | IGetExploreBuildingsSuccess
  | IGetExploreBuildingsFailed
  | IReceiveExploreBuildingsSuccess
  | IGetBuildingDetails
  | IGetBuildingDetailsSuccess
  | IGetBuildingDetailsFailed
  | IReceiveBuildingDetailsSuccess;
