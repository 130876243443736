export interface ILocationState {
  locationValue: string;
  locationCoordinates: { latitude: number; longitude: number; accuracy: number };
}
/**
 * Location Reducer name
 */

export const LocationReducerName = 'location';
/**
 * BUILDING_92 Coordinates
 */
export const BUILDING_92 = {
  latitude: 47.64269456471515,
  longitude: -122.13701605796815,
  accuracy: 25,
};
/**
 * Type for Coordinates
 */
export declare type Coordinates = {
  accuracy?: number;
  latitude: number;
  longitude: number;
};
