import {
  ISubmitTicketAction,
  ActionTypes,
  IFetchTicketAction,
  IResetTicketAction,
  ISetTicketAction,
  IFetchTicketFailedAction,
  ISubmitTicketSuccessAction,
  ISubmitTicketFailedAction,
} from './ReportProblem.action-types';
import { ITicketsModel } from './Models/ITicketsModel';

export const submitTickets = (payload: object = {}): ISubmitTicketAction => ({
  type: ActionTypes.SUBMIT_TICKET,
  payload,
});

export const submitTicketSuccess = (): ISubmitTicketSuccessAction => ({
  type: ActionTypes.SUBMIT_TICKET_SUCCEEDED,
});

export const submitTicketFailed = (payload: object): ISubmitTicketFailedAction => ({
  type: ActionTypes.SUBMIT_TICKET_FAILED,
  payload: payload,
});

export const fetchTickets = (): IFetchTicketAction => ({
  type: ActionTypes.FETCH_OPEN_TICKET,
});

export const openTicketsFailed = (): IFetchTicketFailedAction => ({
  type: ActionTypes.FETCH_TICKET_FAILED,
});

export const setTickets = (payload: ITicketsModel[]): ISetTicketAction => ({
  type: ActionTypes.FETCH_TICKET_SUCCEEDED,
  payload: payload,
});

/**
 * Action to reset ticket
 */
export const resetTicket = (): IResetTicketAction => ({
  type: ActionTypes.RESET_TICKET,
});
