import { minWidth, maxWidth } from './Styles/Media';

export const Main = styled.main`
  // Flex for sticky footer.
  flex: 1 0 auto;
  // Adding space for header.
  height: auto;
  width: 100%;

  ${minWidth.xl} {
    &.navCollapsed {
      margin-left: 48px;
      width: calc(100% - 48px);
    }

    &.navExpanded {
      margin-left: 228px;
      width: calc(100% - 228px);
    }
  }

  ${maxWidth.xl} {
    &.navCollapsed,
    &.navExpanded {
      margin-top: -40px;
    }
  }
`;

export const CenterContainer = styled.main`
background-color: #f1f1f1;
align-items: center;
display: flex;
flex-direction: column;
height: 100%;
justify-content: center;
// Added padding for gutter on lowest resolution.
padding-top: 60px;
text-align: center;
width: 100%;
`;
export const ImageContainer = styled.div`
  width: 180px;
  height: 180px;
  overflow: hidden;
`;
export const Heading = styled.text`
margin:  60px 0 20px 0;
font-weight: 600;
font-size: 26px;
letter-spacing: 0.33px;
`;
export const SubHeading = styled.text`
  font-size: 17px;
  margin-bottom: 50px
`;

export const Link = styled.text`
  font-size: 12px;
`;
export const Stores = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px
`;

