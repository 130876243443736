import moment from 'moment';

export type Event = {
  title: string;
  description: string;
  location: string;
  start: Date;
  end: Date;
};

export default class OutlookHelper {
  private static getRandomKey(): string {
    const n = Math.floor(Math.random() * 999999999999).toString();
    return new Date().getTime().toString() + '_' + n;
  }

  private static formatDate(date: Date): string {
    const formattedDate = moment(date, 'MMMM D, YYYY, h:mma');
    return formattedDate.format();
  }

  public static createEvent(event: Event): void {
    let link = 'https://outlook.office.com/owa/?rru=addevent';
    link += '&startdt=' + this.formatDate(event.start);
    link += '&enddt=' + this.formatDate(event.end);
    link += '&subject=' + encodeURIComponent(event.title);
    link += '&location=' + encodeURIComponent(event.location);
    link += '&body=' + encodeURIComponent(event.description);
    link += '&allday=false';
    link += '&uid=' + this.getRandomKey();
    link += '&path=/calendar/view/Month';
    window.open(link, '_blank');
  }
}
