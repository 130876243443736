import { FontWeights } from 'office-ui-fabric-react';
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths';
import { SurfaceColors, NeutralColors, FontSizes, TextColors } from '@cseo/styles';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { IconButton } from 'office-ui-fabric-react';
import { minWidth } from '../../../../Styles/Media';
// TODO-6185926: check if we can use some styles from Card Component Feed/Card
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: calc(5vw - 8px);
  margin-right: calc(5vw - 8px);

  ${minWidth.xl} {
    margin-left: calc(5vw - 12px);
    margin-right: calc(5vw - 12px);
  }
`;

export const SeeMoreText = styled.div`
  position: relative;
  width: 91px;
  height: 22px;
  left: calc(50% - 91px / 2 + 0.5px);
  top: calc(50% - 22px / 2 - 48.5px);
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #fafafa;
`;
export const SeeMoreCard = styled.div`
  background: linear-gradient(97.17deg, #a7cbf6 -5.86%, #0078d7 106.84%);
  border-radius: 2px;
  margin-bottom: 16px;
  ${minWidth.s} {
    min-width: 98%;
    max-width: 98%;
    margin-left: 1%;
    margin-right: 1%;
  }

  ${minWidth.m} {
    min-width: 48%;
    max-width: 48%;
    margin-left: 1%;
    margin-right: 1%;
  }

  ${minWidth.l} {
    min-width: 31%;
    max-width: 31%;
  }

  ${minWidth.xl} {
    min-width: 23%;
    max-width: 23%;
    margin-left: 1%;
    margin-right: 1%;
  }

  ${minWidth.xxl} {
    min-width: 18%;
    max-width: 18%;
    margin-left: 1%;
    margin-right: 1%;
  }
`;

export const Card = styled.div`
  background-color: ${SurfaceColors.primary};
  border-radius: 2px;
  box-shadow: ${Depths.depth4};
  margin-bottom: 16px;
  min-width: 98%;
  max-width: 98%;
  margin-left: 1%;
  margin-right: 1%;

  ${minWidth.m} {
    min-width: 48%;
    max-width: 48%;
    margin-left: 1%;
    margin-right: 1%;
  }

  ${minWidth.l} {
    min-width: 31%;
    max-width: 31%;
  }

  ${minWidth.xl} {
    min-width: 23%;
    max-width: 23%;
    margin-left: 1%;
    margin-right: 1%;
  }

  ${minWidth.xxl} {
    min-width: 18%;
    max-width: 18%;
    margin-left: 1%;
    margin-right: 1%;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px); // Subtracting card footer height.
`;

export const CardMetadataContainer = styled.div`
  bottom: 4px;
  position: absolute;
  width: 100%;
`;

export const CardMetadata = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

export const CardMetadataIcon = styled(FontIcon)`
  margin-right: 8px;
`;

export const CardMetadataText = styled.div`
  color: ${NeutralColors.gray130};
  font-size: ${FontSizes.size12};
  width: 100%;
`;

export const CardFooter = styled.div`
  border-top: 1px solid ${NeutralColors.gray40};
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
  padding-left: 16px;
`;

export const CardFooterItem = styled.div`
  align-items: center;
  display: flex;
`;

export const CardTag = styled.div`
  align-items: center;
  background: ${SurfaceColors.tertiary};
  border-radius: 2px;
  display: flex;
  font-size: ${FontSizes.size12};
  height: 20px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const CardActionButton = styled(IconButton)`
  height: 40px;
`;

export const PostImageContainer = styled.div`
  height: 138px;
  position: relative;
`;

export const PostImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const IconImage = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 6px;
`;

export const TextContainer = styled.div`
  height: 80px;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
`;

export const Title = styled.div`
  margin-top: 9px;
  color: ${TextColors.lightPrimary};
  font-size: ${FontSizes.size14};
  font-weight: ${FontWeights.semibold};
`;

export const Posted = styled.div`
  margin-top: 7px;
`;

export const HandleContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const Desc = styled.div`
  color: ${NeutralColors.gray200};
  margin-top: 4px;
`;
export const FeedSponsorText = styled.div`
  background: ${NeutralColors.gray20};
  bottom: 0px;
  font-size: ${FontSizes.size12};
  max-width: 100%;
  opacity: 0.8;
  overflow: hidden;
  padding: 6px 16px;
  position: absolute;
  right: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
