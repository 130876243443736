import { createSelector } from 'reselect';
import { initialReportProblemState } from './ReportProblem.reducer';
import { ReportProblemReducerName } from './ReportProblem.resources';
import { IMyHubAppState } from '../../../App.types';
import { IReportProblemState } from './ReportProblem.types';

export const fetchTicketsSelector = createSelector(
  (state: IMyHubAppState) => state[ReportProblemReducerName] ?? initialReportProblemState,
  (reportProblemState: IReportProblemState) => reportProblemState.openTickets
);
export const submitTicketsLoadingStatusSelector = createSelector(
  (state: IMyHubAppState) => state[ReportProblemReducerName] ?? initialReportProblemState,
  (reportProblemState: IReportProblemState) => reportProblemState.submitTicketLoadingStatus
);

export const openTicketsLoadingStatusSelector = createSelector(
  (state: IMyHubAppState) => state[ReportProblemReducerName] ?? initialReportProblemState,
  (reportProblemState: IReportProblemState) => reportProblemState.openTicketsLoadingStatus
);
