import * as React from 'react';
import * as Styled from './FeedCarouselItem.styled';
import TextEllipsis from 'react-text-ellipsis';
import { Link as LinkTag } from '../../../../Styles/Link';
import { BookmarkButton } from '../Bookmarks/BookmarkButton/BookmarkButton';
const Entities = require('entities');
import OutlookHelper, { Event } from '../../../../Shared/Helpers/OutlookHelper';
import { IBookmarkPayload } from '../../Feed/Models/IFeedCardModel';

import { StripHtmlTagsRegEx } from '../Feed.resources';
import {
  Card,
  CardBody,
  SeeMoreCard,
  SeeMoreText,
  CardFooter,
  CardMetadata,
  CardMetadataContainer,
  CardFooterItem,
  CardMetadataText,
} from './FeedCarouselItem.styled';
import { Link } from 'react-router-dom';
// import { useRef } from 'react';
import { CardActionButton } from '../Card';
import { TelemetryService } from '../../../../Shared/shared/TelemetryService';
import { Capability, EventName, SubCapability } from '../../../../Shared/Types';

interface IFeedItemProps {
  imageUrl?: string | undefined | false | null;
  fallbackImageUrl?: string;
  title?: string;
  dateLabel?: string;
  link?: string;
  linkLabel?: string;
  linkIcon?: string;
  feedId?: string;
  feedType?: string;
  bookmarkPayload?: IBookmarkPayload;
  linkAccessibilitylabel?: string;
  key?: number;
  itemNumber?: number;
  getFocusEle?: (itemno: number) => void;
  seeMoreCard?: boolean;
  seeMoreLink?: string;
  descLines?: number;
  showBookmark?: boolean;
  showDescription?: boolean;
  showFeedSponsorTitle?: boolean;
}

export function FeedCarouselItem(props: IFeedItemProps): React.ReactElement {
  const {
    imageUrl,
    fallbackImageUrl,
    title,
    dateLabel,
    link,
    linkLabel,
    linkIcon,
    feedType,
    bookmarkPayload,
    linkAccessibilitylabel,
    itemNumber,
    getFocusEle,
    seeMoreCard,
    descLines,
    showDescription,
    showFeedSponsorTitle,
  } = props;

  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const checkElement = (): void => {
    getFocusEle(itemNumber);
  };
  function addToCalendarClicked(): void {
    const event: Event = {
      title: title ? title : '',
      description: bookmarkPayload.shortDescription,
      location: bookmarkPayload.location,
      start: bookmarkPayload.startDate,
      end: bookmarkPayload.endDate,
    };
    TelemetryService.trackEvent(
      {
        capability: Capability.MyHub,
        subCapability: SubCapability.Events_AddToCalender,
        eventName: EventName.BUTTON_CLICKED,
      },
      { event }
    );
    OutlookHelper.createEvent(event);
  }

  const contentHeight = {
    5: '171px',
    4: '130px',
    3: '110px',
    2: '88px',
    1: '88px',
  };
  const containerHeight = descLines ? contentHeight[descLines] : {};
  const getItemCard = (): React.ReactElement => {
    return (
      <>
        <Card onFocus={checkElement}>
          <CardBody>
            <Styled.PostImageContainer
              tabIndex={0}
              role="img"
              style={feedType === 'microsoftLife' ? { height: '268px' } : {}}
            >
              <Styled.PostImage src={imageUrl || fallbackImageUrl} alt="" />
              {showFeedSponsorTitle && bookmarkPayload?.feedSponsorTitle && (
                <Styled.FeedSponsorText>
                  {bookmarkPayload?.feedSponsorTitle.replace(/-/g, '\u2013')}
                </Styled.FeedSponsorText> // replace hypen with EN DASH(\u2013)
              )}
            </Styled.PostImageContainer>
            <Styled.TextContainer style={descLines ? { height: containerHeight } : {}} role="text">
              {title && (
                <Styled.Title tabIndex={0} role="text">
                  <TextEllipsis lines={descLines && !showDescription ? descLines : 2} accessibile={true}>
                    {Entities.decodeHTML(title).replace(StripHtmlTagsRegEx, '')}
                  </TextEllipsis>
                </Styled.Title>
              )}
              {bookmarkPayload?.description && showDescription && (
                <Styled.Desc>
                  <TextEllipsis lines={2}>
                    {Entities.decodeHTML(bookmarkPayload.description).replace(StripHtmlTagsRegEx, '')}
                  </TextEllipsis>
                </Styled.Desc>
              )}
              {dateLabel && (
                <CardMetadataContainer>
                  {dateLabel && (
                    <CardMetadata>
                      <CardMetadataText>
                        <Styled.Posted tabIndex={0} role="text">
                          <TextEllipsis lines={1}>{dateLabel}</TextEllipsis>
                        </Styled.Posted>
                      </CardMetadataText>
                    </CardMetadata>
                  )}
                </CardMetadataContainer>
              )}
            </Styled.TextContainer>
          </CardBody>
          <CardFooter>
            <CardFooterItem>
              {link && (
                <LinkTag href={link} target="_blank" aria-label={linkAccessibilitylabel}>
                  <Styled.HandleContainer>
                    {linkIcon && <Styled.IconImage src={linkIcon} alt="" />}
                    {linkLabel}
                  </Styled.HandleContainer>
                </LinkTag>
              )}
            </CardFooterItem>
            <CardFooterItem>
              {feedType === 'events' && dateLabel && (
                <CardActionButton
                  ariaLabel="Add to calendar"
                  iconProps={{ iconName: 'Calendar' }}
                  onClick={addToCalendarClicked}
                ></CardActionButton>
              )}
              <BookmarkButton {...bookmarkPayload}></BookmarkButton>
            </CardFooterItem>
          </CardFooter>
        </Card>
      </>
    );
  };

  const getSeeMoreCard = (): React.ReactElement => {
    return (
      <>
        <SeeMoreCard onFocus={checkElement}>
          <Link to={link}>
            <SeeMoreText>{title}</SeeMoreText>
          </Link>
        </SeeMoreCard>
      </>
    );
  };
  return <>{seeMoreCard ? getSeeMoreCard() : getItemCard()}</>;
}
