import {
  ActionTypes,
  IEndMicroSentimentFlowAction,
  ISubmitMicroSentimentAction,
  ISubmitMicroSentimentFailedAction,
  ISubmitMicroSentimentSucceededAction,
} from './MicroSentiment.action-types';

export function endMicroSentimentFlowAction(): IEndMicroSentimentFlowAction {
  return {
    type: ActionTypes.END_MICRO_SENTIMENT_FLOW,
  };
}

export function submitMicroSentimentAction(): ISubmitMicroSentimentAction {
  return {
    type: ActionTypes.SUBMIT_MICRO_SENTIMENT,
  };
}

export function submitMicroSentimentFailedAction(): ISubmitMicroSentimentFailedAction {
  return {
    type: ActionTypes.SUBMIT_MICRO_SENTIMENT_FAILED,
  };
}

export function submitMicroSentimentSucceededAction(): ISubmitMicroSentimentSucceededAction {
  return {
    type: ActionTypes.SUBMIT_MICRO_SENTIMENT_SUCCEEDED,
  };
}
