import * as React from 'react';
import { Announced } from 'office-ui-fabric-react/lib/Announced';
import { IGridProps } from './Grid.types';
import { Card } from '../Card';
import { catalogItemsSelector } from '../Dashboard.selectors';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { ICatalogItem } from '../Shared/Models/ICatalogItem';
import { useEffect, useContext, useState, createRef } from 'react';
import * as Styled from './Grid.styled';
import { ICard } from '../Shared/Models/ICard';
import GridService from './../Shared/Services/GridService';
import { IGridItem } from '../Shared/Models/IGridItem';
import { IGridMoveEvent } from '../Shared/Models/IGridMoveEvent';
import { isNavOpenSelector } from '../../../App.selectors';
import { IGridDragStartPredicateEvent } from '../Shared/Models/IGridDragStartPredicateEvent';
import { moveCardAction } from '../Dashboard.actions';

export const Grid: React.FC<IGridProps> = (props: IGridProps): React.ReactElement => {
  const { cards } = props;
  const cardRefs = cards.map(() => createRef<HTMLDivElement>());
  const { dispatch, useSelector } = useContext(ReduxContext);
  const catalogItems: ICatalogItem[] = useSelector(catalogItemsSelector);
  const isNavOpen: boolean = useSelector(isNavOpenSelector);
  const [grid, setGrid] = useState();
  const [draggedCardId, setDraggedCardId] = useState('');
  const [message, setMessage] = useState();

  useEffect(() => {
    const grid = new GridService('.grid', {
      dragCssProps: {
        touchAction: 'pan-y',
      },
      dragEnabled: true,
      dragPlaceholder: {
        duration: 100,
        easing: 'linear',
        enabled: true,
      },
      dragStartPredicate: (item: IGridItem, event: IGridDragStartPredicateEvent): boolean | void => {
        if (
          event.target.closest('a') ||
          event.target.closest('button') ||
          event.target.closest('input') ||
          event.target.closest('select') ||
          event.target.closest('textarea')
        ) {
          return false;
        } else if (event.srcEvent.pointerType === 'mouse' || event.srcEvent.pointerType === 'touch') {
          return true;
        } else if (event.deltaTime > 500) {
          return true;
        }
        // Do not return so that the the method is called again with the next deltaTime value.
      },
      dragSortPredicate: { threshold: 10 },
      layout: {
        fillGaps: true,
      },
    });
    const dragStartEventListener = grid.on('dragStart', (item: IGridItem) => {
      setDraggedCardId(item._element.id);
      setMessage(catalogItems.find((data) => data.id === item._element.id).title + ' card dragged');
    });
    const dragEndEventListener = grid.on('dragEnd', () => {
      setDraggedCardId('');
      setMessage('');
    });
    const moveEventListener = grid.on('move', (data: IGridMoveEvent) => {
      dispatch(moveCardAction(data.fromIndex, data.toIndex));
    });
    setGrid(grid);
    return (): void => {
      grid.off('dragStart', dragStartEventListener);
      grid.off('dragEnd', dragEndEventListener);
      grid.off('move', moveEventListener);
      grid.destroy(true);
    };
  }, [dispatch]);

  useEffect(() => {
    if (grid) {
      const gridItems: IGridItem[] = grid.getItems();
      if (gridItems.length < cards.length) {
        // Cards were added.
        const newCardRefs = [];
        for (const cardIndex in cards) {
          if (!gridItems.find((gridItem) => gridItem._element.id === cards[cardIndex].id)) {
            newCardRefs.push(cardRefs[cardIndex].current);
          }
        }
        if (newCardRefs.length) {
          grid.add(newCardRefs, { layout: false });
        }
      } else if (gridItems.length > cards.length) {
        // Card was removed.
        for (const gridItem of gridItems) {
          if (!cards.find((card) => card.id === gridItem._element.id)) {
            grid.remove([gridItem], { layout: false });
            break;
          }
        }
      } else {
        // Card was resized, card order was changed or navbar was toggled.
        grid.refreshItems();
      }
      grid.layout();
    }
  }, [cards, isNavOpen]);

  return (
    <Styled.Grid className="grid">
      {cards.map((card: ICard, index: number) => (
        // Muuri needs the extra wrapper div.
        <Styled.CardContainer
          key={card.id}
          className={
            card.size.toLowerCase() +
            (draggedCardId && draggedCardId !== card.id ? ' background-card' : '') +
            ' ' +
            'item'
          }
          id={card.id}
          ref={cardRefs[index]}
        >
          <Announced message={message} aria-live="assertive" />
          <Card
            id={card.id}
            scriptPath={catalogItems.find((item) => item.id === card.id)?.scriptPath}
            size={card.size}
            title={catalogItems.find((item) => item.id === card.id)?.title}
            componentName={catalogItems.find((item) => item.id === card.id)?.componentName}
            data={catalogItems.find((item) => item.id === card.id)?.data}
          />
        </Styled.CardContainer>
      ))}
    </Styled.Grid>
  );
};
