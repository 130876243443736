import * as React from 'react';
import { Icon, IconType } from 'office-ui-fabric-react/lib/Icon';
import { registerIcons } from 'office-ui-fabric-react/lib/Styling';

export function initializeCustomIcons(): void {
  registerIcons({
    icons: {
      '/assets/HRWeb/hr.svg': (
        <Icon
          imageProps={{
            src: '/assets/HRWeb/hr.svg',
            width: 20,
          }}
          iconType={IconType.Image}
        />
      ),
    },
  });
}
