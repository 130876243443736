import { createSelector } from 'reselect';
import { IMyHubAppState } from '../../../../App.types';
import { initialWhatsNewState } from './WhatsNew.reducer';
import { WhatsNewReducerName } from './WhatsNew.resources';
import { IWhatsNewState } from './WhatsNew.types';

export const whatsNewReleasesSelector = createSelector(
  (state: IMyHubAppState) => state[WhatsNewReducerName] ?? initialWhatsNewState,
  (whatsNewState: IWhatsNewState) => whatsNewState.whatsNewReleases
);

export const isWhatsNewDataAvailableSelector = createSelector(
  (state: IMyHubAppState) => state[WhatsNewReducerName] ?? initialWhatsNewState,
  (whatsNewState: IWhatsNewState) => whatsNewState.isWhatsNewDataAvailable
);
