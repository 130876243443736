/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars, no-console */
// KA - Lint rules for newweb are much stricter than they were for shared
// TODO: Fix linter errors resulted from importing this file from "shared"
import { Action } from '../Types';
import { isEmpty } from 'lodash-es';
import { Saga, SagaMonitor } from 'redux-saga';
import { Effect } from 'redux-saga/effects';
import { TelemetryService } from './TelemetryService';

class AppInsightsSagaMonitor implements SagaMonitor {
  public actionDispatched?(action: Action<any>): void {
    const { type, payload } = action;
    let extendedProperties = {};
    if (payload) {
      const { headers, data, error } = payload;
      if (!isEmpty(error)) {
        // on FAILURE action log the error object.
        extendedProperties = { ...extendedProperties, ...{ error } };
      } else if (!isEmpty(data) && !type.endsWith('_SUCCESS')) {
        // don't log the data object on SUCESS/dispatch action scenarios.
        extendedProperties = { ...extendedProperties, ...{ data } };
      } else if (!headers) {
        extendedProperties = { payload };
      }
    }
    TelemetryService.trackTrace(type, undefined, extendedProperties);
  }

  public effectTriggered(options: { effect: Effect; effectId: number; label?: string; parentEffectId: number }): void {
    if (options && options.effect) {
      switch (options.effect.type) {
        case 'CALL':
          if (options.effect.payload && options.effect.payload.fn) {
            const { fn } = options.effect.payload;
            if (fn.name === 'http' || fn.name.includes('saga')) {
              return;
            }
            TelemetryService.trackTrace(`SagaMonitor - ${fn.name}`, undefined, {
              effectType: options.effect.type,
            });
            break;
          }
        default:
          break;
      }
    }
  }
  public rootSagaStarted?(options: { args: any[]; effectId: number; saga: Saga }): void {
    TelemetryService.trackTrace('SagaMonitor - Root Saga started');
  }
}

export const appInsightsSagaMonitor = new AppInsightsSagaMonitor();
