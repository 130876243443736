import * as React from 'react';
import { IFeaturedAreasProps } from './../Help.types';
import { Capability, SubCapability, EventName } from '../../../Shared/Types';
import { TelemetryService } from '../../../Shared/shared';
import { featuredAreasHeader } from '../Help.resources';
import { IQnaDocumentModel } from '../Models/IQnaDocumentModel';
import { PanelSectionLink } from '../../../Styles/Panel';

export const FeaturedAreas: React.FC<IFeaturedAreasProps> = (props: IFeaturedAreasProps) => {
  const { categories, faqs, onClicked } = props;
  const filterFaqByDisplayCategory = (
    resFaqs: IQnaDocumentModel[] | undefined,
    displaycategory: string
  ): IQnaDocumentModel[] | [] => {
    if (resFaqs && resFaqs.length > 0) {
      return resFaqs.filter((faq) =>
        faq.metadata.filter((metadata) => metadata.name === 'displaycategory')[0]?.value.includes(displaycategory)
      );
    }
    return [];
  };

  const onFeaturedItemClick = (item: string): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      //[ToDo:7/10/2020 - Saloni Daga] - change subcapability
      subCapability: SubCapability.Faq,
      eventName: EventName.PANEL_OPENED,
    });
    const filteredFaqs: IQnaDocumentModel[] = filterFaqByDisplayCategory(faqs, item);
    onClicked(item, filteredFaqs);
  };
  /**
   * Capitalizes the first letter of each word after hyphen and space. Removes hyphen after that (wordone word-two --> Wordone WordTwo)
   */
  const toTitleCaseWithHyphen = (value: string): string => {
    return value
      .replace(/(?: |\b)(\w)/g, (word: string) => {
        return word.toUpperCase();
      })
      .replace(/-/g, '');
  };
  return (
    <div>
      {categories.map((item: string, index: number) => (
        <PanelSectionLink key={index} onClick={(): void => onFeaturedItemClick(item)}>
          {item === featuredAreasHeader ? item : toTitleCaseWithHyphen(item)}
        </PanelSectionLink>
      ))}
    </div>
  );
};
