import {
  ActionTypes,
  IAddCardsAction,
  IMoveCardAction,
  IReceiveCatalogItemsAction,
  IRemoveCardAction,
  IRequestCatalogItemsAction,
  IResetAction,
  IToggleCatalogAction,
  IUpdateCardAction,
} from './Dashboard.action-types';
import { ICatalogItem } from './Shared/Models/ICatalogItem';
import { ICard } from './Shared/Models/ICard';

export function addCardsAction(cards: ICard[]): IAddCardsAction {
  return {
    type: ActionTypes.ADD_CARDS,
    cards: cards,
  };
}

export function moveCardAction(fromIndex: number, toIndex: number): IMoveCardAction {
  return {
    type: ActionTypes.MOVE_CARD,
    fromIndex: fromIndex,
    toIndex: toIndex,
  };
}

export function receiveCatalogItemsAction(items: ICatalogItem[]): IReceiveCatalogItemsAction {
  return {
    type: ActionTypes.RECEIVE_CATALOG_ITEMS,
    items: items,
  };
}

export function removeCardAction(id: string): IRemoveCardAction {
  return {
    type: ActionTypes.REMOVE_CARD,
    id: id,
  };
}

export function requestCatalogItemsAction(): IRequestCatalogItemsAction {
  return {
    type: ActionTypes.REQUEST_CATALOG_ITEMS,
  };
}

export function resetAction(): IResetAction {
  return {
    type: ActionTypes.RESET,
  };
}

export function toggleCatalog(isOpen: boolean): IToggleCatalogAction {
  return {
    type: ActionTypes.TOGGLE_CATALOG,
    isOpen,
  };
}

export function updateCardAction(card: ICard): IUpdateCardAction {
  return {
    type: ActionTypes.UPDATE_CARD,
    card: card,
  };
}
