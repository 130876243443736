import * as React from 'react';
import * as Styled from './OpenTicket.styled';
import { IOpenTicketsProps } from './OpenTicket.types';
import moment from 'moment';
import TextEllipsis from 'react-text-ellipsis';
import { Link as LinkTag } from '../../../../Styles/Link';
import { ticketStates } from '../../Header.Resources';

export const OpenTicket: React.FC<IOpenTicketsProps> = (props: IOpenTicketsProps) => {
  const { item } = props;
  const state = ticketStates.find((data) => data.value === item.state).label;
  const time = moment(item.time, 'YYYY-MM-DD hh:mm:ss').format('MMM DD, YYYY; h:mm:ssA');
  const link = __SERVICENOW__ + item.sys_id;
  return (
    <Styled.Container>
      <Styled.TextContainer>
        <Styled.Heading>
          <TextEllipsis lines={2}>
            {
              <LinkTag href={link} target="_blank">
                {item.short_description}
              </LinkTag>
            }
          </TextEllipsis>
        </Styled.Heading>
        <Styled.SubHeading>{item.incident_number}</Styled.SubHeading>
        <Styled.SubHeading>{time}</Styled.SubHeading>
        <Styled.SubHeading>{state}</Styled.SubHeading>
      </Styled.TextContainer>
    </Styled.Container>
  );
};
