/* eslint-disable @typescript-eslint/camelcase */
export enum SubCapability {
  NotApplicable = 'NA',
  NewsFeed = 'MyHub.Feed.News',
  MicrosoftLife = 'MyHub.Feed.MicrosoftLife',
  Events = 'MyHub.Feed.Events',
  Events_AddToCalender = 'MyHub.Feed.Events.AddToCalender',
  ReportAProblem_SubmitTab = 'MyHub.ReportAProblem.SubmitTab',
  ReportAProblem_SubmitTicket = 'MyHub.ReportAProblem.SubmitTicket',
  ReportAProblem = 'MyHub.ReportAProblem',
  ReportAProblem_OpenIncidents = 'MyHub.ReportAProblem.OpenIncidents',
  Admin = 'MyHub.Admin',
  Faq = 'MyHub.Faq',
  Faq_List = 'MyHub.Faq.List',
  Faq_Details = 'MyHub.Faq.Details',
  Faq_MyHub_Link = 'Faq.MyHub Link',
  Faq_Privacy_Link = 'Faq.Privacy Link',
  Faq_Featured_Areas = 'Faq.FeaturedAreas',
  Faq_Search = 'MyHub.Faq.Search',
  Faq_Search_Clear = 'MyHub.Faq.Search.Clear',
  Error = 'MyHub.Error',
  ErrorRefresh = 'MyHub.Error.Refresh',
  Explore = 'MyHub.Explore',
  Dashboard = 'MyHub.Dashboard',
  Dashboard_AddCard = 'MyHub.Dashboard.AddCard',
  Dashboard_RemoveCard = 'MyHub.Dashboard.RemoveCard',
  Dashboard_ResizeCard = 'MyHub.Dashboard.ResizeCard',
  Dashboard_Empty = 'MyHub.Dashboard.Empty',
  Dashboard_GetDefault = 'MyHub.Dashboard.Defaulted',
  Dashboard_Catalog = 'MyHub.Dashboard.Catalog',
  Dashboard_Catalog_Open = 'MyHub.Dashboard.Catalog.Open',
  Dashboard_Catalog_Close = 'MyHub.Dashboard.Catalog.Close',
  Dashboard_Catalog_Change = 'MyHub.Dashboard.Catalog.Change',
  Bookmark = 'MyHub.Bookmarks',
  Bookmark_Added = 'MyHub.Bookmarks.Add',
  Bookmark_Removed = 'MyHub.Bookmarks.Remove',
  NotAllowed = 'NotAllowed',
  SubmitNotification = 'MyHub.SubmitNotification',
  Events_ClickNotifications = 'MyHub.Feed.Events.NotificationsClicked',
  Settings_WhatsNew = 'Settings.WhatsNew',
  WhatsNew_NoContent_Roadmap = 'WhatsNewNoContent.Roadmap',
  MicroSentiment_HomePage = 'HomePage',
  MicroSentiment_CloseNotNow = 'CloseNotNow',
  MicroSentiment_RatingClicked = 'RatingClicked',
  MicroSentiment_RatingSubmitted = 'RatingSubmitted',
  MicroSentiment_ThanksScreen = 'ThanksScreen',
  MicroSentiment_ThanksScreen_CloseNotNow = 'ThanksScreen.CloseNotNow',
  MicroSentiment_ThanksScreen_TellUsMore = 'ThanksScreen.TellUsMore',
  Search = 'MyHub.Search',
  Building = 'MyHub.Building',
  OOF_OPENED = 'OOF.Opened',
  OOF_CLOSED = 'OOF.Closed',
  OOF_ADD_RECIPIENT = 'OOF.AddRecipient',
  OOF_REMOVE_RECIPIENT = 'OOF.RemoveRecipient',
  OOF_SUBMIT = 'OOF.Submit',
  OOF_UPDATE_AUTOMATIC_REPLY = 'OOF.UpdateAutomaticReply',
  OOF_UPDATE_AUTOMATIC_REPLY_CONTENT = 'OOF.UpdateAutomaticReplyContent',
  OOF_UPDATE_BLOCK_CALENDAR = 'OOF.UpdateBlockMyCalendar',
  OOF_UPDATE_CANCEL_CONNECTOR = 'OOF.UpdateCancelMyConnector',
  OOF_UPDATE_CANCEL_MEETINGS = 'OOF.UpdateCancelMyMeetings',
  OOF_UPDATE_EMAIL_CONTENT = 'OOF.UpdateEmailContent',
  OOF_UPDATE_NON_BLOCKING_EVENT = 'OOF.UpdateNonBlockingEvent',
  OOF_UPDATE_RESPOND_MEETINGS = 'OOF.UpdateRespondToOthersMeetings',
  OOF_UPDATE_SEND_EMAIL = 'OOF.UpdateSendEmail',
  OOF_STATUS_OPENED = 'OOF.Status.Opened',
  OOF_STATUS_CLOSED = 'OOF.Status.Closed',
  OOF_STATUS_DISMISS = 'OOF.Status.Dismiss',
  OOF_PARTNER_CALLBACK = 'OOF.Partner.CallBack',
}
