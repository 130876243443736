import { SurfaceColors } from '@cseo/styles';
import { ICenterContainerProps } from './CenterContainer.types';

export const CenterContainer = styled.div`
  align-items: center;
  background: ${(props: ICenterContainerProps): string => (props.innerPageUse ? 'none' : SurfaceColors.primary)};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  // Added padding for gutter on lowest resolution.
  padding: 16px;
  text-align: center;
  width: 100%;
`;
