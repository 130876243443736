import { INewsModel } from './Models/INewsModel';
import { IEventsModel } from './Models/IEventsModel';
import { IMicrosoftLifeModel } from './Models/IMicrosoftLifeModel';

export enum ActionTypes {
  RECEIVE_EVENTS = 'RECEIVE_EVENTS',
  REQUEST_NEWS_FEED_DATA = 'REQUEST_NEWS_FEED_DATA',
  RECEIVE_NEWS_FEED_DATA_FAILED = 'RECEIVE_NEWS_FEED_DATA_FAILED',
  RECEIVE_NEWS_FEED_DATA_SUCCEEDED = 'RECEIVE_NEWS_FEED_DATA_SUCCEEDED',
  REQUEST_EVENT_FEED_DATA = 'REQUEST_EVENT_FEED_DATA',
  RECEIVE_EVENT_FEED_DATA_FAILED = 'RECEIVE_EVENT_FEED_DATA_FAILED',
  RECEIVE_EVENT_FEED_DATA_SUCCEEDED = 'RECEIVE_EVENT_FEED_DATA_SUCCEEDED',
  RECEIVE_NEWS = 'RECEIVE_NEWS',
  REQUEST_MICROSOFT_LIFE = 'REQUEST_MICROSOFT_LIFE',
  RECEIVE_MICROSOFT_LIFE_DATA_FAILED = 'RECEIVE_MICROSOFT_LIFE_DATA_FAILED',
  RECEIVE_MICROSOFT_LIFE_DATA_SUCCEEDED = 'RECEIVE_MICROSOFT_LIFE_DATA_SUCCEEDED',
}

export interface IReceiveEventsAction {
  type: ActionTypes.RECEIVE_EVENTS;
  events: IEventsModel[];
}

export interface IReceiveNewsFeedDataFailedAction {
  type: ActionTypes.RECEIVE_NEWS_FEED_DATA_FAILED;
}

export interface IReceiveNewsFeedDataSucceededAction {
  type: ActionTypes.RECEIVE_NEWS_FEED_DATA_SUCCEEDED;
}

export interface IReceiveEventFeedDataFailedAction {
  type: ActionTypes.RECEIVE_EVENT_FEED_DATA_FAILED;
}

export interface IReceiveEventFeedDataSucceededAction {
  type: ActionTypes.RECEIVE_EVENT_FEED_DATA_SUCCEEDED;
}

export interface IRequestMicrosoftLifeAction {
  type: ActionTypes.REQUEST_MICROSOFT_LIFE;
}
export interface IReceiveMicrosoftLifeDataSucceededAction {
  type: ActionTypes.RECEIVE_MICROSOFT_LIFE_DATA_SUCCEEDED;
  microsoftLife: IMicrosoftLifeModel[];
}

export interface IReceiveMicrosoftLifeDataFailedAction {
  type: ActionTypes.RECEIVE_MICROSOFT_LIFE_DATA_FAILED;
}

export interface IReceiveNewsAction {
  type: ActionTypes.RECEIVE_NEWS;
  news: INewsModel[];
}

export interface IRequestEventFeedDataAction {
  type: ActionTypes.REQUEST_EVENT_FEED_DATA;
}

export interface IRequestNewsFeedDataAction {
  type: ActionTypes.REQUEST_NEWS_FEED_DATA;
}

export type IFeedAction =
  | IReceiveEventsAction
  | IReceiveNewsAction
  | IReceiveNewsFeedDataFailedAction
  | IReceiveNewsFeedDataSucceededAction
  | IReceiveFeedDataFailedAction
  | IReceiveFeedDataSucceededAction
  | IRequestNewsFeedDataAction
  | IRequestMicrosoftLifeAction
  | IReceiveMicrosoftLifeDataSucceededAction
  | IReceiveMicrosoftLifeDataFailedAction
  | IRequestEventFeedDataAction
  | IReceiveEventFeedDataFailedAction
  | IReceiveEventFeedDataSucceededAction;
