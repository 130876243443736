import {
  ActionTypes,
  IUserAction,
  userGlobalConfigPayload,
  userGlobalConfigDetailsPayload,
  User,
} from './User.action-types';
import {
  IUserState,
  usageUserIdKey,
  sessionIDKey,
  lastActiveTimeKey,
  userGlobalConfig,
  userGlobalConfigDetails,
  userBuildingLocationKey,
} from '../Models/User.types';
import { v4 as guid } from 'uuid';

export const initialUserState: IUserState = {
  [usageUserIdKey]: __UNINITIALIZED__,
  [sessionIDKey]: __UNINITIALIZED__,
  [lastActiveTimeKey]: new Date('0001-01-01T00:00:00Z'),
  [userGlobalConfigDetails]: {} as userGlobalConfigDetailsPayload,
  [userGlobalConfig]: {} as userGlobalConfigPayload,
  user: {} as User,
  [userBuildingLocationKey]: {},
};

export function userReducer(prev: IUserState = initialUserState, action: IUserAction): IUserState {
  switch (action.type) {
    case ActionTypes.RECEIVE_USAGE_USER_ID: {
      return {
        ...prev,
        [usageUserIdKey]: action.UsageUserId,
      };
    }
    case ActionTypes.START_NEW_SESSION: {
      return {
        ...prev,
        [sessionIDKey]: guid(),
      };
    }
    case ActionTypes.SET_LAST_ACTIVE_TIME: {
      const current = new Date();
      return {
        ...prev,
        [lastActiveTimeKey]: current,
      };
    }
    case ActionTypes.RECEIVE_USAGE_USER_ID_FAILED: {
      return {
        ...prev,
        [usageUserIdKey]: 'Error',
      };
    }
    case ActionTypes.FETCH_USER_GLOBAL_CONFIG_WEB_SUCCESS: {
      return {
        ...prev,
        [userGlobalConfig]: action.payload,
        [userGlobalConfigDetails]: action.userDetails,
      };
    }
    case ActionTypes.FETCH_USER_DETAILS_SUCCESS: {
      return {
        ...prev,
        user: action.payload,
      };
    }
    case ActionTypes.FETCH_USER_BUILDING_LOCATION_SUCCESS: {
      return {
        ...prev,
        [userBuildingLocationKey]: action.payload,
      };
    }
    case ActionTypes.FETCH_USER_BUILDING_LOCATION_FAILED: {
      return {
        ...prev,
        [userBuildingLocationKey]: action.payload,
      };
    }
    default:
      return prev || initialUserState;
  }
}
