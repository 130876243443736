import { FontSizes, NeutralColors, TextColors } from '@cseo/styles';
import { FontWeights, IconButton } from 'office-ui-fabric-react';
// import { minWidth } from '../../../../Styles/Media';

interface ICarouselScrollProps {
  translateX: number;
}

export const CarouselScroll = styled.div<ICarouselScrollProps>`
  flex: 1;
  display: flex;
  transform: ${function (props): string {
    return `translate(${props.translateX}%, 0px)`;
  }};
  transition: transform 0.2s ease-in-out 0s;
`;

export const CarouselScrollContainer = styled.div`
  overflow: hidden;
`;
export const LeftButton = styled(IconButton)`
  color: ${NeutralColors.black};
  height: 32px;
  width: 20px;
  z-index: 1;
  margin-left: 24px;
  &:hover {
    color: ${NeutralColors.black};
  }
`;

export const Header = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex: 1;
  color: ${TextColors.lightPrimary};
  font-size: ${FontSizes.size18};
  font-weight: ${FontWeights.semibold};
  line-height: 24px;
`;

export const RightButton = styled(IconButton)`
    color: ${NeutralColors.black};
    height: 32px;
    width: 20px;
    margin-left: 20px;
    z-index: 1
    &:hover {
        color: ${NeutralColors.black};
    }
`;

export const CarouselControls = styled.div`
  margin-right: 1%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 19px;
  flex: 1;
  align-items: center;
`;

export const CarouselHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 44px;
  margin-left: 7px;
`;
