import { all, put, takeLatest, getContext, call, SimpleEffect, select } from 'redux-saga/effects';
import { HttpClient } from '@employee-experience/core';
import { ApiHelper } from '../../../../Shared/Helpers/ApiHelper';
import { ActionTypes } from './WhatsNew.action-types';
import { WhatsNewRelease } from './WhatsNew.types';
import { setWhatsNewDataAction, setWhatsNewDataAvailableAction } from './WhatsNew.actions';
import { TelemetryService } from '../../../../Shared/shared';
import { whatsNewReleasesSelector } from './WhatsNew.selectors';
import { isEqual, isEmpty } from 'lodash-es';

export function* fetchWhatsNewData(): IterableIterator<SimpleEffect<{}, {}>> {
  const httpClient: HttpClient = yield getContext('httpClient');
  try {
    const ring = __ENVIRONMENT__ === 'production' ? 'prod' : 'nonprod';
    const { data: whatsNewData }: { data: WhatsNewRelease[] } = yield call([httpClient, httpClient.request], {
      url: `${__API__.myMsftApim.resourceUrl}/whatsnew/whatsnew/web/${ring}`,
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    });
    const existingWhatsNewData = yield select(whatsNewReleasesSelector);
    if (!isEqual(whatsNewData, existingWhatsNewData)) {
      if (!isEmpty(whatsNewData)) {
        yield put(setWhatsNewDataAvailableAction(true));
      }
      yield put(setWhatsNewDataAction(whatsNewData));
    }
  } catch (error) {
    TelemetryService.trackException(error);
  }
}

export function* whatsNewSagas(): IterableIterator<{}> {
  yield all([takeLatest(ActionTypes.FETCH_WHATS_NEW_DATA, fetchWhatsNewData)]);
}
