import { IDropdownOption } from 'office-ui-fabric-react';
import { LoadingStates } from '../../Shared/Models/LoadingStates';

export const confirmedUserSGsKey = 'confirmedUserSGs';
export const confirmedUserSGsErrorKey = 'confirmedUserSGsError';
export const submitNotificationStatusKey = 'submitNotificationStatus';
export const submitNotificationErrorKey = 'submitNotificationError';
export const searchSGorUsersKey = 'searchSGorUsers';
export const searchSGorUsersLoadingKey = 'searchSGorUsersLoading';
export const referenceDataLoadingKey = 'referenceDataLoading';

export interface IAdminState {
  [confirmedUserSGsKey]: string[] | undefined;
  [confirmedUserSGsErrorKey]: {} | undefined;
  [submitNotificationStatusKey]: LoadingStates;
  [submitNotificationErrorKey]: {} | undefined;
  [searchSGorUsersKey]: {} | undefined;
  [searchSGorUsersLoadingKey]: {} | undefined;
  countryCode: IDropdownOption[];
  companyCode: IDropdownOption[];
}

export type NotificationPayload = {
  name: string;
  date: string;
  dueDate: string;
  expireDate: string;
  type: string;
  assignedPerson: string;
  assignedGroups: string;
  isNotifyToAllUsers: boolean;
  isNotifyToAllFTEUsers: boolean;
  isNotifyToAllManagerUsers: boolean;
  countryCode: string;
  companyCode: string;
  longDescription: string;
  description: string;
  isActionable: boolean;
  displaySnooze: boolean;
  ttl: number;
  cloudEventsVersion: string;
  sendOSNotification: boolean;
  notificationIcon: string;
  iconColor: string;
  iconBackgroundColor: string;
  buttons: ActionButton[];
};

export type ActionButton = {
  accessibilityText: string;
  buttonText: string;
  doStatusChange: boolean;
  isVisible: boolean;
  newStatus: string;
  route: string;
  url: string;
  actionType: string;
};

export type SgUserDataSearchModel = {
  users?: UsersDataSearchModel[];
  buildings?: [];
  groups?: SgDataSearchModel[];
};

export type UsersDataSearchModel = {
  displayName: string;
  jobTitle: string;
  officeLocation: string;
  alias: string;
  mail: string;
  workPhone: string;
  mobilePhone: string;
  officeLocLat: string;
  officeLocLong: string;
  givenName: string;
  surname: string;
  initials: string;
};

export type ISgUsersModel = {
  alias: string;
  mail: string;
  imageInitials: string;
  imageUrl?: string;
  isValid: boolean;
  optionalText?: string;
  secondaryText: string;
  tertiaryText?: string;
  text: string;
};

export type SgDataSearchModel = {
  displayName: string;
  mailEnabled: boolean;
  mail: string;
  id: string;
  description: string;
};

export interface IReferenceData {
  type: string;
  code: string;
  description: string;
  languageCode: string;
  etag: string;
}
