import {
  ActionTypes,
  IAddBookmarkAction,
  IAddBookmarkFailedAction,
  IAddBookmarkSucceededAction,
  IRemoveBookmarkAction,
  IRemoveBookmarkSucceededAction,
  IRemoveBookmarkFailedAction,
  IReceiveBookmarksDataFailedAction,
  IReceiveBookmarksDataSucceededAction,
  IRequestBookmarksDataAction,
} from './Bookmarks.action-types';
import { IBookmarksModel } from '../Models/IBookmarksModel';

export function addBookmarkAction(bookmark: IBookmarksModel): IAddBookmarkAction {
  return {
    type: ActionTypes.ADD_BOOKMARK,
    bookmark: bookmark,
  };
}

export function addBookmarkSuccessAction(): IAddBookmarkSucceededAction {
  return {
    type: ActionTypes.ADD_BOOKMARK_SUCCEEDED,
  };
}

export function addBookmarkFailedAction(): IAddBookmarkFailedAction {
  return {
    type: ActionTypes.ADD_BOOKMARK_FAILED,
  };
}

export function removeBookmarkAction(bookmarkId: string): IRemoveBookmarkAction {
  return {
    type: ActionTypes.REMOVE_BOOKMARK,
    bookmarkId: bookmarkId,
  };
}

export function removeBookmarkSuccessAction(): IRemoveBookmarkSucceededAction {
  return {
    type: ActionTypes.REMOVE_BOOKMARK_SUCCEEDED,
  };
}

export function removeBookmarkFailedAction(): IRemoveBookmarkFailedAction {
  return {
    type: ActionTypes.REMOVE_BOOKMARK_FAILED,
  };
}

export function receiveBookmarksDataFailedAction(): IReceiveBookmarksDataFailedAction {
  return {
    type: ActionTypes.RECEIVE_BOOKMARKS_DATA_FAILED,
  };
}

export function receiveBookmarksDataSucceededAction(
  bookmarks: IBookmarksModel[]
): IReceiveBookmarksDataSucceededAction {
  return {
    type: ActionTypes.RECEIVE_BOOKMARKS_DATA_SUCCEEDED,
    bookmarks,
  };
}

export function requestBookmarksDataAction(): IRequestBookmarksDataAction {
  return {
    type: ActionTypes.REQUEST_BOOKMARKS_DATA,
  };
}
