import { FontSizes } from '@cseo/styles';
import { FontWeights } from 'office-ui-fabric-react';

export const PanelContent = styled.div`
  text-align: center;
`;

export const PanelContentTitle = styled.p`
  font-size: ${FontSizes.size34};
  font-weight: ${FontWeights.bold};
  margin-top: 14px;
`;

export const TickImage = styled.img`
  margin-top: 76px;
`;

export const RatingMessage = styled.p`
  font-size: ${FontSizes.size26};
  font-weight: ${FontWeights.semibold};
  margin-top: 173px;
`;

export const SuccessImage = styled.img`
  margin-top: 40px;
`;

export const RatingStyles = {
  rootIsLarge: {
    height: '100%',
  },
  ratingStarIsLarge: {
    height: 46,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
};
