import * as React from 'react';
import { CoherencePanel } from '@cseo/controls';
import { SearchBox, Announced } from 'office-ui-fabric-react';
import { faqSelector } from '../Help.selector';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import * as Styled from '../Help.styled';
import { FaqDetail } from './FaqDetail';
import { ZeroSearchResults } from './ZeroSearchResults';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { TelemetryService } from '../../../Shared/shared';
import { Capability, SubCapability, EventName } from '../../../Shared/Types';
import TextEllipsis from 'react-text-ellipsis';
import { searchResults, featuredAreasHeader } from '../Help.resources';
import { ReportProblem } from '../../Header/ReportProblem/ReportProblem';
import { IQnaDocumentModel } from '../Models/IQnaDocumentModel';
import ReactMarkdown = require('react-markdown');
import { BackButtonText } from '../../../Shared/Resources/Panel.resources';
import { IPanelProps } from '../../../Shared/Types/interfaces/IPanelProps';
import {
  PanelHeader,
  PanelSection,
  PanelSectionHeader,
  PanelSectionLink,
  PanelSectionSeparator,
  PanelSectionSubLink,
} from '../../../Styles/Panel';

interface IFaqListProps extends IPanelProps {
  category: string;
  filteredFaq: IQnaDocumentModel[];
}

export function FaqList(props: IFaqListProps): React.ReactElement {
  const { useSelector } = React.useContext(ReduxContext);
  const helpSelector = useSelector(faqSelector);
  const [isPanelOpen, setPanelState] = React.useState(false);
  const [question, setQuestion] = React.useState('');
  const [answer, setAnswer] = React.useState('');
  const faqs = React.useState(helpSelector.faqData);
  const [headerText, setHeaderText] = React.useState(props.category);
  const [searchTerm, setSearchTerm] = React.useState('');
  const { telemetryClient } = React.useContext(ComponentContext);
  const [filteredFaq, setfilteredFaq] = React.useState(props.filteredFaq);
  const [isReportPanelOpen, setReportPanelState] = React.useState(false);
  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const resetState = (): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Faq_Search_Clear,
      eventName: EventName.BUTTON_CLICKED,
    });
    setHeaderText(props.category);
    setfilteredFaq(props.filteredFaq);
  };
  const [message, setMessage] = React.useState('');
  React.useEffect(() => {
    setHeaderText(props.category);
    setfilteredFaq(props.filteredFaq);
  }, [props.category, props.filteredFaq]);

  const toggleDetailPanel = (): void => {
    if (!isPanelOpen)
      TelemetryService.trackEvent({
        capability: Capability.MyHub,
        subCapability: SubCapability.Faq_Details,
        eventName: EventName.PANEL_OPENED,
      });
    setPanelState(!isPanelOpen);
  };

  const openDetail = (index: number): void => {
    setQuestion(filteredFaq[index].questions[0]);
    setAnswer(filteredFaq[index].answer);
    TelemetryService.trackEvent(
      {
        capability: Capability.MyHub,
        subCapability: SubCapability.Faq_List,
        eventName: EventName.LINK_CLICKED,
      },
      { FaqQuestion: filteredFaq[index].questions[0], index: index }
    );
    toggleDetailPanel();
  };
  // Handles light dismiss.
  const onPanelClosed = (ev?: React.SyntheticEvent<HTMLElement, Event> | undefined): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Faq_List,
      eventName: EventName.PANEL_CLOSED,
    });
    if (ev) {
      props.backButton?.onClick();
    }
  };

  const filterResults = (searchVal: string | undefined): void => {
    TelemetryService.trackEvent(
      {
        capability: Capability.MyHub,
        subCapability: SubCapability.Faq_Search,
        eventName: EventName.BUTTON_CLICKED,
      },
      { searchVal }
    );
    if (searchVal) {
      setSearchTerm(searchVal);
      setHeaderText(searchResults);
      const results = [];
      for (let i = 0; i < faqs[0].length; i++) {
        if (faqs[0][i]['questions'][0]?.toLowerCase().search(searchVal?.toLowerCase()) >= 0) {
          results.push(faqs[0][i]);
        }
      }
      setfilteredFaq(results);
      if (results.length == 0) {
        setMessage('No results found for: ' + searchVal);
        TelemetryService.trackTrace('FAQ Search: No results found', 4, { searchVal });
      } else {
        setMessage('Showing ' + results.length + ' results for your search : ' + searchVal);
      }
    } else if (searchVal == '') {
      setMessage('No search results found');
      resetState();
    }
  };

  /**
   * Capitalizes the first letter of each word after hyphen and space. Removes hyphen after that (wordone word-two --> Wordone WordTwo)
   */
  const toTitleCaseWithHyphen = (value: string): string => {
    return value
      .replace(/(?: |\b)(\w)/g, (word: string) => {
        return word.toUpperCase();
      })
      .replace(/-/g, '');
  };

  const toggleReportProblemPanel = (): void => {
    setReportPanelState(!isReportPanelOpen);
  };

  return (
    <div>
      <CoherencePanel
        isOpen={props.isOpen}
        backButton={props.backButton}
        isLightDismiss={true}
        telemetryHook={telemetryClient}
        isBlocking={false}
        titleText={'Help'}
        onDismiss={onPanelClosed}
      >
        <PanelHeader>Frequently asked questions</PanelHeader>
        <SearchBox
          placeholder="Search"
          onEscape={resetState}
          onClear={resetState}
          onChange={(_, newValue: string | undefined): void => filterResults(newValue)}
          onSearch={(newValue): void => {
            filterResults(newValue);
          }}
        />
        {message && <Announced message={message} aria-live="assertive" />}
        {filteredFaq.length == 0 ? (
          <Styled.ZeroResultsFound>No results found for: {searchTerm}</Styled.ZeroResultsFound>
        ) : null}
        <PanelSection>
          <PanelSectionHeader>
            {headerText === featuredAreasHeader ? headerText : toTitleCaseWithHyphen(headerText)}
          </PanelSectionHeader>
          {filteredFaq.length == 0 ? (
            <ZeroSearchResults />
          ) : (
            filteredFaq.map((item: IQnaDocumentModel, index: number) => {
              return headerText != searchResults ? (
                <PanelSectionLink key={index} onClick={(): void => openDetail(index)}>
                  {item.questions[0]}
                </PanelSectionLink>
              ) : (
                <div key={index}>
                  <Styled.FaqQuestion>{item.questions[0]}</Styled.FaqQuestion>
                  <Styled.FaqAnswer>
                    <TextEllipsis lines={6}>
                      <ReactMarkdown source={item.answer} escapeHtml={false} />
                    </TextEllipsis>
                  </Styled.FaqAnswer>
                  <PanelSectionSubLink key={index} onClick={(): void => openDetail(index)}>
                    Learn More
                  </PanelSectionSubLink>
                </div>
              );
            })
          )}
        </PanelSection>
        {filteredFaq.length == 0 || headerText == searchResults ? (
          <>
            <PanelSectionSeparator />
            <PanelSection>
              <PanelSectionHeader>Need More Help?</PanelSectionHeader>
              <PanelSectionLink onClick={(): void => toggleReportProblemPanel()}>Report a problem</PanelSectionLink>
            </PanelSection>
          </>
        ) : null}
      </CoherencePanel>
      {isPanelOpen ? (
        <FaqDetail
          isOpen={isPanelOpen}
          question={question}
          answer={answer}
          backButton={{ ariaLabel: BackButtonText, onClick: toggleDetailPanel }}
        ></FaqDetail>
      ) : null}
      {isReportPanelOpen ? (
        <ReportProblem
          isOpen={isReportPanelOpen}
          backButton={{ ariaLabel: BackButtonText, onClick: toggleReportProblemPanel }}
        ></ReportProblem>
      ) : null}
    </div>
  );
}
