export enum ActionTypes {
  GET_TRENDING_SEARCH = 'GET_TRENDING_SEARCH',
  GET_TRENDING_SEARCH_SUCCESS = 'GET_TRENDING_SEARCH_SUCCESS',
  GET_TRENDING_SEARCH_FAILED = 'GET_TRENDING_SEARCH_FAILED',

  GET_FREQUENTED_CONTACTS = 'GET_FREQUENTED_CONTACTS',
  GET_FREQUENTED_CONTACTS_SUCCESS = 'GET_FREQUENTED_CONTACTS_SUCCESS',
  GET_FREQUENTED_CONTACTS_FAILED = 'GET_FREQUENTED_CONTACTS_FAILED',
}

export interface IGetTrendingSearch {
  type: ActionTypes.GET_TRENDING_SEARCH;
}

export interface IGetTrendingSearchSuccess {
  type: ActionTypes.GET_TRENDING_SEARCH_SUCCESS;
  data: [];
}

export interface IGetTrendingSearchFailed {
  type: ActionTypes.GET_TRENDING_SEARCH_FAILED;
  error: string;
}

export interface IGetFrequentedContacts {
  type: ActionTypes.GET_FREQUENTED_CONTACTS;
}

export interface IGetFrequentedContactsSuccess {
  type: ActionTypes.GET_FREQUENTED_CONTACTS_SUCCESS;
  data: [];
}

export interface IGetFrequentedContactsFailed {
  type: ActionTypes.GET_FREQUENTED_CONTACTS_FAILED;
  error: string;
}

export type IDefaultSearchAction =
  | IGetTrendingSearch
  | IGetTrendingSearchSuccess
  | IGetTrendingSearchFailed
  | IGetFrequentedContacts
  | IGetFrequentedContactsSuccess
  | IGetFrequentedContactsFailed;
