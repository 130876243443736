import * as React from 'react';
import { CoherencePanel, CoherencePanelSize } from '@cseo/controls';
import { ICatalogItem } from '../Shared/Models/ICatalogItem';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { toggleCatalog, addCardsAction } from '../Dashboard.actions';
import { CatalogItem } from './CatalogItem';
import * as Styled from './Catalog.styled';
import { catalogItemsSelector, cardsSelector } from '../Dashboard.selectors';
import { useContext, useState } from 'react';
import { ICard } from '../Shared/Models/ICard';
import * as CardService from '../Shared/Services/CardService';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { TelemetryService } from '../../../Shared/shared';
import { Capability, SubCapability, EventName } from '../../../Shared/Types';

export function Catalog(): React.ReactElement {
  const { dispatch, useSelector } = useContext(ReduxContext);
  const catalogItems: ICatalogItem[] = useSelector(catalogItemsSelector);
  const cards: ICard[] = useSelector(cardsSelector);
  const [selectedItemIds, setSelectedItemIds] = useState();
  const { telemetryClient } = React.useContext(ComponentContext);

  // eslint-disable-next-line myhub-web-lint/check-usage-events
  function catalogItemClicked(itemId: string, isSelected?: boolean): void {
    if (isSelected) {
      setSelectedItemIds((prev: string[]) => (prev ? prev.concat(itemId) : [itemId]));
    } else {
      setSelectedItemIds((prev: string[]) => prev.filter((prevItemId) => prevItemId !== itemId));
    }
  }

  function addCardsButtonClicked(): void {
    TelemetryService.trackEvent(
      {
        capability: Capability.MyHub,
        subCapability: SubCapability.Dashboard_AddCard,
        eventName: EventName.BUTTON_CLICKED,
      },
      { selectedItemIds }
    );
    dispatch(addCardsAction(CardService.getNewCards(selectedItemIds)));
    dispatch(toggleCatalog(false));
  }

  // Handles light dismiss.
  const onPanelClosed = (ev?: React.SyntheticEvent<HTMLElement, Event> | undefined): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Dashboard_Catalog,
      eventName: EventName.PANEL_CLOSED,
    });
    // In the case that the action button is clicked, the event comes as null. So we don't set the state here as we handle it in the OnClick handler instead.
    if (ev) {
      dispatch(toggleCatalog(false));
    }
  };

  return (
    <CoherencePanel
      titleText={'Catalogue'}
      panelSize={CoherencePanelSize.medium}
      isOpen
      isLightDismiss
      onDismiss={onPanelClosed}
      hasCloseButton
      isFooterAtBottom
      telemetryHook={telemetryClient}
      panelBodyResponsiveMargin={false}
      onRenderFooter={(): JSX.Element => (
        <Styled.AddCardsButton
          ariaLabel="Add Cards"
          disabled={selectedItemIds === undefined || selectedItemIds.length === 0}
          onClick={addCardsButtonClicked}
        >
          Add Cards
        </Styled.AddCardsButton>
      )}
      styles={Styled.componentStyles}
      closeButtonAriaLabel="Close"
    >
      {catalogItems.length
        ? catalogItems
            .filter((item) => !cards.map((card) => card.id).includes(item.id))
            .map((item) => <CatalogItem key={item.id} item={item} onClicked={catalogItemClicked} />)
        : null}
    </CoherencePanel>
  );
}
