import { createSelector } from 'reselect';
import { SearchReducerName } from './Search.resources';
import { initialSearchState } from './Search.reducer';
import { ISearchState } from './Search.types';
import { IMyHubAppState } from '../../../App.types';

export const searchSelector = createSelector(
  (state: IMyHubAppState) => state[SearchReducerName] ?? initialSearchState,
  (searchState: ISearchState) => searchState.SearchResults
);

export const searchLoadingStatusSelector = createSelector(
  (state: IMyHubAppState) => state[SearchReducerName] ?? initialSearchState,
  (searchState: ISearchState) => searchState.SearchLoadingStatus
);
