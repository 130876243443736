import * as React from 'react';
import { CoherencePanel, CoherencePanelSize } from '@cseo/controls';
import { IMapExploreBuildingsModel } from '../MapView/MapView.types';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { useContext } from 'react';
import { LoadingContent } from './LoadingContent';
import { BuildingDetails } from './BuildingDetails';
import { buildingDetailSelector, buildingDetailStatusSelector } from '../MapView/MapView.selectors';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { LoadingStates } from '../../../Shared/Models/LoadingStates';
import { ErrorBar } from './ErrorBar';
import { coherencePanelStyles } from './BuildingPanel.styled';

type Props = {
  selectedBuilding: IMapExploreBuildingsModel | undefined;
  onOpen: () => void;
  onDismiss: () => void;
};

export function BuildingPanel(props: Props): React.ReactElement {
  const { useSelector } = React.useContext(ReduxContext);
  const { selectedBuilding, onOpen, onDismiss } = props;
  const { telemetryClient } = useContext(ComponentContext);
  const buildingDetails = useSelector(buildingDetailSelector);
  const requestStatus = useSelector(buildingDetailStatusSelector);

  const getContent = (): React.ReactElement => {
    let render;
    switch (requestStatus) {
      case LoadingStates.STARTED:
        render = <LoadingContent />;
        break;
      case LoadingStates.FAILED:
        render = <ErrorBar onPress={onDismiss} />;
        break;
      case LoadingStates.SUCCEEDED:
        render = <BuildingDetails details={buildingDetails} />;
        break;
      default:
        render = null;
    }
    return render;
  };
  return (
    <CoherencePanel
      isOpen={selectedBuilding !== undefined}
      telemetryHook={telemetryClient}
      onOpen={onOpen}
      onDismiss={onDismiss}
      panelSize={CoherencePanelSize.medium}
      styles={coherencePanelStyles}
    >
      {getContent()}
    </CoherencePanel>
  );
}
