/* eslint-disable myhub-web-lint/check-usage-events */
import * as React from 'react';
import {
  ColorPicker,
  ChoiceGroup,
  Dropdown,
  IChoiceGroupOption,
  IColor,
  IDropdownOption,
  Label,
  Link,
  Slider,
  TextField,
} from 'office-ui-fabric-react';
import { IBasePickerSuggestionsProps, NormalPeoplePicker } from 'office-ui-fabric-react/lib/Pickers';
import { Page, PageHeadingContainer, PageHeading } from '../../../Styles/Page';
import * as Styled from './SubmitNotifications.styled';
import { useConstCallback } from '@uifabric/react-hooks';
import { useContext } from 'react';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import {
  submitNotificationStatusSelector,
  submitNotificationErrorSelector,
  fetchSgUserSearchSelector,
  fetchSgUserLoadingSelector,
  fetchCountryCodeDataSelector,
  fetchCompanyCodeDataSelector,
} from '../Admin.selectors';
import { LoadingStates } from '../../../Shared/Models/LoadingStates';
import {
  submitNotificationAction,
  fetchSgUsersSearchResults,
  resetSubmitNotificationStatus,
  fetchCountryCodeData,
  fetchCompanyCodeData,
} from '../Admin.actions';
import { ActionButton, NotificationPayload, ISgUsersModel } from '../Admin.types';
import moment from 'moment';
import { mobileScreens } from '../Admin.resources';
import { map, startCase } from 'lodash-es';
import { TelemetryService } from '../../../Shared/shared';
import { Capability, SubCapability, EventName } from '../../../Shared/Types';
import { dropdownStyles } from '../../../Layout/Header/ReportProblem/SubmitTicket/SubmitTicket.styled';

// Environment choices
const nonProdKey = 'NonProd';
const prodKey = 'Prod';
const ringValues = {
  [nonProdKey]: 'ring1',
  [prodKey]: 'ring4',
};

const environment: { [key: string]: string } = {
  development: nonProdKey,
  uat: nonProdKey,
  production: prodKey,
};

const environmentOptions: IChoiceGroupOption[] = [
  {
    key: nonProdKey,
    text: 'Non-Prod (Rings 0 and 1)',
    styles: { root: { paddingRight: 30 } },
  },
  { key: prodKey, text: 'Production (Rings 2, 3 and 4)' },
];

// Audience choices
const sgListKey = 'sgList';
const specificAliasKey = 'alias';
const allUsers = 'allUsers';
const allFTEs = 'allFTEs';
const allManagers = 'allManagers';
const audienceOptions: IChoiceGroupOption[] = [
  { key: specificAliasKey, text: 'To a  alias(es) (for testing)' },
  { key: sgListKey, text: 'To a specific security group(s)' },
  { key: allUsers, text: 'To all users' },
  { key: allFTEs, text: 'To all FTEs (excluding vendors)' },
  { key: allManagers, text: 'To all managers' },
];

// Country or company code choices
const companyCode = 'companyCode';
const countryCode = 'countryCode';
const locationOptions: IChoiceGroupOption[] = [
  { key: countryCode, text: 'Send to specific country or countries' },
  { key: companyCode, text: 'Send to specific company code(s)' },
];

// Link choices
const noLinkKey = 'NoLink';
const appPageKey = 'AppPage';
const externalURLKey = 'ExternalURL';
const linkOptions: IChoiceGroupOption[] = [
  { key: noLinkKey, text: 'No button' },
  { key: externalURLKey, text: 'Open a URL on the phone browser' },
  { key: appPageKey, text: 'Open a specific page of the app' },
];

// OS notification choices
const sendOSNotification: string = 'sendOSNotification';
const notSendOSNotification: string = 'notSendOSNotification';
const sendOSNotificationsOptions: IChoiceGroupOption[] = [
  { key: sendOSNotification, text: 'Yes' },
  { key: notSendOSNotification, text: 'No' },
];
// In-app link types
const dropdownKey = 'Dropdown';
const freeformTextKey = 'FreeformText';
const inappLinkTypes: IChoiceGroupOption[] = [
  { key: dropdownKey, text: 'Select a page from a dropdown list' },
  { key: freeformTextKey, text: 'Enter destination route name manually' },
];

// In-ap link options
const inappLinkChoices: IDropdownOption[] = [];
map(mobileScreens, function (key: string, value: string) {
  inappLinkChoices.push({ key, text: value });
});

// IDs for accessibility linking
const titleID = 'notificationTitle';
const descriptionID = 'notificationDescription';
const targetSGAudienceID = 'sgAudience';
const targetAliasAudienceID = 'aliasAudience';
const targetCountryAudienceID = 'countryAudience';
const targetCompanyCodeAudienceID = 'companyCodeAudience';
const appPageID1 = 'notificationAppPage1';
const appPageID2 = 'notificationAppPage2';
const urlID1 = 'notificationUrl1';
const urlID2 = 'notificationUrl2';
const buttonTextID1 = 'buttonText1';
const buttonTextID2 = 'buttonText2';
const iconNameID = 'iconName';
const iconColorID = 'iconColor';
const iconBGColorID = 'iconBGColor';

// Consts for math, default values, and validation
const importantConst = 'Important';
const learnMoreConst = 'Learn More';
const secondsInADay = 86400;
const buttonTextCharacterLimit = 16;
const selectAll = {
  key: '-1',
  text: 'Select All',
};

const selectAllSupportedCountryCodes = [
  'CR',
  'FR',
  'IL',
  'SG',
  'CA',
  'DE',
  'IN',
  'US',
  'IE',
  'JP',
  'UK',
  'AT',
  'IT',
  'NL',
  'SE',
  'CH',
  'BE',
  'CY',
  'CZ',
  'DK',
  'FI',
  'GR',
  'IS',
  'LU',
  'NO',
  'AZ',
  'BH',
  'BY',
  'BG',
  'EE',
  'GE',
  'JO',
  'KZ',
  'KE',
  'KW',
  'LV',
  'LB',
  'LT',
  'MU',
  'OM',
  'PK',
  'QA',
  'RU',
  'SA',
  'TR',
  'AL',
  'DZ',
  'AO',
  'AM',
  'BA',
  'HR',
  'CM',
  'CI',
  'EG',
  'GH',
  'HU',
  'LY',
  'MA',
  'NA',
  'NG',
  'PL',
  'RO',
  'SN',
  'RS',
  'SK',
  'SI',
  'ZA',
  'TN',
  'UA',
  'AU',
  'BD',
  'BN',
  'KR',
  'ID',
  'MM',
  'MY',
  'NZ',
  'PH',
  'TH',
  'VN',
  'LK',
  'AR',
  'BO',
  'BR',
  'CL',
  'CO',
  'SV',
  'EC',
  'GT',
  'HN',
  'JM',
  'MX',
  'PA',
  'PR',
  'PY',
  'PE',
  'UY',
  'VE',
  'DO',
  'CN',
  'HK',
  'MO',
  'TW',
];

export function SubmitNotifications(): React.ReactElement {
  const { dispatch, useSelector } = useContext(ReduxContext);

  React.useEffect(() => {
    dispatch(fetchCountryCodeData());
    dispatch(fetchCompanyCodeData());
  }, [dispatch]);
  const submitNotificationStatus: LoadingStates = useSelector(submitNotificationStatusSelector);
  const searchLoadingStatus: LoadingStates = useSelector(fetchSgUserLoadingSelector);
  const fetchSgUserSearchResults = useSelector(fetchSgUserSearchSelector);
  const submitNotificationError: {} = useSelector(submitNotificationErrorSelector);
  const countryCodeData: IDropdownOption[] = useSelector(fetchCountryCodeDataSelector);
  const companyCodeData: IDropdownOption[] = useSelector(fetchCompanyCodeDataSelector);

  const [companyCodes, setCompanyCodes] = React.useState([selectAll, ...companyCodeData]);

  React.useEffect(() => {
    setCompanyCodes([selectAll, ...companyCodeData]);
  }, [companyCodeData]);

  const [countryCodes, setCountryCodes] = React.useState([selectAll, ...countryCodeData]);

  React.useEffect(() => {
    setCountryCodes([
      selectAll,
      ...countryCodeData.filter((item) => selectAllSupportedCountryCodes.indexOf(item.key as string) > -1),
    ]);
  }, [countryCodeData]);

  const [targetAliasValue, setTargetAliasValue] = React.useState<string>('');

  const [selectedUsers, setSelectedUsers] = React.useState<ISgUsersModel[]>();
  const [selectedSecurityGroups, setSelectedSecurityGroups] = React.useState<ISgUsersModel[]>();
  // Title
  const [title, setTitle] = React.useState<string>('');
  const onTitleChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setTitle(newValue || '');
    },
    []
  );
  // Description
  const [description, setDescription] = React.useState<string>('');
  const onDescriptionChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setDescription(newValue || '');
    },
    []
  );

  // Environment
  const [selectedEnvironmentKey, setSelectedEnvironmentKey] = React.useState<string>(environment[__ENVIRONMENT__]);
  const onChangeEnvironmentChoice = React.useCallback(
    (ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
      setSelectedEnvironmentKey(option.key);
    },
    []
  );

  // Target audience (SGs or specific alias) & disabling form components based on selection
  const [disableLocationChoice, setDisableLocationChoice] = React.useState<boolean>(true);
  const [selectedAudienceKey, setSelectedAudienceKey] = React.useState<string>(specificAliasKey);
  const onChangeAudienceChoice = React.useCallback(
    (ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
      setSelectedAudienceKey(option.key);
      /* Clear the Selected USers or Service Groups ff Target Audience is not Users or Service Groups */
      if (option.key !== specificAliasKey) {
        setSelectedUsers([]);
      }
      if (option.key !== sgListKey) {
        setSelectedSecurityGroups([]);
      }

      // disable choose location section of the form based on option selected
      if (option.key === specificAliasKey || option.key === sgListKey) {
        setDisableLocationChoice(true);
      } else {
        setDisableLocationChoice(false);
      }
    },
    []
  );

  const [selectedLocation, setSelectedLocation] = React.useState<string>(countryCode);
  const onChangeLocation = React.useCallback((ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
    setSelectedLocation(option.key);
    if (option.key === countryCode) {
      setCompanyCodes([]);
    } else {
      setSelectedCountries([]);
    }
  }, []);

  const filterSelectAll = (data) => {
    return data.filter((item) => item !== '-1');
  };

  const [selectedCountries, setSelectedCountries] = React.useState<string[]>([]);
  const onChangeCountryCodeDropdown = (ev: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    if (item) {
      if (item.key === '-1') {
        if (item.selected) {
          const countryCodes: string[] = countryCodeData
            .filter((item) => selectAllSupportedCountryCodes.indexOf(item.key as string) > -1)
            .map((item) => item.key as string);
          setSelectedCountries(['-1', ...countryCodes]);
        } else {
          setSelectedCountries([]);
        }
      } else {
        let countryCodes: string[] = [];
        if (item.selected) {
          countryCodes = [...selectedCountries, item.key as string];
        } else {
          countryCodes = selectedCountries.filter((key) => !(key === item.key || key === '-1'));
        }
        setSelectedCountries(countryCodes);
      }
    }
  };

  const [selectedCompanyCodes, setSelectedCompanyCodes] = React.useState<string[]>([]);
  const onChangeCompanyCodeDropdown = (ev: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    if (item) {
      if (item.key === '-1') {
        if (item.selected) {
          const companyCodes: string[] = companyCodeData.map((item) => item.key as string);
          setSelectedCompanyCodes(['-1', ...companyCodes]);
        } else {
          setSelectedCompanyCodes([]);
        }
      } else {
        let companyCodes: string[] = [];
        if (item.selected) {
          companyCodes = [...selectedCompanyCodes, item.key as string];
        } else {
          companyCodes = selectedCompanyCodes.filter((key) => !(key === item.key || key === '-1'));
        }
        setSelectedCompanyCodes(companyCodes);
      }
    }
  };

  // Internal or External URL (Button 1)
  const [selectedLink1Key, setselectedLink1Key] = React.useState<string>(noLinkKey);
  const onChangeLink1Choice = React.useCallback((ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
    setselectedLink1Key(option.key);
  }, []);
  const [externalURL1Value, setExternalLink1Value] = React.useState<string>('');
  const onExternalUrl1Change = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setExternalLink1Value(newValue || '');
    },
    []
  );

  // Internal URL key (Button 1)
  const [inAppLinkType1Key, setSelectedInAppLink1Type] = React.useState<string>(dropdownKey);
  const onChangeInAppLink1Type = React.useCallback(
    (ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
      setSelectedInAppLink1Type(option.key);
    },
    []
  );
  const [selectedLink1FromDropdown, setselectedLink1FromDropdown] = React.useState<string>('');
  const onChangeLink1FromDropdown = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    setselectedLink1FromDropdown(item.key);
  };
  const [appLinkFreeform1Value, setInAppLink1] = React.useState<string>('');
  const onAppLink1Change = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setInAppLink1(newValue || '');
    },
    []
  );

  // Button Text (Button 1)
  const [buttonText1Value, setbuttonText1Value] = React.useState<string>(learnMoreConst);
  const onButtonText1Change = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setbuttonText1Value(newValue || '');
    },
    []
  );

  // Internal or External URL (Button 2)
  const [selectedLink2Key, setselectedLink2Key] = React.useState<string>(noLinkKey);
  const onChangeLink2Choice = React.useCallback((ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
    setselectedLink2Key(option.key);
  }, []);
  const [externalURL2Value, setExternalLink2Value] = React.useState<string>('');
  const onExternalUrl2Change = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setExternalLink2Value(newValue || '');
    },
    []
  );

  // Send OS Notification
  const [osNotificationPreference, setOsNotificationPreference] = React.useState<string>(notSendOSNotification);
  const onChangeOsNotificationPreference = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, option: IChoiceGroupOption) => {
      setOsNotificationPreference(option.key);
    },
    []
  );
  // Internal URL key (Button 2)
  const [inAppLinkType2Key, setSelectedInAppLink2Type] = React.useState<string>(dropdownKey);
  const onChangeInAppLink2Type = React.useCallback(
    (ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
      setSelectedInAppLink2Type(option.key);
    },
    []
  );
  const [selectedLink2FromDropdown, setselectedLink2FromDropdown] = React.useState<string>('');
  const onChangeLink2FromDropdown = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    setselectedLink2FromDropdown(item.key);
  };
  const [appLinkFreeform2Value, setInAppLink2] = React.useState<string>('');
  const onAppLink2Change = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setInAppLink2(newValue || '');
    },
    []
  );

  // Button Text (Button 2)
  const [buttonText2Value, setbuttonText2Value] = React.useState<string>(learnMoreConst);
  const onButtonText2Change = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setbuttonText2Value(newValue || '');
    },
    []
  );

  // Icon Name
  const [iconName, setIconName] = React.useState<string>(importantConst);
  const onIconNameChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setIconName(newValue || importantConst); // Intentionally default to "important"
    },
    []
  );

  // Icon Color
  const [color, setColor] = React.useState('#005E50');
  const updateColor = useConstCallback((ev: {}, colorObj: IColor) => setColor(colorObj.str));

  // Icon Background Color
  const [bgColor, setBGColor] = React.useState('#E9F5EE');
  const updateBGColor = useConstCallback((ev: {}, colorObj: IColor) => setBGColor(colorObj.str));

  // Duration
  const [duration, setDuration] = React.useState(7);
  const updateDuration = useConstCallback((value: number) => setDuration(value));

  const validateInput = (): string[] => {
    const errorArray: string[] = [];
    // Basic properties validation
    if (!title) errorArray.push('Title must not be blank');
    if (!description) errorArray.push('Description must not be blank');
    if (selectedAudienceKey === sgListKey && !targetAliasValue)
      errorArray.push('Security Group audience is selected, so its value must not be blank');
    if (selectedAudienceKey === sgListKey && selectedSecurityGroups.length > 10)
      errorArray.push('Security Group audience is selected, maximum 10 security groups allow to send notification');
    if (selectedAudienceKey === specificAliasKey && !targetAliasValue)
      errorArray.push('Specific alias audience is selected, so its value must not be blank');
    if (
      (selectedAudienceKey === allUsers || selectedAudienceKey === allFTEs || selectedAudienceKey === allManagers) &&
      selectedLocation === countryCode &&
      filterSelectAll(selectedCountries).length === 0
    )
      errorArray.push('Send to specific country or counties is selected, please select at least one country.');
    if (
      (selectedAudienceKey === allUsers || selectedAudienceKey === allFTEs || selectedAudienceKey === allManagers) &&
      selectedLocation === companyCode &&
      filterSelectAll(selectedCompanyCodes).length === 0
    )
      errorArray.push('Send to specific company code is selected, please select at least one company code.');
    if (selectedAudienceKey === specificAliasKey && selectedUsers.length > 10)
      errorArray.push('Specific alias audience is selected,, maximum 10 aliases allow to send notification');
    // Button 1 validation
    if (selectedLink1Key === appPageKey) {
      if (inAppLinkType1Key === dropdownKey && !selectedLink1FromDropdown)
        errorArray.push('In-app is selected for button 1, please select a value from the dropdown');
      else if (inAppLinkType1Key === freeformTextKey && !appLinkFreeform1Value)
        errorArray.push('In-app is selected for button 1, please enter a route for the app');
    }
    if (selectedLink1Key === externalURLKey && !externalURL1Value)
      errorArray.push('Button 1 external URL is selected, so its value must not be blank');
    if (selectedLink1Key !== noLinkKey && !buttonText1Value) errorArray.push('Button 1 label text must not be blank');
    if (selectedLink1Key !== noLinkKey && buttonText1Value && buttonText1Value.length > buttonTextCharacterLimit)
      errorArray.push('Button 1 label text must not exceed ' + buttonTextCharacterLimit + ' characters');
    // Button 2 validation
    if (selectedLink2Key === appPageKey) {
      if (inAppLinkType2Key === dropdownKey && !selectedLink2FromDropdown)
        errorArray.push('In-app is selected for button 2, please select a value from the dropdown');
      else if (inAppLinkType2Key === freeformTextKey && !appLinkFreeform2Value)
        errorArray.push('In-app is selected for button 2, please enter a route for the app');
    }
    if (selectedLink2Key === externalURLKey && !externalURL2Value)
      errorArray.push('Button 2 external URL is selected, so its value must not be blank');
    if (selectedLink2Key !== noLinkKey && !buttonText2Value) errorArray.push('Button 2 label text must not be blank');
    if (selectedLink2Key !== noLinkKey && buttonText2Value && buttonText2Value.length > buttonTextCharacterLimit)
      errorArray.push('Button 2 label text must not exceed ' + buttonTextCharacterLimit + ' characters');
    // Icon validation
    if (!iconName)
      errorArray.push("Push notification icon name must not be blank (default is '" + importantConst + "')");
    return errorArray;
  };

  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const submitNotification = (): void => {
    const validationErrors = validateInput();
    if (validationErrors.length > 0) {
      alert('Please fix the following to submit a notification: \n\n' + validationErrors.join('\n'));
      return;
    }

    // Button data prepration based on what the user selected
    const actionButtons: ActionButton[] = [];
    // Button 1
    if (selectedLink1Key !== noLinkKey) {
      let tempAppLinkValue = '';
      if (selectedLink1Key === appPageKey) {
        if (inAppLinkType1Key === dropdownKey) tempAppLinkValue = '' + selectedLink1FromDropdown;
        else tempAppLinkValue = appLinkFreeform1Value;
      }
      const tempButton: ActionButton = {
        accessibilityText: 'Double tab to navigate',
        isVisible: true,
        buttonText: buttonText1Value,
        route: tempAppLinkValue,
        doStatusChange: false,
        newStatus: 'complete',
        actionType: '', // Not sure about all possible values besides "Required" which just adds some styling
        url: selectedLink1Key === externalURLKey ? externalURL1Value : '',
      };
      actionButtons.push(tempButton);
    }
    // Button 2
    if (selectedLink2Key !== noLinkKey) {
      let tempAppLinkValue = '';
      if (selectedLink2Key === appPageKey) {
        if (inAppLinkType2Key === dropdownKey) tempAppLinkValue = '' + selectedLink2FromDropdown;
        else tempAppLinkValue = appLinkFreeform2Value;
      }
      const tempButton: ActionButton = {
        accessibilityText: 'Double tab to navigate',
        isVisible: true,
        buttonText: buttonText2Value,
        route: tempAppLinkValue,
        doStatusChange: false,
        newStatus: 'complete',
        actionType: '', // Not sure about all possible values besides "Required" which just adds some styling
        url: selectedLink2Key === externalURLKey ? externalURL2Value : '',
      };
      actionButtons.push(tempButton);
    }

    // Convert the icon name to proper casing, and remove unncessary spaces added by the user or case conversion
    const tempIconName = startCase(iconName).replace(/ /g, '');
    const countryCodes = filterSelectAll(selectedCountries);
    const notificationData: NotificationPayload = {
      name: title,
      date: moment().format('YYYY/M/DD'),
      dueDate: '', // Probably not used
      expireDate: '', // Probably not used
      type: 'communication',
      assignedPerson: selectedAudienceKey === specificAliasKey ? targetAliasValue : '',
      assignedGroups: selectedAudienceKey === sgListKey ? targetAliasValue : '',
      isNotifyToAllUsers: selectedAudienceKey === allUsers ? true : false,
      isNotifyToAllFTEUsers: selectedAudienceKey === allFTEs ? true : false,
      isNotifyToAllManagerUsers: selectedAudienceKey === allManagers ? true : false,
      countryCode: filterSelectAll(selectedCountries).toString(),
      companyCode: filterSelectAll(selectedCompanyCodes).toString(),
      longDescription: '', // Not sure how this is different from 'description' aside from the order displayed
      description,
      isActionable: false, // If set to "true", this notification will show up on the "Feed" page
      displaySnooze: false,
      ttl: duration * secondsInADay,
      cloudEventsVersion: '1.0',
      sendOSNotification: osNotificationPreference === sendOSNotification,
      notificationIcon: tempIconName,
      iconColor: color,
      iconBackgroundColor: bgColor,
      buttons: actionButtons,
      ring: ringValues[selectedEnvironmentKey],
    };

    TelemetryService.trackEvent(
      {
        capability: Capability.MyHub,
        subCapability: SubCapability.SubmitNotification,
        eventName: EventName.NOTIFICATION_CREATED,
      },
      {
        notificationName: notificationData.name,
        notificationType: notificationData.type,
        notificationAssignedPerson: notificationData.assignedPerson,
        notificationAssignedGroups: notificationData.assignedGroups,
        notificationIconName: notificationData.notificationIcon,
        notificationAllUSFTEs: notificationData.isNotifyToAllFTEUsers,
        notificationAllUSUsers: notificationData.isNotifyToAllUsers,
        notificationAllUSManagers: notificationData.isNotifyToAllManagerUsers,
      }
    );

    const splitArray = (array: any[], chunkSize: number) => {
      const result: any[][] = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    };

    if (selectedCountries.length > 0) {
      if (
        selectedCountries.filter((x) => x !== '-1').length ===
        countryCodeData.filter((item) => selectAllSupportedCountryCodes.indexOf(item.key as string) > -1).length
      ) {
        notificationData.countryCode = 'All';
        // eslint-disable-next-line no-console
        console.log(notificationData);
        dispatch(submitNotificationAction(notificationData));
      } else {
        const chunkedItems = splitArray(selectedCountries, 10);
        chunkedItems.forEach((items) => {
          const newNotificationData = {
            ...notificationData,
            countryCode: items.join(','),
          };
          // eslint-disable-next-line no-console
          console.log(newNotificationData);
          dispatch(submitNotificationAction(newNotificationData));
        });
      }
    } else if (selectedCompanyCodes.length > 0) {
      if (selectedCompanyCodes.filter((x) => x !== '-1').length === companyCodeData.length) {
        notificationData.companyCode = 'All';
        // eslint-disable-next-line no-console
        console.log(notificationData);
        dispatch(submitNotificationAction(notificationData));
      } else {
        const chunkedItems = splitArray(selectedCompanyCodes, 10);
        chunkedItems.forEach((items) => {
          const newNotificationData = {
            ...notificationData,
            companyCode: items.join(','),
          };
          // eslint-disable-next-line no-console
          console.log(newNotificationData);
          dispatch(submitNotificationAction(newNotificationData));
        });
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(notificationData);
      dispatch(submitNotificationAction(notificationData));
    }
  };

  const fetchResults = React.useRef([]);
  const resultsLoadingState = React.useRef();

  React.useEffect(() => {
    resultsLoadingState.current = searchLoadingStatus;
    if (searchLoadingStatus === LoadingStates.SUCCEEDED) {
      fetchResults.current = fetchSgUserSearchResults;
    }
  });

  const renderSearchResults = (searchText: string): Promise<ISgUsersModel[] | Promise<ISgUsersModel[]>> => {
    if (searchText) {
      return new Promise((resolve, reject) => {
        dispatch(
          fetchSgUsersSearchResults({
            searchText,
            searchCategory: selectedAudienceKey === specificAliasKey ? 'users' : 'groups',
            resolve,
            reject,
          })
        );
      }).then(() => {
        return fetchResults.current;
      });
    }
    return [];
  };

  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Search Results',
    mostRecentlyUsedHeaderText: 'Suggested Contacts',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading',
    showRemoveButtons: true,
    suggestionsAvailableAlertText: 'People Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested contacts',
  };

  function handleOnChange(items: ISgUsersModel[]): void {
    setTargetAliasValue(items.map((item) => item.alias || item.mail).join(','));
    if (selectedAudienceKey === specificAliasKey) {
      setSelectedUsers(items);
    } else if (selectedAudienceKey === sgListKey) {
      setSelectedSecurityGroups(items);
    }
  }

  const renderPortalLink = (): React.ReactElement => {
    const link =
      selectedEnvironmentKey === nonProdKey ? 'https://aka.ms/myhubadmin-nonprod' : 'https://aka.ms/myhubadmin';
    return (
      <Link href={link} target="_blank">
        {`${selectedEnvironmentKey} Admin Portal`}
      </Link>
    );
  };

  const renderEnviromentAdminPortalDetails = (): React.ReactElement => {
    return (
      <>
        <Styled.ChildHalfContainer>
          <Label htmlFor={titleID} required={true}>
            {`Click the below link to send notifications to ${selectedEnvironmentKey} environment`}
          </Label>
        </Styled.ChildHalfContainer>
        <Styled.ChildHalfContainer>{renderPortalLink()}</Styled.ChildHalfContainer>
        <hr />
      </>
    );
  };

  const renderSubmitNotificationContent = (): React.ReactElement => {
    return (
      <>
        <Styled.ParentContainer>
          <Styled.ChildHalfContainer>
            <p>
              <b>Notification</b>
            </p>
          </Styled.ChildHalfContainer>
          <Styled.ChildHalfContainer>
            <Label htmlFor={titleID} required={true}>
              Notification Title
            </Label>
            <TextField id={titleID} onChange={onTitleChange} required={true} />
            <Label htmlFor={descriptionID} required={true}>
              Notification Description
            </Label>
            <TextField id={descriptionID} onChange={onDescriptionChange} required={true} />
          </Styled.ChildHalfContainer>
        </Styled.ParentContainer>
        <hr />
        <Styled.ParentContainer>
          <Styled.ChildHalfContainer>
            <ChoiceGroup
              selectedKey={selectedAudienceKey}
              options={audienceOptions}
              onChange={onChangeAudienceChoice}
              label="Choose Audience"
            />
            <p>
              <br />
              Note: These options are still limited by the &quot;environment&quot; selected above
            </p>
          </Styled.ChildHalfContainer>
          <Styled.ChildHalfContainer>
            <Label
              htmlFor={targetAliasAudienceID}
              disabled={selectedAudienceKey !== specificAliasKey}
              required={selectedAudienceKey === specificAliasKey}
            >
              Single target audience
            </Label>
            <NormalPeoplePicker
              onResolveSuggestions={renderSearchResults}
              pickerSuggestionsProps={suggestionProps}
              className={'ms-PeoplePicker ms-bgColor-white'}
              key={'users'}
              removeButtonAriaLabel={'Remove'}
              disabled={selectedAudienceKey !== specificAliasKey}
              onChange={handleOnChange}
              selectedItems={selectedUsers}
            />
            <Label
              htmlFor={targetSGAudienceID}
              disabled={selectedAudienceKey !== sgListKey}
              required={selectedAudienceKey === sgListKey}
            >
              Security Group Alias
            </Label>
            <NormalPeoplePicker
              onResolveSuggestions={renderSearchResults}
              pickerSuggestionsProps={suggestionProps}
              className={'ms-PeoplePicker ms-bgColor-white'}
              key={'groups'}
              removeButtonAriaLabel={'Remove'}
              disabled={selectedAudienceKey !== sgListKey}
              onChange={handleOnChange}
              selectedItems={selectedSecurityGroups}
            />
            <ChoiceGroup
              selectedKey={selectedLocation}
              options={locationOptions}
              onChange={onChangeLocation}
              disabled={disableLocationChoice}
              label="Choose location"
            />
            <Label
              htmlFor={targetCountryAudienceID}
              disabled={selectedLocation !== countryCode || disableLocationChoice}
            >
              Country
            </Label>
            <Dropdown
              placeholder="Select country"
              multiSelect
              styles={dropdownStyles}
              selectedKeys={selectedCountries}
              options={countryCodes}
              disabled={selectedLocation !== countryCode || disableLocationChoice}
              onChange={onChangeCountryCodeDropdown}
            />
            <Label
              htmlFor={targetCompanyCodeAudienceID}
              disabled={selectedLocation !== companyCode || disableLocationChoice}
            >
              Company Code
            </Label>
            <Dropdown
              placeholder="Select company code"
              multiSelect
              styles={dropdownStyles}
              selectedKeys={selectedCompanyCodes}
              options={companyCodes}
              disabled={selectedLocation !== companyCode || disableLocationChoice}
              onChange={onChangeCompanyCodeDropdown}
            />
          </Styled.ChildHalfContainer>
        </Styled.ParentContainer>
        <hr />
        <Styled.ParentContainer>
          <Styled.ChildHalfContainer>
            <ChoiceGroup
              selectedKey={selectedLink1Key}
              options={linkOptions}
              onChange={onChangeLink1Choice}
              label="Choose button 1 options"
            />
            <Styled.Indented>
              <ChoiceGroup
                disabled={selectedLink1Key !== appPageKey}
                selectedKey={inAppLinkType1Key}
                options={inappLinkTypes}
                onChange={onChangeInAppLink1Type}
                label="Choose internal destination"
              />
            </Styled.Indented>
          </Styled.ChildHalfContainer>
          <Styled.ChildHalfContainer>
            <Label
              htmlFor={buttonTextID1}
              disabled={selectedLink1Key === noLinkKey}
              required={selectedLink1Key !== noLinkKey}
            >
              Button Text ({buttonTextCharacterLimit} character limit)
            </Label>
            <TextField
              id={buttonTextID1}
              defaultValue={learnMoreConst}
              disabled={selectedLink1Key === noLinkKey}
              required={selectedLink1Key !== noLinkKey}
              onChange={onButtonText1Change}
            />
            <Label
              htmlFor={urlID1}
              disabled={selectedLink1Key !== externalURLKey}
              required={selectedLink1Key === externalURLKey}
            >
              External URL
            </Label>
            <TextField
              id={urlID1}
              disabled={selectedLink1Key !== externalURLKey}
              required={selectedLink1Key === externalURLKey}
              onChange={onExternalUrl1Change}
            />
            <Dropdown
              placeholder="No page selected"
              label="Select a page for the button to navigate to"
              options={inappLinkChoices}
              onChange={onChangeLink1FromDropdown}
              required={selectedLink1Key === appPageKey && inAppLinkType1Key === dropdownKey}
              disabled={selectedLink1Key !== appPageKey || inAppLinkType1Key !== dropdownKey}
              selectedKey={selectedLink1FromDropdown}
            />
            <Label
              htmlFor={appPageID1}
              disabled={selectedLink1Key !== appPageKey || inAppLinkType1Key !== freeformTextKey}
              required={selectedLink1Key === appPageKey && inAppLinkType1Key === freeformTextKey}
            >
              In-App link
            </Label>
            <TextField
              id={appPageID1}
              disabled={selectedLink1Key !== appPageKey || inAppLinkType1Key !== freeformTextKey}
              required={selectedLink1Key === appPageKey && inAppLinkType1Key === freeformTextKey}
              onChange={onAppLink1Change}
            />
          </Styled.ChildHalfContainer>
        </Styled.ParentContainer>
        <hr />
        <Styled.ParentContainer>
          <Styled.ChildHalfContainer>
            <ChoiceGroup
              selectedKey={selectedLink2Key}
              options={linkOptions}
              onChange={onChangeLink2Choice}
              label="Choose button 2 options"
            />
            <Styled.Indented>
              <ChoiceGroup
                disabled={selectedLink2Key !== appPageKey}
                selectedKey={inAppLinkType1Key}
                options={inappLinkTypes}
                onChange={onChangeInAppLink2Type}
                label="Choose internal destination"
              />
            </Styled.Indented>
          </Styled.ChildHalfContainer>
          <Styled.ChildHalfContainer>
            <Label
              htmlFor={buttonTextID2}
              disabled={selectedLink2Key === noLinkKey}
              required={selectedLink2Key !== noLinkKey}
            >
              Button Text ({buttonTextCharacterLimit} character limit)
            </Label>
            <TextField
              id={buttonTextID2}
              defaultValue={learnMoreConst}
              disabled={selectedLink2Key === noLinkKey}
              required={selectedLink2Key !== noLinkKey}
              onChange={onButtonText2Change}
            />
            <Label
              htmlFor={urlID2}
              disabled={selectedLink2Key !== externalURLKey}
              required={selectedLink2Key === externalURLKey}
            >
              External URL
            </Label>
            <TextField
              id={urlID2}
              disabled={selectedLink2Key !== externalURLKey}
              required={selectedLink2Key === externalURLKey}
              onChange={onExternalUrl2Change}
            />
            <Dropdown
              placeholder="No page selected"
              label="Select a page for the button to navigate to"
              options={inappLinkChoices}
              onChange={onChangeLink2FromDropdown}
              required={selectedLink2Key === appPageKey && inAppLinkType2Key === dropdownKey}
              disabled={selectedLink2Key !== appPageKey || inAppLinkType2Key !== dropdownKey}
              selectedKey={selectedLink2FromDropdown}
            />
            <Label
              htmlFor={appPageID2}
              disabled={selectedLink2Key !== appPageKey || inAppLinkType2Key !== freeformTextKey}
              required={selectedLink2Key === appPageKey && inAppLinkType2Key === freeformTextKey}
            >
              In-App link
            </Label>
            <TextField
              id={appPageID2}
              disabled={selectedLink2Key !== appPageKey || inAppLinkType2Key !== freeformTextKey}
              required={selectedLink2Key === appPageKey && inAppLinkType2Key === freeformTextKey}
              onChange={onAppLink2Change}
            />
          </Styled.ChildHalfContainer>
        </Styled.ParentContainer>
        <hr />
        <Styled.ParentContainer>
          <Styled.ChildHalfContainer>
            <ChoiceGroup
              selectedKey={osNotificationPreference}
              options={sendOSNotificationsOptions}
              onChange={onChangeOsNotificationPreference}
              label="Send OS notifications"
            />
          </Styled.ChildHalfContainer>
        </Styled.ParentContainer>
        <hr />
        <Styled.ParentContainer>
          <Styled.ChildThirdContainer>
            <Label htmlFor={iconColorID} required={true}>
              Icon Primary Color
            </Label>
            <ColorPicker
              color={color}
              onChange={updateColor}
              alphaSliderHidden={true}
              showPreview={true}
              styles={{
                panel: { padding: 12 },
                root: {
                  maxWidth: 352,
                  minWidth: 352,
                },
                colorRectangle: { height: 268 },
              }}
            />
          </Styled.ChildThirdContainer>
          <Styled.ChildThirdContainer>
            <Label htmlFor={iconBGColorID} required={true}>
              Icon Background Color
            </Label>
            <ColorPicker
              color={bgColor}
              onChange={updateBGColor}
              alphaSliderHidden={true}
              showPreview={true}
              styles={{
                panel: { padding: 12 },
                root: {
                  maxWidth: 352,
                  minWidth: 352,
                },
                colorRectangle: { height: 268 },
              }}
            />
          </Styled.ChildThirdContainer>
          <Styled.ChildThirdContainer>
            <Label htmlFor={iconNameID} required={true}>
              Icon Name
            </Label>
            <TextField id={iconNameID} defaultValue={importantConst} onChange={onIconNameChange} required={true} />
            <p>
              <br />
              Use &quot;friendly name&quot; of any &quot;Fabric External MDL2&quot; icon{' '}
              <Link
                href="https://iconcloud.design/home/Full%20MDL2%20Assets/Fabric%20External%20MDL2%20Assets"
                target="_blank"
              >
                listed here
              </Link>
              .
            </p>
            <p>
              Latest Icon Version (in the app): 3.78, Last Updated: 4/22/2020
              <br />
              <br />
              <br />
            </p>
            <Slider
              label="Notification duration (in days, maximum 14)"
              min={1}
              max={14}
              value={duration}
              onChange={updateDuration}
              showValue={true}
            />
          </Styled.ChildThirdContainer>
        </Styled.ParentContainer>
        <hr />
        <Styled.SubmitButton text="Submit" onClick={submitNotification} />
      </>
    );
  };

  const renderPage = (): React.ReactElement => {
    return (
      <Styled.PageContainer>
        <Styled.ParentContainer>
          <Styled.ChildHalfContainer>
            <ChoiceGroup
              selectedKey={selectedEnvironmentKey}
              options={environmentOptions}
              onChange={onChangeEnvironmentChoice}
              label="Environment"
              styles={{ flexContainer: { display: 'flex' } }}
            />
          </Styled.ChildHalfContainer>
        </Styled.ParentContainer>
        <hr />
        {environment[__ENVIRONMENT__] === selectedEnvironmentKey
          ? renderSubmitNotificationContent()
          : renderEnviromentAdminPortalDetails()}
      </Styled.PageContainer>
    );
  };

  let content = null;
  if (submitNotificationStatus === LoadingStates.SUCCEEDED) {
    alert('Your notification has been successfully submitted');
    // Refresh the page, to reset the state for "SubmitNotifications" and "Admin"
    // Resetting the submitNotificationStatus to NOT_STARTED
    dispatch(resetSubmitNotificationStatus());
    window.location.reload(true);
  }

  if (submitNotificationStatus === LoadingStates.FAILED) {
    // eslint-disable-next-line no-console
    console.log('SUBMIT NOTIFICATION ERROR: ', submitNotificationError);
    content = (
      <Styled.PageContainer>
        <p>
          An error occured while attempting to send the notification. Please see the developer console for more details.
        </p>
      </Styled.PageContainer>
    );
  } else if (submitNotificationStatus === LoadingStates.STARTED) {
    // Some loading state while waiting for the the notification to send and success/failure to be returned
    content = (
      <Styled.PageContainer>
        <p>Attempting to send the notification. Please wait...</p>
      </Styled.PageContainer>
    );
  } else {
    // Success and "Not Started" flow
    content = renderPage();
  }

  return (
    <Page>
      <PageHeadingContainer>
        <PageHeading>Submit Notifications</PageHeading>
      </PageHeadingContainer>
      {content}
    </Page>
  );
}
