import { NeutralColors } from '@cseo/styles';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';

export const Container = styled(DefaultButton)`
  display: flex;
  padding: 10px 0 6px 0;
  width: 100%;
  height: 100%;
  border: none;
  border-bottom: 1px solid ${NeutralColors.gray30};
  text-align: left;
  &:hover {
    background-color: ${NeutralColors.white};
  }
`;

export const Heading = styled.div`
  overflow: hidden; /* prevents extra lines from being visible */
  padding-right: 32px; /* to stop overflow of the text at the right*/
`;

export const SubHeading = styled.div`
  cursor: default;
  color: ${NeutralColors.gray130};
`;
export const TextContainer = styled.div`
  align-self: baseline;
  line-height: 25px; /* height is 2x line-height, so two lines will display */
`;
