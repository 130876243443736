import { FontWeights } from '@uifabric/styling';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { FontSizes } from '@cseo/styles';

export const Container = styled.div`
  display: block;
  padding: 12px 0 11px 0;
  width: 100%;
  height: 100%;
  border: none;
  text-align: right;
`;

export const PanelContent = styled.div`
  flex-direction: column;
  display: flex;
`;

export const RowContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const RowText = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 48px;
`;
export const FontIconContainer = styled.div`
  height: 32px;
  width: 32px;
  font-family: Fabric MDL2 Assets;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
`;

export const NotStartedStatusIcon = styled(FontIcon)`
  color: #acacac;
`;

export const SuccessStatusIcon = styled(FontIcon)`
  color: #107c10;
`;

export const FailedStatusIcon = styled(FontIcon)`
  color: #a80000;
`;

export const StatusLabel = styled.label`
  font-size: ${FontSizes.size18};
  font-weight: ${FontWeights.semibold};
`;

export const ErrorStatusLabel = styled.label`
  font-size: ${FontSizes.size18};
  font-weight: ${FontWeights.semibold};
  color: #a80000;
`;

export const StatusContainer = styled.div`
  margin-left: 32px;
  text-align: left;
`;
export const ErrorWrapper = styled.div`
  color: #a80000;
  font-family: Segoe UI;
  font-size: 14px;
  line-height: 20px;
`;
