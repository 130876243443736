import {
  ActionTypes,
  IReceiveNotificationsDataFailedAction,
  IReceiveNotificationsDataSucceededAction,
  IRequestNotificationsDataAction,
  IUpdateNotificationsReadAction,
  IUpdateNotificationsReadSucceededAction,
  IUpdateNotificationsReadFailedAction,
  IDeleteNotificationAction,
  IDeleteNotificationSucceededAction,
  IDeleteNotificationFailedAction,
} from './Notifications.action-types';
import { INotificationsModel } from './Models/INotificationsModel';

export function receiveNotificationsDataFailedAction(): IReceiveNotificationsDataFailedAction {
  return {
    type: ActionTypes.RECEIVE_NOTIFICATIONS_DATA_FAILED,
  };
}

export function receiveNotificationsDataSucceededAction(
  Notifications: INotificationsModel[]
): IReceiveNotificationsDataSucceededAction {
  return {
    type: ActionTypes.RECEIVE_NOTIFICATIONS_DATA_SUCCEEDED,
    Notifications: Notifications,
  };
}

export function requestNotificationsDataAction(): IRequestNotificationsDataAction {
  return {
    type: ActionTypes.REQUEST_NOTIFICATIONS_DATA,
  };
}

export function setNotificationsAsRead(payload: INotificationsModel[]): IUpdateNotificationsReadAction {
  return {
    payload,
    type: ActionTypes.UPDATE_NOTIFICATIONS_AS_READ,
  };
}

export function setNotificationsAsReadSucceededAction(): IUpdateNotificationsReadSucceededAction {
  return {
    type: ActionTypes.UPDATE_NOTIFICATIONS_AS_READ_SUCCEEDED,
  };
}

export function setNotificationsAsReadFailedAction(): IUpdateNotificationsReadFailedAction {
  return {
    type: ActionTypes.UPDATE_NOTIFICATIONS_AS_READ_FAILED,
  };
}

export function deleteNotification(payload: string): IDeleteNotificationAction {
  return {
    payload,
    type: ActionTypes.DELETE_NOTIFICATION,
  };
}

export function deleteNotificationSucceededAction(): IDeleteNotificationSucceededAction {
  return {
    type: ActionTypes.DELETE_NOTIFICATION_SUCCEEEDED,
  };
}

export function deleteNotificationFailedAction(): IDeleteNotificationFailedAction {
  return {
    type: ActionTypes.DELETE_NOTIFICATION_FAILED,
  };
}
