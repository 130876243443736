import * as React from 'react';
import { CenterContainer } from '../../../../Shared/Components/CenterContainer';
import { SpinnerSize, Spinner } from 'office-ui-fabric-react';
import * as Styled from './LoadingCard.styled';

export function LoadingCard(): React.ReactElement {
  return (
    <CenterContainer>
      <Spinner size={SpinnerSize.large} />
      <Styled.LoadingText>Loading content</Styled.LoadingText>
    </CenterContainer>
  );
}
