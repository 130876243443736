import { createSelector } from 'reselect';
import { ILocationState, LocationReducerName } from './Location.types';
import { initialLocationState } from './Location.reducer';
import { IMyHubAppState } from '../../../App.types';

export const getLocationPermissions = createSelector(
  (state: IMyHubAppState) => state[LocationReducerName] ?? initialLocationState,
  (locationState: ILocationState) => locationState.locationValue
);

export const getLocationCoordinates = createSelector(
  (state: IMyHubAppState) => state[LocationReducerName] ?? initialLocationState,
  (locationState: ILocationState) => locationState.locationCoordinates
);
