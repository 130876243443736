import { IAdminActions, ActionTypes } from './Admin.action-types';
import {
  IAdminState,
  confirmedUserSGsKey,
  confirmedUserSGsErrorKey,
  submitNotificationStatusKey,
  submitNotificationErrorKey,
  searchSGorUsersKey,
  searchSGorUsersLoadingKey,
} from './Admin.types';
import { LoadingStates } from '../../Shared/Models/LoadingStates';

export const initialAdminState: IAdminState = {
  [submitNotificationStatusKey]: LoadingStates.NOT_STARTED,
  [submitNotificationErrorKey]: undefined,
  [confirmedUserSGsKey]: undefined,
  [confirmedUserSGsErrorKey]: undefined,
  [searchSGorUsersKey]: undefined,
  [searchSGorUsersLoadingKey]: undefined,
  countryCode: [],
  companyCode: [],
};

export function adminReducer(prev: IAdminState = initialAdminState, action: IAdminActions): IAdminState {
  switch (action.type) {
    case ActionTypes.RESET: {
      return initialAdminState;
    }
    case ActionTypes.SUBMIT_NOTIFICATION: {
      return {
        ...prev,
        [submitNotificationStatusKey]: LoadingStates.STARTED,
      };
    }
    case ActionTypes.SUBMIT_NOTIFICATION_SUCCESS: {
      return {
        ...prev,
        [submitNotificationStatusKey]: LoadingStates.SUCCEEDED,
      };
    }
    case ActionTypes.SUBMIT_NOTIFICATION_FAILED: {
      return {
        ...prev,
        [submitNotificationStatusKey]: LoadingStates.FAILED,
        [submitNotificationErrorKey]: action.error,
      };
    }
    case ActionTypes.RESET_SUBMIT_NOTIFICATION_STATUS: {
      return {
        ...prev,
        [submitNotificationStatusKey]: LoadingStates.NOT_STARTED,
      };
    }
    case ActionTypes.CHECK_USER_SG_MEMBERSHIP_SUCCESS: {
      const sgArray = action.result.value;
      let newArray = [...(prev[confirmedUserSGsKey] || [])];
      sgArray.forEach((sgID: string) => {
        if (!newArray.includes(sgID)) newArray.push(sgID);
      });

      // The person is UNAUTHORIZED, remove the SG from the array if it was present previously
      if (sgArray.length === 0) {
        const sgToCheck = action.securityGroupId;
        newArray = newArray.filter(function (value) {
          return value !== sgToCheck;
        });
      }

      return {
        ...prev,
        [confirmedUserSGsKey]: newArray,
      };
    }
    case ActionTypes.CHECK_USER_SG_MEMBERSHIP_FAILED: {
      return {
        ...prev,
        [confirmedUserSGsErrorKey]: action.error,
      };
    }
    case ActionTypes.RECEIVE_SG_OR_USERS_DATA: {
      return {
        ...prev,
        [searchSGorUsersLoadingKey]: LoadingStates.STARTED,
      };
    }
    case ActionTypes.RECEIVE_SG_OR_USERS_DATA_SUCCESS: {
      return {
        ...prev,
        [searchSGorUsersLoadingKey]: LoadingStates.SUCCEEDED,
        [searchSGorUsersKey]: action.result,
      };
    }
    case ActionTypes.RECEIVE_SG_OR_USERS_DATA_FAILED: {
      return {
        ...prev,
        [searchSGorUsersLoadingKey]: LoadingStates.FAILED,
        [searchSGorUsersKey]: action.error,
      };
    }

    case ActionTypes.COUNTRY_CODE_DATA_SUCCESS: {
      return {
        ...prev,
        countryCode: action.result,
      };
    }

    case ActionTypes.COMPANY_CODE_DATA_SUCCESS: {
      return {
        ...prev,
        companyCode: action.result,
      };
    }

    default:
      return prev;
  }
}
