import { all, put, takeLatest, getContext, call, SimpleEffect } from 'redux-saga/effects';
import { ActionTypes } from './Bookmarks.action-types';
import { IAuthClient, IUser } from '@employee-experience/common/lib/Models';
import { HttpClient } from '@employee-experience/core';
import { ApiHelper } from '../../../../Shared/Helpers/ApiHelper';
import { IBookmarksModel } from '../Models/IBookmarksModel';
import { receiveBookmarksDataFailedAction, receiveBookmarksDataSucceededAction } from './Bookmarks.actions';

export function* addBookmark(action: { bookmark: IBookmarksModel }): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: HttpClient = yield getContext('httpClient');
    const authClient: IAuthClient = yield getContext('authClient');
    const user: IUser = yield call([authClient, authClient.getUser]);

    const request = {
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
      data: { ...action.bookmark, upn: user.id },
    };

    const url = `${__API__.myMsftApim.resourceUrl}/userpreferences/user/${user.id}/preferences/bookmarks/create`;
    yield call([httpClient, httpClient.post], url, request);
    yield put({ type: ActionTypes.ADD_BOOKMARK_SUCCEEDED });
  } catch (error) {
    yield put({ type: ActionTypes.ADD_BOOKMARK_FAILED });
  }
}

export function* removeBookmark(action: { bookmarkId: string }): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: HttpClient = yield getContext('httpClient');
    const authClient: IAuthClient = yield getContext('authClient');
    const user: IUser = yield call([authClient, authClient.getUser]);

    const request = {
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    };
    const url = `${__API__.myMsftApim.resourceUrl}/userpreferences/user/${user.id}/preferences/bookmarks/delete?id=${action.bookmarkId}`;
    yield call([httpClient, httpClient.post], url, request);
    yield put({ type: ActionTypes.REMOVE_BOOKMARK_SUCCEEDED });
  } catch (error) {
    yield put({ type: ActionTypes.REMOVE_BOOKMARK_FAILED });
  }
}

export function* getBookmarksData(): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: HttpClient = yield getContext('httpClient');
    const authClient: IAuthClient = yield getContext('authClient');

    const user: IUser = yield call([authClient, authClient.getUser]);
    const { data: bookmarks }: { data: IBookmarksModel[] } = yield call([httpClient, httpClient.request], {
      url: __API__.myMsftApim.resourceUrl + '/userpreferences/user/' + user.id + '/preferences/bookmarks',
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    });
    yield put(receiveBookmarksDataSucceededAction(bookmarks));
  } catch (error) {
    // TODO: Log error.
    yield put(receiveBookmarksDataFailedAction());
  }
}

export function* bookmarkSagas(): IterableIterator<{}> {
  yield all([takeLatest(ActionTypes.ADD_BOOKMARK, addBookmark)]);
  yield all([takeLatest(ActionTypes.REMOVE_BOOKMARK, removeBookmark)]);
  yield all([takeLatest(ActionTypes.REQUEST_BOOKMARKS_DATA, getBookmarksData)]);
}
