import { ActionTypes, IAppAction } from './App.action-types';
import { IAppState } from './App.types';
import { REHYDRATE } from 'redux-persist/es/constants';
import { Action } from 'redux';
import { breakpointMap } from './Styles/Media';

export const initialAppState: IAppState = {
  isNavOpen: window.innerWidth > breakpointMap.xl,
  storeRehydrateComplete: false,
};

export function appReducer(prev: IAppState = initialAppState, action: IAppAction | Action): IAppState {
  switch (action.type) {
    case ActionTypes.TOGGLE_NAV: {
      return {
        ...prev,
        isNavOpen: !prev.isNavOpen,
      };
    }
    case REHYDRATE: {
      return {
        ...prev,
        storeRehydrateComplete: true,
      };
    }
    default:
      return prev;
  }
}
