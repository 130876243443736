import { IAuthClient, IUser } from '@employee-experience/common/lib/Models';
import { HttpClient } from '@employee-experience/core';
import { all, call, getContext, put, SimpleEffect, takeLatest } from 'redux-saga/effects';
import { ApiHelper } from '../Helpers/ApiHelper';
import { TelemetryService } from '../shared';
import { ActionTypes, IRegisterNotificationSubscription } from './PushNotification.action-types';
import { IPushNotificationSubscription } from './PushNotification.types';

/**
 * Worker saga to save the web push notification subscriptions
 */
export function* registerPushNotificationSubscription(
  pushNotificationSubscription: IRegisterNotificationSubscription
): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const {
      payload: { endpoint },
    } = pushNotificationSubscription;
    if (!endpoint) {
      throw Error('No endpoint is passed in push notification subscription');
    }

    const httpClient: HttpClient = yield getContext('httpClient');
    const authClient: IAuthClient = yield getContext('authClient');
    const user: IUser = yield call([authClient, authClient.getUser]);
    const subscription = { ...pushNotificationSubscription.payload, ...{ upn: user.id } };

    yield call(
      [httpClient, httpClient.post],
      __API__.myMsftApim.resourceUrl + `/notification/api/user/${user.id}/webPushNotificationRegistrations`,
      {
        resource: __API__.myMsftApim.resourceId,
        header: ApiHelper.getMyMsftApiHeaders(),
        data: {
          ...subscription,
        },
      }
    );
  } catch (error) {
    TelemetryService.trackException(error);
  }
}

/**
 * Worker saga to save the web push notification subscriptions
 */
export function* deRegisterPushNotificationSubscription(action: {
  payload: IPushNotificationSubscription;
}): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const { endpoint } = action.payload;
    if (!endpoint) {
      throw Error(
        'DeRegistering Push Subscription Failed. Unable to get the current PushSubscription endpoint details'
      );
    }

    const httpClient: HttpClient = yield getContext('httpClient');
    const authClient: IAuthClient = yield getContext('authClient');
    const user: IUser = yield call([authClient, authClient.getUser]);
    const subscriptionData = { endPoint: endpoint, upn: user.id };
    yield call(
      [httpClient, httpClient.delete],
      __API__.myMsftApim.resourceUrl + `/notification/api/user/${user.id}/webPushNotificationRegistrations`,
      {
        resource: __API__.myMsftApim.resourceId,
        header: ApiHelper.getMyMsftApiHeaders(),
        data: {
          ...subscriptionData,
        },
      }
    );
  } catch (error) {
    TelemetryService.trackException(error);
  } finally {
    yield put({ type: ActionTypes.DEREGISTER_NOTIFICATION_SUBSCRIPTION_COMPLETED });
  }
}

/**
 * Watcher for exploreSagas
 */
export function* pushNotificationSagas(): IterableIterator<{}> {
  yield all([
    takeLatest(ActionTypes.REGISTER_NOTIFICATION_SUBSCRIPTION, registerPushNotificationSubscription),
    takeLatest(ActionTypes.DEREGISTER_NOTIFICATION_SUBSCRIPTION, deRegisterPushNotificationSubscription),
  ]);
}
