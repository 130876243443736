import { Usage, EventName, SubCapability, Capability } from '../../Shared/Types';
import * as React from 'react';
import { Page, PageHeadingContainer, PageHeading } from '../../Styles/Page';
import { SubmitNotifications } from './SubmitNotifications';
import { notificationSGGuid } from './Admin.resources';
import { confirmedUserSGsSelector, confirmedUserSGsErrorSelector } from './Admin.selectors';
import { checkUserSecurityGroup, resetAction } from './Admin.actions';
import { useContext, useEffect } from 'react';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { usePageTracker } from '../../Shared/Hooks/usePageTracker';

export function Admin(): React.ReactElement {
  const { dispatch, useSelector } = useContext(ReduxContext);
  const confirmedUserSGs: string[] = useSelector(confirmedUserSGsSelector);
  const confirmedUserSGsError: {} = useSelector(confirmedUserSGsErrorSelector);

  useEffect(() => {
    dispatch(resetAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(checkUserSecurityGroup(notificationSGGuid));
  }, [dispatch]);

  const pageEvent: Usage = {
    capability: Capability.MyHub,
    subCapability: SubCapability.Admin,
    eventName: EventName.PAGE_LOAD,
  };
  usePageTracker(pageEvent);

  let content = null;
  if (!confirmedUserSGs) {
    content = (
      <PageHeadingContainer>
        <PageHeading>Loading...</PageHeading>
      </PageHeadingContainer>
    );
  } else if (confirmedUserSGs.includes(notificationSGGuid)) {
    // Intentionally do not wrap inside "<Page>" because SubmitNotifications has its own
    return <SubmitNotifications />;
  } else {
    // eslint-disable-next-line no-console
    console.log('CHECK USER SECURITY GROUP ERROR: ', confirmedUserSGsError);
    content = (
      <div>
        <PageHeadingContainer>
          <PageHeading>Access Denied</PageHeading>
        </PageHeadingContainer>
        <br />
        <p>
          Either an error has occured, or you are not authorized to access this page. Please see the developer console
          for more details
        </p>
      </div>
    );
  }

  return <Page>{content}</Page>;
}
