import { all, call, getContext, put, SimpleEffect, takeLatest } from 'redux-saga/effects';
import { ApiHelper } from '../../Shared/Helpers/ApiHelper';
import { HttpClient } from '@employee-experience/core';
import { ActionTypes } from './Help.action-types';
import { setFaqData } from './Help.actions';
import { TelemetryService } from '../../Shared/shared/TelemetryService';
import { FLIGHTS } from '../../Constants/flightingConstants';
import { isEmpty } from 'lodash-es';
export function* getFaqData(action: { configs: string | string[] }): IterableIterator<SimpleEffect<{}, {}>> {
  const httpClient: HttpClient = yield getContext('httpClient');
  try {
    const isManager = action.configs?.includes(FLIGHTS.MANAGER_CHECK);
    const url = '/qnamaker/v4.0/knowledgebases/qna?platform=web&isManager=' + isManager;
    const { data: faqData } = yield call([httpClient, httpClient.request], {
      url: __API__.myMsftApim.resourceUrl + url,
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    });

    let filteredFaqData;
    if (isEmpty(action.configs)) {
      filteredFaqData = faqData?.qnaDocuments?.length > 0 ? faqData.qnaDocuments : null;
    } else {
      filteredFaqData =
        faqData?.qnaDocuments?.length > 0
          ? faqData.qnaDocuments.filter(
              (faq) =>
                !faq.metadata?.find((metadata: { name: string }) => metadata.name === 'featureflight') ||
                action.configs.includes(
                  faq.metadata?.filter((metadata: { name: string }) => metadata.name === 'featureflight')[0]?.value
                )
            )
          : null;
    }
    const payload = {
      faqData: filteredFaqData,
    };
    yield put(setFaqData(payload));
  } catch (error) {
    TelemetryService.trackException(error);
    yield put({ type: ActionTypes.GET_FAQ_DATA_FAILED, error });
  }
}

export function* faqSagas(): IterableIterator<{}> {
  yield all([takeLatest(ActionTypes.GET_FAQ_DATA, getFaqData)]);
}
