import { Buffer } from 'buffer';

export class ImageHelper {
  static encodeImage = (arrayBuffer, mimeType = 'image/jpeg'): string | undefined => {
    let encodedImage;
    if (arrayBuffer) {
      encodedImage = Buffer.from(arrayBuffer, 'binary').toString('base64');
    }
    const uri = `data:${mimeType};base64,${encodedImage || 'undefined'}`;
    return encodedImage ? uri : undefined;
  };
}
