import { DefaultThemeColors, NeutralColors } from '@cseo/styles';
import { Link } from 'office-ui-fabric-react';

export const PanelHeader = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
`;

export const PanelSection = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
`;

export const PanelSectionHeader = styled.h3`
  font-weight: 600;
`;

export const PanelSectionSubLink = styled(Link)`
  align-items: center;
  color: ${DefaultThemeColors.blue90};
  display: flex;
  font-weight: 600;
`;

export const PanelSectionLink = styled(PanelSectionSubLink)`
  margin-top: 16px;
`;

export const PanelSectionSeparator = styled.div`
  background-color: ${NeutralColors.gray30};
  height: 1px;
`;
