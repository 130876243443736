import { FontSizes, TextColors } from '@cseo/styles';

export const PostImageContainer = styled.div`
  height: 216px;
  position: relative;
`;

export const PostImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const IconImage = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 6px;
`;

export const TextContainer = styled.div`
  height: 100%;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
`;

export const Title = styled.div`
  margin-top: 9px;
  margin-bottom: 11px;
  color: ${TextColors.lightPrimary};
  font-size: ${FontSizes.size14};
  line-height: 20px;
`;

export const Posted = styled.div`
  margin-top: 7px;
`;

export const HandleContainer = styled.div`
  display: flex;
  align-items: center;
`;
