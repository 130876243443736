import { minWidth } from '../../../Styles/Media';

export const FooterMenus = styled.ul`
  padding-left: 0;
  text-align: center;
`;

export const FooterMenuItem = styled.li`
  display: block;
  text-align: right;
  margin-bottom: 12px;

  ${minWidth.s} {
    display: inline-block;
    text-align: center;
    margin-left: 37px;
    margin-right: 37px;
    margin-bottom: 0;
  }
`;
