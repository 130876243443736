import * as React from 'react';
import { Switch, Route, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { Dashboard } from './Dashboard';
import { Page, PageHeadingContainer, PageHeading } from '../../Styles/Page';
import { ActionBar } from './ActionBar';
import { DashboardPivot } from './DashboardPivot';
import { ManagerDashboard } from './ManagerDashboard';
import { useUserGlobalConfig, useUserGlobalConfigDetails } from '../../Shared/Hooks/useUserGlobalConfig';

function DashboardRoutes(props: RouteComponentProps): React.ReactElement {
  const { path } = props.match;
  const configs = useUserGlobalConfig(['You', 'ManagerDashboard']);
  const userDetails = useUserGlobalConfigDetails();
  const shouldShowPivot = configs.You && configs.ManagerDashboard;
  const isOnlyManagerDashboard = !configs.You && configs.ManagerDashboard;

  return (
    <Page>
      <PageHeadingContainer>
        {isOnlyManagerDashboard && (
          <PageHeading>
            {!!userDetails.preferredFirstName
              ? userDetails.preferredFirstName
              : !!userDetails.firstName
              ? userDetails.firstName
              : 'Your '}
            {!!userDetails.preferredFirstName || !!userDetails.firstName ? "'s " : ''} team
          </PageHeading>
        )}
        {!isOnlyManagerDashboard && <PageHeading>Dashboard</PageHeading>}
        {!shouldShowPivot && <Route path={path} exact={true} component={ActionBar} />}
      </PageHeadingContainer>

      {shouldShowPivot && (
        <PageHeadingContainer>
          <DashboardPivot />
          <Route path={path} exact={true} component={ActionBar} />
        </PageHeadingContainer>
      )}

      <Switch>
        {configs.You && <Route path={path} exact={true} component={Dashboard} />}
        {configs.You && <Route path={`${path}/cafe`} component={Dashboard} />}
        {configs.ManagerDashboard && <Route path={`${path}/team`} component={ManagerDashboard} />}
        <Redirect to="/" />
      </Switch>
    </Page>
  );
}

const connected = withRouter(DashboardRoutes);
export { connected as DashboardRoutes };
