import { createSelector } from 'reselect';
import { IMyHubAppState, IAppState } from './App.types';
import { AppReducerName } from './App.resources';
import { initialAppState } from './App.reducer';

export const isNavOpenSelector = createSelector(
  (state: IMyHubAppState) => state[AppReducerName] ?? initialAppState,
  (appState: IAppState) => appState.isNavOpen
);

export const storeRehydrateCompleteSelector = createSelector(
  (state: IMyHubAppState) => state[AppReducerName] ?? initialAppState,
  (appState: IAppState) => appState.storeRehydrateComplete
);
