import * as React from 'react';
import { MapContainer } from './MapView.styled';
import { AzureMap, AzureMapsProvider, GeometryType } from 'react-azure-maps';
import * as atlas from 'azure-maps-control';
import { useContext, useEffect } from 'react';
import { requestBuildingsDataAction } from './MapView.actions';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { buildingsSelector } from './MapView.selectors';
import { Capability, SubCapability, Usage, EventName } from '../../../Shared/Types';
import { usePageTracker } from '../../../Shared/Hooks/usePageTracker';
import { TelemetryService } from '../../../Shared/shared';
import { IMapExploreBuildingsModel } from './MapView.types';

type Props = {
  onBuildingSelected: (building: IMapExploreBuildingsModel) => void;
};

export function MapView(props: Props): React.ReactElement {
  const { dispatch, useSelector } = useContext(ReduxContext);
  const buildings = useSelector(buildingsSelector);
  const pageEvent: Usage = {
    capability: Capability.MyHub,
    subCapability: SubCapability.Explore,
    eventName: EventName.PAGE_LOAD,
  };
  usePageTracker(pageEvent);
  const globalSpaceId = 'd64fa6a7-b615-e711-80c3-00155daa74cb';
  useEffect(() => {
    dispatch(requestBuildingsDataAction({ regionId: globalSpaceId }));
  }, [dispatch]);

  let map: atlas.Map;

  const generateBuildingsPoints = (pin: {}): atlas.data.Feature<GeometryType, IMapExploreBuildingsModel>[] => {
    const layerData: atlas.data.Feature<GeometryType, IMapExploreBuildingsModel>[] = [];
    pin.map((building: IMapExploreBuildingsModel) => {
      const { buildingId, spaceId, latitude, longitude, hasCafe, name, formattedName, coordinates } = building;
      layerData.push(
        new atlas.data.Feature(new atlas.data.Point([longitude, latitude]), {
          buildingId,
          spaceId,
          latitude,
          longitude,
          hasCafe,
          name,
          formattedName,
          coordinates,
        })
      );
    });
    return layerData;
  };

  const onBuildingClick = (buildingSymbol: atlas.SymbolLayerOptions): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Explore,
      eventName: EventName.MARKER_SELECTED,
    });
    if (buildingSymbol.shapes && buildingSymbol.shapes.length > 0) {
      if (buildingSymbol.shapes[0]) {
        const exploreBuilding: IMapExploreBuildingsModel = buildingSymbol.shapes[0].getProperties();
        if (exploreBuilding) {
          props.onBuildingSelected(exploreBuilding);
        }
      }
    }
  };

  const getMap = (): void => {
    map = new atlas.Map('map', {
      center: [-122.127222, 47.641944],
      zoom: 13,
      view: 'Auto',
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: _MAP_SUBSCRIPTION_KEY_,
      },
    });
    map.events.add('ready', () => {
      const zoomControl = new atlas.control.ZoomControl();
      map.controls.add(zoomControl, {
        position: atlas.ControlPosition.BottomLeft,
      });
      const dataSource = new atlas.source.DataSource(undefined, {
        cluster: true,
        clusterRadius: 60,
        clusterMaxZoom: 15,
      });
      map.sources.add(dataSource);
      const points = generateBuildingsPoints(buildings);
      dataSource.add(points);
      const clusterBubbleLayer = new atlas.layer.BubbleLayer(dataSource, undefined, {
        radius: [
          'step',
          ['get', 'point_count'],
          20, // Default of 20 pixel bubble radius.
          10,
          30, // If point_count >= 10, bubble radius is 30 pixels.
          75,
          40, // If point_count >= 75, bubble radius is 40 pixels.
        ],
        color: 'rgba(1, 128, 216, 0.25)',
        strokeWidth: 0,
        filter: ['has', 'point_count'], // Only rendered data points which have a point_count property, which clusters do.
      });
      const clusterCountLayer = new atlas.layer.SymbolLayer(dataSource, undefined, {
        iconOptions: {
          image: 'none', // Hide the icon image.
          allowOverlap: true,
        },
        textOptions: {
          textField: ['get', 'point_count_abbreviated'],
          color: '#0078D4',
          offset: [0, 0.4],
          allowOverlap: true,
        },
      });
      const symbolLayer = new atlas.layer.SymbolLayer(dataSource, undefined, {
        iconOptions: { anchor: 'bottom', allowOverlap: true },
        textOptions: { textField: ['get', 'formattedName'], anchor: 'top', allowOverlap: false },
        filter: ['!', ['has', 'point_count']],
      });
      map.events.add('click', symbolLayer, onBuildingClick);
      map.layers.add([clusterBubbleLayer, clusterCountLayer, symbolLayer]);
    });
  };

  React.useEffect(() => {
    getMap();
    return (): void => {
      map.events.remove('ready', function () {
        const zoomControl = new atlas.control.ZoomControl();
        map.controls.remove(zoomControl);
      });
    };
  }, []);

  return (
    <MapContainer id="map">
      <AzureMapsProvider>
        <AzureMap></AzureMap>
      </AzureMapsProvider>
    </MapContainer>
  );
}
