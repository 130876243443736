import { User } from '../User/User.action-types';

export const usageUserIdKey = 'usageUserId';
export const userKey = 'userDetails';
export const sessionIDKey = 'sessionID';
export const lastActiveTimeKey = 'lastActiveTime';
export const userGlobalConfig = 'userGlobalConfig';
export const userGlobalConfigDetails = 'userGlobalConfigDetails';
export const user = 'user';
export const userBuildingLocationKey = 'userBuildingLocation';

export interface IUserGlobalConfig {
  featureType: 'PL' | 'PR';
  name: string;
  status?: 'active' | 'inactive';
}

export interface IUserGlobalConfigDetails {
  firstName: string;
  lastName: string;
  preferredFirstName: string;
  preferredLastName: string;
  preferredName: string;
}

export interface IUserState {
  [usageUserIdKey]: string;
  [sessionIDKey]: string;
  [lastActiveTimeKey]: Date | string;
  [lastActiveTimeKey]: Date | string;
  [userGlobalConfig]: { config: IUserGlobalConfig[] };
  [userGlobalConfigDetails]: IUserGlobalConfigDetails;
  [user]: User;
  [userBuildingLocationKey]: {};
}
