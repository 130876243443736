import { LoadingStates } from '../../../Shared/Models/LoadingStates';
import { ISearchState } from '../Search/Search.types';
import { ActionTypes, ISearchAction } from '../Search/Search.action-types';
import { ISearchResultsModel } from './Models/ISearchResultsModel';

export const initialSearchState: ISearchState = {
  SearchLoadingStatus: LoadingStates.NOT_STARTED,
  SearchResults: {} as ISearchResultsModel,
  SearchError: {},
};

export function searchReducer(prev: ISearchState = initialSearchState, action: ISearchAction): ISearchState {
  switch (action.type) {
    case ActionTypes.FETCH_SEARCH_RESULTS_FAILURE: {
      return {
        ...prev,
        SearchError: action.error,
        SearchLoadingStatus: LoadingStates.FAILED,
      };
    }
    case ActionTypes.FETCH_SEARCH_RESULTS_SUCCESS: {
      return {
        ...initialSearchState,
        SearchResults: action.SearchResults,
        SearchLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }
    case ActionTypes.FETCH_SEARCH_RESULTS: {
      return {
        ...prev,
        SearchLoadingStatus: LoadingStates.STARTED,
      };
    }
    case ActionTypes.RESET_SEARCH_RESULTS: {
      return {
        ...initialSearchState,
      };
    }
    default:
      return prev;
  }
}
