import * as React from 'react';
import * as Styled from './NewsCard.styled';
import {
  Card,
  CardBody,
  CardFooter,
  CardFooterItem,
  CardMetadata,
  CardMetadataContainer,
  CardMetadataText,
  CardTag,
} from './../../Card';
import { INewsCardProps } from './NewsCard.types';
import TextEllipsis from 'react-text-ellipsis';
import moment from 'moment';
import { StripHtmlTagsRegEx } from '../../Feed.resources';
import { Link as LinkTag } from './../../../../../Styles/Link';
import { BookmarkButton } from '../../Bookmarks/BookmarkButton';
import { IBookmarksModel } from '../../Models/IBookmarksModel';
import Entities = require('entities');

export function NewsCard(props: INewsCardProps): React.ReactElement {
  const { Images, FeedSponsorTitle, Title, Description, PublicationDate, Link, FeedsType, Id } = props.news;
  let imagePath = '/assets/Feed/NewsDefault.svg'; // default image path;
  if (Images && Images[0]) {
    imagePath = Images[0];
  }
  const news: IBookmarksModel = {
    description: Description,
    feedSponsorTitle: FeedSponsorTitle,
    images: imagePath,
    link: Link,
    publicationDate: PublicationDate ? PublicationDate.toString() : undefined,
    title: Title,
    feedsType: FeedsType,
    id: Id,
  };

  return (
    <Card>
      <CardBody tabIndex={0}>
        <Styled.ImageContainer>
          {<Styled.Image src={imagePath} alt="" />}
          {FeedSponsorTitle && (
            <Styled.FeedSponsorText>{FeedSponsorTitle.replace(/-/g, '\u2013')}</Styled.FeedSponsorText> // replace hypen with EN DASH(\u2013)
          )}
        </Styled.ImageContainer>
        <Styled.TextContainer>
          {Title && (
            <Styled.Title>
              <TextEllipsis lines={2}>{Entities.decodeHTML(Title).replace(StripHtmlTagsRegEx, '')}</TextEllipsis>
            </Styled.Title>
          )}
          {Description && (
            <Styled.Desc>
              <TextEllipsis lines={2}>{Entities.decodeHTML(Description).replace(StripHtmlTagsRegEx, '')}</TextEllipsis>
            </Styled.Desc>
          )}
          {moment(PublicationDate).isValid() && (
            <CardMetadataContainer>
              <CardMetadata>
                <CardMetadataText>
                  <TextEllipsis lines={1}>{moment(PublicationDate).format('Do MMM YYYY')}</TextEllipsis>
                </CardMetadataText>
              </CardMetadata>
            </CardMetadataContainer>
          )}
        </Styled.TextContainer>
      </CardBody>
      <CardFooter>
        <CardFooterItem>
          {Link && (
            <LinkTag href={Link} target="_blank">
              See more
            </LinkTag>
          )}
        </CardFooterItem>
        <CardFooterItem>
          <CardTag tabIndex={0}>News</CardTag>
          {props.showBookmark ? <BookmarkButton {...news}></BookmarkButton> : null}
        </CardFooterItem>
      </CardFooter>
    </Card>
  );
}
