import * as React from 'react';
import { CoherenceNav } from '@cseo/controls/lib/nav';
import { useNavGroups } from './useNavGroups';
import { INavProps } from './Nav.types';
import * as Styled from './Nav.styled';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { useUserGlobalConfig } from '../../Shared/Hooks/useUserGlobalConfig';
import { FLIGHTS } from '../../Constants/flightingConstants';

export const Nav: React.FC<INavProps> = (props: INavProps) => {
  const { isOpen, onToggle } = props;
  const { telemetryClient } = React.useContext(ComponentContext);

  const configs = useUserGlobalConfig([
    FLIGHTS.MANAGER_DASHBOARD,
    FLIGHTS.HRWEB,
    FLIGHTS.DASHBOARD,
    FLIGHTS.EXPLORE,
    FLIGHTS.NEWS,
    FLIGHTS.EVENTS,
  ]);
  const navLinks = [];
  if (configs.dashboard) {
    navLinks.push({
      key: 'Dashboard',
      name: 'Dashboard',
      href: configs.ManagerDashboard ? '/dashboard/team' : '/dashboard',
      icon: 'AppIconDefault',
    });
  }
  if (configs[FLIGHTS.NEWS] || configs[FLIGHTS.EVENTS]) {
    navLinks.push({
      key: 'Feed',
      name: 'Feed',
      href: '/feed',
      icon: 'PageList',
    });
  }
  if (configs.explore) {
    navLinks.push({
      key: 'Explore',
      name: 'Explore',
      href: '/explore',
      icon: 'MapPin',
    });
  }
  if (configs.hrweb) {
    navLinks.push({
      key: 'HRweb',
      name: 'HRweb',
      href: 'https://microsoft.sharepoint.com/sites/hrw/pages/home.aspx',
      icon: '/assets/HRWeb/hr.svg',
    });
  }

  const navGroups = useNavGroups(
    [
      {
        key: 'Main',
        links: navLinks,
      },
    ],
    // Nav link state will be updated when pathname changes, or the those config are updated
    [configs.dashboard, configs.explore, configs.hrweb]
  );

  return (
    <CoherenceNav
      groups={navGroups}
      isNavCollapsed={!isOpen}
      onNavCollapsed={onToggle}
      styles={isOpen ? Styled.openNavStyles : Styled.hiddenNavStyles}
      telemetryHook={telemetryClient}
    />
  );
};
