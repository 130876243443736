export enum ActionTypes {
  TOGGLE_NAV = 'TOGGLE_NAV',
  INITIATE_APP_LOAD = 'INITIATE_APP_LOAD',
}

export interface IToggleNavAction {
  type: ActionTypes.TOGGLE_NAV;
}

export interface IAppLoad {
  type: ActionTypes.INITIATE_APP_LOAD;
}

export type IAppAction = IToggleNavAction | IAppLoad;
