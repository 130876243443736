//disabled camelcase rule explictly as snow api allows predefined variable with -
/* eslint-disable @typescript-eslint/camelcase */
import { all, call, getContext, put, SimpleEffect, takeLatest, putResolve } from 'redux-saga/effects';
import { ApiHelper } from '../../../Shared/Helpers/ApiHelper';
import { HttpClient } from '@employee-experience/core';
import { assignmentGroup } from './ReportProblem.resources';
import { ActionTypes } from './ReportProblem.action-types';
import { setTickets, openTicketsFailed, submitTicketSuccess, submitTicketFailed } from './ReportProblem.actions';
import { ITicketDetailModel } from './Models/ITicketDetailModel';
import { IAuthClient } from '@employee-experience/common/lib/Models';
import { ITicketsModel } from './Models/ITicketsModel';
import { ISubmitTicketData } from './SubmitTicket/SubmitTicket.types';

const getAlias = (email: string): string => {
  return email.substring(0, email.indexOf('@'));
};

/**
 * Method to convert the response object to a simpler one which will be persisted in redux.
 */
// any rule is disabled explictly as fetch api returns the data with many attributes and we fetch only the required attributes.
/* eslint-disable @typescript-eslint/no-explicit-any */
const convertToSimpleObj = (tickets: any): ITicketsModel[] => {
  const ticketData = tickets.result
    .map((ticket: ITicketDetailModel) => {
      return {
        short_description: ticket.short_description,
        incident_number: ticket.number,
        time: ticket.sys_created_on,
        sys_id: ticket.sys_id,
        state: ticket.state,
      };
    })
    .sort((a: any, b: any): string => b.incident_number.localeCompare(a.incident_number));
  return ticketData;
};

/**
 * Worker saga to fetch Open Tickets
 */
export function* fetchOpenTickets(): IterableIterator<SimpleEffect<{}, {}>> {
  const httpClient: HttpClient = yield getContext('httpClient');
  const authClient: IAuthClient = yield getContext('authClient');
  const userid: string = yield call([authClient, authClient.getUserId]);
  const alias = getAlias(userid);

  try {
    const url = `${__API__.myMsftApim.resourceUrl}/snow/api/x_micr5_api/incident/v2?sysparm_query=caller_id.user_name=${alias}^state!=7`;
    const { data: ticketData } = yield call([httpClient, httpClient.request], {
      url: url,
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    });
    const data = convertToSimpleObj(ticketData);
    yield put(setTickets(data));
  } catch (error) {
    yield put(openTicketsFailed());
  }
}

/**
 * Worker saga to submit Tickets
 */
function* submitTicket({ payload }: ISubmitTicketData): IterableIterator<SimpleEffect<{}, {}>> {
  const httpClient: HttpClient = yield getContext('httpClient');
  const authClient: IAuthClient = yield getContext('authClient');
  const user: string = yield call([authClient, authClient.getUserId]);
  const alias = getAlias(user);
  const formData = {
    ...payload.data,
    caller_id: alias,
    assignment_group: assignmentGroup,
  };
  const request = {
    resource: __API__.myMsftApim.resourceId,
    header: ApiHelper.getMyMsftApiHeaders(),
    data: formData,
  };
  const url = `${__API__.myMsftApim.resourceUrl}/snow/api/x_micr5_api/incident/v2`;
  try {
    yield call([httpClient, httpClient.post], url, request);
    yield putResolve(submitTicketSuccess());
  } catch (error) {
    yield put(submitTicketFailed(error));
  }
}

/**
 * Watcher for SNOW sagas
 */
export function* reportProblemSagas(): IterableIterator<{}> {
  yield all([
    takeLatest(ActionTypes.SUBMIT_TICKET, submitTicket),
    takeLatest(ActionTypes.FETCH_OPEN_TICKET, fetchOpenTickets),
  ]);
}
