import { all, put, takeLatest, getContext, call, SimpleEffect, select } from 'redux-saga/effects';
import { HttpClient } from '@employee-experience/core';
import { ActionTypes } from './User.Action-types';
import { ApiHelper } from '../Helpers/ApiHelper';
import { fetchUserDetailsSuccess, fetchUserGlobalConfigSuccess } from './User.actions';
import { TelemetryService } from '../../Shared/shared';
import { isEmpty, keyBy } from 'lodash-es';
import { getUserDetailsConfig } from './User.selectors';
const { FETCH_USER_BUILDING_LOCATION_SUCCESS, FETCH_USER_BUILDING_LOCATION_FAILED } = ActionTypes;

export function* fetchUsageUserId(): IterableIterator<SimpleEffect<{}, {}>> {
  const httpClient: HttpClient = yield getContext('httpClient');
  try {
    const { data: result } = yield call([httpClient, httpClient.request], {
      url: __API__.myMsftApim.resourceUrl + `/usageuserid`,
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    });
    yield put({ type: ActionTypes.RECEIVE_USAGE_USER_ID, ...result });
  } catch (error) {
    // TODO: Log error.
    yield put({ type: ActionTypes.RECEIVE_USAGE_USER_ID_FAILED });
  }
}

export function* fetchUserGlobalConfigWeb(): IterableIterator<SimpleEffect<{}, {}>> {
  const httpClient: HttpClient = yield getContext('httpClient');
  try {
    const { data: currConfig } = yield call([httpClient, httpClient.request], {
      url:
        __API__.myMsftApim.resourceUrl + '/globalConfig/featureconfig?env=' + _UTPE_ENVIRONMENT_NAME_ + '&platform=web',
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    });
    let responseData = {};
    if (currConfig && !isEmpty(currConfig.config)) {
      responseData = keyBy(currConfig.config, 'name');
    } else {
      TelemetryService.trackException('Globalisation api call returned empty response');
    }

    yield put(fetchUserGlobalConfigSuccess(responseData, currConfig.userDetails));
  } catch (error) {
    TelemetryService.trackException(error);
    yield put({ type: ActionTypes.FETCH_USER_GLOBAL_CONFIG_WEB_FAILED, error });
  }
}

export function* fetchUserDetails(): IterableIterator<SimpleEffect<{}, {}>> {
  const httpClient: HttpClient = yield getContext('httpClient');
  try {
    const { data: userInfo } = yield call([httpClient, httpClient.request], {
      url: __API__.myMsftApim.resourceUrl + `/msgraph/v1.0/me`,
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    });
    yield put(fetchUserDetailsSuccess(userInfo));
  } catch (error) {
    TelemetryService.trackException(error);
    yield put({ type: ActionTypes.FETCH_USER_DETAILS_FAILED, error });
  }
}
export function* fetchUserBuildingLocation(): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const user = yield select(getUserDetailsConfig);
    const httpClient: HttpClient = yield getContext('httpClient');
    const url = `/dip/query/api/v1.0/users?type=employee&$filter=userPrincipalName eq '${user?.userPrincipalName}'&includes=building,location`;
    TelemetryService.trackTrace(`Start: fetchUserBuildingLocation - url: ${url}`);
    const { data, error } = yield call([httpClient, httpClient.request], {
      url: __API__.adtV2.resourceUrl + url,
      resource: __API__.adtV2.resourceId,
      header: ApiHelper.getADTv2ApiHeaders(),
    });
    let userBuldingLocation: UserBuilding = {};
    if (!error) {
      const dipUserBuildingLocationResponse = Array.isArray(data?.items) && data?.items.length ? data.items[0] : null;
      userBuldingLocation = {
        buildingId: dipUserBuildingLocationResponse?.buildingId,
        buildingName: dipUserBuildingLocationResponse?.building?.name,
        latitude: dipUserBuildingLocationResponse?.building?.location[0]?.latitude,
        longitude: dipUserBuildingLocationResponse?.building?.location[0]?.longitude,
      };
      TelemetryService.trackTrace(
        `Complete: fetchUserBuildingLocation - buildingId: ${userBuldingLocation.buildingId}, buildingName: ${userBuldingLocation.buildingName}, latitude: ${userBuldingLocation.latitude}, longitude: ${userBuldingLocation.longitude}`
      );
      yield put({ type: FETCH_USER_BUILDING_LOCATION_SUCCESS, payload: { data: userBuldingLocation } });
    } else {
      TelemetryService.trackException(error);
      yield put({ type: FETCH_USER_BUILDING_LOCATION_FAILED, payload: { error } });
    }
  } catch (err) {
    const error = new Error(err);
    TelemetryService.trackException(error);
    yield put({ type: FETCH_USER_BUILDING_LOCATION_FAILED, payload: { error: { error_message: error?.message } } });
  }
}

export function* userSagas(): IterableIterator<{}> {
  yield all([takeLatest(ActionTypes.REQUEST_USAGE_USER_ID, fetchUsageUserId)]);
  yield all([takeLatest(ActionTypes.FETCH_USER_GLOBAL_CONFIG_WEB, fetchUserGlobalConfigWeb)]);
  yield all([takeLatest(ActionTypes.FETCH_USER_DETAILS, fetchUserDetails)]);
}
