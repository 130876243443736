import { ActionTypes } from './Dashboard.action-types';
import { all, put, SimpleEffect, takeLatest } from '@redux-saga/core/effects';
import { receiveCatalogItemsAction } from './Dashboard.actions';
import { CatalogItems } from './Dashboard.resources';

export function* fetchCatalogItems(): IterableIterator<SimpleEffect<{}, {}>> {
  yield put(receiveCatalogItemsAction(CatalogItems));
}

export function* dashboardSagas(): IterableIterator<{}> {
  yield all([takeLatest(ActionTypes.REQUEST_CATALOG_ITEMS, fetchCatalogItems)]);
}
