import { Shimmer, ShimmerElementType } from 'office-ui-fabric-react';
import * as React from 'react';
import { loadingImage } from './LoadingContent.styled';

export function LoadingContent(): React.ReactElement {
  const shimmerWithElementTitleRow = [{ type: ShimmerElementType.line, height: 32 }];
  const shimmerWithElementFirstRow = [{ type: ShimmerElementType.line, height: 24 }];
  const shimmerWithElementSecondRow = [{ type: ShimmerElementType.line, height: 170 }];
  return (
    <>
      <Shimmer shimmerElements={shimmerWithElementTitleRow} />
      <Shimmer style={loadingImage} width={'62%'} shimmerElements={shimmerWithElementFirstRow} />
      <Shimmer shimmerElements={shimmerWithElementSecondRow} />
    </>
  );
}
