import { LoadingStates } from '../Models/LoadingStates';
import { ActionTypes as LocalActionTypes, MicroSentimentAction } from './MicroSentiment.action-types';
import { IMicroSentimentState } from './MicroSentiment.types';
import { MicroSentimentActionTypes as PartnerToolsActionTypes } from '@microsoft/myhub-web-partner-tools';

export const initialMicroSentimentState: IMicroSentimentState = {
  request: null,
  submitLoadingStatus: LoadingStates.NOT_STARTED,
};

export function microSentimentReducer(
  prev = initialMicroSentimentState,
  action: MicroSentimentAction
): IMicroSentimentState {
  switch (action.type) {
    case LocalActionTypes.END_MICRO_SENTIMENT_FLOW: {
      return initialMicroSentimentState;
    }
    case PartnerToolsActionTypes.START_MICRO_SENTIMENT_FLOW: {
      if (action.request.feature && action.request.ratingMessage && action.request.taskMessage) {
        return {
          ...prev,
          request: action.request,
        };
      }
      return prev;
    }
    case LocalActionTypes.SUBMIT_MICRO_SENTIMENT: {
      return {
        ...prev,
        submitLoadingStatus: LoadingStates.STARTED,
      };
    }
    case LocalActionTypes.SUBMIT_MICRO_SENTIMENT_FAILED: {
      return {
        ...prev,
        submitLoadingStatus: LoadingStates.FAILED,
      };
    }
    case LocalActionTypes.SUBMIT_MICRO_SENTIMENT_SUCCEEDED: {
      return {
        ...prev,
        submitLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }
    default: {
      return prev;
    }
  }
}
