import {
  ActionTypes,
  IReset,
  ISubmitNotification,
  ISubmitNotificationSuccess,
  ISubmitNotificationFailure,
  ICheckUserSGMembership,
  IReceiveUserSGMembership,
  IReceiveUserSGMembershipFailed,
  IReceiveSGOrUsersData,
  IReceiveSGOrUsersDataSuccess,
  IReceiveSGOrUsersDataFailed,
  IResetSubmitNotificationStatus,
  ICountryCodeData,
  ICountryCodeDataFailed,
  ICountryCodeDataSuccess,
  ICompanyCodeData,
  ICompanyCodeDataSuccess,
  ICompanyCodeDataFailed,
} from './Admin.action-types';
import { SGMembershipInfo } from './Admin.resources';
import { NotificationPayload, SgUserDataSearchModel } from './Admin.types';
import { IDropdownOption } from 'office-ui-fabric-react';

export function resetAction(): IReset {
  return {
    type: ActionTypes.RESET,
  };
}

export function submitNotificationAction(payload: NotificationPayload): ISubmitNotification {
  return {
    payload: payload,
    type: ActionTypes.SUBMIT_NOTIFICATION,
  };
}

export function submitNotificationSuccessAction(): ISubmitNotificationSuccess {
  return {
    type: ActionTypes.SUBMIT_NOTIFICATION_SUCCESS,
  };
}

export function submitNotificationFailedAction(error: {}): ISubmitNotificationFailure {
  return {
    type: ActionTypes.SUBMIT_NOTIFICATION_FAILED,
    error,
  };
}

export function resetSubmitNotificationStatus(): IResetSubmitNotificationStatus {
  return {
    type: ActionTypes.RESET_SUBMIT_NOTIFICATION_STATUS,
  };
}

export function checkUserSecurityGroup(securityGroupId: string): ICheckUserSGMembership {
  return {
    type: ActionTypes.CHECK_USER_SG_MEMBERSHIP,
    securityGroupId,
  };
}

export function checkUserSecurityGroupSuccess(
  givenSGMembershipInfo: SGMembershipInfo,
  securityGroupId: string
): IReceiveUserSGMembership {
  return {
    type: ActionTypes.CHECK_USER_SG_MEMBERSHIP_SUCCESS,
    result: givenSGMembershipInfo,
    securityGroupId,
  };
}

export function checkUserSecurityGroupFailed(error: {}): IReceiveUserSGMembershipFailed {
  return {
    type: ActionTypes.CHECK_USER_SG_MEMBERSHIP_FAILED,
    error,
  };
}

export function fetchSgUsersSearchResults(payload: {
  searchText: string;
  searchCategory: string;
  resolve: Function;
  reject: Function;
}): IReceiveSGOrUsersData {
  return { type: ActionTypes.RECEIVE_SG_OR_USERS_DATA, payload: payload };
}

export function fetchSgUsersSearchResultsSuccess(value: SgUserDataSearchModel): IReceiveSGOrUsersDataSuccess {
  return {
    type: ActionTypes.RECEIVE_SG_OR_USERS_DATA_SUCCESS,
    result: value,
  };
}

export function fetchSgUsersSearchResultsFailed(error: {}): IReceiveSGOrUsersDataFailed {
  return {
    type: ActionTypes.RECEIVE_SG_OR_USERS_DATA_FAILED,
    error,
  };
}

export function fetchCountryCodeData(): ICountryCodeData {
  return {
    type: ActionTypes.COUNTRY_CODE_DATA,
  };
}

export function fetchCountryCodeDataSuccess(value: IDropdownOption[]): ICountryCodeDataSuccess {
  return {
    type: ActionTypes.COUNTRY_CODE_DATA_SUCCESS,
    result: value,
  };
}

export function fetchCountryCodeDataFailed(error: {}): ICountryCodeDataFailed {
  return {
    type: ActionTypes.COUNTRY_CODE_DATA_FAILED,
    error,
  };
}

export function fetchCompanyCodeData(): ICompanyCodeData {
  return {
    type: ActionTypes.COMPANY_CODE_DATA,
  };
}

export function fetchCompanyCodeDataSuccess(value: IDropdownOption[]): ICompanyCodeDataSuccess {
  return {
    type: ActionTypes.COMPANY_CODE_DATA_SUCCESS,
    result: value,
  };
}

export function fetchCompanyCodeDataFailed(error: {}): ICompanyCodeDataFailed {
  return {
    type: ActionTypes.COMPANY_CODE_DATA_FAILED,
    error,
  };
}
