import { useContext } from 'react';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { getUserGlobalConfig } from '../User/User.selectors';

export function useFeaturedFlights(): string[] {
  const { useSelector } = useContext(ReduxContext);
  const config = useSelector(getUserGlobalConfig);
  return Object.keys(config)
    .filter((f) => config[f].status?.toLowerCase() === 'active')
    .map((f) => f.toLowerCase());
}
