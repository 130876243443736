import { useEffect, useRef, useState, useContext } from 'react';
import * as React from 'react';
import { isEmpty, keyBy } from 'lodash-es';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { addBookmarkAction, removeBookmarkAction } from '../Bookmarks.actions';
import { CardActionButton } from '../../Card';
import { bookmarksSelector } from '../Bookmarks.selectors';
import { IBookmarksModel } from '../../Models/IBookmarksModel';
import { Announced } from 'office-ui-fabric-react/lib/Announced';
import { Usage, Capability, SubCapability, EventName } from '../../../../../Shared/Types';
import { TelemetryService } from '../../../../../Shared/shared';
import { useUserGlobalConfig } from '../../../../../Shared/Hooks/useUserGlobalConfig';
import { FLIGHTS } from '../../../../../Constants/flightingConstants';

export function BookmarkButton(props: IBookmarksModel): React.ReactElement {
  const didMount = useRef(false);
  const { id, title } = props;
  const { dispatch, useSelector } = useContext(ReduxContext);
  const bookmarks = useSelector(bookmarksSelector);
  const bookmarksData = isEmpty(bookmarks) ? {} : keyBy(bookmarks, 'id');
  const isAlreadyBookmarked = !isEmpty(bookmarksData) && !isEmpty(bookmarksData[id]) ? true : false;
  const [isBookmarkChecked, setIsBookmarkChecked] = useState(isAlreadyBookmarked);
  const [message, setMessage] = useState('');
  const isBookmarkFeatureEnabled = useUserGlobalConfig([FLIGHTS.BOOKMARKS]).bookmark;

  const addBookmark = (): void => {
    dispatch(addBookmarkAction(props));
    const bookMarkEvent: Usage = {
      capability: Capability.MyHub,
      subCapability: SubCapability.Bookmark_Added,
      eventName: EventName.BUTTON_CLICKED,
    };
    TelemetryService.trackEvent(bookMarkEvent, { props });
    setMessage(`${title} bookmark added`);
  };

  const removeBookmark = (): void => {
    dispatch(removeBookmarkAction(props.id));
    const bookMarkEvent: Usage = {
      capability: Capability.MyHub,
      subCapability: SubCapability.Bookmark_Removed,
      eventName: EventName.BUTTON_CLICKED,
    };
    TelemetryService.trackEvent(bookMarkEvent, { props });
    setMessage(`${title} bookmark removed`);
  };

  useEffect(() => {
    if (didMount.current) {
      isBookmarkChecked ? addBookmark() : removeBookmark();
    }
  }, [isBookmarkChecked]);

  useEffect(() => {
    didMount.current = true;
  }, []);

  function clickBookmark(): void {
    setIsBookmarkChecked(!isBookmarkChecked);
  }

  return (
    <>
      {isBookmarkFeatureEnabled ? (
        <>
          <Announced message={message} aria-live="assertive" />
          <CardActionButton
            ariaLabel={isBookmarkChecked ? 'Remove from Bookmarks' : 'Add to Bookmarks'}
            onClick={(): void => clickBookmark()}
            iconProps={{ iconName: isBookmarkChecked ? 'SingleBookmarkSolid' : 'SingleBookmark' }}
          />
        </>
      ) : null}
    </>
  );
}
