import { ActionTypes } from './Footer.action-types';
import { all, put, SimpleEffect, takeLatest } from '@redux-saga/core/effects';
import { receiveFrequentlyUsedLinksAction } from './Footer.actions';
import { FrequentlyUsedLinks } from './Footer.resources';

export function* fetchFrequentlyUsedLinks(): IterableIterator<SimpleEffect<{}, {}>> {
  yield put(receiveFrequentlyUsedLinksAction(FrequentlyUsedLinks));
}

export function* footerSagas(): IterableIterator<{}> {
  yield all([takeLatest(ActionTypes.REQUEST_FREQUENTLY_USED_LINKS, fetchFrequentlyUsedLinks)]);
}
