import {
  ActionTypes,
  IReceiveFrequentlyUsedLinksAction,
  IRequestFrequentlyUsedLinksAction,
} from './Footer.action-types';
import { IFrequentlyUsedLink } from './Footer.types';

export function receiveFrequentlyUsedLinksAction(links: IFrequentlyUsedLink[]): IReceiveFrequentlyUsedLinksAction {
  return {
    type: ActionTypes.RECEIVE_FREQUENTLY_USED_LINKS,
    links: links,
  };
}

export function requestFrequentlyUsedLinksAction(): IRequestFrequentlyUsedLinksAction {
  return {
    type: ActionTypes.REQUEST_FREQUENTLY_USED_LINKS,
  };
}
