import * as React from 'react';
import { CenterContainer } from '../../../../Shared/Components/CenterContainer';
import * as Styled from './ErrorCard.styled';

export function ErrorCard(): React.ReactElement {
  return (
    <CenterContainer>
      <Styled.Image alt="" src="/assets/Dashboard/Card_Error.svg" />
      <Styled.Title>Something went wrong :(</Styled.Title>
      <div>If you require technical support, please report the problem.</div>
    </CenterContainer>
  );
}
