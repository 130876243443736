import * as React from 'react';
import { IErrorScreenProps } from './ErrorScreen.types';
import { PrimaryButton } from 'office-ui-fabric-react';
import { SplashScreen } from '../SplashScreen';

export function ErrorScreen(props: IErrorScreenProps): React.ReactElement {
  const {
    innerPageUse,
    description,
    imagePath,
    title,
    onRefresh,
    showTitle,
    showDescription,
    imageSize,
    noButton,
    titleSize,
  } = props;
  /* Todo Add page tracking after mocking Error screen in tests
    const pageEvent: Usage = {
        capability: Capability.Me,
        subCapability: SubCapability.Error,
        eventName: EventName.PAGE_LOAD
    };
    usePageTracker(pageEvent);
    */
  // eslint-disable-next-line myhub-web-lint/check-usage-events
  function refreshButtonClicked(): void {
    location.reload(true);
  }

  return (
    <SplashScreen
      description={showDescription ? description || 'Please try refreshing the page.' : undefined}
      imagePath={imagePath || '/assets/Shared/Page/Error.svg'}
      innerPageUse={innerPageUse}
      title={showTitle ? title || 'Something went wrong :(' : undefined}
      imageSize={imageSize}
      titleSize
    >
      {!noButton && <PrimaryButton ariaLabel="Refresh" text="Refresh" onClick={onRefresh || refreshButtonClicked} />}
    </SplashScreen>
  );
}
