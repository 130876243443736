/* eslint-disable myhub-web-lint/check-usage-events */
import { source } from 'azure-maps-control';
import { Image, ImageFit, List } from 'office-ui-fabric-react';
import React from 'react';
import { ReactElement } from 'react';
import { PeopleSearchListItem } from './PeopleSearchListItem';
import {
  DefaultImg,
  FeatureImg,
  Features,
  Initials,
  ItemCell,
  ItemContent,
  ItemIndex,
  ItemName,
  ListHeader,
  Locations,
  People,
  TrendingCell,
  TrendingIcon,
  TrendingLabel,
  TrendingListHeader,
} from './SearchList.Styled';

interface IProps {
  trendingResults: {}[];
  features: {}[];
  buildings: {}[];
  people: {}[];
  source: any;
}
export function SearchList(props: IProps): ReactElement {
  const onRenderCell = (item, size, type): JSX.Element => {
    let Img;
    let borderRadius = '';
    if (type === 'feature') {
      Img = FeatureImg;
      borderRadius = '2px';
    } else if (type === 'building' || type === 'people') {
      Img = DefaultImg;
      borderRadius = '16px';
    } else {
      Img = FeatureImg;
    }
    return (
      <ItemCell data-is-focusable={true} onClick={item.onClick}>
        <Img>
          {type === 'people' && item.name ? (
            <Initials>
              {item.name
                .split(' ')
                .map((n) => n[0])
                .join('')
                .slice(0, 2)}
            </Initials>
          ) : (
            <Image style={{ borderRadius }} src={item.thumbnail} width={size} height={size} imageFit={ImageFit.cover} />
          )}
        </Img>
        <ItemContent>
          <ItemName>{item.name}</ItemName>
          <ItemIndex>{item.details}</ItemIndex>
        </ItemContent>
      </ItemCell>
    );
  };

  const onRenderFeaturesCell = (item): JSX.Element => {
    return onRenderCell(item, 34, 'feature');
  };

  const onRenderBuildingsCell = (item): JSX.Element => {
    return onRenderCell(item, 32, 'building');
  };

  const onRenderPeopleCell = (item): JSX.Element => {
    return item.name ? (
      <PeopleSearchListItem key={item.name} item={item} size={32} source={props.source} />
    ) : (
      onRenderCell(item, 32, 'people')
    );
  };

  const onRenderTrendingCell = (item): JSX.Element => {
    return (
      <TrendingCell>
        <TrendingIcon iconName="Trending12" />
        <TrendingLabel>{item.Searchtext}</TrendingLabel>
      </TrendingCell>
    );
  };
  return (
    <div>
      {/*
            <Features>
                <ListHeader>Features</ListHeader>
                <List items={props.features} onRenderCell={onRenderFeaturesCell} />
            </Features>
            */}
      {props.buildings?.length > 0 && props.buildings[0] !== {} && (
        <Locations>
          <ListHeader>Locations</ListHeader>
          <List items={props.buildings} onRenderCell={onRenderBuildingsCell} />
        </Locations>
      )}
      {props.people?.length > 0 && props.people[0] !== {} && (
        <People>
          <ListHeader>People</ListHeader>
          <List items={props.people} onRenderCell={onRenderPeopleCell} />
        </People>
      )}
      {props.trendingResults?.length > 0 && props.trendingResults[0] !== {} && (
        <>
          <TrendingListHeader>Trending on MyHub</TrendingListHeader>
          <List items={props.trendingResults} onRenderCell={onRenderTrendingCell} />{' '}
        </>
      )}
    </div>
  );
}
