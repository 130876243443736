import { IOOFActions, OOFActionTypes, ConnectorActionTypes } from './OOF.action-types';
import { Status } from './OOF.types';
import { IOOFState } from './OOF.types';

export const initialOOFState: IOOFState = {
  BlockCalendarStatus: { error: '', status: Status.NotStarted },
  CancelConnectorStatus: { error: '', status: Status.NotStarted },
  CancelMeetingsStatus: { error: '', status: Status.NotStarted },
  GetEventsStatus: { error: '', status: Status.NotStarted },
  NonBlockingEventStatus: { error: '', status: Status.NotStarted },
  OofOptions: {
    blockCalendar: false,
    cancelConnector: false,
    cancelMeetings: false,
    nonBlockingEvent: false,
    respondToMeetings: false,
    sendEmail: false,
    setAutomaticReply: false,
  },
  OofStatus: Status.NotStarted,
  OofStatusIsVisible: false,
  RespondToMeetingsStatus: { error: '', status: Status.NotStarted },
  SendEmailStatus: { error: '', status: Status.NotStarted },
  SetAutomaticReplyStatus: { error: '', status: Status.NotStarted },
  OofPanelToggle: false,
  ConnectorItineraries: {},
  OofConfig: {},
};

export function oofReducer(prev: IOOFState = initialOOFState, action: IOOFActions): IOOFState {
  switch (action.type) {
    case OOFActionTypes.OOF_PANEL_TOGGLE: {
      return { ...prev, OofPanelToggle: action.isOpen };
    }
    case OOFActionTypes.BLOCK_CALENDAR_STATUS: {
      return { ...prev, BlockCalendarStatus: action.payload };
    }
    case OOFActionTypes.CANCEL_CONNECTOR_STATUS: {
      return { ...prev, CancelConnectorStatus: action.payload };
    }
    case OOFActionTypes.CANCEL_MEETINGS_STATUS: {
      return { ...prev, CancelMeetingsStatus: action.payload };
    }
    case OOFActionTypes.GET_EVENTS_STATUS: {
      return { ...prev, GetEventsStatus: action.payload };
    }
    case OOFActionTypes.NON_BLOCKING_EVENT_STATUS: {
      return { ...prev, NonBlockingEventStatus: action.payload };
    }
    case OOFActionTypes.RESPOND_TO_MEETINGS_STATUS: {
      return { ...prev, RespondToMeetingsStatus: action.payload };
    }
    case OOFActionTypes.SEND_EMAIL_STATUS: {
      return { ...prev, SendEmailStatus: action.payload };
    }
    case OOFActionTypes.SET_AUTOMATIC_REPLY_STATUS: {
      return { ...prev, SetAutomaticReplyStatus: action.payload };
    }
    case OOFActionTypes.UPDATE_OOF_OPTIONS: {
      return { ...prev, OofOptions: action.payload };
    }
    case OOFActionTypes.UPDATE_OOF_STATUS: {
      return { ...prev, OofStatus: action.payload };
    }
    case OOFActionTypes.UPDATE_OOF_STATUS_VISIBILITY: {
      return { ...prev, OofStatusIsVisible: action.payload };
    }
    case ConnectorActionTypes.GET_CONNECTOR_ITINERARIES_SUCCESS:
    case ConnectorActionTypes.GET_CONNECTOR_ITINERARIES_FAILED: {
      return {
        ...prev,
        ConnectorItineraries: action.payload,
      };
    }
    case OOFActionTypes.RECEIVE_OOF_CONFIG: {
      return { ...prev, OofConfig: action.payload };
    }
    default:
      return prev;
  }
}
