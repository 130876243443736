import {
  ActionTypes,
  IDeRegisterNotificationSubscription,
  IRegisterNotificationSubscription,
} from './PushNotification.action-types';
import { IPushNotificationSubscription } from './PushNotification.types';

export function RegisterNotificationSubscription(
  subscription: IPushNotificationSubscription
): IRegisterNotificationSubscription {
  return {
    type: ActionTypes.REGISTER_NOTIFICATION_SUBSCRIPTION,
    payload: subscription,
  };
}

export function DeRegisterNotificationSubscription(
  subscription: PushSubscription
): IDeRegisterNotificationSubscription {
  return {
    type: ActionTypes.DEREGISTER_NOTIFICATION_SUBSCRIPTION,
    payload: subscription,
  };
}
