import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';

export const SubmitButton = styled(PrimaryButton)``;

export const PageContainer = styled.div`
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
`;

export const ParentContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const ChildHalfContainer = styled.div`
  flex: 2;
  margin-left: 20px;
  margin-right: 20px;
`;

export const ChildThirdContainer = styled.div`
  flex: 2;
  margin-left: 10px;
  margin-right: 10px;
`;

export const Indented = styled.div`
  margin-left: 20px;
`;
