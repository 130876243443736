import { breakpointMap } from '../../Styles/Media';

export const hiddenNavStyles = {
  root: {
    selectors: {
      [`@media (max-width: ${breakpointMap.xl - 1}px)`]: {
        left: '-48px',
        position: 'absolute',
      },
    },
  },
};
export const openNavStyles = {
  root: {
    selectors: {
      [`@media (max-width: ${breakpointMap.l}px)`]: {
        left: '0px',
        top: '0px',
        position: 'absolute',
      },
    },
  },
};
