import * as React from 'react';
import { CoherencePanel, CoherencePanelSize } from '@cseo/controls';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import * as Styled from './OOF.Styled';
import { Capability, SubCapability, EventName } from '../../../Shared/Types';
import { TelemetryService } from '../../../Shared/shared';
import { OofPanelToggle, manageOOF, fetchConnectorItineraries } from './OOF.actions';
import { getConnectorItineraries, getOofPanelToggleStatus } from './OOF.selectors';
import {
  ToggleName,
  OOFConfig,
  OOFOptions,
  OOFOptionKeys,
  GraphEmail,
  OOFPayload,
  OOFEvent,
  ConnectorItinerary,
} from './OOF.types';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { useContext, useState } from 'react';
import { Label, TextField, Toggle } from 'office-ui-fabric-react';
import { IBasePickerSuggestionsProps, NormalPeoplePicker } from 'office-ui-fabric-react/lib/Pickers';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { fetchSgUserSearchSelector, fetchSgUserLoadingSelector } from '../../../Pages/Admin/Admin.selectors';
import { LoadingStates } from '../../../Shared/Models/LoadingStates';
import { fetchSgUsersSearchResults } from '../../../Pages/Admin/Admin.actions';
import { ISgUsersModel } from '../../../Pages/Admin/Admin.types';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { IPersonaProps } from '@employee-experience/common/lib/Components/Persona/Persona.types';
type Props = {
  oofOptions: OOFConfig;
};
export function OOF(props: Props): React.ReactElement {
  const { dispatch, useSelector } = useContext(ReduxContext);
  const { telemetryClient } = React.useContext(ComponentContext);
  const oOfPanelToggleStatus = useSelector(getOofPanelToggleStatus);

  React.useEffect(() => {
    if (oOfPanelToggleStatus) {
      TelemetryService.trackEvent({
        capability: Capability.OOF,
        subCapability: SubCapability.OOF_OPENED,
        eventName: EventName.PANEL_OPENED,
      });
    }
  }, [oOfPanelToggleStatus]);
  const oofOptions = props.oofOptions;
  let emailContent = '';
  const paramsAreValid = validateParams(oofOptions);
  if (paramsAreValid) {
    emailContent = oofOptions.events[0].description;
  }

  const {
    blockCalendar,
    cancelConnector,
    cancelMeetings,
    nonBlockingEvent,
    respondToMeetings,
    sendEmail,
    setAutomaticReply,
  } = oofOptions.toggleConfig;

  /* State Variables to hold user's choice to checked unchecked toogle options */
  const [blockCalendarValue, setBlockCalendarValue] = useState<boolean>(false);
  const [cancelConnectorValue, setCancelConnectorValue] = useState<boolean>(false);
  const [cancelMeetingsValue, setCancelMeetingsValue] = useState<boolean>(false);
  const [nonBlockingEventValue, setNonBlockingEventValue] = useState<boolean>(false);
  const [respondToMeetingsValue, setRespondToMeetingsValue] = useState<boolean>(false);
  const [sendEmailValue, setSendEmailValue] = useState<boolean>(false);
  const [sendEmailContent, setSendEmailContent] = useState<string>(emailContent);
  const [showSendEmailContentError, setSendEmailContentError] = useState<boolean>(false);
  const [automaticReplyValue, setAutomaticReplyValue] = useState<boolean>(false);
  const [automaticReplyConent, setAutomaticReplyContent] = useState<string>(emailContent);
  const [showAutomaticReplyContentError, setAutomaticReplyContentError] = useState<boolean>(false);
  const [optionCheckedCount, setOptionCheckedCount] = useState<number>(0);
  /* Display Dialog when setAutomaticReplyValue = true */
  const [hideDialog, setHideDialog] = React.useState<boolean>(true);
  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Replace existing message?',
    closeButtonAriaLabel: 'Close',
    subText: 'The out of office message you submit will replace your existing one.',
  };

  const cancelableConnectorItineraries = React.useRef<ConnectorItinerary[]>([]);
  const connectorItineraries = useSelector(getConnectorItineraries);

  const fetchResults = React.useRef<IPersonaProps[]>([]);
  const resultsLoadingState = React.useRef();
  const searchLoadingStatus: LoadingStates = useSelector(fetchSgUserLoadingSelector);
  const fetchSgUserSearchResults = useSelector(fetchSgUserSearchSelector);
  const [emailRecipients, setEmailRecipients] = React.useState<ISgUsersModel[]>([]);
  const [showEmailRecipientsError, setEmailRecipientsError] = useState<boolean>(false);
  const [showEventRecipientsError, setEventRecipientsError] = useState<boolean>(false);
  const [eventRecipients, setEventRecipients] = React.useState<ISgUsersModel[]>([]);
  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Search Results',
    mostRecentlyUsedHeaderText: 'Suggested Contacts',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading',
    showRemoveButtons: true,
    suggestionsAvailableAlertText: 'People Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested contacts',
  };

  React.useEffect(() => {
    resultsLoadingState.current = searchLoadingStatus;
    if (searchLoadingStatus === LoadingStates.SUCCEEDED) {
      fetchResults.current = fetchSgUserSearchResults;
    }
  });
  React.useEffect(() => {
    if (cancelConnector) {
      dispatch(fetchConnectorItineraries());
    }
  }, [cancelConnector, dispatch]);

  function renderSearchResults(searchText: string): Promise<IPersonaProps[]> {
    if (searchText) {
      return new Promise((resolve, reject) => {
        dispatch(
          fetchSgUsersSearchResults({
            searchText,
            searchCategory: 'users',
            resolve,
            reject,
          })
        );
      }).then(() => {
        return fetchResults.current;
      });
    }
    return [];
  }

  const onItemsChange = (items: ISgUsersModel[], toggleName: ToggleName): void => {
    if (toggleName === OOFOptionKeys.SendEmail) {
      setEmailRecipientsError(false);
      setEmailRecipients(items);
    } else if (toggleName === OOFOptionKeys.NonBlockingEvent) {
      setEventRecipients(items);
      setEmailRecipientsError(false);
    }
  };

  const onTextChange = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue: string,
    toggleName: ToggleName
  ): void => {
    let subCapabilityName = '';
    switch (toggleName) {
      case OOFOptionKeys.SendEmail:
        subCapabilityName = SubCapability.OOF_UPDATE_EMAIL_CONTENT;
        setSendEmailContent(newValue);
        setSendEmailContentError(false);
        break;
      case OOFOptionKeys.SetAutomaticReply:
        subCapabilityName = SubCapability.OOF_UPDATE_AUTOMATIC_REPLY_CONTENT;
        setAutomaticReplyContent(newValue);
        setAutomaticReplyContentError(false);
        break;
      default:
        break;
    }
    TelemetryService.trackEvent(
      {
        capability: Capability.OOF,
        subCapability: subCapabilityName,
        eventName: EventName.TEXT_CHANGED,
      },
      { newValue }
    );
  };

  const updateOptionCheckedCount = (checked: boolean): void => {
    const checkedcount = checked ? optionCheckedCount + 1 : optionCheckedCount - 1;
    setOptionCheckedCount(checkedcount);
  };

  // Handles light dismiss.
  const onPanelClosed = (ev?: React.SyntheticEvent<HTMLElement, Event> | undefined): void => {
    TelemetryService.trackEvent({
      capability: Capability.OOF,
      subCapability: SubCapability.OOF_CLOSED,
      eventName: EventName.PANEL_CLOSED,
    });
    // In the case that the action button is clicked, the event comes as null. So we don't set the state here as we handle it in the OnClick handler instead.
    if (ev) {
      dispatch(OofPanelToggle(false));
    }
  };

  React.useEffect(() => {
    const filterCancelableConnectorItineraries: ConnectorItinerary[] = [];
    const { data: itineraryList, error } = connectorItineraries;
    const itinerariesData = !isEmpty(itineraryList) && !error ? itineraryList : [];

    oofOptions.events?.forEach((event: OOFEvent) => {
      const filterItineraries: ConnectorItinerary[] = itinerariesData.filter(
        (itinerary: ConnectorItinerary) =>
          Date.parse(moment(itinerary.tripDate).format('YYYY-MM-DD')) >=
            Date.parse(moment(event.fromDate).format('YYYY-MM-DD')) &&
          Date.parse(moment(itinerary.tripDate).format('YYYY-MM-DD')) <=
            Date.parse(moment(event.toDate).format('YYYY-MM-DD'))
      );

      filterCancelableConnectorItineraries.push(...filterItineraries);
    });

    cancelableConnectorItineraries.current = filterCancelableConnectorItineraries;
  }, [connectorItineraries, oofOptions.events]);

  function validateSendEmail(): boolean {
    let validateResult = true;
    if (emailRecipients.length === 0) {
      setEmailRecipientsError(true);
      validateResult = false;
    }
    if (sendEmailContent.length === 0) {
      setSendEmailContentError(true);
      validateResult = false;
    }
    return validateResult;
  }

  function validateAll(): boolean {
    let validateResult = true;
    if (sendEmailValue) {
      validateResult = validateSendEmail() && validateResult;
    }
    if (nonBlockingEventValue) {
      if (eventRecipients.length === 0) {
        setEventRecipientsError(true);
        validateResult = false;
      }
    }
    if (automaticReplyValue) {
      if (automaticReplyConent.length === 0) {
        setAutomaticReplyContentError(true);
        validateResult = false;
      }
    }
    return validateResult;
  }

  function onUpdateCalendarButtonClicked(): void {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.OOF_SUBMIT,
      eventName: EventName.BUTTON_CLICKED,
    });

    if (!validateAll()) {
      return;
    }

    const eventRecipientsList: GraphEmail[] = [];
    if (nonBlockingEventValue) {
      for (const recipient of eventRecipients) {
        eventRecipientsList.push({
          address: recipient.alias ? recipient.alias : '',
          name: recipient.text ? recipient.text : '',
        });
      }
    }

    const emailRecipientsList: Array<{ emailAddress: GraphEmail }> = [];
    if (sendEmailValue) {
      for (const recipient of emailRecipients) {
        emailRecipientsList.push({
          emailAddress: {
            address: recipient.alias ? recipient.alias : '',
            name: recipient.text ? recipient.text : '',
          },
        });
      }
    }

    const events: OOFEvent[] = [];
    for (const event of oofOptions.events) {
      event.attendees = eventRecipientsList;
      events.push(event);
    }

    const payload: OOFPayload = {
      automaticReplyMessage: automaticReplyConent,
      cancelConnectorItineraries: cancelableConnectorItineraries.current,
      emailInfo: {
        content: sendEmailContent,
        recipients: emailRecipientsList,
      },
      events,
      options: {
        blockCalendar: blockCalendarValue,
        cancelConnector: cancelConnectorValue,
        cancelMeetings: cancelMeetingsValue,
        nonBlockingEvent: nonBlockingEventValue,
        respondToMeetings: respondToMeetingsValue,
        sendEmail: sendEmailValue,
        setAutomaticReply: automaticReplyValue,
      },
    };
    dispatch(manageOOF(payload));
    /* Close the Panel */
    dispatch(OofPanelToggle(false));
  }

  function validateToggles(toggleConfig: OOFOptions): boolean {
    return (
      toggleConfig.blockCalendar ||
      toggleConfig.cancelConnector ||
      toggleConfig.cancelMeetings ||
      toggleConfig.nonBlockingEvent ||
      toggleConfig.respondToMeetings ||
      toggleConfig.sendEmail ||
      toggleConfig.setAutomaticReply
    );
  }

  function validateParams(oofOptions: OOFConfig | undefined): boolean {
    if (!oofOptions || !oofOptions.events || isEmpty(oofOptions.events)) {
      return false;
    }
    let titlesAreValid = true;
    let descriptionsAreValid = true;
    let fromDatesAreValid = true;
    let toDatesAreValid = true;
    for (const event of oofOptions.events) {
      titlesAreValid = titlesAreValid && !isEmpty(event.title);
      descriptionsAreValid = descriptionsAreValid && !isEmpty(event.description);
      fromDatesAreValid = fromDatesAreValid && moment(event.fromDate, 'YYYY-MM-DD', true).isValid();
      toDatesAreValid = toDatesAreValid && moment(event.toDate, 'YYYY-MM-DD', true).isValid();
    }
    const atLeastOneOptionIsSelected = validateToggles(oofOptions.toggleConfig);
    return titlesAreValid && descriptionsAreValid && fromDatesAreValid && toDatesAreValid && atLeastOneOptionIsSelected;
  }

  function updateToggle(_ev: React.MouseEvent<HTMLElement>, checked: boolean, toggleName: ToggleName): void {
    let subCapabilityName = '';
    switch (toggleName) {
      case OOFOptionKeys.BlockCalendar:
        subCapabilityName = SubCapability.OOF_UPDATE_BLOCK_CALENDAR;
        setBlockCalendarValue(checked);
        break;
      case OOFOptionKeys.CancelConnector:
        subCapabilityName = SubCapability.OOF_UPDATE_CANCEL_CONNECTOR;
        setCancelConnectorValue(checked);
        break;
      case OOFOptionKeys.CancelMeetings:
        subCapabilityName = SubCapability.OOF_UPDATE_CANCEL_MEETINGS;
        setCancelMeetingsValue(checked);
        break;
      case OOFOptionKeys.NonBlockingEvent:
        if (checked && isEmpty(eventRecipients) && !isEmpty(emailRecipients)) {
          setEventRecipients(emailRecipients);
        }
        subCapabilityName = SubCapability.OOF_UPDATE_NON_BLOCKING_EVENT;
        setNonBlockingEventValue(checked);
        break;
      case OOFOptionKeys.RespondToMeetings:
        subCapabilityName = SubCapability.OOF_UPDATE_RESPOND_MEETINGS;
        setRespondToMeetingsValue(checked);
        break;
      case OOFOptionKeys.SendEmail:
        if (checked && isEmpty(emailRecipients) && !isEmpty(eventRecipients)) {
          setEmailRecipients(eventRecipients);
        }
        subCapabilityName = SubCapability.OOF_UPDATE_SEND_EMAIL;
        setSendEmailValue(checked);
        break;
      case OOFOptionKeys.SetAutomaticReply:
        subCapabilityName = SubCapability.OOF_UPDATE_AUTOMATIC_REPLY;
        setAutomaticReplyValue(checked);
        if (checked) {
          setHideDialog(false);
        }
        break;
      default:
        break;
    }
    /* Enable / Disable Add to Calendar Button */
    updateOptionCheckedCount(checked);

    TelemetryService.trackEvent(
      {
        capability: Capability.OOF,
        subCapability: subCapabilityName,
        eventName: EventName.CHECK_BOX_CHANGED,
      },
      { checked }
    );
  }

  function renderReceipents(toggleName: ToggleName, selectedItems: IPersonaProps[]): React.ReactNode {
    const showError =
      (showEmailRecipientsError && toggleName === OOFOptionKeys.SendEmail) ||
      (showEventRecipientsError && toggleName === OOFOptionKeys.NonBlockingEvent);
    return (
      <Styled.PeoplePickerContainer data-testid={`${toggleName}-PeoplePickerContainer`}>
        <Label>Choose recipient(s)</Label>
        <NormalPeoplePicker
          onResolveSuggestions={(filter): Promise<IPersonaProps[]> => renderSearchResults(filter)}
          pickerSuggestionsProps={suggestionProps}
          className={'ms-PeoplePicker ms-bgColor-white'}
          key={'users'}
          styles={showError ? Styled.normalPickerStyles : {}}
          removeButtonAriaLabel={'Remove'}
          disabled={false}
          onChange={(items): void => onItemsChange(items, toggleName)}
          selectedItems={selectedItems}
          resolveDelay={300}
        />
        {showError && (
          <Styled.RowText data-testid={`${toggleName}-EmailRecepientError`}>
            <Styled.FontIconContainer>
              <Styled.FailedStatusIcon iconName="ErrorBadge" />
            </Styled.FontIconContainer>
            <Styled.StatusContainer>Please add recipients to update your calendar.</Styled.StatusContainer>
          </Styled.RowText>
        )}
      </Styled.PeoplePickerContainer>
    );
  }

  function renderChildren(
    headerText: string,
    subHeaderText: string,
    toggleName: ToggleName,
    toggleValue: boolean
  ): React.ReactNode {
    return (
      <Styled.Container data-testid={`${toggleName}-Container`}>
        <Styled.HeaderContainer>
          <Styled.HeaderText>{headerText}</Styled.HeaderText>
          <Styled.TogglerContainer data-testid={`${toggleName}-ToggleContainer`}>
            <Toggle
              checked={toggleValue}
              onChange={(event, checked): void => updateToggle(event, checked, toggleName)}
              data-testid={`${toggleName}-Toggle`}
              ariaLabel={`${toggleName}`}
            />
          </Styled.TogglerContainer>
        </Styled.HeaderContainer>
        <Styled.SubHeaderContainer>
          <Styled.SubHeaderText>{subHeaderText}</Styled.SubHeaderText>
        </Styled.SubHeaderContainer>
      </Styled.Container>
    );
  }

  return (
    <>
      <CoherencePanel
        panelSize={CoherencePanelSize.medium}
        titleText={'Clear your calendar'}
        isOpen={true}
        isLightDismiss={false}
        onDismiss={onPanelClosed}
        hasCloseButton={true}
        telemetryHook={telemetryClient}
        isFooterAtBottom={true}
        onRenderFooter={(): JSX.Element => (
          <PrimaryButton
            ariaLabel="Update calendar"
            disabled={optionCheckedCount === 0}
            onClick={onUpdateCalendarButtonClicked}
            text="Update calendar"
            data-testid="updateCalendar-Button"
          />
        )}
        closeButtonAriaLabel="Close"
        data-testid="clearYourCalendar-CoherencePanel"
      >
        {!paramsAreValid ? (
          <Styled.PanelContent data-testid={`invalidConfig`}>
            <Label>The configuration used for this page functionality is invalid.</Label>
          </Styled.PanelContent>
        ) : (
          <Styled.PanelContent>
            {setAutomaticReply
              ? renderChildren(
                  'Turn on automatic replies',
                  'When you are out of the office or unavailable to respond to email. Your message will appear in meeting responses and calendar.',
                  OOFOptionKeys.SetAutomaticReply,
                  automaticReplyValue
                )
              : null}
            {automaticReplyValue ? (
              <>
                <TextField
                  label="Automatic reply message"
                  multiline
                  autoAdjustHeight
                  rows={3}
                  errorMessage={
                    showAutomaticReplyContentError ? (
                      <Styled.RowText data-testid={`${OOFOptionKeys.SetAutomaticReply}-TextFieldError`}>
                        <Styled.FontIconContainer>
                          <Styled.FailedStatusIcon iconName="ErrorBadge" />
                        </Styled.FontIconContainer>
                        <Styled.StatusContainer>Email Content is required</Styled.StatusContainer>
                      </Styled.RowText>
                    ) : undefined
                  }
                  placeholder="I'm out of office today. Sent via MyHub."
                  defaultValue={automaticReplyConent}
                  onChange={(event, newValue): void => onTextChange(event, newValue, OOFOptionKeys.SetAutomaticReply)}
                  data-testid={`${OOFOptionKeys.SetAutomaticReply}-TextField`}
                />
              </>
            ) : null}
            {cancelMeetings
              ? renderChildren(
                  'Cancel meetings',
                  'Where you are the organizer.',
                  OOFOptionKeys.CancelMeetings,
                  cancelMeetingsValue
                )
              : null}
            {respondToMeetings
              ? renderChildren(
                  'Reply “Tentative” to meeting invites',
                  'On the days specified.',
                  OOFOptionKeys.RespondToMeetings,
                  respondToMeetingsValue
                )
              : null}
            {blockCalendar
              ? renderChildren(
                  'Block your calendar',
                  'As an “all day event” for the time you’re away.',
                  OOFOptionKeys.BlockCalendar,
                  blockCalendarValue
                )
              : null}
            {nonBlockingEvent
              ? renderChildren(
                  'Send a non-blocking event',
                  'To the recipients you choose, showing when you’re away',
                  OOFOptionKeys.NonBlockingEvent,
                  nonBlockingEventValue
                )
              : null}
            {nonBlockingEventValue ? renderReceipents(OOFOptionKeys.NonBlockingEvent, eventRecipients) : null}

            {sendEmail
              ? renderChildren(
                  'Send an email',
                  'Draft and send a message directly to recipients you choose.',
                  OOFOptionKeys.SendEmail,
                  sendEmailValue
                )
              : null}
            {sendEmailValue ? (
              <>
                {renderReceipents(OOFOptionKeys.SendEmail, emailRecipients)}
                <TextField
                  data-testid={`${OOFOptionKeys.SendEmail}-TextField`}
                  label="Edit email"
                  multiline
                  autoAdjustHeight
                  rows={3}
                  errorMessage={
                    showSendEmailContentError ? (
                      <Styled.RowText data-testid={`${OOFOptionKeys.SendEmail}-TextFieldError`}>
                        <Styled.FontIconContainer>
                          <Styled.FailedStatusIcon iconName="ErrorBadge" />
                        </Styled.FontIconContainer>
                        <Styled.StatusContainer>Email Content is required</Styled.StatusContainer>
                      </Styled.RowText>
                    ) : undefined
                  }
                  placeholder="Type your email message here"
                  defaultValue={sendEmailContent}
                  onChange={(event, newValue): void => onTextChange(event, newValue, OOFOptionKeys.SendEmail)}
                />
              </>
            ) : null}

            {cancelConnector && cancelableConnectorItineraries.current.length > 0
              ? renderChildren(
                  'Cancel your connector trips',
                  'Cancel confirmed and standby reservations',
                  OOFOptionKeys.CancelConnector,
                  cancelConnectorValue
                )
              : null}

            <Dialog
              hidden={hideDialog}
              onDismiss={(): void => setHideDialog(true)}
              dialogContentProps={dialogContentProps}
              data-testid={`${OOFOptionKeys.SetAutomaticReply}-Dialog`}
            >
              <DialogFooter>
                <PrimaryButton
                  onClick={(): void => {
                    setAutomaticReplyValue(false);
                    setHideDialog(true);
                    updateOptionCheckedCount(false);
                  }}
                  text="Cancel"
                  ariaLabel="Cancel"
                  data-testid="Cancel-Button"
                />
                <DefaultButton
                  onClick={(): void => {
                    setAutomaticReplyValue(true);
                    setHideDialog(true);
                  }}
                  text="Yes"
                  ariaLabel="Yes"
                  data-testid="Yes-Button"
                />
              </DialogFooter>
            </Dialog>
          </Styled.PanelContent>
        )}
      </CoherencePanel>
    </>
  );
}
