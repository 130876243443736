import { IExploreActions, ActionTypes } from './MapView.action-types';
import { IExploreState } from './MapView.types';
import { LoadingStates } from '../../../Shared/Models/LoadingStates';

export const initialExploreState: IExploreState = {
  exploreLoadingStatus: LoadingStates.NOT_STARTED,
  exploreBuildings: {},
  buildingDetailsLoadingStatus: LoadingStates.NOT_STARTED,
  exploreBuildingDetails: {},
  error: '',
};

// Three actions are enough i.e request, success and failed. recieved is the same as success so is not needed.
export function exploreReducer(prev: IExploreState = initialExploreState, action: IExploreActions): IExploreState {
  switch (action.type) {
    case ActionTypes.GET_EXPLORE_BUILDINGS: {
      return {
        ...prev,
        exploreLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }

    case ActionTypes.GET_EXPLORE_BUILDINGS_SUCCESS: {
      return {
        ...prev,
        exploreLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }
    case ActionTypes.GET_EXPLORE_BUILDINGS_FAILED: {
      return {
        ...prev,
        exploreLoadingStatus: LoadingStates.FAILED,
      };
    }
    case ActionTypes.RECEIVE_BUILDINGS: {
      return {
        ...prev,
        exploreBuildings: action.buildings,
        exploreLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }

    case ActionTypes.GET_BUILDING_DETAILS: {
      return {
        ...prev,
        buildingDetailsLoadingStatus: LoadingStates.STARTED,
      };
    }

    case ActionTypes.GET_BUILDING_DETAILS_SUCCESS: {
      return {
        ...prev,
        buildingDetailsLoadingStatus: LoadingStates.SUCCEEDED,
        error: '',
      };
    }
    case ActionTypes.GET_BUILDINGS_DETAILS_FAILED: {
      return {
        ...prev,
        error: action.error,
        buildingDetailsLoadingStatus: LoadingStates.FAILED,
      };
    }
    case ActionTypes.RECEIVE_BUILDING_DETAILS: {
      return {
        ...prev,
        exploreBuildingDetails: action.buildingDetails,
        buildingDetailsLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }

    default:
      return prev;
  }
}
