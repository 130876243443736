import { IMyHubAppState } from './../../App.types';
import { IDashboardState } from './Dashboard.types';
import { DashboardReducerName } from './Dashboard.resources';
import { initialDashboardState } from './Dashboard.reducer';
import { createSelector } from 'reselect';

export const cardsCountSelector = createSelector(
  (state: IMyHubAppState) => state[DashboardReducerName] ?? initialDashboardState,
  (dashboardState: IDashboardState) => dashboardState.cards.length
);

export const cardsSelector = createSelector(
  (state: IMyHubAppState) => state[DashboardReducerName] ?? initialDashboardState,
  (dashboardState: IDashboardState) => dashboardState.cards
);

export const catalogItemsCountSelector = createSelector(
  (state: IMyHubAppState) => state[DashboardReducerName] ?? initialDashboardState,
  (dashboardState: IDashboardState) => dashboardState.catalogItems.length
);

export const catalogItemsLoadingStatusSelector = createSelector(
  (state: IMyHubAppState) => state[DashboardReducerName] ?? initialDashboardState,
  (dashboardState: IDashboardState) => dashboardState.catalogItemsLoadingStatus
);

export const catalogItemsSelector = createSelector(
  (state: IMyHubAppState) => state[DashboardReducerName] ?? initialDashboardState,
  (dashboardState: IDashboardState) => dashboardState.catalogItems
);

export const isCatalogOpenSelector = createSelector(
  (state: IMyHubAppState) => state[DashboardReducerName] ?? initialDashboardState,
  (dashboardState: IDashboardState) => dashboardState.isCatalogOpen
);
