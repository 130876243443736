import { FontWeights } from '@uifabric/styling';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';

export const Content = styled.div`
  width: 100%;
`;

export const PanelContent = styled.div`
  flex-direction: column;
  display: flex;
`;

export const Container = styled.div`
  display: block;
  padding: 12px 0 11px 0;
  width: 100%;
  height: 100%;
  border: none;
  text-align: left;
`;
export const RowText = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
`;
export const StatusContainer = styled.div`
  margin-left: 5px;
  font-size: 14px;
  text-align: left;
  color: #a80000;
`;
export const FontIconContainer = styled.div`
  font-family: Fabric MDL2 Assets;
  line-height: 22px;
  text-align: center;
`;

export const FailedStatusIcon = styled(FontIcon)`
  color: #a80000;
`;

export const ErrorContainer = styled.div`
  color: #a80000;
`;

export const HeaderText = styled.div`
  font-weight: ${FontWeights.semibold};
  display: inline-block;
`;

export const SubHeaderText = styled.div`
  line-height: 20px; /* height is 2x line-height, so two lines will display */
  overflow: hidden; /* prevents extra lines from being visible */
  padding-right: 32px; /* to stop overflow of the text at the right*/
  padding-top: 6px;
`;
export const SubHeaderContainer = styled.div`
  align-self: baseline;
  display: inline-block;
  width: 95%;
`;
export const HeaderContainer = styled.div`
  width: 100%;
`;
export const TogglerContainer = styled.div`
  display: inline-block;
  float: right;
`;

export const AddCalendarButton = styled(PrimaryButton)``;

export const PeoplePickerContainer = styled.div`
  width: 100%;
`;

export const normalPickerStyles = {
  input: {
    border: '1px solid #a80000',
  },
};
