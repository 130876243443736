import { Image, ImageFit } from 'office-ui-fabric-react';
import React, { ReactElement, useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultImg, Initials, ItemCell, ItemContent, ItemIndex, ItemName } from './SearchList.Styled';
import { httpClient } from '../../../../ShellWithStore';
import { ApiHelper } from '../../../../Shared/Helpers/ApiHelper';
import { ImageHelper } from '../../../../Shared/Helpers/ImageHelper';

interface IProps {
  item: { name: string; onClick: () => void; details: string; thumbnail: {} };
  size: number;
  source: any;
}

export function PeopleSearchListItem(props: IProps): ReactElement {
  const upn = props.item.details;
  const url = `/msgraph/v1.0/users/${upn}/photo/$value`;
  const [photo, setPhoto] = useState<{} | undefined>(undefined);
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    if (!loading) {
      setLoading(true);
      httpClient
        .request({
          url: __API__.myMsftApim.resourceUrl + url,
          resource: __API__.myMsftApim.resourceId,
          header: ApiHelper.getMyMsftApiHeaders(),
          responseType: 'arraybuffer',
          cancelToken: props.source.token,
        })
        .then((response) => {
          const { data } = response;
          const imageUrl = ImageHelper.encodeImage(data);
          setPhoto({ uri: imageUrl });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [props]);

  useEffect(() => {
    return () => {
      setPhoto(undefined);
      setLoading(false);
    };
  }, []);
  return (
    <ItemCell data-is-focusable={true} onClick={props.item.onClick}>
      <DefaultImg>
        {loading === false && !photo ? (
          <Initials>
            {props.item.name
              .split(' ')
              .map((n) => n[0])
              .join('')
              .slice(0, 2)}
          </Initials>
        ) : (
          <Image
            style={{ borderRadius: '16px' }}
            src={photo ? photo.uri : null}
            width={props.size}
            height={props.size}
            imageFit={ImageFit.cover}
            loading={loading}
          />
        )}
      </DefaultImg>
      <ItemContent>
        <ItemName>{props.item.name}</ItemName>
        <ItemIndex>{props.item.details}</ItemIndex>
      </ItemContent>
    </ItemCell>
  );
}
