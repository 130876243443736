/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import { EventCard } from '../Events/EventCard/EventCard';
import { NewsCard } from '../News/NewsCard/NewsCard';
import { isEmpty } from 'lodash-es';
import { ReactElement, useContext, useEffect } from 'react';
import { CardContainer } from './../Card';
import { SplashScreen } from '../../../../Shared/Components/SplashScreen';
import { ErrorScreen } from '../../../../Shared/Components/ErrorScreen';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { bookmarksSelector, bookmarksLoadingStatusSelector } from './Bookmarks.selectors';
import { LoadingStates } from '../../../../Shared/Models/LoadingStates';
import { LoadingScreen } from '../../../../Shared/Components/LoadingScreen';
import { requestBookmarksDataAction } from './Bookmarks.actions';
import { Usage, Capability, SubCapability, EventName } from '../../../../Shared/Types';
import { usePageTracker } from '../../../../Shared/Hooks/usePageTracker';
import {
  ICommonBookmarkModel,
  IEventBookmarkModel,
  IMicrosoftBookmarksModel,
  INewsBookmarksModel,
} from '../Models/IBookmarksModel';
import { MicrosoftLifeCard } from '../MicrosoftLife/MicrosoftLifeCard';
import { EVENT_FEED_TYPE, MICROSOFT_LIFE_FEED_TYPE, NEWS_FEED_TYPE } from '../Feed.resources';
import { Page, PageHeadingContainer, PageHeading } from '../../../../Styles/Page';

const renderNewsCard = (item: INewsBookmarksModel): ReactElement => {
  return (
    <NewsCard
      key={`${item.title}${item.link}`}
      news={{
        Description: item.description,
        FeedSponsorTitle: item.feedSponsorTitle,
        Images: [item.images],
        Link: item.link,
        PublicationDate: item.publicationDate,
        Title: item.title,
      }}
      showBookmark={false}
    />
  );
};

const renderEventCard = (item: IEventBookmarkModel): ReactElement => {
  return (
    <EventCard
      key={`${item.title}${item.link}`}
      event={{
        Description: item.description,
        EndDate: item.endDate,
        Link: item.link,
        Location: item.location,
        MswAudience: item.mswAudience,
        ShortDescription: item.shortDescription,
        StartDate: item.startDate,
        Title: item.title,
        MoreInfoURL: item.registrationUrl,
      }}
      showBookmark={false}
    />
  );
};

const renderMicrosoftLifeCard = (item: IMicrosoftBookmarksModel): ReactElement => {
  return (
    <MicrosoftLifeCard
      key={`${item.title}${item.link}`}
      post={{
        post_id: item.id,
        network: item.extended?.network,
        post_time: item.extended?.postTime,
        text: item.title,
        photos: [
          {
            m: item.images,
          },
        ],
        user_name: item.extended?.userName,
        permalink: item.link,
        feedsType: item.feedsType,
      }}
      showBookmark={false}
    />
  );
};

const renderCard = (item: ICommonBookmarkModel): ReactElement | null => {
  switch (item.feedsType) {
    case NEWS_FEED_TYPE:
      return renderNewsCard(item);
    case EVENT_FEED_TYPE:
      return renderEventCard(item);
    case MICROSOFT_LIFE_FEED_TYPE:
      return renderMicrosoftLifeCard(item);
    default:
      return null;
  }
};

export function Bookmarks(): ReactElement {
  const { dispatch, useSelector } = useContext(ReduxContext);
  const bookmarks = useSelector(bookmarksSelector);
  const bookmarksLoadingStatus = useSelector(bookmarksLoadingStatusSelector);
  const pageEvent: Usage = {
    capability: Capability.MyHub,
    subCapability: SubCapability.Bookmark,
    eventName: EventName.PAGE_LOAD,
  };
  usePageTracker(pageEvent);
  useEffect(() => {
    dispatch(requestBookmarksDataAction());
  }, [dispatch]);
  window.scrollTo(0, 0);
  if (bookmarksLoadingStatus === LoadingStates.NOT_STARTED || bookmarksLoadingStatus === LoadingStates.STARTED) {
    return <LoadingScreen innerPageUse={true} />;
  } else if (bookmarksLoadingStatus === LoadingStates.FAILED) {
    return <ErrorScreen innerPageUse={true} />;
  } else if (isEmpty(bookmarks)) {
    return (
      <SplashScreen
        description="You can bookmark your favorite news items and events."
        imagePath="/assets/Shared/Page/Empty.svg"
        innerPageUse={true}
        title="Your bookmarks will appear here"
      />
    );
  } else {
    return (
      <Page>
        <PageHeadingContainer>
          <PageHeading tabIndex={0} aria-label="Bookmarks">
            Bookmarks
          </PageHeading>
        </PageHeadingContainer>
        <CardContainer>{bookmarks && bookmarks.map((item: ICommonBookmarkModel) => renderCard(item))}</CardContainer>
      </Page>
    );
  }
}
