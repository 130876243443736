import * as React from 'react';
import * as Styled from './EventCard.styled';
import {
  Card,
  CardBody,
  CardFooter,
  CardFooterItem,
  CardMetadata,
  CardMetadataContainer,
  CardMetadataIcon,
  CardMetadataText,
  CardTag,
  CardActionButton,
} from './../../Card';
import { IEventCardProps } from './EventCard.types';
import moment from 'moment';
import TextEllipsis from 'react-text-ellipsis';
import { StripHtmlTagsRegEx } from '../../Feed.resources';
import { Link as LinkTag } from './../../../../../Styles/Link';
import { BookmarkButton } from '../../Bookmarks/BookmarkButton/BookmarkButton';
import OutlookHelper, { Event } from '../../../../../Shared/Helpers/OutlookHelper';
const Entities = require('entities');
import { IBookmarksModel } from '../../Models/IBookmarksModel';
import { TelemetryService } from '../../../../../Shared/shared/TelemetryService';
import { Capability, SubCapability, EventName } from '../../../../../Shared/Types';

export function EventCard(props: IEventCardProps): React.ReactElement {
  const { Title, ShortDescription, Location, Link, Description, MswAudience, MoreInfoURL, FeedsType, Id } = props.event;
  const StartDate = new Date(props.event.StartDate);
  const EndDate = new Date(props.event.EndDate);
  let formattedDate = '';
  if (moment(StartDate).isValid() && moment(EndDate).isValid()) {
    if (StartDate.getFullYear() === EndDate.getFullYear()) {
      if (StartDate.getMonth() === EndDate.getMonth()) {
        if (StartDate.getDate() === EndDate.getDate()) {
          formattedDate =
            moment(StartDate).format('DD') +
            ' ' +
            moment(EndDate).format('MMM') +
            ', ' +
            moment(EndDate).format('YYYY');
        } else {
          formattedDate =
            moment(StartDate).format('DD') +
            '-' +
            moment(EndDate).format('DD') +
            ' ' +
            moment(EndDate).format('MMM') +
            ', ' +
            moment(EndDate).format('YYYY');
        }
      } else {
        formattedDate =
          moment(StartDate).format('DD MMM') +
          '-' +
          moment(EndDate).format('DD MMM') +
          ' ' +
          moment(EndDate).format('YYYY');
      }
    } else {
      formattedDate = moment(StartDate).format('DD MMM YYYY') + '-' + moment(EndDate).format('DD MMM YYYY');
    }
    formattedDate += ' ' + moment(StartDate).format('hh:mm A');
  }

  function addToCalendarClicked(): void {
    const event: Event = {
      title: Title,
      description: ShortDescription,
      location: Location,
      start: StartDate,
      end: EndDate,
    };
    TelemetryService.trackEvent(
      {
        capability: Capability.MyHub,
        subCapability: SubCapability.Events_AddToCalender,
        eventName: EventName.BUTTON_CLICKED,
      },
      { event }
    );
    OutlookHelper.createEvent(event);
  }

  const event: IBookmarksModel = {
    description: Description,
    endDate: EndDate ? EndDate.toString() : undefined,
    link: Link,
    location: Location,
    mswAudience: MswAudience,
    shortDescription: ShortDescription,
    startDate: StartDate ? StartDate.toString() : undefined,
    title: Title,
    registrationUrl: MoreInfoURL,
    feedsType: FeedsType,
    id: Id,
  };

  return (
    <Card>
      <CardBody tabIndex={0}>
        <Styled.TextContainer>
          {Title && (
            <Styled.Title>
              <TextEllipsis lines={2}>{Entities.decodeHTML(Title).replace(StripHtmlTagsRegEx, '')}</TextEllipsis>
            </Styled.Title>
          )}
          {ShortDescription && (
            <Styled.Desc>
              <TextEllipsis lines={8}>
                {Entities.decodeHTML(ShortDescription).replace(StripHtmlTagsRegEx, '')}
              </TextEllipsis>
            </Styled.Desc>
          )}
          {(formattedDate || Location) && (
            <CardMetadataContainer>
              {formattedDate && (
                <CardMetadata>
                  <CardMetadataIcon iconName="Calendar" />
                  <CardMetadataText>
                    <TextEllipsis lines={1}>{formattedDate}</TextEllipsis>
                  </CardMetadataText>
                </CardMetadata>
              )}
              {Location && Location !== '-' && (
                <CardMetadata>
                  <CardMetadataIcon iconName="MapPin" />
                  <CardMetadataText>
                    <TextEllipsis lines={1}>{Location}</TextEllipsis>
                  </CardMetadataText>
                </CardMetadata>
              )}
            </CardMetadataContainer>
          )}
        </Styled.TextContainer>
      </CardBody>
      <CardFooter>
        <CardFooterItem>
          {Link && (
            <LinkTag href={Link} target="_blank">
              Go to event
            </LinkTag>
          )}
        </CardFooterItem>
        <CardFooterItem>
          <CardTag tabIndex={0}>Events</CardTag>
          {formattedDate && (
            <CardActionButton
              ariaLabel="Add to calendar"
              iconProps={{ iconName: 'Calendar' }}
              onClick={addToCalendarClicked}
            ></CardActionButton>
          )}
          {props.showBookmark ? <BookmarkButton {...event}></BookmarkButton> : null}
        </CardFooterItem>
      </CardFooter>
    </Card>
  );
}
