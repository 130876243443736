import { FontWeights } from 'office-ui-fabric-react';

export const ImageInfoText = styled.div`
  text-align: center;
  margin-top: 32px;
`;

export const HeaderText = styled.div`
  margin-top: 24px;
  font-size: 18px;
  font-weight: ${FontWeights.semibold};
`;

export const DetailText = styled.div`
  margin-top: 8px;
  font-size: 12px;
`;
