export const Image = styled.img`
  margin-bottom: 24px;
`;

export const headerStyle = {
  marginBottom: 0,
  lineHeight: '32px',
};

export const subHeadingStyle = {
  marginTop: '10px',
  marginBottom: '20px',
  lineHeight: '24px',
  fontSize: '18px',
};
