import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';

export const SubmitButton = styled(PrimaryButton)``;

export const dropdownStyles = {
  dropdown: { width: '240px' },
};

export const comboboxStyles = {
  container: {
    maxWidth: '240px',
  },
};

export const textfieldStyles = {
  fieldGroup: { height: '330px' },
};
