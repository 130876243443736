import { ActionTypes } from './ReportProblem.action-types';
import { IReportProblemState } from './ReportProblem.types';
import { LoadingStates } from '../../../Shared/Models/LoadingStates';
import { ITicketsModel } from '../ReportProblem/Models/ITicketsModel';
export const initialReportProblemState = {
  openTickets: {} as ITicketsModel[],
  submittedTicket: {},
  submitTicketLoadingStatus: LoadingStates.NOT_STARTED,
  openTicketsLoadingStatus: LoadingStates.NOT_STARTED,
};
export function reportProblemReducer(prev = initialReportProblemState, action): IReportProblemState {
  switch (action.type) {
    case ActionTypes.FETCH_TICKET_SUCCEEDED: {
      return {
        ...prev,
        openTickets: action.payload,
        openTicketsLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }
    case ActionTypes.FETCH_TICKET_FAILED: {
      return {
        ...prev,
        openTicketsLoadingStatus: LoadingStates.FAILED,
      };
    }
    case ActionTypes.FETCH_OPEN_TICKET: {
      return {
        ...prev,
        openTicketsLoadingStatus: LoadingStates.STARTED,
      };
    }
    case ActionTypes.SUBMIT_TICKET_FAILED: {
      return {
        ...prev,
        submittedTicket: action.payload,
        submitTicketLoadingStatus: LoadingStates.FAILED,
      };
    }
    case ActionTypes.SUBMIT_TICKET_SUCCEEDED: {
      return {
        ...prev,
        submitTicketLoadingStatus: LoadingStates.SUCCEEDED,
      };
    }
    case ActionTypes.SUBMIT_TICKET: {
      return {
        ...prev,
        submitTicketLoadingStatus: LoadingStates.STARTED,
      };
    }
    case ActionTypes.RESET_TICKET: {
      return {
        ...prev,
        submittedTicket: {},
        submitTicketLoadingStatus: LoadingStates.NOT_STARTED,
      };
    }
    default: {
      return prev;
    }
  }
}
