import * as React from 'react';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { Faq } from './Components/Faq';
import { FaqDetail } from './Components/FaqDetail';
import { ReportProblem } from '../Header/ReportProblem/ReportProblem';
import { TelemetryService } from '../../Shared/shared';
import { Capability, SubCapability, EventName } from '../../Shared/Types';
import { SearchBox } from 'office-ui-fabric-react';
import { FeaturedAreas } from './Components/FeaturedAreas';
import { FaqList } from './Components/FaqList';
import { IQnaDocumentModel } from './Models/IQnaDocumentModel';
import { faqSelector } from './Help.selector';
import { useFeaturedFlights } from '../../Shared/Hooks/useFeaturedFlights';
import { getFaqData } from './Help.actions';
import { isEmpty } from 'lodash-es';
import { BackButtonText } from '../../Shared/Resources/Panel.resources';
import {
  PanelHeader,
  PanelSection,
  PanelSectionHeader,
  PanelSectionLink,
  PanelSectionSeparator,
} from '../../Styles/Panel';

export function HelpScreen(): React.ReactElement {
  const { dispatch, useSelector } = React.useContext(ReduxContext);
  const helpSelector = useSelector(faqSelector);
  const [isPanelOpen, setPanelState] = React.useState(false);
  const [faqItemIndex, setItemIndex] = React.useState(0);
  const [faqPanel, setFaqPanel] = React.useState(false);
  const [isReportPanelOpen, setReportPanelState] = React.useState(false);
  const [isFaqListOpen, setListPanelState] = React.useState(false);
  const [filteredFaq, setfilteredFaq] = React.useState(helpSelector.faqData);
  const [category, setCategory] = React.useState('');
  const featureFlights = useFeaturedFlights();
  React.useEffect(() => {
    if (isEmpty(helpSelector.faqData)) {
      dispatch(getFaqData(featureFlights));
    }
  }, [dispatch]);
  const getDisplayCategories = (resFaqs): IQnaDocumentModel[] => {
    if (resFaqs && resFaqs.length > 0) {
      const DisplayCategories = [];
      resFaqs.map((faq) =>
        faq.metadata.map((metadata) =>
          metadata.name.includes('displaycategory') && DisplayCategories.indexOf(metadata.value) === -1
            ? DisplayCategories.push(metadata.value)
            : ''
        )
      );
      return DisplayCategories.sort((a, b) => a.localeCompare(b));
    }
    return [];
  };

  const displayCategories = getDisplayCategories(helpSelector.faqData);
  const togglePanel = (): void => {
    if (!isPanelOpen)
      TelemetryService.trackEvent({
        capability: Capability.MyHub,
        subCapability: SubCapability.Faq_Details,
        eventName: EventName.PANEL_OPENED,
      });
    setPanelState(!isPanelOpen);
  };
  const toggleFaqList = (): void => {
    if (!isFaqListOpen)
      TelemetryService.trackEvent({
        capability: Capability.MyHub,
        subCapability: SubCapability.Faq_List,
        eventName: EventName.PANEL_OPENED,
      });

    setListPanelState(!isFaqListOpen);
  };
  const toggleFaqPanel = (keyCode?): void => {
    if (keyCode == null || (keyCode != 9 && keyCode != 16 && keyCode != 38 && keyCode != 40)) {
      if (!faqPanel)
        TelemetryService.trackEvent({
          capability: Capability.MyHub,
          subCapability: SubCapability.Faq,
          eventName: EventName.PANEL_OPENED,
        });
      setFaqPanel(!faqPanel);
    }
  };

  const toggleReportProblemPanel = (): void => {
    setReportPanelState(!isReportPanelOpen);
  };

  const setFaqItem = (itemIndex: number): void => {
    setItemIndex(itemIndex);
    togglePanel();
  };
  function featuredAreaClicked(category: string, filteredFaq: IQnaDocumentModel[]): void {
    TelemetryService.trackTrace('Featured Area Clicked', 4, category);
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Faq_Featured_Areas + '.' + category,
      eventName: EventName.LINK_CLICKED,
    });
    setCategory(category);
    setfilteredFaq(filteredFaq);
    toggleFaqList();
  }

  const filterFeaturedFaq = (faqs: Faq): IQnaDocumentModel[] => {
    if (faqs && faqs.length > 0) {
      return faqs.filter((faq) =>
        faq?.metadata?.filter((metadata) => metadata.name === 'tags')[0]?.value.includes('featured')
      );
    }
    return [];
  };

  const featuredFaqs = filterFeaturedFaq(helpSelector.faqData);
  return (
    <div>
      <PanelHeader>Frequently asked questions</PanelHeader>
      <SearchBox
        placeholder="Search"
        autoFocus={false}
        onKeyDownCapture={(e: React.KeyboardEvent<HTMLInputElement>): void => toggleFaqPanel(e.keyCode)}
        onChange={toggleFaqPanel}
      />
      <PanelSection>
        <PanelSectionHeader>Top questions</PanelSectionHeader>
        {featuredFaqs &&
          featuredFaqs.slice(0, 5).map((qnaItem, index) => {
            return (
              <PanelSectionLink key={index} onClick={(): void => setFaqItem(index)}>
                {qnaItem?.questions[0]}
              </PanelSectionLink>
            );
          })}
      </PanelSection>

      <PanelSectionSeparator />

      <PanelSection>
        <PanelSectionHeader>Featured areas</PanelSectionHeader>
        {helpSelector.faqData ? (
          <FeaturedAreas
            categories={displayCategories.slice(0, 7)}
            faqs={helpSelector.faqData}
            onClicked={featuredAreaClicked}
          />
        ) : null}
        <PanelSectionLink onClick={(): void => toggleFaqPanel()}>Show all</PanelSectionLink>
      </PanelSection>

      <PanelSectionSeparator />

      <PanelSection>
        <PanelSectionHeader>Need More Help?</PanelSectionHeader>
        <PanelSectionLink onClick={(): void => toggleReportProblemPanel()}>Report a problem</PanelSectionLink>
      </PanelSection>

      <PanelSectionSeparator />

      <PanelSection>
        <PanelSectionHeader>About MyHub</PanelSectionHeader>
        <PanelSectionLink
          href="https://microsoft.sharepoint.com/teams/MicrosoftMyHub/SitePages/Home.aspx"
          target="_blank"
          onClick={(): void =>
            TelemetryService.trackEvent({
              capability: Capability.MyHub,
              subCapability: SubCapability.Faq_MyHub_Link,
              eventName: EventName.LINK_CLICKED,
            })
          }
        >
          About
        </PanelSectionLink>
        <PanelSectionLink
          href="https://msdpn.azurewebsites.net/default?LID=62"
          target="_blank"
          onClick={(): void =>
            TelemetryService.trackEvent({
              capability: Capability.MyHub,
              subCapability: SubCapability.Faq_Privacy_Link,
              eventName: EventName.LINK_CLICKED,
            })
          }
        >
          Terms of Service
        </PanelSectionLink>
        <PanelSectionLink
          href="https://privacy.microsoft.com/en-gb/privacystatement"
          target="_blank"
          onClick={(): void =>
            TelemetryService.trackEvent({
              capability: Capability.MyHub,
              subCapability: SubCapability.Faq_Privacy_Link,
              eventName: EventName.LINK_CLICKED,
            })
          }
        >
          Privacy and cookies
        </PanelSectionLink>
      </PanelSection>
      {faqPanel ? (
        <Faq
          isOpen={faqPanel}
          backButton={{ ariaLabel: BackButtonText, onClick: toggleFaqPanel }}
          categories={displayCategories}
        ></Faq>
      ) : null}
      {isPanelOpen && helpSelector.faqData && helpSelector.faqData.length > 0 ? (
        <FaqDetail
          isOpen={isPanelOpen}
          question={helpSelector.faqData[faqItemIndex].questions[0]}
          answer={helpSelector.faqData[faqItemIndex].answer}
          backButton={{ ariaLabel: BackButtonText, onClick: togglePanel }}
        ></FaqDetail>
      ) : null}
      {isFaqListOpen ? (
        <FaqList
          isOpen={isFaqListOpen}
          backButton={{ ariaLabel: BackButtonText, onClick: toggleFaqList }}
          category={category}
          filteredFaq={filteredFaq}
        ></FaqList>
      ) : null}
      {isReportPanelOpen ? (
        <ReportProblem
          isOpen={isReportPanelOpen}
          backButton={{ ariaLabel: BackButtonText, onClick: toggleReportProblemPanel }}
        ></ReportProblem>
      ) : null}
    </div>
  );
}
