import { IUsePushoNotificationSubscription } from '../../Shared/Hooks/usePushNotificationSubscription';

export enum ActionTypes {
  LOGOUT = 'LOGOUT',
}

export interface ILogout {
  type: ActionTypes.LOGOUT;
  payload: IUsePushoNotificationSubscription;
}

export type IHeaderAction = ILogout;
