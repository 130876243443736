import * as React from 'react';
import { Image, headerStyle, subHeadingStyle } from './BuildingDetails.styled';

interface Props {
  onPress?: () => void;
  anemities?: string[];
  details: IdetailsType;
}

interface IdetailsType {
  name: string;
  address: IaddressType[];
}

interface IaddressType {
  street1: string;
  street2: string;
  zipcode: number;
}

export function BuildingDetails(props: Props): React.ReactElement {
  const { name, address } = props.details;
  const completeAddress = address
    ? address[0]?.street1 + ' ' + address[0]?.street2 + ' ' + address[0]?.zipcode
    : 'unavailable';
  return (
    <>
      <h1 style={headerStyle}>{name}</h1>
      <p style={subHeadingStyle}>{completeAddress}</p>
      <Image src="/assets/Explore/Building_Placeholder.svg" />
    </>
  );
}
