export const ApimSubscriptionKey = 'Dwp-Apim-Subscription-Key';
export const DipSubscriptionKey = 'Ocp-Apim-Subscription-Key';
export const AppVersion = 'X-App-Version';

interface IMyMsftApiHeader {
  [ApimSubscriptionKey]: string;
  [AppVersion]: string;
}

interface IADTv2ApiHeader {
  [DipSubscriptionKey]: string;
}

export class ApiHelper {
  static getMyMsftApiHeaders(): IMyMsftApiHeader {
    return {
      [ApimSubscriptionKey]: __API__.myMsftApim.subscriptionKey,
      [AppVersion]: __VERSION__,
    };
  }
  static getADTv2ApiHeaders(): IADTv2ApiHeader {
    return {
      [DipSubscriptionKey]: __API__.adtV2.subscriptionKey,
    };
  }
}
