import { all, call, getContext, put, SimpleEffect, takeLatest } from 'redux-saga/effects';
import { ApiHelper } from '../../../Shared/Helpers/ApiHelper';
import { TelemetryService } from '../../../Shared/shared';
import { ActionTypes } from './DefaultSearch.action-types';

export function* fetchPopularSearch(): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: HttpClient = yield getContext('httpClient');
    const url = '/api/search/popular';
    const response = yield call([httpClient, httpClient.request], {
      url: __API__.myMsftApim.resourceUrl + url,
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    });
    const data = response.data;
    yield put({ type: ActionTypes.GET_TRENDING_SEARCH_SUCCESS, data: JSON.parse(data) });
  } catch (error) {
    const errorString = JSON.stringify(error, Object.getOwnPropertyNames(error));
    TelemetryService.trackException(error, 1, {
      message: 'DefaultSearch.sagas.ts ===> Unable to fetch trending search',
    });
    yield put({ type: ActionTypes.GET_TRENDING_SEARCH_FAILED, error: errorString });
  }
}

export function* fetchFrequentedContacts(): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: HttpClient = yield getContext('httpClient');
    const url = `/msgraph/v1.0/me/people?$top=11&$filter=personType/class eq 'Person' and personType/subclass eq 'OrganizationUser'`;
    const response = yield call([httpClient, httpClient.request], {
      url: __API__.myMsftApim.resourceUrl + url,
      resource: __API__.myMsftApim.resourceId,
      header: ApiHelper.getMyMsftApiHeaders(),
    });
    const data = response.data.value;
    yield put({ type: ActionTypes.GET_FREQUENTED_CONTACTS_SUCCESS, data });
  } catch (error) {
    const errorString = JSON.stringify(error, Object.getOwnPropertyNames(error));
    TelemetryService.trackException(error, 1, {
      message: 'DefaultSearch.sagas.ts ===> Unable to fetch usr frequent contacts',
    });
    yield put({ type: ActionTypes.GET_FREQUENTED_CONTACTS_FAILED, error: errorString });
  }
}

export function* defaultSearchSagas(): IterableIterator<{}> {
  yield all([takeLatest(ActionTypes.GET_TRENDING_SEARCH, fetchPopularSearch)]);
  yield all([takeLatest(ActionTypes.GET_TRENDING_SEARCH, fetchFrequentedContacts)]);
}
