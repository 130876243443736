/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars, no-console */
// KA - Lint rules for newweb are much stricter than they were for shared
// TODO: Fix linter errors resulted from importing this file from "shared"
import {
  IAutoExceptionTelemetry,
  IDependencyTelemetry,
  IExceptionTelemetry,
  IMetricTelemetry,
  IPageViewTelemetry,
  ITraceTelemetry,
} from '@microsoft/applicationinsights-web';
import { ITelemetryService, Usage, asUserEvent } from '../Types';
import { cloneDeepWith, extend, isEmpty } from 'lodash-es';
import { ITelemetryClient } from '@employee-experience/common/lib';

export class TelemetryServiceImpl implements ITelemetryService {
  private userDetails: { userAlias: string } = { userAlias: 'UnInitialized' };
  public telemetryClient?: ITelemetryClient;
  public ClientInfo: { [key: string]: any } | undefined;
  public SetTelemetryClient(telemetryClient: ITelemetryClient) {
    this.telemetryClient = telemetryClient;
  }
  public SetUser(userPrincipalName: { userPrincipalName: string }) {
    //TOD: Validate the user data
    //Convert the attribute name of upn to userAlias so that it aligns with Mobile attributes.
    //and kusto/DAC queries can be understood seamlessly
    this.userDetails = { userAlias: userPrincipalName.userPrincipalName };
  }
  public getServiceTreeMetaData = (): { [key: string]: string } => {
    const UTPData = {
      ServiceOffering: 'End User Services',
      ServiceLine: 'MS Employee Experience',
      Service: 'MyMicrosoft',
      ComponentName: 'MyMicrosoft',
      ComponentId: '35fb3d17-ecd3-4f93-ad83-e2fae4186c9f',
    };
    return UTPData;
  };

  public getStandardTelemetryData = (
    properties: { [key: string]: string } | undefined,
    ...extendedProperties: any[]
  ): { [key: string]: string } => {
    try {
      // ServiceTree properties for UTP
      const UTPData = this.getServiceTreeMetaData();

      // User data (userAlias and SessionID)
      const userData = this.getUserData();

      // omit "Authorization" key from properties "recursively" to avoid logging token to telemetry
      const propertiesWithOutAuthKey = this.omitKeys(properties, ['Authorization']);
      // Combine the data (and sanitize)
      const totalProperties = extend({}, propertiesWithOutAuthKey || {}, this.ClientInfo || {}, UTPData, userData);

      // Remove Authorization key and Condense "extendedProperties" into a single property
      const extendedPropsString = JSON.stringify(this.omitKeys(extendedProperties, ['Authorization']));
      // Replace double-quotes or it will break telemetry records
      const sanitizedExtendedPropertiesString = extendedPropsString.replace(/"/g, "'");
      totalProperties.extendedProperties = sanitizedExtendedPropertiesString;

      return totalProperties;
    } catch (error) {
      // In case of unexpected error, log it with the telemetry so we at least
      // know why the standard additional properties are not present
      const ex: Error = error;
      const telemetryErrorMessage =
        'TelemetryService:getStandardTelemetry - Unexpected error while retrieving standard data';
      console.warn(telemetryErrorMessage, error);
      const errorProperties = {
        telemetryErrorMessage,
        telemetryError: ex.message,
      };
      return errorProperties;
    }
  };

  public getUserData = () => {
    //TODO: Mask upn if required. Add Ring params once ring strategy is finalized
    return this.userDetails;
  };

  public startTrackEvent = (usageEvent: Usage) => {
    // Intentionally doesn't call "dispatch", no properties to add to this call

    try {
      if (this.telemetryClient) {
        const userEvent = { ...usageEvent, ...asUserEvent(usageEvent) }; // Pass-on additional props if passed in
        this.telemetryClient.startTrackEvent(userEvent);
      } else {
        console.error('TelemetryService:startTrackEvent - ERROR - telemetryClient is not available');
      }
    } catch (ex) {
      console.error('TelemetryService:startTrackEvent - Unexpected error', ex);
    }
  };

  public startTrackPage = (name?: string) => {
    // Intentionally doesn't call "dispatch", no properties to add to this call
    try {
      if (this.telemetryClient) {
        this.telemetryClient.startTrackPage(name);
      } else {
        console.error('TelemetryService:startTrackPage - ERROR - telemetryClient is not available');
      }
    } catch (ex) {
      console.error('TelemetryService:startTrackPage - Unexpected error', ex);
    }
  };

  public stopTrackEvent = (
    usageEvent: Usage,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number },
    ...extendedProperties: any[]
  ) => {
    try {
      if (this.telemetryClient) {
        const standardProperties = this.getStandardTelemetryData(properties, ...extendedProperties);
        const userEvent = { ...usageEvent, ...asUserEvent(usageEvent) }; // Pass-on additional props if passed in
        this.telemetryClient.stopTrackEvent(userEvent, standardProperties, measurements);
      } else {
        console.error('TelemetryService:stopTrackEvent - ERROR - telemetryClient is not available');
      }
    } catch (ex) {
      console.error('TelemetryService:stopTrackEvent - Unexpected error', ex);
    }
  };

  public stopTrackPage = (
    name?: string,
    url?: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number },
    ...extendedProperties: any[]
  ) => {
    try {
      if (this.telemetryClient) {
        const enhancedProperties = this.getStandardTelemetryData(properties, {
          ...extendedProperties,
          ...measurements,
        });
        this.telemetryClient.stopTrackPage(name, url, enhancedProperties);
      } else {
        console.error('TelemetryService:startTrackPage - ERROR - telemetryClient is not available');
      }
    } catch (ex) {
      console.error('TelemetryService:stopTrackPage - Unexpected error', ex);
    }
  };

  public trackDependency = (
    id: string,
    name: string,
    responseCode: number,
    duration?: number,
    success?: boolean,
    correlationContext?: string,
    type?: string,
    data?: string,
    target?: string,
    properties?: {
      [key: string]: string;
    },
    measurements?: {
      [key: string]: number;
    },
    ...extendedProperties: any[]
  ) => {
    try {
      if (this.telemetryClient) {
        const enhancedProperties = this.getStandardTelemetryData(properties, ...extendedProperties);
        const trackDependencyData: IDependencyTelemetry = {
          id,
          name,
          responseCode,
          duration,
          success,
          correlationContext,
          type,
          data,
          target,
          properties: enhancedProperties,
          measurements,
        };
        this.telemetryClient.trackDependencyData(trackDependencyData);
      } else {
        console.error('TelemetryService:trackDependency - ERROR - telemetryClient is not available');
      }
    } catch (ex) {
      console.error('TelemetryService:trackDependency - Unexpected error', ex);
    }
  };

  public trackEvent = (usageEvent: Usage, properties?: {}, measurements?: {}, ...extendedProperties: any[]) => {
    try {
      const standardProperties = this.getStandardTelemetryData(properties, ...extendedProperties);
      if (this.telemetryClient) {
        const userEvent = { ...usageEvent, ...asUserEvent(usageEvent) }; // Pass-on additional props if passed in
        this.telemetryClient.trackEvent(userEvent, { ...standardProperties, ...measurements });
        // TODO: Validate HEART (ras1)
        // BREAK-LINT-TO-SEEK-ATTENTION
      } else {
        console.error('TelemetryService:trackEvent - ERROR - telemetryClient is not available');
      }
    } catch (ex) {
      // Throws an exception every time, but still logs. See the error below:
      // "Browser does not support local storage. Session durations will be inaccurate."
      if (ex.messageId !== 39) {
        console.error('TelemetryService:trackEvent - Unexpected error', ex);
      } else {
        // TODO: Return to this issue and resolve
        console.error('TelemetryService:trackEvent - EXPECTED error (no action is needed)', ex);
      }
    }
  };

  public trackException = (
    error: any,
    severityLevel?: number,
    properties?: {},
    measurements?: {},
    ...extendedProperties: any[]
  ) => {
    try {
      // Typescript catch clauses provide errors as "any" objects, needs conversion
      const ex: Error = error;

      if (this.telemetryClient) {
        const enhancedProperties = this.getStandardTelemetryData(properties, ...extendedProperties);
        const trackExceptionData: IExceptionTelemetry = {
          error: ex,
          severityLevel,
          properties: enhancedProperties,
          measurements,
        };
        this.telemetryClient.trackException(trackExceptionData);
      } else {
        console.error('TelemetryService:trackException - ERROR - telemetryClient is not available');
      }
    } catch (ex) {
      console.error('TelemetryService:trackException - Unexpected error', ex);
    }
  };

  public trackMetric = (
    name: string,
    average: number,
    sampleCount?: number,
    min?: number,
    max?: number,
    properties?: {},
    ...extendedProperties: any[]
  ) => {
    try {
      if (this.telemetryClient) {
        const metricTelemetry: IMetricTelemetry = {
          name,
          average,
          sampleCount,
          min,
          max,
        };
        const enhancedProperties = this.getStandardTelemetryData(properties, ...extendedProperties);
        this.telemetryClient.trackMetric(metricTelemetry, enhancedProperties);
      } else {
        console.error('TelemetryService:trackMetric - ERROR - telemetryClient is not available');
      }
    } catch (ex) {
      console.error('TelemetryService:trackMetric - Unexpected error', ex);
    }
  };

  public trackPageView = (
    name?: string,
    uri?: string,
    refUri?: string,
    pageType?: string,
    isLoggedIn?: boolean,
    properties?: {
      [key: string]: string;
    },
    measurements?: {
      [key: string]: number;
    },
    ...extendedProperties: any[]
  ) => {
    try {
      if (this.telemetryClient) {
        const enhancedProperties = this.getStandardTelemetryData(properties, ...extendedProperties);
        const trackPageViewData: IPageViewTelemetry = {
          name,
          uri,
          refUri,
          pageType,
          isLoggedIn,
          properties: enhancedProperties,
          measurements,
        };
        this.telemetryClient.trackPageView(trackPageViewData);
      } else {
        console.error('TelemetryService:trackPageView - ERROR - telemetryClient is not available');
      }
    } catch (ex) {
      // Throws an exception every time, but still logs. See the error below:
      // "trackPageView: navigation timing API used for calculation of page duration is not supported
      // in this browser. This page view will be collected without duration and timing info."
      if (ex.messageId !== 37) {
        console.error('TelemetryService:trackPageView - Unexpected error', ex);
      } else {
        // TODO: Return to this issue and resolve
        console.error('TelemetryService:trackPageView - EXPECTED error (no action is needed)', ex);
      }
    }
  };

  public trackTrace = (message: string, severityLevel?: number, properties?: {}, ...extendedProperties: any[]) => {
    try {
      if (this.telemetryClient) {
        const enhancedProperties = this.getStandardTelemetryData(properties, ...extendedProperties);
        const traceTelemetry: ITraceTelemetry = {
          message,
          severityLevel,
          properties: enhancedProperties,
        };
        this.telemetryClient.trackTrace(traceTelemetry);
      } else {
        console.error('TelemetryService:trackTrace - ERROR - telemetryClient is not available');
      }
    } catch (ex) {
      console.error('TelemetryService:trackTrace - Unexpected error', ex);
    }
  };

  // function to omit specific keys in a collection "recursively" .
  private omitKeys = (collection: any, excludeKeys: string[]) => {
    // function to remove the keys
    const omitFn = (value: any) => {
      if (value && typeof value === 'object') {
        excludeKeys.forEach((key: string) => {
          delete value[key];
        });
      }
    };

    if (!isEmpty(collection) && typeof collection === 'object') {
      // some errors are "Wrapper" objects so stringify and then convert to JSON object
      const collectionObj = JSON.parse(JSON.stringify(collection));
      return cloneDeepWith(collectionObj, omitFn);
    } else {
      return collection;
    }
  };
}
export const TelemetryService: ITelemetryService = new TelemetryServiceImpl();
