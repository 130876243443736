import { IStartMicroSentimentFlowAction } from '@microsoft/myhub-web-partner-tools';

export enum ActionTypes {
  END_MICRO_SENTIMENT_FLOW = 'END_MICRO_SENTIMENT_FLOW',
  SUBMIT_MICRO_SENTIMENT = 'SUBMIT_MICRO_SENTIMENT',
  SUBMIT_MICRO_SENTIMENT_FAILED = 'SUBMIT_MICRO_SENTIMENT_FAILED',
  SUBMIT_MICRO_SENTIMENT_SUCCEEDED = 'SUBMIT_MICRO_SENTIMENT_SUCCEEDED',
}

export interface IEndMicroSentimentFlowAction {
  type: ActionTypes.END_MICRO_SENTIMENT_FLOW;
}

export interface ISubmitMicroSentimentAction {
  type: ActionTypes.SUBMIT_MICRO_SENTIMENT;
}

export interface ISubmitMicroSentimentFailedAction {
  type: ActionTypes.SUBMIT_MICRO_SENTIMENT_FAILED;
}

export interface ISubmitMicroSentimentSucceededAction {
  type: ActionTypes.SUBMIT_MICRO_SENTIMENT_SUCCEEDED;
}

export type MicroSentimentAction =
  | IEndMicroSentimentFlowAction
  | IStartMicroSentimentFlowAction
  | ISubmitMicroSentimentAction
  | ISubmitMicroSentimentFailedAction
  | ISubmitMicroSentimentSucceededAction;
