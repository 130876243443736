import * as React from 'react';
import { ReactElement, useState } from 'react';
import { Card, CardBody, CardContainer } from './../Card';
import { newsSelector, newsFeedLoadingStatus } from '../Feed.selectors';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { LoadingStates } from '../../../../Shared/Models/LoadingStates';
import { LoadingScreen } from '../../../../Shared/Components/LoadingScreen';
import { ErrorScreen } from '../../../../Shared/Components/ErrorScreen';
import { Usage, Capability, SubCapability, EventName } from '../../../../Shared/Types';
import { usePageTracker } from '../../../../Shared/Hooks/usePageTracker';
import { FeedCarousel, FeedCarouselItem } from '../FeedCarousel';
import { INewsModel } from '../Models/INewsModel';
import { getNewsFeedItemProps } from './NewsCard/NewsCard.util';
import { CarouselContainer } from '../Feed.styled';
import { Page, PageHeadingContainer, PageHeading } from '../../../../Styles/Page';
import { requestNewsFeedDataAction } from '../Feed.actions';

export function News({ isScrollable }: { isScrollable: boolean }): ReactElement {
  const pageEvent: Usage = {
    capability: Capability.MyHub,
    subCapability: SubCapability.NewsFeed,
    eventName: EventName.PAGE_LOAD,
  };
  usePageTracker(pageEvent);
  const [itemNo, setItemNumber] = useState(0);
  const { useSelector, dispatch } = React.useContext(ReduxContext);
  let newsList = useSelector(newsSelector);
  if (newsList.length > 16 && isScrollable) {
    const seeMore = [
      {
        seeMore: true,
        Title: 'See All',
        Link: '/news',
      },
    ];
    newsList = [...newsList, ...seeMore];
  }
  const newsItemsLoadingStatus = useSelector(newsFeedLoadingStatus);
  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const reloadNews = (): void => {
    dispatch(requestNewsFeedDataAction());
  };
  const elementNumber = (itemNo: number): void => {
    setItemNumber(itemNo);
  };
  if (newsItemsLoadingStatus === LoadingStates.NOT_STARTED || newsItemsLoadingStatus === LoadingStates.STARTED) {
    return <LoadingScreen innerPageUse={true} />;
  } else if (newsItemsLoadingStatus === LoadingStates.FAILED || !newsList.length) {
    return (
      <CarouselContainer>
        <FeedCarousel currentFocusElement={itemNo} header="News" seeMoreLink="/news">
          <Card>
            <CardBody>
              <ErrorScreen
                innerPageUse={true}
                showTitle={true}
                showDescription={false}
                imagePath={'/assets/binocularWoman.svg'}
                onRefresh={reloadNews}
                imageSize="medium"
                title="Nothing to see here"
                description="Check back later"
                noButton
                titleSize
              />
            </CardBody>
          </Card>
        </FeedCarousel>
      </CarouselContainer>
    );
  } else {
    if (isScrollable) {
      // TODO: Should get error/ empty screens for horizontal feed scroll
      return (
        <>
          <CarouselContainer>
            <FeedCarousel currentFocusElement={itemNo} header="News" seeMoreLink="/news">
              {newsList &&
                newsList.map((post: INewsModel, index: number) => (
                  <FeedCarouselItem
                    {...getNewsFeedItemProps(post)}
                    showBookmark={true}
                    seeMoreCard={post.seeMore}
                    showDescription={false}
                    getFocusEle={elementNumber}
                    itemNumber={index}
                    key={index}
                  />
                ))}
            </FeedCarousel>
          </CarouselContainer>
        </>
      );
    }

    return (
      <Page>
        <PageHeadingContainer>
          <PageHeading tabIndex={0} aria-label="News">
            News
          </PageHeading>
        </PageHeadingContainer>
        <CardContainer>
          {newsList &&
            newsList.map((item: INewsModel, index: number) => (
              <FeedCarouselItem
                {...getNewsFeedItemProps(item)}
                descLines={4}
                showBookmark={true}
                showFeedSponsorTitle={true}
                showDescription={true}
                itemNumber={index}
                key={index}
              />
            ))}
        </CardContainer>
      </Page>
    );
  }
}
