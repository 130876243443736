import * as React from 'react';
import { CoherencePanel, CoherencePanelSize } from '@cseo/controls';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { Status, OOFStatusType, OOFStatuses } from './OOF.types';

import { Capability, SubCapability, EventName } from '../../../Shared/Types';
import { TelemetryService } from '../../../Shared/shared';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { useContext } from 'react';
import { resetOOFStatus, updateOOFStatusVisibility } from './OOF.actions';
import * as Styled from './OOFStatus.Styled';
import {
  getBlockCalendarStatus,
  getCancelConnectorStatus,
  getCancelMeetingsStatus,
  getEventsStatus,
  getNonBlockingEventStatus,
  getRespondToMeetingsStatus,
  getSetAutomaticReplyStatus,
  getOOFOptions,
  getSendEmailStatus,
  getOOFStatus,
} from './OOF.selectors';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

export function OOFStatus(): React.ReactElement {
  const { dispatch, useSelector } = useContext(ReduxContext);
  const { telemetryClient } = React.useContext(ComponentContext);

  const blockCalendarStatus = useSelector(getBlockCalendarStatus);
  const cancelConnectorStatus = useSelector(getCancelConnectorStatus);
  const cancelMeetingsStatus = useSelector(getCancelMeetingsStatus);
  const eventsStatus = useSelector(getEventsStatus);
  const automaticReplyStatus = useSelector(getSetAutomaticReplyStatus);
  const respondToMeetingsStatus = useSelector(getRespondToMeetingsStatus);
  const nonBlockingEventStatus = useSelector(getNonBlockingEventStatus);
  const options = useSelector(getOOFOptions);
  const sendEmailStatus = useSelector(getSendEmailStatus);
  const oofStatus = useSelector(getOOFStatus);

  const oofStatuses: OOFStatuses = [
    OOFStatusType.GetEvents,
    OOFStatusType.CancelConnector,
    OOFStatusType.CancelMeetings,
    OOFStatusType.SetAutomaticReply,
    OOFStatusType.RespondToMeetings,
    OOFStatusType.BlockCalendar,
    OOFStatusType.NonBlockingEvent,
    OOFStatusType.SendEmail,
  ];

  const generateStatusIcon = (status: Status): React.ReactNode => {
    if (status == Status.Failed) {
      return <Styled.FailedStatusIcon iconName="ErrorBadge" />;
    } else if (status == Status.Success) {
      return <Styled.SuccessStatusIcon iconName="Completed" />;
    } else if (status == Status.InProgress) {
      return <Spinner size={SpinnerSize.large} />;
    } else {
      return <Styled.NotStartedStatusIcon iconName="Completed" />;
    }
  };

  const {
    blockCalendar,
    cancelConnector,
    cancelMeetings,
    nonBlockingEvent,
    respondToMeetings,
    sendEmail,
    setAutomaticReply,
  } = options;

  const displayForOofStatus = (oofStatus) => {
    switch (oofStatus) {
      case OOFStatusType.GetEvents:
        return cancelMeetings || respondToMeetings;
      case OOFStatusType.SetAutomaticReply:
        return setAutomaticReply;
      case OOFStatusType.CancelConnector:
        return cancelConnector;
      case OOFStatusType.CancelMeetings:
        return cancelMeetings;
      case OOFStatusType.RespondToMeetings:
        return respondToMeetings;
      case OOFStatusType.BlockCalendar:
        return blockCalendar;
      case OOFStatusType.NonBlockingEvent:
        return nonBlockingEvent;
      case OOFStatusType.SendEmail:
        return sendEmail;
      default:
        return Status.Failed;
    }
  };

  const getStatusForOofStatus = (oofStatus) => {
    switch (oofStatus) {
      case OOFStatusType.GetEvents:
        return eventsStatus;
      case OOFStatusType.SetAutomaticReply:
        return automaticReplyStatus;
      case OOFStatusType.CancelConnector:
        return cancelConnectorStatus;
      case OOFStatusType.CancelMeetings:
        return cancelMeetingsStatus;
      case OOFStatusType.RespondToMeetings:
        return respondToMeetingsStatus;
      case OOFStatusType.BlockCalendar:
        return blockCalendarStatus;
      case OOFStatusType.NonBlockingEvent:
        return nonBlockingEventStatus;
      case OOFStatusType.SendEmail:
        return sendEmailStatus;
      default:
        return Status.Failed;
    }
  };

  // Handles light dismiss.
  const onPanelClosed = (ev?: React.SyntheticEvent<HTMLElement, Event> | undefined): void => {
    TelemetryService.trackEvent({
      capability: Capability.OOF,
      subCapability: SubCapability.OOF_STATUS_CLOSED,
      eventName: EventName.PANEL_CLOSED,
    });
    // In the case that the action button is clicked, the event comes as null. So we don't set the state here as we handle it in the OnClick handler instead.
    if (ev) {
      if (oofStatus === Status.Success || oofStatus === Status.Failed) {
        dispatch(resetOOFStatus());
      }
      dispatch(updateOOFStatusVisibility(false));
    }
  };
  const onDismissButtonClicked = (): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.OOF_STATUS_DISMISS,
      eventName: EventName.BUTTON_CLICKED,
    });
    if (oofStatus === Status.Success || oofStatus === Status.Failed) {
      dispatch(resetOOFStatus());
    }
    dispatch(updateOOFStatusVisibility(false));
  };

  return (
    <>
      <CoherencePanel
        panelSize={CoherencePanelSize.medium}
        titleText={'Clear your calendar'}
        isOpen={true}
        isLightDismiss={false}
        onDismiss={onPanelClosed}
        hasCloseButton={true}
        telemetryHook={telemetryClient}
        isFooterAtBottom={true}
        onRenderFooter={{
          primaryButton: {
            text: 'Dismiss',
            onAction: (): void => {
              onDismissButtonClicked();
            },
            disabled: false,
          },
        }}
        closeButtonAriaLabel="Close"
      >
        <Styled.PanelContent>
          <Styled.Container>
            {oofStatuses.map((oofStatus) => {
              const show = displayForOofStatus(oofStatus);
              const { status, error } = getStatusForOofStatus(oofStatus);
              return (
                show && (
                  <Styled.RowContainer>
                    <Styled.RowText>
                      <Styled.FontIconContainer>{generateStatusIcon(status)}</Styled.FontIconContainer>
                      <Styled.StatusContainer>
                        {status == Status.Failed ? (
                          <Styled.ErrorStatusLabel>{`${oofStatus}...`}</Styled.ErrorStatusLabel>
                        ) : (
                          <Styled.StatusLabel>{`${oofStatus}...`}</Styled.StatusLabel>
                        )}
                        <Styled.ErrorWrapper>{error}</Styled.ErrorWrapper>
                      </Styled.StatusContainer>
                    </Styled.RowText>
                  </Styled.RowContainer>
                )
              );
            })}
          </Styled.Container>
        </Styled.PanelContent>
      </CoherencePanel>
    </>
  );
}
