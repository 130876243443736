import { ICatalogItem } from './Shared/Models/ICatalogItem';

export const DashboardReducerName = 'dashboard';

export const CatalogItems: ICatalogItem[] = [
  // Replace scriptPath with Partner CDN as given below
  //scriptPath: 'https://myhubendpoint.azureedge.net/partnercontainer/hello-card.js',
  {
    description: 'Celebrate your fellow employees by giving them public recognition and kudos!',
    id: 'KUDOS_CARD_UNIQUE_ID',
    imageAltText: 'Kudos image',
    imagePath: '/assets/Dashboard/Catalog_Kudos.svg',
    scriptPath: '/bundles/error-card.js',
    title: 'Kudos',
    componentName: 'Kudos',
  },
  {
    description:
      'See your official earnings along with direct deposit, pay period, benefits compensation, retirement matching, taxes and deductions, personal time off balances, and more.',
    id: 'PAYROLL_CARD_UNIQUE_ID',
    imageAltText: 'Payroll image',
    imagePath: '/assets/Dashboard/Catalog_Payroll.svg',
    scriptPath: '/bundles/error-card.js',
    title: 'Payroll',
    componentName: 'Payroll',
  },
  {
    description:
      'Learn about cafe and restaurant menus and hours, dining locations, payment options, vending machines, nutrition info, and more.',
    id: 'DINING_CARD_UNIQUE_ID',
    imageAltText: 'Dining image',
    imagePath: '/assets/Dashboard/Catalog_Dining.svg',
    scriptPath: __MYHUB_CONFIGS__.dining.script,
    title: 'Dining',
    componentName: __MYHUB_CONFIGS__.dining.name,
  },
  {
    description: 'See a schedule of local food trucks and what yummy options are in your area today.',
    id: 'FOODTRUCK_CARD_UNIQUE_ID',
    imageAltText: 'Truck image',
    imagePath: '/assets/Dashboard/Catalog_FoodTruck.svg',
    scriptPath: '/bundles/error-card.js',
    title: 'Food Truck',
    componentName: 'FoodTruck',
  },
  {
    description: 'Book a shuttle for inter-campus travel now.',
    id: 'SHUTTLE_CARD_UNIQUE_ID',
    imageAltText: 'Shuttle image',
    imagePath: '/assets/Dashboard/Catalog_Shuttle.svg',
    scriptPath: '/bundles/error-card.js',
    title: 'Shuttle',
    componentName: 'Shuttle',
  },
  {
    description: 'Find info about routes, schedules and advisories for the campus Connector service.',
    id: 'CONNECTOR_CARD_UNIQUE_ID',
    imageAltText: 'Connector image',
    imagePath: '/assets/Dashboard/Catalog_Connector.svg',
    scriptPath: '/bundles/error-card.js',
    title: 'Connector',
    componentName: 'Connector',
  },
  {
    description: 'Track Microsoft stock and see your personal awards and vesting schedule.',
    id: 'STOCK_CARD_UNIQUE_ID',
    imageAltText: 'Stock image',
    imagePath: '/assets/Dashboard/Catalog_Stock.svg',
    scriptPath: __MYHUB_CONFIGS__.stock.script,
    title: 'Stock',
    componentName: __MYHUB_CONFIGS__.stock.name,
    data: { ...__MYHUB_CONFIGS__.stock.data },
  },
  {
    description: 'Log your personal time-off including vacation, sick pay, jury duty, and more.',
    id: 'TIMEOFF_CARD_UNIQUE_ID',
    imageAltText: 'Timeoff image',
    imagePath: '/assets/Dashboard/Catalog_TimeOff.svg',
    scriptPath: __MYHUB_CONFIGS__.timeaway.script,
    title: 'Time off',
    componentName: __MYHUB_CONFIGS__.timeaway.name,
    data: { ...__MYHUB_CONFIGS__.timeaway.data },
  },
  {
    description: 'See your current fitness benefits and sign up for a gym during the open enrollment period.',
    id: 'STAYFIT_CARD_UNIQUE_ID',
    imageAltText: 'Stay Fit image',
    imagePath: '/assets/Dashboard/Catalog_StayFit.svg',
    scriptPath: __MYHUB_CONFIGS__.stayfit.script,
    title: 'Stay Fit',
    componentName: __MYHUB_CONFIGS__.stayfit.name,
    data: { ...__MYHUB_CONFIGS__.stayfit.data },
  },
];
