import { ISearchResultsModel } from './Models/ISearchResultsModel';
import {
  ActionTypes,
  IFetchSearchResultsAction,
  IFetchSearchResultsSuccessAction,
  IFetchSearchResultsFailureAction,
  IResetSearchResultsAction,
} from './Search.action-types';
import { IFetchSearchResultsActionPayload } from './Search.types';

export function fetchSearchResults(payload: IFetchSearchResultsActionPayload): IFetchSearchResultsAction {
  return {
    payload,
    type: ActionTypes.FETCH_SEARCH_RESULTS,
  };
}

export function fetchSearchResultsSuccessAction(SearchResults: ISearchResultsModel): IFetchSearchResultsSuccessAction {
  return {
    type: ActionTypes.FETCH_SEARCH_RESULTS_SUCCESS,
    SearchResults: SearchResults,
  };
}

export function fetchSearchResultsFailureAction(error: unknown): IFetchSearchResultsFailureAction {
  return {
    type: ActionTypes.FETCH_SEARCH_RESULTS_FAILURE,
    error,
  };
}

export function resetSearchResults(): IResetSearchResultsAction {
  return {
    type: ActionTypes.RESET_SEARCH_RESULTS,
  };
}
