import { ITicketsModel } from './Models/ITicketsModel';

export enum ActionTypes {
  SUBMIT_TICKET = 'SUBMIT_TICKET',
  SUBMIT_TICKET_SUCCEEDED = 'SUBMIT_TICKET_SUCCEEDED',
  SUBMIT_TICKET_FAILED = 'SUBMIT_TICKET_FAILED',
  FETCH_OPEN_TICKET = 'FETCH_OPEN_TICKET',
  FETCH_TICKET_SUCCEEDED = 'FETCH_TICKET_SUCCEEDED',
  FETCH_TICKET_FAILED = 'FETCH_TICKET_FAILED',
  RESET_TICKET = 'RESET_TICKET',
}

export interface ISubmitTicketAction {
  type: ActionTypes.SUBMIT_TICKET;
  payload: {};
}

export interface ISubmitTicketSuccessAction {
  type: ActionTypes.SUBMIT_TICKET_SUCCEEDED;
}

export interface ISubmitTicketFailedAction {
  type: ActionTypes.SUBMIT_TICKET_FAILED;
  payload: {};
}

export interface IFetchTicketAction {
  type: ActionTypes.FETCH_OPEN_TICKET;
}

export interface IFetchTicketFailedAction {
  type: ActionTypes.FETCH_TICKET_FAILED;
}

export interface IResetTicketAction {
  type: ActionTypes.RESET_TICKET;
}

export interface ISetTicketAction {
  type: ActionTypes.FETCH_TICKET_SUCCEEDED;
  payload: ITicketsModel[];
}

export type IReportProblemAction =
  | ISubmitTicketAction
  | IFetchTicketAction
  | IResetTicketAction
  | IFetchTicketFailedAction
  | ISubmitTicketSuccessAction
  | ISubmitTicketFailedAction;
