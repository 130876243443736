import { ActionTypes, IFooterAction } from './Footer.action-types';
import { IFooterState } from './Footer.types';

export const initialFooterState: IFooterState = {
  frequentlyUsedLinks: [],
};

export function footerReducer(prev: IFooterState = initialFooterState, action: IFooterAction): IFooterState {
  switch (action.type) {
    case ActionTypes.RECEIVE_FREQUENTLY_USED_LINKS: {
      return {
        ...prev,
        frequentlyUsedLinks: action.links,
      };
    }
    default:
      return prev;
  }
}
