import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths';
import { minWidth } from '../../../Styles/Media';

export const Grid = styled.div`
  /* ----- Default muuri style ----- */
  position: relative;

  /* ----- Custom styles ----- */
  // Removed the card margins from the margins of the grid.
  // Left-most and right-most cards add extra left and right margins.
  // All cards add a top and bottom margin.
  margin-bottom: 8px;
  margin-left: calc(5vw - 8px);
  margin-right: calc(5vw - 8px);
  margin-top: 8px;
  // Disabling selection during drag and drop on Safari.
  user-select: none;

  & .muuri-item-placeholder {
    background: rgba(0, 120, 215, 0.2);
    border-radius: 2px;
    border: 2px solid #0078d7;
  }

  ${minWidth.xl} {
    margin-bottom: 10px;
    margin-left: calc(5vw - 12px);
    margin-right: calc(5vw - 12px);
    margin-top: 10px;
  }
`;

export const CardContainer = styled.div`
  /* ----- Default muuri style ----- */
  position: absolute;

  /* ----- Custom styles ----- */
  cursor: grab;
  // Space between cards is 16px. Equally distributing between adjacent cards to make calculations easier.
  margin: 8px;

  /* ----- Default muuri style ----- */
  &.muuri-item-dragging {
    z-index: 3;
  }

  &.muuri-item-releasing {
    z-index: 2;
  }

  /* ----- Custom styles ----- */
  &.muuri-item-dragging {
    box-shadow: ${Depths.depth64};
    cursor: grabbing;
  }

  &:active {
    // Setting this to grabbing as the default cursor during drag is text on Safari(Mac).
    cursor: grabbing;
  }

  &.background-card {
    opacity: 0.5;
  }

  &.small {
    height: 256px;
    width: calc(100% - 16px);
  }

  &.medium {
    height: 256px;
    width: calc(100% - 16px);
  }

  &.large {
    height: 528px;
    width: calc(100% - 16px);
  }

  ${minWidth.m} {
    // Removing 1 pixel from the widths to account for decimal rounding by muuri.
    // Muuri rounding can be disabled but at certain widths a strange issue was observed where the cards were moving to the next line even if there was space on the previous line.
    // [When the widths of the cards are different]: Removing 1px from small cards and 2px from medium/large cards to maintain vertical alignment.
    &.small {
      height: 252px;
      width: calc(50% - 16px - 1px);
    }

    &.medium {
      height: 252px;
      width: calc(100% - 16px - 2px);
    }

    &.large {
      width: calc(100% - 16px - 2px);
    }
  }

  ${minWidth.l} {
    &.small {
      height: 256px;
      width: calc(50% - 16px - 1px);
    }

    &.medium {
      height: 256px;
      width: calc(50% - 16px - 1px);
    }

    &.large {
      width: calc(50% - 16px - 1px);
    }
  }

  ${minWidth.xl} {
    margin: 12px;

    &.small {
      height: 252px;
      width: calc(25% - 24px - 1px);
    }

    &.medium {
      height: 252px;
      width: calc(50% - 24px - 2px);
    }

    &.large {
      width: calc(50% - 24px - 2px);
    }
  }

  ${minWidth.xxl} {
    &.small {
      width: calc(16.665% - 24px - 1px);
    }

    &.medium {
      width: calc(33.33% - 24px - 2px);
    }

    &.large {
      width: calc(33.33% - 24px - 2px);
    }
  }

  ${minWidth.xxxl} {
    &.small {
      width: calc(12.5% - 24px - 1px);
    }

    &.medium {
      width: calc(25% - 24px - 2px);
    }

    &.large {
      width: calc(25% - 24px - 2px);
    }
  }
`;
