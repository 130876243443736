import { FontIcon, FontSizes } from 'office-ui-fabric-react';

export const ItemCell = styled.div`
  display: flex;
  flex-direction: 'row';
  margin-left: 12px;
  border-bottom: 1px solid #f3f2f1;
  margin-bottom: '25px';
  cursor: pointer;
  &:hover {
    background-color: #fafafa;
  }
`;

export const TrendingCell = styled.div`
  display: flex;
  flex-direction: 'row';
  margin-left: 12px;
  border-top: 1px solid #f3f2f1;
  cursor: pointer;
  &:hover {
    background-color: #fafafa;
  }
`;

export const TrendingIcon = styled(FontIcon)`
  color: #797775;
  line-height: 28px;
  font-size: 28px;
  padding: 10px 16px 10px 4px;
`;

export const TrendingLabel = styled.a`
  padding: 13px 0px 16px 0px;
  font-size: '14px';
  line-height: '19px';
`;

export const ItemContent = styled.div`
  padding: 6px 0px 0px 0px;
  overflow: hidden;
  flex-grow: 1;
`;

export const ItemName = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: #323130;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Initials = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 600;
  line-height: 32px;
  background-color: rgb(92, 46, 145);
  text-align: center;
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto,
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
`;

export const ItemIndex = styled.p`
  padding: 1px 0px 0px 0px;
  font-size: 12px;
  line-height: 16px;
  color: #797775;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FeatureImg = styled.div`
  padding: 7px 10px 7px 4px;
  border-radius: 2px;
`;

export const DefaultImg = styled.div`
  padding: 8px 10px 8px 4px;
`;

export const ListHeader = styled.h1`
  padding: 6px 8px 6px 16px;
  font-size: ${FontSizes.size14};
  line-height: 19px;
  color: '#323130';
`;

export const TrendingListHeader = styled.h1`
  padding: 13px 8px 13px 16px;
  font-size: 14px;
  line-height: 19px;
  color: '#323130';
`;

export const Features = styled.div`
  padding: 0px 0px 25px 0px;
`;

export const Locations = styled.div`
  padding: 0px 0px 8px 0px;
`;

export const People = styled.div`
  padding: 0px 0px 8px 0px;
`;
