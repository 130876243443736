import { FontIcon } from 'office-ui-fabric-react';

export const barStyle = {
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: '#FDE7E9',
  alignItems: 'center',
};

export const CardActionButton = styled(FontIcon)`
  width: 32px,
  height: 32px,
  font-size: 20px
`;

export const closeIcon = { height: '16px', margin: '8px', fontSize: '16px', color: '#A80000', cursor: 'pointer' };

export const messageText = { lineHeight: '16px', fontSize: '12px' };
