import React = require('react');
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { useUserGlobalConfigDetails } from '../../Shared/Hooks/useUserGlobalConfig';
import {
  DeRegisterNotificationSubscription,
  RegisterNotificationSubscription,
} from '../PushNotification/PushNotification.action';
import {
  IPushNotificationSubscription,
  ISafariPushNotificationPermission,
  SafariPushNotificationPermissionTypes,
} from '../PushNotification/PushNotification.types';
import { TelemetryService } from '../shared';

export interface IUsePushNotificationSubscription {
  subscribeUser?: () => Promise<void>;
  unsubscribeUser?: () => Promise<(boolean | undefined)[]>;
}

export const usePushNotificationSubscription = (): IUsePushNotificationSubscription => {
  const { dispatch } = React.useContext(ReduxContext);
  const userDetails = useUserGlobalConfigDetails();

  const safariPushRequestPermissionCallback = (permission: ISafariPushNotificationPermission): void => {
    if (permission.permission === SafariPushNotificationPermissionTypes.GRANTED) {
      //TODO: device token available at this point and can be leveraged.
    }
  };

  const safariPushRequestPermission = (): void => {
    window?.safari?.pushNotification.requestPermission(
      __SAFARI_PUSH_WEBSERVICEURL__,
      __SAFARI_PUSH_WEBSITEPUSHID__,
      { upn: userDetails.preferredName }, //TODO: have the upn saved to redux and use it here,
      safariPushRequestPermissionCallback
    );
  };

  const safariPushCheckPermission = (): void => {
    const permissionResult: {
      permission: SafariPushNotificationPermissionTypes;
    } = window?.safari?.pushNotification?.permission(__SAFARI_PUSH_WEBSITEPUSHID__);
    if (permissionResult.permission === SafariPushNotificationPermissionTypes.DEFAULT) {
      //request permission
      safariPushRequestPermission();
    } else if (permissionResult.permission === SafariPushNotificationPermissionTypes.GRANTED) {
    } else if (permissionResult.permission === SafariPushNotificationPermissionTypes.DENIED) {
    }
  };

  /**
   * get user consent on notification
   */
  async function askUserPermission(): Promise<NotificationPermission> {
    return await Notification.requestPermission();
  }

  /**
   * check get serviceworker instance
   */
  async function getServiceWorker(): Promise<ServiceWorkerRegistration> {
    return await navigator.serviceWorker.ready;
  }

  /**
   * get user notification subscription
   */
  async function getUserSubscription(): Promise<PushSubscription | null> {
    const serviceWorker = await getServiceWorker();
    return await serviceWorker.pushManager.getSubscription();
  }

  /**
   *
   * register the subscription to the server
   *
   */
  function sendSubscription(subscription: PushSubscription): void {
    const parsedSubscription = JSON.parse(JSON.stringify(subscription));
    const {
      endpoint,
      expirationTime,
      keys: { auth, p256dh },
    } = parsedSubscription;
    const pushNotificationSubscription: IPushNotificationSubscription = {
      upn: '',
      endpoint,
      expirationTime,
      auth,
      p256dh,
    };

    dispatch(RegisterNotificationSubscription(pushNotificationSubscription));
  }

  /**
   *
   * Deregister the subscription to the server
   *
   */
  function DeRegisterSubscription(subscription: PushSubscription): void {
    dispatch(DeRegisterNotificationSubscription(subscription));
  }

  async function subscribeNotificationNonSafari(): Promise<void> {
    const notificationPermission = await askUserPermission();
    TelemetryService.trackTrace('PushNotification permission status', 0, notificationPermission);
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then(function (registration) {
          if (!registration.pushManager) {
            TelemetryService.trackTrace('Push manager unavailable.');
            return;
          }
          if (notificationPermission !== 'granted') {
            TelemetryService.trackTrace('PushNotification is not granted, skipping the push notification subscription');
            return;
          }

          registration.pushManager.getSubscription().then(function (existedSubscription) {
            if (existedSubscription === null) {
              TelemetryService.trackTrace('No subscription detected, make a request.');
              registration.pushManager
                .subscribe({
                  applicationServerKey: __PUSHNOTIFICAION_APPLICATION_SERVER_KEY__,
                  userVisibleOnly: true,
                })
                .then(function (newSubscription) {
                  TelemetryService.trackTrace('New subscription added.');
                  sendSubscription(newSubscription);
                })
                .catch(function (e) {
                  if (Notification.permission !== 'granted') {
                    TelemetryService.trackException('PushManager Permission was not granted.');
                  } else {
                    TelemetryService.trackException('An error ocurred during the subscription process.', e);
                  }
                });
            } else {
              TelemetryService.trackTrace('Existed subscription detected.');
              sendSubscription(existedSubscription);
            }
          });
        })
        .catch(function (e) {
          TelemetryService.trackTrace('An error ocurred during Service Worker registration.', e);
        });
    }
  }

  /**
   *
   * using the registered service worker creates a push notification subscription and returns it
   *
   */
  async function subscribeUser(): Promise<void> {
    if (window.safari) {
      safariPushCheckPermission();
    } else {
      subscribeNotificationNonSafari();
    }
  }

  async function unsubscribe(subscription: PushSubscription): Promise<boolean | undefined> {
    try {
      return await subscription.unsubscribe();
    } catch (error) {
      TelemetryService.trackException('PushNotification-Unsubscribe-Failed', error);
    }
  }

  async function unsubscribeUser(): Promise<(boolean | undefined)[]> {
    return await Promise.all([
      await getUserSubscription()
        .then((subscription) => {
          if (subscription) {
            unsubscribe(subscription);
            DeRegisterSubscription(subscription);
            return true;
          }
        })
        .catch((error) => {
          TelemetryService.trackException('PushNotification-Unsubscribe-ErrorOccured', error);
          return false;
        }),
    ]);
  }

  return {
    subscribeUser,
    unsubscribeUser,
  };
};
