import { IFrequentlyUsedLink } from './Footer.types';

export enum ActionTypes {
  RECEIVE_FREQUENTLY_USED_LINKS = 'RECEIVE_FREQUENTLY_USED_LINKS',
  REQUEST_FREQUENTLY_USED_LINKS = 'REQUEST_FREQUENTLY_USED_LINKS',
}

export interface IReceiveFrequentlyUsedLinksAction {
  type: ActionTypes.RECEIVE_FREQUENTLY_USED_LINKS;
  links: IFrequentlyUsedLink[];
}

export interface IRequestFrequentlyUsedLinksAction {
  type: ActionTypes.REQUEST_FREQUENTLY_USED_LINKS;
}

export type IFooterAction = IReceiveFrequentlyUsedLinksAction | IRequestFrequentlyUsedLinksAction;
