import * as React from 'react';
import { ICatalogItemProps } from './CatalogItem.types';
import { useState } from 'react';
import * as Styled from './CatalogItem.styled';
import { Capability, SubCapability, EventName } from '../../../../Shared/Types';
import { TelemetryService } from '../../../../Shared/shared';

export const CatalogItem: React.FC<ICatalogItemProps> = (props: ICatalogItemProps) => {
  const { item, onClicked } = props;
  const [selected, setSelected] = useState(false);

  const onCatalogItemChange = (): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Dashboard_Catalog_Change,
      eventName: EventName.BUTTON_CLICKED,
    });
    setSelected(!selected);
    onClicked(item.id, !selected);
  };

  return (
    <Styled.Container
      role="checkbox"
      aria-label={item.title}
      onClick={onCatalogItemChange}
      className={selected ? 'container-selected' : ''}
      aria-checked={selected}
    >
      <Styled.Image src={item.imagePath} alt="" />
      <Styled.TextContainer>
        <Styled.Heading>{item.title}</Styled.Heading>
        <Styled.SubHeading>{item.description}</Styled.SubHeading>
      </Styled.TextContainer>
    </Styled.Container>
  );
};
