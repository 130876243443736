import { IMyHubAppState } from '../../../App.types';
import { IExploreState, IMapExploreBuildingsModel } from './MapView.types';
import { ExploreReducerName } from './MapView.resources';
import { initialExploreState } from './MapView.reducer';
import { createSelector } from 'reselect';

export const buildingsSelector = createSelector(
  (state: IMyHubAppState) => state[ExploreReducerName] ?? initialExploreState,
  (buildingsState: IExploreState) => buildingsState.exploreBuildings
);

export const buildingsIdSetSelector = createSelector(
  (state: IMyHubAppState) => state[ExploreReducerName] ?? initialExploreState,
  (buildingsState: IExploreState) => {
    const buildings = buildingsState.exploreBuildings;
    const buildingIdArray = buildings
      ? buildings.map((building: IMapExploreBuildingsModel) => building.buildingId)
      : [];
    return new Set(buildingIdArray);
  }
);

export const bookmarksLoadingStatusSelector = createSelector(
  (state: IMyHubAppState) => state[ExploreReducerName] ?? initialExploreState,
  (buildingsState: IExploreState) => buildingsState.exploreLoadingStatus
);

export const buildingDetailSelector = createSelector(
  (state: IMyHubAppState) => state[ExploreReducerName] ?? initialExploreState,
  (buildingsState: IExploreState) => buildingsState.exploreBuildingDetails
);

export const buildingDetailStatusSelector = createSelector(
  (state: IMyHubAppState) => state[ExploreReducerName] ?? initialExploreState,
  (buildingsState: IExploreState) => buildingsState.buildingDetailsLoadingStatus
);
