/* eslint-disable myhub-web-lint/check-usage-events */
import { CoherenceCustomizations } from '@cseo/styles';
import { useUsageTelemetry } from '@employee-experience/common';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { useLoginOnStartup } from '@employee-experience/common/lib/useLoginOnStartup';
import { Footer } from '@employee-experience/shell/lib/Footer';
import { ShellStyles } from '@employee-experience/shell/lib/Shell';
import { Customizer } from 'office-ui-fabric-react';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'web-animations-js';
import { appLoad, toggleNav } from './App.actions';
import { AppRoutes } from './App.routes';
import { isNavOpenSelector, storeRehydrateCompleteSelector } from './App.selectors';
import * as Styled from './App.styled';
import { ErrorBoundary } from './Layout/ErrorBoundary';
import { Footer as MyhubFooter } from './Layout/Footer';
import { Header } from './Layout/Header';
import { Nav } from './Layout/Nav';
import * as serviceWorker from './serviceWorker';
import { usePageTracker } from './Shared/Hooks/usePageTracker';
import { usePushNotificationSubscription } from './Shared/Hooks/usePushNotificationSubscription';
import { MicroSentiment } from './Shared/MicroSentiment/MicroSentiment';
import { Usage, Capability, SubCapability, EventName } from './Shared/Types';
import { ShellWithStore } from './ShellWithStore';
import { initializeCustomIcons } from './Styles/initializeCustomIcons';
import { Image } from 'office-ui-fabric-react/lib/Image';

initializeCustomIcons();

function MyHubLink(): React.ReactElement {
  const platform = window.navigator.platform;
  let link: string | undefined;
  if (platform.includes('iphone') || platform.includes('Intel')) {
    link =
      'https://microsoft.sharepoint.com/sites/deviceexperience/sitepages/device%20experience%20-%20android%20-%20apps.aspx';
  } else {
    link = 'https://play.google.com/store/apps/details?id=com.microsoft.myhub.prod';
  }
  return (
    <Styled.CenterContainer>
      <Styled.ImageContainer>
        <Image style={{ borderRadius: '45px' }} src={'/assets/Dashboard/Logo.svg'} />
      </Styled.ImageContainer>
      <Styled.Heading>You’re almost there!</Styled.Heading>
      <Styled.SubHeading>Download the app to get stuff done on campus.</Styled.SubHeading>
      <Styled.Stores>
        <a href="https://apps.apple.com/us/app/microsoft-myhub/id1476326475" style={{ marginRight: '5px' }}>
          <img src={'/assets/Dashboard/AppStore.svg'} alt="icon" />
        </a>
        <a href={link} style={{ marginLeft: '5px' }}>
          <img src={'/assets/Dashboard/GooglePlay.svg'} alt="icon" />
        </a>
      </Styled.Stores>
      <div style={{ flexDirection: 'row' }}>
        <Styled.Link>On an Android device and having trouble opening the app? Get help</Styled.Link>
        <a href="https://microsoft.sharepoint.com/sites/DeviceExperience/SitePages/Device%20Experience%20-%20Android%20-%20FAQ.aspx?xsdata=MDV8MDF8fDEzNGQ0NTFkMGY5NzQ0OGU1ZWY2MDhkYjJiZjg4NzZkfDcyZjk4OGJmODZmMTQxYWY5MWFiMmQ3Y2QwMTFkYjQ3fDB8MHw2MzgxNTIxMTg2MjcwMzI4MjJ8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxNVFkzT1RZeE5UQTJNak01TlRzeE5qYzVOakUxTURZeU16azFPekU1T2poaFl6Um1OakprTlRCaU16UTVaR0ZoTkRBMFpHWm1ZakJoWldRd05qQTJRSFJvY21WaFpDNTJNZz09fDYxNWI4NTc3ZmE3ZTQzOWM1ZWY2MDhkYjJiZjg4NzZkfDI1ZWM1YzEzMmQ5ZTRmYzg4ZTg3NzQ0OTA4Nzg2Mzli&sdata=MWJyTW9LVldwSERtdWRpa0Zkem9qME9ZZUFMczV2R1U2c2pDSGtCT1hhMD0%3D&ovuser=72f988bf-86f1-41af-91ab-2d7cd011db47%2Cantsveta%40microsoft.com&OR=Teams-HL&CT=1679691160901&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyOC8yMzAzMTkwMTIwMCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D" style={{ paddingLeft:5,fontSize:12}}>
          here.
        </a>
      </div>
    </Styled.CenterContainer>
  );
}

function App(): React.ReactElement {
  useLoginOnStartup();
  useUsageTelemetry();
  const pageEvent: Usage = {
    capability: Capability.MyHub,
    subCapability: SubCapability.NotApplicable,
    eventName: EventName.PAGE_LOAD,
  };
  usePageTracker(pageEvent);
  const { dispatch, useSelector } = useContext(ReduxContext);
  const isNavOpen: boolean = useSelector(isNavOpenSelector);
  const storeRehydrateComplete: boolean = useSelector(storeRehydrateCompleteSelector);
  const { subscribeUser } = usePushNotificationSubscription();

  function navToggled(): void {
    dispatch(toggleNav());
  }

  useEffect(() => {
    dispatch(appLoad());
    if (subscribeUser) {
      subscribeUser();
    }
  }, []);

  return (
    <Customizer {...CoherenceCustomizations}>
      <ShellStyles />
      <BrowserRouter>
        {storeRehydrateComplete && (
          <>
            <Header />
            <Nav isOpen={isNavOpen} onToggle={navToggled} />
            <Styled.Main className={isNavOpen ? 'navExpanded' : 'navCollapsed'} tabIndex={-1}>
              <ErrorBoundary>
                <AppRoutes />
              </ErrorBoundary>
            </Styled.Main>
            <Footer>
              <MyhubFooter />
            </Footer>
            <MicroSentiment />
          </>
        )}
      </BrowserRouter>
    </Customizer>
  );
}
function renderApp() {
  const currentURL = window.location.href;
  if (currentURL.includes('myHubLink')) {
    return <MyHubLink />;
  } else {
    return <App />;
  }
}

render(<ShellWithStore>{renderApp()}</ShellWithStore>, document.getElementById('app'));

serviceWorker.register();
