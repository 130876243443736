import * as React from 'react';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { useHistory, useLocation } from 'react-router-dom';
import { DashboardKey, DashboardRoute } from './DashboardPivot.types';
import { useUserGlobalConfigDetails } from '../../../Shared/Hooks/useUserGlobalConfig';

export function DashboardPivot(): React.ReactElement | null {
  const { pathname } = useLocation();
  const history = useHistory();
  const userDetails = useUserGlobalConfigDetails();
  const [key, setKey] = React.useState<DashboardKey>(DashboardKey.YOUR_TEAM);

  React.useEffect(() => {
    if (pathname.toLowerCase().indexOf(DashboardRoute.YOUR_TEAM) !== -1) return;

    setKey(DashboardKey.YOU);
  }, [pathname]);

  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const handleLinkClicked = (item: PivotItem | undefined): void => {
    const newKey = item?.props?.itemKey as DashboardKey;

    setKey(newKey);
    history.push(DashboardRoute[newKey]);
  };

  const tabTitle = `${
    !!userDetails.preferredFirstName
      ? userDetails.preferredFirstName
      : !!userDetails.firstName
      ? userDetails.firstName
      : 'Your '
  }${!!userDetails.preferredFirstName || !!userDetails.firstName ? "'s " : ''} team`;

  return (
    <Pivot onLinkClick={handleLinkClicked} selectedKey={key}>
      <PivotItem headerText={tabTitle} itemKey={DashboardKey.YOUR_TEAM} />
      <PivotItem headerText="You" itemKey={DashboardKey.YOU} />
    </Pivot>
  );
}
