import { CardSizes } from '../Models/CardSizes';
import { ICard } from '../Models/ICard';

const DefaultCardSize = CardSizes.MEDIUM;

export function getNewCards(cardIds: string[]): ICard[] {
  return cardIds.map((cardId) => {
    return {
      id: cardId,
      size: DefaultCardSize,
    };
  });
}
