import * as React from 'react';
import * as Styled from './ReportProblem.styled';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { Pivot, PivotItem } from 'office-ui-fabric-react';
import { fetchTickets } from './ReportProblem.actions';
import { SubmitTicket } from './SubmitTicket/SubmitTicket';
import { fetchTicketsSelector, openTicketsLoadingStatusSelector } from './ReportProblem.selector';
import { ITicketDetailModel } from './Models/ITicketDetailModel';
import { OpenTicket } from './OpenTicket/OpenTicket';
import { CoherencePanel } from '@cseo/controls';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { SubCapability, EventName, Capability } from '../../../Shared/Types';
import { ErrorScreen } from '../../../Shared/Components/ErrorScreen';
import { LoadingStates } from '../../../Shared/Models/LoadingStates';
import { SplashScreen } from '../../../Shared/Components/SplashScreen';
import { LoadingScreen } from '../../../Shared/Components/LoadingScreen';
import { TelemetryService } from '../../../Shared/shared';
import { IPanelProps } from '../../../Shared/Types/interfaces/IPanelProps';

export const ReportProblem: React.FC<IPanelProps> = (props: IPanelProps) => {
  const { isOpen, backButton } = props;
  const { dispatch, useSelector } = React.useContext(ReduxContext);
  const openTickets: ITicketDetailModel[] = useSelector(fetchTicketsSelector);
  const openTicketsLoadingStatus = useSelector(openTicketsLoadingStatusSelector);
  const { telemetryClient } = React.useContext(ComponentContext);
  const [selectedKey, setSelectedKey] = React.useState(0);

  React.useEffect(() => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.ReportAProblem,
      eventName: EventName.PANEL_OPENED,
    });
    dispatch(fetchTickets());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line myhub-web-lint/check-usage-events
  const handleSubmitClicked = (): void => {
    setSelectedKey((selectedKey + 1) % 2);
  };

  const onLinkClick = (item: PivotItem): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.ReportAProblem,
      eventName: EventName.LINK_CLICKED,
    });
    setSelectedKey(Number(item.props.itemKey));
  };

  // Handles light dismiss.
  const onPanelClosed = (ev?: React.SyntheticEvent<HTMLElement, Event> | undefined): void => {
    TelemetryService.trackEvent({
      capability: Capability.MyHub,
      subCapability: SubCapability.Faq_List,
      eventName: EventName.PANEL_CLOSED,
    });
    if (ev) {
      props.backButton?.onClick();
    }
  };

  return (
    <div>
      <CoherencePanel
        titleText={'Report a Problem'}
        isOpen={isOpen}
        backButton={backButton}
        isLightDismiss={true}
        telemetryHook={telemetryClient}
        isBlocking={false}
        onDismiss={onPanelClosed}
        focusTrapZoneProps={{ forceFocusInsideTrap: false }}
        closeButtonAriaLabel="Close"
      >
        <Pivot styles={Styled.PivotStyles} selectedKey={selectedKey.toString()} onLinkClick={onLinkClick}>
          <PivotItem headerText="Submit ticket" itemKey="0">
            <SubmitTicket onClicked={handleSubmitClicked} />
          </PivotItem>

          <PivotItem headerText="Open tickets" itemKey="1">
            {((): React.ReactElement => {
              if (openTicketsLoadingStatus === LoadingStates.FAILED) {
                return <ErrorScreen innerPageUse={true} />;
              } else if (openTicketsLoadingStatus === LoadingStates.STARTED) {
                return <LoadingScreen innerPageUse={true} />;
              } else if (openTickets && !openTickets.length) {
                return <SplashScreen imagePath="/assets/Shared/Panel/Empty.svg" title="No open tickets found" />;
              } else {
                return (
                  <Styled.Container>
                    {openTickets.map((item) => (
                      <OpenTicket key={item.sys_id} item={item} />
                    ))}
                  </Styled.Container>
                );
              }
            })()}
          </PivotItem>
        </Pivot>
      </CoherencePanel>
    </div>
  );
};
