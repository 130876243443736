import { IBookmarksModel } from '../Models/IBookmarksModel';

export enum ActionTypes {
  ADD_BOOKMARK = 'ADD_BOOKMARK',
  ADD_BOOKMARK_SUCCEEDED = 'ADD_BOOKMARK_SUCCEEDED',
  ADD_BOOKMARK_FAILED = 'ADD_BOOKMARK_FAILED',
  REMOVE_BOOKMARK = 'REMOVE_BOOKMARK',
  REMOVE_BOOKMARK_SUCCEEDED = 'REMOVE_BOOKMARK_SUCCEEDED',
  REMOVE_BOOKMARK_FAILED = 'REMOVE_BOOKMARK_FAILED',
  RECEIVE_BOOKMARKS_DATA_FAILED = 'RECEIVE_BOOKMARKS_DATA_FAILED',
  RECEIVE_BOOKMARKS_DATA_SUCCEEDED = 'RECEIVE_BOOKMARKS_DATA_SUCCEEDED',
  REQUEST_BOOKMARKS_DATA = 'REQUEST_BOOKMARKS_DATA',
}

export interface IAddBookmarkAction {
  type: ActionTypes.ADD_BOOKMARK;
  bookmark: IBookmarksModel;
}

export interface IAddBookmarkSucceededAction {
  type: ActionTypes.ADD_BOOKMARK_SUCCEEDED;
}

export interface IAddBookmarkFailedAction {
  type: ActionTypes.ADD_BOOKMARK_FAILED;
}

export interface IRemoveBookmarkAction {
  type: ActionTypes.REMOVE_BOOKMARK;
  bookmarkId: string;
}

export interface IRemoveBookmarkFailedAction {
  type: ActionTypes.REMOVE_BOOKMARK_FAILED;
}

export interface IRemoveBookmarkSucceededAction {
  type: ActionTypes.REMOVE_BOOKMARK_SUCCEEDED;
}

export interface IReceiveBookmarksDataFailedAction {
  type: ActionTypes.RECEIVE_BOOKMARKS_DATA_FAILED;
}

export interface IReceiveBookmarksDataSucceededAction {
  type: ActionTypes.RECEIVE_BOOKMARKS_DATA_SUCCEEDED;
  bookmarks: IBookmarksModel[];
}

export interface IRequestBookmarksDataAction {
  type: ActionTypes.REQUEST_BOOKMARKS_DATA;
}

export type IBookmarkAction =
  | IAddBookmarkAction
  | IAddBookmarkFailedAction
  | IAddBookmarkSucceededAction
  | IRemoveBookmarkAction
  | IRemoveBookmarkSucceededAction
  | IRemoveBookmarkFailedAction
  | IReceiveBookmarksDataFailedAction
  | IReceiveBookmarksDataSucceededAction
  | IRequestBookmarksDataAction;
