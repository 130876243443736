function daysToSeconds(days: number): number {
    return days * 24 * 60 * 60;
}

/**
 * Campaign definitions define a survey campaign to run
 * For building your own survey, see https://petrolapi.azurewebsites.net/docson/index.html#../schemas/CampaignDefinitionsSchema.json
 * (you may have to click the link twice due to authentication issues)
 */
const campaignDefinitions: object[] = [
    {
        CampaignId: __OCV__.campaignId, // Assigned by OCV
        StartTimeUtc: '2019-01-17T00:00:00Z', // If null, the campaign is inactive
        GovernedChannelType: 0,
        NominationScheme: {
            Type: 0, // Default
            PercentageNumerator: __ENVIRONMENT__ === 'development' ? 100 : 50, // Ratio of users to be nominated to be a candidate numerator
            PercentageDenominator: 100, // Ratio of users to be nominated to be a candidate denominator
            NominationPeriod: {
                Type: 0, // Default
                IntervalSeconds: daysToSeconds(30) // Re-evalatue nomination after 30 days, NOT used when ratio is 100%
            },
            CooldownPeriod: {
                Type: 0, // Default
                IntervalSeconds: daysToSeconds(45) // Will give users a survey every 45 days
            },
            FallbackSurveyDurationSeconds: daysToSeconds(5)
        },
        SurveyTemplate: {
            Type: 2, // NPS Template, can be NPS 5pts (0), NPS 11pts (1), FPS (2), NLQs (3), NPS (4), or GenericMessaging Survey (22)
            // See above link for details
            ActivationEvent: {
                Type: 0, // 0 For one event, 1 for multiple events (array of events)
                Activity: 'SurveyActivityTriggered',
                Count: 2, // triggers on second session
                IsAggregate: true
            },
            Content: {
                Prompt: {
                    Title: "We'd love your feedback!",
                    Question: 'We just have two questions for you.',
                    YesLabel: 'Yes',
                    NoLabel: 'No'
                },
                Rating: {
                    IsZeroBased: false,
                    Question: 'How satisfied are you with MyHub?',
                    RatingValuesAscending: [
                        '1 = Extremely dissatisfied',
                        '2 = Somewhat dissatisfied',
                        '3 = Neither satisfied nor dissatisfied',
                        '4 = Somewhat satisfied',
                        '5 = Extremely satisfied'
                    ]
                },
                Question: {
                    Question: 'We’d like to understand more. Please tell us why you gave this satisfaction rating.'
                }
            }
        }
    }
];

export default campaignDefinitions;
